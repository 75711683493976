import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
import productPriceSchema from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
// import Input from '../../../../../../core/widgets/form/input'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
// import Button from '../../../../../../core/widgets/buttons/Button'
import { updateProductPrice, postProductPrice, updateUserRole } from '../../api/actions'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import schemaRole from './schema'
import { updateUserProfile } from '../../../profiles/api/actions'
import { Heading, Button, Flex, VStack, Box } from '@chakra-ui/react'

import Select from '../../../../../../ui/molecules/Select'

const roleOptions = [
	{ value: 'user', label: 'User' },
	{ value: 'editor', label: 'Editor' },
	{ value: 'admin', label: 'Admin' },
]

const RoleForm = ({ data, onSubmitted }) => {
	const dispatch = useDispatch()

	/** History hook */
	const navigate = useNavigate()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaRole),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (vals) => {
		/** Construct our new object */
		const model = { ...data, ...vals }
		if (model.id) {
			dispatch(updateUserRole(model))
		}

		//trigger submit callback if any
		onSubmitted != undefined && onSubmitted()
	}

	console.log('data, values', data, values, formState)

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box w="full" backgroundColor="#F2F6FA" borderRadius="md" p="1rem" mb="1rem">
				<Flex justify="space-between" alignItems="center" mb="1rem">
					<Flex>
						<Heading as="h4" size="sm">
							Change Role
						</Heading>
					</Flex>
					<Flex>
						<Button type="submit" disabled={formState.isSubmitting} maxH="2rem" size="sm">
							Save
						</Button>
					</Flex>
				</Flex>
				<VStack spacing="1rem" w="full">
					<Select
						control={control}
						errors={errors}
						name="role"
						defaultValue={values.role || ''}
						options={roleOptions.map((el, i) => (
							<option key={i} value={el.value}>
								{el.label}
							</option>
						))}
					/>
				</VStack>
			</Box>
		</form>
	)
}

RoleForm.propTypes = {}

export default RoleForm
