import React from 'react' 
import {StyledButton} from './styles'


/**
 * Default Button component
 * @param {*} 
 * @returns 
 */
const Button = ({children, ...rest}) => {
    return <StyledButton {...rest}>{children}</StyledButton>
}

export default Button;