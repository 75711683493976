/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import loadable from '@loadable/component'
import styled from 'styled-components'
// import useAuthedUser from '../hooks/useAuthedUser'
import useCompatibilityHook from './hooks/useCompatibilityHook'
// import ProtectedRoute from '../views/utils/ProtectedRoute'
import UnderConstruction from '../views/utils/UnderConstruction'
// import AppView from '../components/AppView'
import Navigation from '../components/Navigation'
import RightPanel from '../interface/components/RightPanel'
import BottomPanel from '../interface/components/BottomPanel'
import SecondaryNavigation from '../interface/components/SecondaryNavigation'
import { AppRoot, AppView } from '../interface'
import { Router as UserRouter } from '../apps/user'
import { Router as ViddayAppRouter } from '../apps/vidday.app'
import { Router as CoreRouter } from '../apps/core'
import { useAuthContext } from '../contexts/AuthProvider'
import AdminProtectedRoute from '../routes/AdminProtectedRoute'
// import Core from '../core'
// import apps from '../apps/index'
// import Router from '../apps/routes'

/* **************************************
 *  AppLayout
 * ************************************ */

const AppLayout = (props) => {
	/** Retrieve Authenticated user */
	// const authedUser = useAuthedUser()
	// const {
	// 	isAuthenticated,
	// 	isAuthenticating,
	// 	user: { role },
	// } = authedUser
	const { isAuthenticated, isAuthenticating, role } = useAuthContext()
	const location = useLocation()
	console.log('location AppLayout', location, isAuthenticated, isAuthenticating, role)

	const isCompatible = useCompatibilityHook()

	// const dispatch = useDispatch()
	// const navigate = useNavigate()
	// const location = useLocation()
	// useEffect(() => {
	// 	console.log(location, "location AppLayout")
	// 	if(location.pathname != '/auth/login' && !isAuthenticating){
	// 		!isAuthenticated && role != 'admin' && location.pathname != '/auth/login' && history.push('/auth/login')
	// 	}
	// }, [isAuthenticated, isAuthenticating, role, location])

	return (
		<AppRoot id="app-layout">
			<MetaTags>
				<title>VidDay - Admin</title>
				<meta
					name="description"
					content="The easy way to make a video montage to give as gift. Bring friends and family together to make a surprise video montage for birthdays, weddings, retirements, anniversaries and more. Create a video slideshow online to send as a meaningful and memorable video gift. Try VidDay's group video maker for free, celebrate any special occasion."
				/>

				<link rel="apple-touch-icon" sizes="180x180" href="/assets/images/fav/apple-touch-icon.png" />
				<link rel="icon" type="image/png" href="/assets/images/fav/favicon-32x32.png" sizes="32x32" />
				<link rel="icon" type="image/png" href="/assets/images/fav/favicon-16x16.png" sizes="16x16" />
				<link rel="mask-icon" href="/assets/images/fav/safari-pinned-tab.svg" color="#f5706c" />
				<link rel="shortcut icon" href="/assets/images/fav/favicon.ico" />
				<meta name="msapplication-config" content="/assets/images/fav/browserconfig.xml" />
				<meta name="theme-color" content="#ffffff" />

				<meta property="fb:app_id" content="1826421140802874" />
				<meta property="og:type" content="product" />
				<meta property="og:title" content="VidDay - The Group Video Gift Maker" />
				<meta property="og:url" content="https://www.vidday.com/" />
				<meta property="og:video" content="https://www.youtube.com/embed/18rUN2i0o2Y" />
				<meta property="og:image" content="/assets/images/Vidday-The-Surprise-Video-Gift_og.jpg" />
				<meta
					property="og:description"
					content="The easy way to make a video montage to give as gift. Bring friends and family together to make a surprise video montage for birthdays, weddings, retirements, anniversaries and more. Create a video slideshow online to send as a meaningful and memorable video gift. Try VidDay's group video maker for free, celebrate any special occasion."
				/>
				<meta property="product:price:amount" content="12" />
				<meta property="product:price:currency" content="USD" />

				<link rel="canonical" href="https://www.vidday.com" />

				<meta name="rating" content="general" />
				<meta name="Classification" content="Greeting cards" />
				<meta name="category" content="general" />
				<meta name="subject" content="Group Video Gift Maker" />
				<meta name="coverage" content="Worldwide" />
				<meta name="distribution" content="Global" />
				<meta
					name="norton-safeweb-site-verification"
					content="k1k3wvdopwddb4oft67k7yb0-7thttw7v5irkprflpznk8jrja5fqc6f4mq6mcwppe72kok8d79ahqb0-d2jp3tmipd7vpc6jf9lpitq4130pvrn4vxfz8jowmplnd3l"
				/>
				<meta name="HandheldFriendly" content="true" />
				<meta name="MobileOptimized" content="320" />
			</MetaTags>

			{/* <RightPanel id="RightPanel" />
			<BottomPanel id="BottomPanel"/> */}

			<AppView>
				{!isCompatible && '' /*incompatibleBrowserModal()*/}
				{isCompatible && (
					<Routes>
						<Route index element={() => <>Index page</>} />
						<Route path="user/*" element={<UserRouter />} />
						<Route path="vidday/*" element={<ViddayAppRouter />} />
						<Route path="core/*" element={<CoreRouter />} />
					</Routes>
				)}
			</AppView>

			{/* <SecondaryNavigation /> */}
			<Navigation />
		</AppRoot>
	)
}

export default AppLayout //hot(AppLayout)
