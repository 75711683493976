import io from 'socket.io-client'

const socket = io({
	reconnectionDelay: 500, // ms (shortened from default of 1000)
	reconnectionDelayMax: 3000, // ms (shortened from default of 5000)
	timeout: 10000, // ms (should somewhat match serverside one)
	transports: ['websocket'],
})

export default socket
