import React from 'react'
import Actions from './tabs/Actions'
// import Edit from './tabs/Edit'
// import Details from './tabs/Details'
import Status from './tabs/Status'
import Notes from './tabs/Notes'
import MediaTools from './tabs/MediaTools'
import Automation from './tabs/Automation'

/**
 * Define our sub-row view tabs and corresponding sub-views
 */
const tabs = [
	{
		id: 'actions',
		title: 'Actions',
		view: Actions,
	},
	// {
	//     id:'details',
	//     title:'Details',
	//     view: Details,
	// },
	// {
	//     id:'edit',
	//     title:'Edit',
	//     view: Edit,
	// },
	{
		id: 'media',
		title: 'Media',
		view: MediaTools,
	},
	/** Render jobs */
	{
		id: 'automation',
		title: 'Automation',
		view: Automation,
	},
	{
		id: 'details',
		title: 'Details',
		view: (props) => (
			<>
				<Status {...props} />
				<Notes {...props} />
			</>
		),
	},
]

export default tabs
// /**
//  * Define our sub-row view tabs and corresponding sub-views
//  */
//  const tabs = [
//     {
//         id:'actions',
//         title:'Actions',
//         view: Actions,
//     },

// ]
