const Link = {
	variants: {
		link: {
			fontWeight: 'bold',
			color: 'link',
		},
	},
}

export default Link
