/* eslint-disable no-console */
/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { Component, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import * as Sentry from '@sentry/react'
import loadable from '@loadable/component'

import { css, createGlobalStyle } from 'styled-components'
import { LocalizationProvider } from '@material-ui/pickers'
// pick an adapter for your date library
import MomentUtils from '@material-ui/pickers/adapter/moment'
import { Globals } from '../styles/Globals'
import { BaseStyle } from '../styles/Base'
import { ResetStyle, NormalizeStyle } from '@bit/vidday.vidday-ui.styles.lib' // GlobalStyle
import { BaseStyles as MomentDatePickerStyles } from '@bit/vidday.vidday-ui.date-time-picker'
import { ReactTableStyles } from '@bit/vidday.vidday-ui.styles.external.react-table'
import { cf } from '@bit/vidday.vidday-ui.mixins.cf'
import { radius } from '@bit/vidday.vidday-ui.mixins.radius'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const AdminStyles = createGlobalStyle`
	ul,
	body ul {
		margin: 0 0;

		a, li {
			margin: 0 0;
		}
	}

	p, a, li {
        margin: 0 0 ;
    }

	h1 {}
	button {}
	
`

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import { stripe as stripeConfig } from '../../config.public'
import useRootSetup from './hooks/useRootSetup'

// const RootLayout = loadable(() => import(/* webpackPrefetch: true */ './RootLayout'))
// const PageLoader = loadable(() => import(/* webpackPrefetch: true */ '../views/utils/PageLoader'))
import RootLayout from './RootLayout'
import PageLoader from '../views/utils/PageLoader'
import AuthProvider from '../contexts/AuthProvider'

import { ChakraProvider, useToast, ColorModeScript } from '@chakra-ui/react'
import theme from '../ui/theme'
import ToastContainer from '../ui/organisms/ToastContainer'
// console.log(RootLayout, "root layout", PageLoader, "page loader")
/**
 * Stripe response
 */
// const stripePromise = loadStripe(stripeConfig.publishableKey)

/**
 * Root Component
 * @param {*} props
 */
const Root = (props) => {
	/** some extra settings during setup */
	useRootSetup()

	return (
		<Sentry.ErrorBoundary fallback={<PageLoader />} showDialog>
			<div>
				{/* <Globals />
				<ResetStyle />
				<NormalizeStyle />
				<BaseStyle />
				<MomentDatePickerStyles /> */}
				<ReactTableStyles />
				<AdminStyles />
				{/* <Elements stripe={stripePromise}> */}

				<ColorModeScript initialColorMode={theme.config.initialColorMode} />
				<ChakraProvider theme={theme}>
					<LocalizationProvider dateAdapter={MomentUtils}>
						<AuthProvider>
							<ToastContainer />
							<Routes>
								<Route path="*" element={<RootLayout />} />
							</Routes>
						</AuthProvider>
						{/* <Route component={RootLayout} /> */}
					</LocalizationProvider>
				</ChakraProvider>
				{/* </Elements> */}
			</div>
		</Sentry.ErrorBoundary>
	)
}

Root.propTypes = {}

export default Sentry.withProfiler(Root) // Attach Sentry profile to top most component
