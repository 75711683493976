import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../core/widgets/form/input'
import { useDispatch } from 'react-redux'

import SubmitButton from '@bit/vidday.vidday-ui.ui.submit-button'
import styled, { css } from 'styled-components'
import { updateEvent } from '@bit/vidday.vidday-admin-store.modules.events'

import { postPublishedMedia } from '@bit/vidday.vidday-admin-store.modules.media'
import Button from '../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SaveBar from '../../../../../core/widgets/others/SaveBar'
/** Define initial Values */
const initialValues = { notes: '' }

const MediaUrl = ({ row }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: initialValues,
		//resolver: yupResolver(schemaPromoCode),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (data) => {
		console.log(data)

		let postData = {
			eventId: row.original.id, // data.key || data.id,
			uuid: row.original.uuid,
			url: data.url, //formData.get('url'), // test with these animoto urls 'https://d2m23yiuv18ohn.cloudfront.net/Video/q6NDuhKeI61S4DvURJyp4A/360p.mp4' //'https://d2m23yiuv18ohn.cloudfront.net/Video/XewH3o7RlfiNY8easrbznw/1080p.mp4' //'https://d2m23yiuv18ohn.cloudfront.net/Video/XewH3o7RlfiNY8easrbznw/360p.mp4' https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4
		}

		dispatch(postPublishedMedia(postData))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Media URL"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Input
					size="small"
					control={control}
					margin="none"
					name="url"
					id="url"
					variant="filled"
					label="Media URL File"
					errors={errors}
					placeholder={''}
					helperText={'(This will overwrite current VidDay)'}
				/>
			</InlineItems>
		</form>
	)
}

MediaUrl.defaultProps = {
	/** Default text for submit button */
	submitText: 'Apply Code',
}

MediaUrl.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
	/** Promotion */
	promotion: PropTypes.object.isRequired,
}

export default MediaUrl
