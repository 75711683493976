import { createSlice } from '@reduxjs/toolkit'

import { USERS } from './constants'
import { USER_PROFILES } from '../../profiles/api/constants'

const initialState = {
	data: null,
	ids: [],
	isFetching: false,
	hasError: false,
	errorMessage: '',
	/** publishing */
}

const UPDATE_USER = (state, action) => {
	if (action.payload && action.payload.entities && action.payload.entities.users) {
		var item = state.data[action.payload.result[0]]
		// If we found an item, update it
		if (item) {
			state.data[action.payload.result[0]] = action.payload.entities.users[action.payload.result[0]]
		}
	}
}

const usersSlice = createSlice({
	initialState: initialState,
	name: 'users',
	extraReducers: {
		[USERS.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[USERS.FETCH_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities) {
				state.data = action.payload.entities.users || {}
				state.ids = action.payload.result
				state.isFetching = false
				state.hasError = false
			}
		},

		[USERS.FETCH_FAILED]: (state, action) => {
			state.hasError = true
			state.isFetching = false
			state.errorMessage = 'An error occured while fetching.'
		},

		[USERS.POST_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities && action.payload.entities.users) {
				state.data[action.payload.result[0]] = action.payload.entities.users[action.payload.result[0]]
			}
		},

		[USERS.UPDATE_SUCCEEDED]: UPDATE_USER,
		[USERS.UPDATE_ROLE_SUCCEEDED]: UPDATE_USER,

		[USERS.DELETE_SUCCEEDED]: (state, action) => {
			var item = state.data[action.payload.data.id]
			// If we found an item, update it
			if (item) {
				delete state.data[action.payload.data.id]
			}
		},

		[USER_PROFILES.UPDATE_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.data && action.payload.data.userId) {
				var userId = action.payload.data.userId
				var item = state.data[userId]
				// If we found an item, update it
				if (item) {
					state.data[userId].profile = action.payload.data
				}
			}
		},
	},
	reducers: {},
})

export default usersSlice.reducer
