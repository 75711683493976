

import * as Yup from 'yup'

const schemaLanguage = Yup.object().shape({
    code: Yup.string().required(),
    label: Yup.string().required(),
    nativeName: Yup.string().required(),
})

export default schemaLanguage
