import React, { useState, useEffect, useReducer, useCallback } from 'react'

/** Icons */
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

/** Import our table component */
import FactoryTable from '../../../../../core/widgets/table/FactoryTableSS'
import { FiltersWrapper } from '../../../../../core/widgets/styles'
import FilterDisplayMode from '../../../../../core/widgets/filters/FilterDisplayMode'
import FilterDropdown, { FilterDropdownV2 } from '../../../../../core/widgets/filters/FilterDropdown'
import eventTypes from '@bit/vidday.vidday-data.data.event-types'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Container, BottomPanel, RightPanel } from '../../../../../interface'
import { HR } from '../../../../../core/widgets/styles'
import { fetchUsers } from '../api/actions' // from '@bit/vidday.vidday-admin-store.modules.users'
import { values } from 'lodash'
import columns from './columns'
import tabs from './tabs'
import { useSearchEngine, serialize, extractKeys } from '../../../../../core'
import SearchEngineProvider, { useSearchEngineContext } from '../../../../../contexts/SearchEngineProvider'
import FilterSearchInput from '../../../../../core/widgets/filters/FilterSearchInput'
import { Flex, Box, Heading } from '@chakra-ui/react'
import VerticalDivider from '../../../../../core/widgets/filters/VerticalDivider'
import TableProvider from '../../../../../contexts/TableProvider'
import TableFilters from '../../../../../core/widgets/table/TableFilters'
import TableResults from '../../../../../core/widgets/table/TableResults'
import Table from '../../../../../core/widgets/table/Table'

const getUsers = (s) => {
	var items = s.entities.users.data || {}
	let collection = []
	collection = values(items)
	return collection
}

const roles = [
	{ value: 'admin', label: 'Administrators' },
	{ value: 'user', label: 'Users' },
	{ value: 'editor', label: 'Editors' },
	{ value: 'guest', label: 'Guests' },
]

const socialPlatforms = [
	{ value: 'basic', label: 'Basic Process' },
	{ value: 'facebookId', label: 'Facebook' },
	{ value: 'googleId', label: 'Google' },
]

const statuses = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' },
]

const ListView = (props) => {
	const dispatch = useDispatch()

	return (
		<Container>
			<Flex w="full" py="1rem" justifyContent="space-between">
				<Heading variant="hero" size="lg" as="h1">
					Users
				</Heading>
				<Box maxW="400px" minW="300px">
					<FilterSearchInput label="Search Users..." tags={['email', 'id']} />
				</Box>
			</Flex>

			<Box w="full" bg="#F2F6FA" borderRadius="base" mb="0.5rem">
				<Flex justifyContent="space-between">
					<Flex>
						<FilterDisplayMode current="table" />
					</Flex>
					<Flex>
						<FilterDropdownV2 collection={roles} label="Role" filter="role" />
						<VerticalDivider />
						<FilterDropdownV2 collection={socialPlatforms} label="Medium" filter="medium" />
						<VerticalDivider />
						<FilterDropdownV2 collection={statuses} label="Status" filter="status" />
					</Flex>
				</Flex>
			</Box>
			<TableFilters />
			<TableResults />
			<Table tabs={tabs} />
			{/* <TableFiltersV2 /> */}
			{/* <TableResultsV2 />
			<TableV2 tabs={tabs} /> */}

			{/* <HR />
			<FiltersWrapper>
				<div className="left">
					<FilterDisplayMode current="table" />
				</div>
				<div className="right">
					<FilterDropdownV2 collection={roles} label="Role" filter="role" />
					<FilterDropdownV2 collection={socialPlatforms} label="Medium" filter="medium" />
					<FilterDropdownV2 collection={statuses} label="Status" filter="status" /> */}

			{/* 
                    <FilterDropdown label="Role" useCheckbox collection={roles} current={roles[0]}/>
                    <FilterDropdown label="Medium" collection={socialPlatforms} current={socialPlatforms[0]} />
                    <FilterDropdown collection={statuses} current={statuses[0]}/> */}
			{/* </div>
			</FiltersWrapper> */}
			{/* <pre
                style={{
                fontSize: '10px',
                }}
            >
                <code>{JSON.stringify(state, null, 2)}</code>
            </pre>

            <pre
                style={{
                fontSize: '10px',
                }}
            >
                
                <code>{serialize(extractKeys(state))}</code>
            </pre> */}

			{/* <FactoryTable instance={searchEngine} data={collection} tabs={tabs} columns={columns} hiddenColumns={[]} /> */}

			<RightPanel>
				<h1>Filters</h1>
			</RightPanel>

			<BottomPanel>
				<h1>More info</h1>
			</BottomPanel>
		</Container>
	)
}

/**
 * Setup Search Engine Context
 */
const ListViewRoot = () => {
	const config = {
		action: fetchUsers,
		slice: 'users',
		actionPrefix: 'USERS',
	}

	return (
		<SearchEngineProvider config={config}>
			<TableProvider columns={columns} hiddenColumns={[]}>
				<ListView />
			</TableProvider>
		</SearchEngineProvider>
	)
}

export default ListViewRoot
