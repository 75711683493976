

import * as Yup from 'yup'

const schemaRegion = Yup.object().shape({
    code: Yup.string().required(),
    countryCode: Yup.string().required(),
    name: Yup.string().required(),
    abbr: Yup.string().notRequired(),
})

export default schemaRegion
