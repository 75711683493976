import * as Yup from 'yup'

export const typeOptions = [
	{ value: 'promotion', label: 'Promotion' },
	{ value: 'maintenance', label: 'Maintenance' },
]

export const isEnabledOptions = [
	{ value: true, label: 'Enabled' },
	{ value: false, label: 'Disabled' },
]

const schemaAnnouncement = Yup.object().shape({
	type: Yup.string().required().oneOf(['promotion', 'maintenance']),
	enabled: Yup.boolean().required(),
	message: Yup.string().required(),
	// timezone: Yup.string().required(),
	starts: Yup.string().required(),
	ends: Yup.string().required(),
})

export default schemaAnnouncement
