import React from 'react'
import { Logo, Seperator, AlignLeft, AlignRight, FixRight, StyledOverflowY } from './styles'
import apps from '../../apps'
import Avatar from '@material-ui/core/Avatar'
import useDimensions from 'react-use-dimensions'

/** Import Search Bar */
import Search from './Search'
import AppSelector from './AppSelector'
import ModuleNav from './ModuleNav'

import StarsRoundedIcon from '@mui/icons-material/StarsRounded'
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded'
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'

import { useDispatch } from 'react-redux'
import { NavigationPanel, useInterfacePanel, SecondaryNavigation } from '../../interface'

import { navigationItems as homeAppItems } from '../../apps/vidday.home'
import { navigationItems as viddayAppItems } from '../../apps/vidday.app'
import { navigationItems as coreItems } from '../../apps/core'
import instances from '../../apps'
import useApps from '../../core/hooks/useApps'

const servicesList = [
	{ title: 'Market Place', Icon: <StarsRoundedIcon /> },
	// { title: 'Affiliates', Icon: <RecordVoiceOverRoundedIcon /> },
	// { title: 'B2B', Icon: <BusinessRoundedIcon /> },
]

// console.log(coreItems, "core items")

const OverflowY = ({ children }) => {
	const [ref, size] = useDimensions()
	console.log(ref, size)
	return <StyledOverflowY ref={ref}>{children}</StyledOverflowY>
}

const Navigation = () => {
	const dispatch = useDispatch()
	const { togglePanel } = useInterfacePanel()

	const { registeredApps, currentAppId } = useApps()

	console.log(currentAppId, 'currentapp id', registeredApps)
	let currentNav

	if (currentAppId === 'vidday') {
		currentNav = viddayAppItems
	} else if (currentAppId === 'home') {
		currentNav = homeAppItems
	}

	console.log(currentNav, 'current nav', currentAppId)

	return (
		<NavigationPanel>
			<Seperator
				css={`
					min-height: 40px;
				`}>
				<Logo />
				<FixRight>
					<a onClick={() => togglePanel('secondary')}>
						<Avatar src="/assets/images/admin/temp/temp.jpg" />
					</a>
				</FixRight>
			</Seperator>
			<Seperator
				css={`
					min-height: 40px;
					display: inline-block;
				`}>
				<Search />
			</Seperator>

			<AppSelector activeId={currentAppId} apps={registeredApps} />
			{/* <OverflowY> */}
			{currentAppId && <ModuleNav list={currentNav} />}
			<ModuleNav section="general" list={coreItems} />
			<ModuleNav section="services" list={servicesList} />
			{/* </OverflowY> */}
			<SecondaryNavigation>
				<h1>Search</h1>
			</SecondaryNavigation>
		</NavigationPanel>
	)
}

export default Navigation
