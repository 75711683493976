import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
import taxCodeRateSchema from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../../core/widgets/form/input'
import Select from '../../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../../core/widgets/buttons/Button'
import { updateTaxCodeRate, postTaxCodeRate } from '../../api/actions'

const getCountries = (s) => {
	var items = s.entities.countries.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const getTaxCodeCategories = (s) => {
	var items = s.entities.taxCodeCategories.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const TaxCodeRateForm = ({ data, onSubmitted }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	const countries = useSelector((s) => getCountries(s))
	const taxCodeCategories = useSelector((s) => getTaxCodeCategories(s))

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(taxCodeRateSchema),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		console.log(data, values, 'submit tax code rate')
		/** Construct our new object */
		const model = { ...data, ...values }

		if (model.id) {
			dispatch(updateTaxCodeRate(model))
		} else {
			dispatch(postTaxCodeRate(model))
			reset()
		}

		onSubmitted != undefined && onSubmitted()
	}

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	const status = [
		{ value: true, label: 'YES, inclusive of tax' },
		{ value: false, label: 'No, exclusive of tax' },
	]

	const countryOptions = []
	countries.forEach((el) => countryOptions.push({ value: el.alpha2Code, label: el.name }))

	const taxCodeCategoryOptions = []
	taxCodeCategories.forEach((el) =>
		taxCodeCategoryOptions.push({ value: el.id, label: '[' + el.zone + '] ' + el.name + ' (' + el.code + ')' })
	)

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Tax Code Rate Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					margin="normal"
					name="categoryId"
					id="categoryId"
					variant="filled"
					label="Category"
					errors={errors}>
					{taxCodeCategoryOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="rate"
					id="rate"
					variant="filled"
					label="Rate (%)"
					errors={errors}
				/>

				<Select
					control={control}
					margin="normal"
					name="countryCode"
					id="countryCode"
					variant="filled"
					label="Country"
					errors={errors}>
					{countryOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					margin="normal"
					name="inclusive"
					id="inclusive"
					variant="filled"
					label="Is Tax inclusive"
					errors={errors}>
					{status.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>
			</InlineItems>
		</form>
	)
}

TaxCodeRateForm.defaultProps = {
	/** Important */
	data: {
		categoryId: null,
		rate: null,
		countryCode: null,
		inclusive: null,
	},
}

TaxCodeRateForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default TaxCodeRateForm
