import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { productListSchema } from './normalizr'
import requests from './requests'
import { PRODUCTS } from './constants'
import { PRODUCT_PRICES } from '../../productPrices/api/constants'

/**
 * Users Saga worker
 * Fetch products
 * @param {*} action 
 */
function* handleFetchProducts(action) {
    try {
        const response = yield call(requests.fetchProducts, action.payload)
        const normalizedData = normalize(response.data.data, productListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: PRODUCTS.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: PRODUCTS.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a product
 * @param {*} action 
 */
 function* handlePostProduct(action) {
    try {
       
        const response = yield call(requests.postProduct, action.payload)
        const normalizedData = normalize([response.data.data], productListSchema)
        yield put({ type: PRODUCTS.POST_SUCCEEDED, payload:normalizedData})
    }
    catch(e){
        yield put({type: PRODUCTS.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a product
 * @param {*} action 
 */
 function* handleUpdateProduct(action) {
    try {
        const response = yield call(requests.updateProduct, action.payload)
        
        const normalizedData = normalize([response.data.data], productListSchema)
        console.log(response.data)
        yield put({ type: PRODUCTS.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: PRODUCTS.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a product
 * @param {*} action 
 */
 function* handleDeleteProduct(action) {
    try {
        const response = yield call(requests.deleteProduct, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: PRODUCTS.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: PRODUCTS.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchProductsFetch(){
    // while(true){
        yield takeLatest(PRODUCTS.FETCH_REQUESTED, handleFetchProducts)
    // }
}

export function* watchUpdateProduct(){
    // while(true){
        yield takeEvery(PRODUCTS.UPDATE_REQUESTED, handleUpdateProduct)
    // }
}

export function* watchPostProduct(){
    // while(true){
        yield takeEvery(PRODUCTS.POST_REQUESTED, handlePostProduct)
    // }
}

export function* watchDeleteProduct(){
    // while(true){  
        yield takeEvery(PRODUCTS.DELETE_REQUESTED, handleDeleteProduct)
    // }
}