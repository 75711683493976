const Modal = {
	parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
	baseStyle: {
		overlay: {
			bg: 'blackAlpha.700',
			backdropFilter: 'blur(10px)',
		},

		dialogContainer: {},
		dialog: {
			borderRadius: '0.5rem',
			p: '2rem',
			mx: ['1rem', 'auto'],
		},
		header: {},
		closeButton: {
			right: '1rem',
			top: '1rem',
			color: 'gray.800',
		},
		body: {
			p: '0',
		},
		footer: {
			pt: '2rem',
			pb: '0',
		},
	},
	sizes: {},
	variants: {},
	defaultProps: {
		size: 'lg',
	},
}

export default Modal
// const sizes = {
// 	xs: getSize("xs"),
// 	sm: getSize("sm"),
// 	md: getSize("md"),
// 	lg: getSize("lg"),
// 	xl: getSize("xl"),
// 	"2xl": getSize("2xl"),
// 	"3xl": getSize("3xl"),
// 	"4xl": getSize("4xl"),
// 	"5xl": getSize("5xl"),
// 	"6xl": getSize("6xl"),
// 	full: getSize("full"),
//   }
