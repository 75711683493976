
import { normalize, schema } from 'normalizr'


const languageSchema = new schema.Entity(
	'languages',
	{
		// regions: [regionSchema],
	},
	{
		idAttribute: (data) => data.code,
	}
)
// Represent all related data coming through the fetch request when loading the user

const languageListSchema = new schema.Array(languageSchema)

export {languageListSchema}