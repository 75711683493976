import React from 'react' 
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

export const StyledSection = styled.span`
    position:relative;
    display:block;
    font-size: 12px;
    color:rgba(255,255,255, 0.7);
    text-transform:uppercase;
    display:block;
    position:relative;
    text-align:left;
    padding:13px 16px;
    background-color:rgba(255,255,255,0.1);

`

export const StyledModuleItem = styled(Link)`
    position:relative;
    padding: 13px 16px;
    font-size:16px;
    display:block;
    color:rgba(255,255,255, 0.8);    
    background-color:transparent;
    font-weight:500;

    svg {
        position:relative;
        top:-4px;
        float:left;
        margin-right:10px;
    }

    &:hover {
        cursor:pointer;
        background-color:rgba(255,255,255,0.1);
        color:white;
        svg {
            color:white;
        }
    }

    ${(props) => props.isActive && css`
        cursor:default;    
        background-color:#0187FF;
        color:white;
        svg {
            color:white;
        }

        &:hover {
            background-color:#0187FF;
            color:white;
            cursor:default;
            svg {
                color:white;
            }
        }
    `}

    
`


export const StyledModuleItemChild = styled(Link)`
    position:relative;
    padding: 8px 16px 8px 50px;
    font-size:14px;
    display:block;
    color:rgba(255,255,255, 0.8);    
    background-color:transparent;
    font-weight:400;

    &:hover {
        cursor:pointer;
        ${'' /* background-color:rgba(255,255,255,0.1); */}
        color:#0187FF;
    }

    ${(props) => props.isActive && css`
        cursor:default;    
        background-color:#0187FF;
        color:white;
        svg {
            color:white;
        }

        &:hover {
            background-color:#0187FF;
            color:white;
            cursor:default;
            svg {
                color:white;
            }
        }
    `}

    
`


export const StyledModuleNav = styled.ul`
    list-style-type:none;
    display:block;
    position:relative;    
    clear:both;
    overflow:visible;
    text-align:left;

    li{
        display:block;
    }

    > ul {
        > li {
            display:block;
        }
    }
`
