
import { normalize, schema } from 'normalizr'


const nexusSchema = new schema.Entity(
	'nexus',
	{},
	{
		idAttribute: (data) => data.id,
	}
)
// Represent all related data coming through the fetch request when loading the user

const nexusListSchema = new schema.Array(nexusSchema)

export {nexusListSchema}