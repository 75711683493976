import React from 'react'
/**
 * Define our Table view columns
 */
 const columns = [
    {
        id: 'flag',
        width: 50,
        Header: 'Flag',
        accessor: 'flag', // String-based value accessors!
        Cell: ({row}) => {
            return (<div><img height="15px" width="20px" src={row.original.flag} /></div>)
        },
    },
    {
        id: 'alpha2Code',
        width: 50,
        Header: 'Alpha Code',
        accessor: 'alpha2Code', // String-based value accessors!
        Cell: ({row}) => <strong>{row.original.alpha2Code}</strong>,
    },
    {
        id: 'alpha3Code',
        width: 50,
        Header: 'Alpha Code (3)',
        accessor: 'alpha3Code', // String-based value accessors!
        Cell: ({row}) => <strong>{row.original.alpha3Code}</strong>,
    },

    {
        id: 'name',
        width: 100,
        Header: 'Name',
        accessor: 'name', // String-based value accessors!
    }, 
    
    {
        id: 'defaultCurrencyCode',
        width: 50,
        Header: 'Default Currency',
        accessor: 'defaultCurrencyCode', // String-based value accessors!
        Cell: ({row}) => {
            return (<span className="badge">{row.original.defaultCurrencyCode}</span>)
        },
    }, 

    {
        id: 'allowedCurrencies',
        width: 150,
        Header: 'Allowed Currencies',
        accessor: 'allowedCurrencies', // String-based value accessors!
        Cell: ({row}) => {
            let badges = row.original.allowedCurrencies.map((el, i) => (<span className="badge" key={i}>{el}</span>))
            return badges
        },
    }, 
]

export default columns