import { Router, BrowserRouter } from 'react-router-dom'
import React, { useState, useLayoutEffect } from 'react'

// see: https://stackoverflow.com/questions/69953377/react-router-v6-how-to-use-navigate-redirection-in-axios-interceptor/70012117#70012117
const CustomRouter = ({ history, ...props }) => {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	})

	useLayoutEffect(() => history.listen(setState), [history])

	return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />
}

export default CustomRouter
