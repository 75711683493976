
import { normalize, schema } from 'normalizr'


const themeSchema = new schema.Entity(
	'themes',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

const themeListSchema = new schema.Array(themeSchema)

export {themeListSchema}