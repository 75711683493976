import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { StyledFilterPageNumber } from './styles'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import ReactTooltip from 'react-tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import { HStack, Text, chakra, Icon, useMultiStyleConfig } from '@chakra-ui/react'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'

const StyledInput = styled.input`
	width: 20px;
	border: 1px solid;
	top: 2px;
	position: relative;
	border-radius: 3px;
	text-align: center;
	${(props) =>
		props.hasError &&
		css`
			border-color: #ff1c63;
			color: #ff1c63;
		`}
`
const ErrorWrapper = styled.i`
	position: relative;
	top: 2px;
	margin-right: 4px;
	svg {
		color: #ff1c63;
	}
`

const FilterPageNumber = ({ lastPage, currentPage, prevPage, nextPage, goToPage }) => {
	const styles = useMultiStyleConfig('Filter')

	const inputRef = useRef(null)
	const [isEditing, setIsEditing] = useState(false)
	const [error, setError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const switchEditMode = () => {
		setIsEditing(!isEditing)
	}

	const submitPageChange = (e) => {
		/** Store retrieved value */
		let val = inputRef.current && inputRef.current.value
		/** Make sure we have a value and it's an integer  */
		if (val && !isNaN(parseInt(val))) {
			/** Check if the input is admissible within our range */
			if (parseInt(val) >= 1 && parseInt(val) <= lastPage + 1) {
				/** set new page */
				goToPage(parseInt(val) - 1)
				/** Reset state */
				setError(false)
				setErrorMessage('')
				setIsEditing(false)
			} else {
				setError(true)
				setErrorMessage('The page number is out of range.')
			}
		} else {
			setError(true)
			setErrorMessage('Please indicate a valid integer within allowed range.')
		}
	}

	console.log(currentPage, lastPage, 'lastPage')
	return (
		<StyledFilterPageNumber>
			<span>Page</span>
			{error && (
				<ErrorWrapper>
					<ErrorIcon data-tip={errorMessage} fontSize="small" />{' '}
				</ErrorWrapper>
			)}
			{error && <ReactTooltip place="top" type="error" effect="solid" backgroundColor="#FF1C63" />}
			<ul>
				<li>
					{!isEditing ? (
						<a onClick={() => switchEditMode()}>{currentPage + 1}</a>
					) : (
						<StyledInput
							hasError={error}
							onBlur={submitPageChange}
							ref={inputRef}
							name="editpagenumber"
							defaultValue={currentPage + 1}
						/>
					)}
				</li>
				<li>/</li>
				<li>{lastPage + 1}</li>
			</ul>
			<ul className="buttons">
				<li>
					<a
						className={currentPage == 0 && 'disabled'}
						onClick={currentPage != 0 ? () => goToPage(0) : () => {}}>
						<FirstPageIcon />
					</a>
				</li>
				<li>
					<a
						className={currentPage == 0 && 'disabled'}
						onClick={currentPage != 0 ? () => prevPage() : () => {}}>
						<KeyboardArrowLeftIcon />
					</a>
				</li>
				<li>
					<a
						className={currentPage == lastPage && 'disabled'}
						onClick={currentPage != lastPage ? () => nextPage() : () => {}}>
						<KeyboardArrowRightIcon />
					</a>
				</li>
				<li>
					<a
						className={currentPage == lastPage && 'disabled'}
						onClick={currentPage != lastPage ? () => goToPage(lastPage) : () => {}}>
						<LastPageIcon />
					</a>
				</li>
			</ul>
		</StyledFilterPageNumber>
	)
}

FilterPageNumber.defaultProps = {}

export default FilterPageNumber
