/** Import our instance config */
// import instance from '../../../../../api/config'

import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = ['slug', 'description', 'duration', 'name', 'group']
const acceptedKeysPUT = ['']
const rejectedKeysPUT = ['publishedMedia']

/**
 * Fetch our products
 */
const fetchProducts = async (params) => {
	const response = await instance.get('/products?' + params)
	return response
}

/**
 * Post a product
 * @param {*} data
 * @returns
 */
const postProduct = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/products', filteredData)
	return response
}

/**
 * Update a product
 * @param {*} data
 * @returns
 */
const updateProduct = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.put('/products/' + data.id, filteredData)
	return response
}

/**
 * Delete a product
 * @param {*} id
 * @returns
 */
const deleteProduct = async (id) => {
	const response = await instance.delete('/products/' + id)
	return response
}

const requests = { fetchProducts, postProduct, updateProduct, deleteProduct }

export default requests
