import {createSlice} from '@reduxjs/toolkit'

import { ANNOUNCEMENTS } from './constants'

const initialState = {
    data:null,
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}

const announcementsSlice = createSlice({
    initialState: initialState,
    name:'announcements',
    extraReducers: {
        [ANNOUNCEMENTS.FETCH_REQUESTED]: (state, action) => {
            state.isFetching = true
        },
        [ANNOUNCEMENTS.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.announcements){
                state.data = action.payload.entities.announcements
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        [ANNOUNCEMENTS.FETCH_FAILED]: (state, action) => {
            state.hasError = true
            state.isFetching = false
            state.errorMessage = 'An error occured while fetching.'
        },

        [ANNOUNCEMENTS.UPDATE_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.announcements){
                var item = state.data[action.payload.result[0]]
                if(item){
                    state.data[action.payload.result[0]] = action.payload.entities.announcements[action.payload.result[0]]
                }
            }
        },
        [ANNOUNCEMENTS.POST_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.announcements){
                state.data[action.payload.result[0]] = action.payload.entities.announcements[action.payload.result[0]]
            }
        },

        [ANNOUNCEMENTS.DELETE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                delete state.data[action.payload.data.id]
            }
        },
    },
    reducers:{},
})


export default announcementsSlice.reducer