
import { normalize, schema } from 'normalizr'


// const regionSchema = new schema.Entity(
// 	'regions',
// 	{},
// 	{
// 		idAttribute: (data) => data.id,
// 	}
// )


const announcementSchema = new schema.Entity(
	'announcements',
	{
		// regions: [regionSchema],
	},
	{
		idAttribute: (data) => data.id,
	}
)

const announcementListSchema = new schema.Array(announcementSchema)

export {announcementListSchema}