import styled from 'styled-components'

import {StyledBaseFilter} from '../styles'


export const StyledFilterPageNumber = styled(StyledBaseFilter)`
   
    svg {
        color:#B5BCC3;
    }

    ul {
        font-size:13px;
        li {
            margin-right:5px;
        }

        li a {
            font-weight:500;
            padding:3px 2px;
            color:#0A2239;
        }

        li a:hover {
            cursor:pointer;
            color:#0187FF;
        }
    }

    ul.buttons {
        li {
            margin-right:0;
        }
        li a {
            padding:0px 2px; 
        }
        li a:hover {
            svg {
                color:#0187FF;
            }
        }
        li a.disabled,
        li a.disabled:hover { {
            cursor:not-allowed;
            svg {
                color:#B5BCC3;
            }
        }
    }
`;