import {USERS} from './constants'

const initialState = {
    loading:false,
    data:null,
    error:null,
}

const usersReducer = (state = initialState, action) => {
    switch(action.type) {
        case USERS.FETCH_REQUESTED:
            return {...state, loading:true}
        case USERS.FETCH_SUCCEEDED:
            return {...state, loading:false, data:action.payload}
        case USERS.FETCH_FAILED:
            return {...state, loading:false, error:action.payload}
        default:
            return state; 
    }
}

export default usersReducer;

