import { THEMES } from './constants'

/** THEMES  */
const fetchThemes = (params) => ({
    type: THEMES.FETCH_REQUESTED,
    payload:params,
})

const setThemes = (themes) => ({
    type: THEMES.FETCH_SUCCEEDED,
    payload:themes,
})
const setError = (error) => ({
    type: THEMES.FETCH_FAILED,
    payload:error,
})

const postTheme = (data) => ({
    type: THEMES.POST_REQUESTED,
    payload:data,
})

const updateTheme = (data) => ({
    type: THEMES.UPDATE_REQUESTED,
    payload:data,
})

const deleteTheme = (id) => ({
    type: THEMES.DELETE_REQUESTED,
    payload:id,
})


export {
    fetchThemes,
    setThemes,
    setError,
    postTheme, 
    updateTheme,
    deleteTheme,
}





