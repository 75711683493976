import React from 'react'
import { Link, Icon } from '@chakra-ui/react'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Link as RouterLink } from 'react-router-dom'

const BackButton = ({ label }) => {
	return (
		<Link
			mb="1rem"
			to="/core/tools"
			as={RouterLink}
			variant="link"
			d="flex"
			alignItems="center"
			fontSize="0.875rem">
			<Icon boxSize="1rem" as={ArrowBackIosNewRoundedIcon} /> {label}
		</Link>
	)
}

BackButton.defaultProps = {
	label: 'Back to tools',
}

export default BackButton
