import React from 'react'
import Details from './tabs/Details'
import Profile from './tabs/Profile'
import Location from './tabs/Location'
import Actions from './tabs/Actions'
import Edit from './tabs/Edit'

/**
 * Define our sub-row view tabs and corresponding sub-views
 */
 const tabs = [
    {
        id:'actions',
        title:'Actions',
        view: Actions,
    },
   
    {
        id:'details',
        title:'Details',
        view: Details,
    },
    {
        id:'profile',
        title:'Profile',
        view: Profile,
    },
    {
        id:'location',
        title:'Location',
        view: Location,
    },
    {
        id:'edit',
        title:'Edit',
        view: Edit,
    },
]


export default tabs;