import { mode } from '@chakra-ui/theme-tools'

const Checkbox = {
	baseStyle: (props) => {
		const { colorScheme: c } = props

		return {
			control: {
				borderColor: 'gray.200',
				color: 'white',

				_checked: {
					bg: mode(`${c}.500`, `${c}.500`)(props),
					borderColor: mode(`${c}.500`, `${c}.500`)(props),
					color: mode('white', 'white')(props),

					_hover: {
						bg: mode(`${c}.600`, `${c}.600`)(props),
						borderColor: mode(`${c}.600`, `${c}.600`)(props),
					},
					_disabled: {
						borderColor: mode('gray.200', 'gray.200')(props),
						bg: mode('gray.200', 'gray.200')(props),
						color: mode('gray.500', 'gray.500')(props),
					},
				},
				_indeterminate: {
					bg: mode(`${c}.500`, `${c}.500`)(props),
					borderColor: mode(`${c}.500`, `${c}.500`)(props),
					color: mode('white', 'white')(props),
				},

				_disabled: {
					bg: mode('gray.100', 'gray.100')(props),
					borderColor: mode('gray.100', 'gray.100')(props),
				},

				_focusVisible: {
					boxShadow: 'outline',
				},

				_invalid: {
					borderColor: mode('red.500', 'red.500')(props),
				},
			},
			label: {
				color: mode(`inherit`, `gray.800`)(props),
			},
		}
	},
	// variants: {
	// 	outline: (props) => {
	//         return {
	//             field: {
	//                 borderColor: mode("gray.200", "gray.200")(props),
	//                 _hover: {
	//                     borderColor: mode("gray.300", "gray.300")(props),
	//                 }
	//             },
	//             addon: {

	//             }
	//         }
	//     },
	// },
}

export default Checkbox
