/* eslint-disable no-console */
import { createSlice, createAction } from '@reduxjs/toolkit'

import * as types from '@bit/vidday.vidday-store.constants'
import { makeActionCreator } from '@bit/vidday.vidday-helpers.utils.make-action-creator'


export const availablePanels = ['navigation','secondary', 'rightPanel', 'bottomPanel', 'modal']



// const registerApplications = createAction('interface/REGISTER_APPLICATIONS')

/** Define our initial interface State */
const defaultState = {
	/** Vidday admin app : Global loading state, 
     * can be used to prevent action on the page. */
	isLoading:false,
    
	panels: {
		/** Main navigation */
		navigation:true,
		/** Secondary navigation */
		secondary: false,
		/** Right Panel  */
		rightPanel: false,
		/** Bottom Panel */
		bottomPanel: false,
		/** Modal */
		modal:false,
	},
}

const interfaceSlice = createSlice({
	initialState: defaultState,
	name: 'interface',
	extraReducers: {},
	reducers: {
		
		/**
		 * Set the visibility of a panel.
		 */
		togglePanel:(state, action) => {
			let panelIndex = availablePanels.findIndex(el => el === action.payload);
			if(panelIndex != -1) {
				state.panels[action.payload] = !state.panels[action.payload]
			}
		},
		/**
		 * Open a panel.
		 */
		openPanel:(state, action) => {
			let panelIndex = availablePanels.findIndex(el => el === action.payload);
			if(panelIndex != -1) {
				state.panels[action.payload] = true
			}
		},
		/**
		 * Close a panel.
		 */
		closePanel:(state, action) => {
			let panelIndex = availablePanels.findIndex(el => el === action.payload);
			if(panelIndex != -1) {
				state.panels[action.payload] = false
			}
		},
	},
})

export default interfaceSlice.reducer

export const interfaceState = defaultState
// Export our actions
export const { togglePanel, openPanel, closePanel } = interfaceSlice.actions
