/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import { pick } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true, // include api cookie in request
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = ['name', 'slug', 'label', 'class', 'description', 'icon', 'poster', 'thumbnail', 'default']

/**
 * Fetch our themes
 */
const fetchThemes = async (params) => {
	const response = await instance.get('/themes?' + params)
	return response
}

/**
 * Post a theme
 * @param {*} data
 * @returns
 */
const postTheme = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/themes', filteredData)
	return response
}

/**
 * Update a theme
 * @param {*} data
 * @returns
 */
const updateTheme = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.put('/themes/' + data.id, filteredData)
	return response
}

/**
 * Delete a theme
 * @param {*} data
 * @returns
 */
const deleteTheme = async (id) => {
	const response = await instance.delete('/themes/' + id)
	return response
}

const requests = { fetchThemes, postTheme, updateTheme, deleteTheme }

export default requests
