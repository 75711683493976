
import { normalize, schema } from 'normalizr'


const themeVersionSchema = new schema.Entity(
	'themeVersions',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

const themeVersionListSchema = new schema.Array(themeVersionSchema)

export {themeVersionListSchema}