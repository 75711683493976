/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import config from '../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

/**
 * Post mail published notification
 * @param {*} data
 * @returns
 */
const postPublishedNotification = async (data) => {
	const response = await instance.post('/mail/published', data)
	return response
}

const requests = { postPublishedNotification }

export default requests
