import styled from 'styled-components'


export const StyledSelectedRowActions = styled.div`
    display:flex;
    position:relative;

    a {
        color: #0187FF;

        &:hover {
            text-decoration:underline;
        }
    }
`