import React from 'react'

import { useDispatch } from 'react-redux'

/** Table components (react-table) */
import { useTable, useSortBy, usePagination, useRowSelect, useExpanded } from 'react-table'

/** Table components (local) */
import FactorySubRow from '../FactorySubRow'

/** Custom hooks */
import useInterfacePanel from '../../../../interface/hooks/useInterfacePanel'

/** Import Filters */
import FilterItemPerView from '../../filters/FilterItemPerView'
import FilterPageNumber from '../../filters/FilterPageNumber'
import FilterSettingButton from '../../filters/FilterSettingButton'
import VerticalDivider from '../../filters/VerticalDivider'
import ButtonEditColumns from '../../buttons/ButtonEditColumns'
import ResultsParagraph from '../../others/ResultsParagraph'
import ButtonExport from '../../buttons/ButtonExport'
import SelectedRowActions from '../../others/SelectedRowsActions'
import { StyledTable } from '../styles'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import { FiltersWrapper, HR } from '../../styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTableContext } from '../../../../contexts/TableProvider'
import { Flex, HStack } from '@chakra-ui/react'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'

const TableFilters = () => {
	const { togglePanel } = useInterfacePanel()

	/**
	 * Retrieve table features
	 */
	const { allColumns } = useTableContext()

	const {
		state,
		state: { pageSize, lastPage, currentPage },
		nextPage,
		prevPage,
		setPageSize,
		goToPage,
	} = useSearchEngineContext()

	console.log(state, 'state of the query')
	return (
		<Flex w="full" justifyContent="space-between">
			<HStack spacing="0.5rem">
				<FilterItemPerView setPageSize={setPageSize} pageSize={pageSize} />
				<FilterPageNumber
					lastPage={lastPage}
					currentPage={currentPage}
					prevPage={prevPage}
					nextPage={nextPage}
					goToPage={goToPage}
				/>
			</HStack>
			<HStack spacing="0.5rem">
				<ButtonExport />
				<ButtonEditColumns columns={allColumns} />
				<VerticalDivider />
				<FilterSettingButton onClick={() => togglePanel('rightPanel')} />
			</HStack>
		</Flex>
	)
}

export default TableFilters
