import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { regionListSchema } from './normalizr'
import requests from './requests'
import { REGIONS } from './constants'

/**
 * Users Saga worker
 * Fetch regions
 * @param {*} action 
 */
function* handleFetchRegions(action) {
    try {
        const response = yield call(requests.fetchRegions, action.payload)
        
        const normalizedData = normalize(response.data.data, regionListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: REGIONS.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: REGIONS.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a region
 * @param {*} action 
 */
 function* handlePostRegion(action) {
    try {
        const response = yield call(requests.postRegion, action.payload)
        const normalizedData = normalize([response.data.data], regionListSchema)
        
        yield put({ type: REGIONS.POST_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: REGIONS.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a region
 * @param {*} action 
 */
 function* handleUpdateRegion(action) {
    try {
        const response = yield call(requests.updateRegion, action.payload)
        
        const normalizedData = normalize([response.data.data], regionListSchema)
        console.log(response.data)
        yield put({ type: REGIONS.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: REGIONS.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a region
 * @param {*} action 
 */
 function* handleDeleteRegion(action) {
    try {
        const response = yield call(requests.deleteRegion, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: REGIONS.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: REGIONS.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchRegionsFetch(){
    // while(true){
        yield takeLatest(REGIONS.FETCH_REQUESTED, handleFetchRegions)
    // }
}

export function* watchUpdateRegion(){
    // while(true){
        yield takeEvery(REGIONS.UPDATE_REQUESTED, handleUpdateRegion)
    // }
}

export function* watchPostRegion(){
    // while(true){
        yield takeEvery(REGIONS.POST_REQUESTED, handlePostRegion)
    // }
}

export function* watchDeleteRegion(){
    // while(true){  
        yield takeEvery(REGIONS.DELETE_REQUESTED, handleDeleteRegion)
    // }
}