import { mode } from '@chakra-ui/theme-tools'

const Select = {
	// baseStyle: (props) => {

	//     return {
	//         color: mode('inherit', 'gray.200')(props),
	//     }
	// },
	variants: {
		outline: (props) => {
			return {
				field: {
					bg: mode('rgba(232,236,240,0.5)', 'gray.700')(props),
				},
				addon: {},
			}
		},
	},
}

export default Select
