import { CURRENCIES } from './constants'

const fetchCurrencies = (params) => ({
    type: CURRENCIES.FETCH_REQUESTED,
    payload:params,
})

const setCurrencies = (promotions) => ({
    type: CURRENCIES.FETCH_SUCCEEDED,
    payload:promotions,
})

const setError = (error) => ({
    type: CURRENCIES.FETCH_FAILED,
    payload:error,
})

const postCurrency = (data) => ({
    type: CURRENCIES.POST_REQUESTED,
    payload:data,
})

const updateCurrency = (data) => ({
    type: CURRENCIES.UPDATE_REQUESTED,
    payload:data,
})

const deleteCurrency = (id) => ({
    type: CURRENCIES.DELETE_REQUESTED,
    payload:id,
})

export {
    fetchCurrencies,
    setCurrencies,
    setError,
    postCurrency,
    updateCurrency,
    deleteCurrency,
}





