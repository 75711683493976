
import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = ['categoryId', 'rate', 'countryCode', 'inclusive']
const acceptedKeysPUT = ['']
const rejectedKeysPUT = ['createdAt', 'updatedAt', 'id', 'categoryId', 'countryCode']

/**
 * Fetch our tax code rates
 */
const fetchTaxCodeRates = async (params) => {
	const response = await instance.get('/tax-code-rates?' + params)
	return response
}

/**
 * Post a tax code rate
 * @param {*} data
 * @returns
 */
const postTaxCodeRate = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/tax-code-rates', filteredData)
	return response
}

/**
 * Update a tax code rate
 * @param {*} data
 * @returns
 */
const updateTaxCodeRate = async (data) => {
	const filteredData = reject(data, rejectedKeysPUT)
	const response = await instance.put('/tax-code-rates/' + data.id, filteredData)
	return response
}

/**
 * Delete a tax code rate
 * @param {*} data
 * @returns
 */
const deleteTaxCodeRate = async (id) => {
	const response = await instance.delete('/tax-code-rates/' + id)
	return response
}

const requests = { fetchTaxCodeRates, postTaxCodeRate, updateTaxCodeRate, deleteTaxCodeRate }

export default requests
