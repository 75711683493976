import React, { createContext, useReducer, useContext, useState, useEffect } from 'react'
import { useSearchEngine } from '../core'
import { values } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
/** Build Current Event Context */
const SearchEngineContext = createContext()

/** Define Current Event Context Provider  */
const SearchEngineProvider = ({ config, children }) => {
	/** Make sure config has been defined */
	if (!config) throw new Error('You forgot to pass a config object to the SearchEngineProvider.')
	if (!config.slice)
		throw new Error('`slice` key is required as part of the config in order to retrieve the collection.')

	/** Retrieve slice prop */
	const { slice } = config

	/** Collection selector */
	const retrieveCollection = (s) => {
		var items = s.entities[slice].data || []
		let collection = []
		collection = values(items)
		return collection
	}

	/** Retrieve corresponding collection */
	const collection = useSelector((s) => retrieveCollection(s), shallowEqual)

	/** Setup our search features */
	const searchEngine = useSearchEngine(config)

	/** Build final state that will provide the Search Engine context */
	const state = {
		...searchEngine,
		collection,
	}

	console.log(state, 'state from search engine context')

	return <SearchEngineContext.Provider value={state}>{children}</SearchEngineContext.Provider>
}

export default SearchEngineProvider

export const useSearchEngineContext = () => useContext(SearchEngineContext)
