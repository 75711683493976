import {createSlice} from '@reduxjs/toolkit'

import { INVOICES } from './constants'

const initialState = {
    data:null,
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}

const invoicesSlice = createSlice({
    initialState: initialState,
    name:'invoices',
    extraReducers: {
        [INVOICES.FETCH_REQUESTED]: (state, action) => {
            state.isFetching = true
        },
        [INVOICES.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.invoices){
                state.data = action.payload.entities.invoices
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        [INVOICES.FETCH_FAILED]: (state, action) => {
            state.hasError = true
            state.isFetching = false
            state.errorMessage = 'An error occured while fetching.'
        },

        [INVOICES.UPDATE_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.invoices){
                var item = state.data[action.payload.result[0]]
                // If we found an item, update it
                if(item){
                    state.data[action.payload.result[0]] = action.payload.entities.invoices[action.payload.result[0]]
                }
            }
        },

        [INVOICES.POST_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.promotions){
                state.data[action.payload.result[0]] = action.payload.entities.promotions[action.payload.result[0]]
            }
        },
        [INVOICES.DELETE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                delete state.data[action.payload.data.id]
            }
        },

        [INVOICES.REFUND_ITEM_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.invoice_items){
                if(action.payload.result[0]){
                    var item = action.payload.entities.invoice_items[action.payload.result[0]]
                    var invoice = state.data[item.invoiceId]
                    // merge keys of new item with old so we can keep relationships
                    var newArray = invoice.items.map((el, i) => el.id == item.id ? {...el, ...item} : el)
                    
                    let itemIndex = newArray.findIndex(el => el.id == item.id)
                    let increasedRefundBy = newArray[itemIndex].amount +  newArray[itemIndex].taxesCollected
                    state.data[item.invoiceId].refunded += increasedRefundBy
                    state.data[item.invoiceId].items = newArray
                }
            }
        },

        [INVOICES.REFUND_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.invoices){
                var item = state.data[action.payload.result[0]]
                // If we found an item, update it (merge keys)
                if(item){
                    state.data[action.payload.result[0]] = {...item, ...action.payload.entities.invoices[action.payload.result[0]]}
                }
            }
        },


        
    },
    reducers:{},
})


export default invoicesSlice.reducer