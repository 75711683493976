import React, { useRef, useState } from 'react'
import SearchInput from '../../search/SearchInput'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'
import { HStack, Link, Flex } from '@chakra-ui/react'

const _styles = {
	fontSize: '0.75rem',
	backgroundColor: 'gray.50',
	color: 'gray.700',
	px: '0.5rem',
	py: '0.2rem',
	borderRadius: 'md',
	_hover: {
		textDecoration: 'none',
		backgroundColor: 'gray.500',
		color: 'gray.50',
	},
}

const activeStyles = {
	textDecoration: 'none',
	backgroundColor: 'gray.500',
	color: 'gray.50',
}

const TagManagerItem = ({ isActive, onClick, value }) => {
	const styles = isActive ? { ..._styles, ...activeStyles } : _styles
	return (
		<Link as="button" onClick={onClick} sx={styles}>
			{value}
		</Link>
	)
}

const TagManager = ({ inputRef, tags }) => {
	const [tag, setTag] = useState(null)

	const handleChange = (val) => {
		const value = inputRef.current.value // Copy of the existing input value
		const tag = `${val}:` // Compose new string to prepend input value with
		const arr = value.split(':') // Split string at `:` in order to differenciate tag and value we're searching
		let new_value

		/** Check if we already have a tag */
		if (arr.length >= 2) {
			/** If the tags are different, assign new tag */
			if (arr[0] != val) {
				new_value = `${val}:${arr[1]}`
			} else {
				/** Otherwise, let's use the previous value (no changes) */
				new_value = value
			}
		} else {
			/** If, no existing tag, assign new one */
			new_value = `${tag}${value}`
		}

		setTag(val) // Set current tag internally so we know which one is active
		inputRef.current.value = new_value // Update the current value of the input through ref
		inputRef.current.focus() // Bring focus to the input
	}

	return (
		<HStack spacing="0.25rem" mr="0.5rem">
			{tags.map((el, i) => (
				<TagManagerItem key={i} value={el} onClick={() => handleChange(el)} isActive={tag == el} />
			))}
		</HStack>
	)
}

const FilterSearchInput = ({ label, tags }) => {
	const {
		state: { searchQuery },
		search,
	} = useSearchEngineContext()

	const inputRef = useRef(null)

	const handleSearch = (e) => {
		const val = String(e.target.value).trim()
		if (val != searchQuery) search(val)
	}

	return (
		<Flex alignItems="center">
			{tags && tags.length > 0 && <TagManager inputRef={inputRef} tags={tags} />}
			<SearchInput onSubmit={handleSearch} label={label} value={searchQuery} ref={inputRef} />
		</Flex>
	)
}

export default FilterSearchInput
