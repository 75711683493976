import React from 'react' 

import {StyledAppRoot} from './styles'

const AppRoot = ({children, id}) => {
    return (
        <StyledAppRoot id={id}>{children}</StyledAppRoot>
    )
}

AppRoot.defaultProps = {
    id: 'app-layout', // Important
}

export default AppRoot;