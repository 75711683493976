const EVENTS = {
    /** Fetch all events (includes filters) */
    FETCH_REQUESTED: 'EVENTS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'EVENTS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'EVENTS_FETCH_FAILED',

    /** Post an event */
    POST_REQUESTED: 'EVENT_POST_REQUESTED',
    POST_SUCCEEDED:'EVENT_POST_SUCCEEDED',
    POST_FAILED: 'EVENT_POST_FAILED',

    /** Update an event */
    UPDATE_REQUESTED: 'EVENT_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'EVENT_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'EVENT_UPDATE_FAILED',

    /** Delete an event */
    DELETE_REQUESTED: 'EVENT_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'EVENT_DELETE_SUCCEEDED',
    DELETE_FAILED: 'EVENT_DELETE_FAILED',
}

export {EVENTS}