import {createSlice} from '@reduxjs/toolkit'

import { NEXUS } from './constants'

const initialState = {
    data:null,
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}

const nexusSlice = createSlice({
    initialState: initialState,
    name:'nexus',
    extraReducers: {
        [NEXUS.FETCH_REQUESTED]: (state, action) => {
            state.isFetching = true
        },
        [NEXUS.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.nexus){
                state.data = action.payload.entities.nexus
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        [NEXUS.FETCH_FAILED]: (state, action) => {
            state.hasError = true
            state.isFetching = false
            state.errorMessage = 'An error occured while fetching.'
        },

        [NEXUS.UPDATE_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.nexus){
                var item = state.data[action.payload.result[0]]
                // If we found an item, update it
                if(item){
                    state.data[action.payload.result[0]] = action.payload.entities.nexus[action.payload.result[0]]
                }
            }
        },

    },
    reducers:{},
})


export default nexusSlice.reducer