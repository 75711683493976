import React, { createContext, useReducer, useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual, useStore as useReduxStore } from 'react-redux'

/** Build Current Event Context */
const AuthContext = createContext()

// WHAT WE WANT TO PROVIDE

const state = {
	// user ( {} or null)
	// userId, ( string or null)
	// isAdmin ( true or false)
	// role, ( one of 'admin', 'editor', 'creator', 'contributor', 'visitor' )
	// isAuthenticated, (true or false)
	// isAuthenticating, (true or false)
	// isImpersonated, (true or false) // Need to review to double check how this is really done
	// statusText,
	// statusType,
}

/** Define Current Event Context Provider  */
const AuthProvider = ({ children }) => {
	/** Set initial state rendered by the context */
	// Get the redux store (important for SSR)
	const store = useReduxStore()

	// Get initial redux state
	const reduxState = store.getState()

	/** Retrieve auth information */
	const auth = useSelector((s) => s.auth, shallowEqual)

	/** Is the authed user an admin */
	const [isAdmin, setIsAdmin] = useState(auth.role == 'admin')
	/** Is the authed user an editor */
	const [isEditor, setIsEditor] = useState(auth.role == 'editor')

	/** Do we have admin privilege (the user is either an admin, an editor, or is impersonated) */
	const [hasAdminPrivilege, setAdminProvilege] = useState(
		auth.isImpersonated || auth.role == 'admin' || auth.role == 'editor'
	)

	/** TODO: Review role of editor */
	useEffect(() => {
		const adminRole = auth.role == 'admin'
		const editorRole = auth.role == 'editor'
		const isImpersonated = auth.isImpersonated

		/**
		 * Check if user is an admin only
		 */
		if (adminRole) {
			setIsAdmin(true)
		} else if (isAdmin != true) {
			/**
			 * If the previous value was true,
			 * we want to set it to false since predicate returned false.
			 * */
			setIsAdmin(false)
		}
		/**
		 * Check if the user has admin privileges (admin, editor, impersonated)
		 */
		if (adminRole || editorRole || isImpersonated) {
			setAdminProvilege(true)
		} else {
			setAdminProvilege(false)
		}
	}, [auth])

	const s = {
		...auth,
		isAdmin,
		isEditor,
		hasAdminPrivilege,
	}
	console.log('AuthProvider state', s)
	return <AuthContext.Provider value={s}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuthContext = () => useContext(AuthContext)
