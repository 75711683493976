import styled, {css} from 'styled-components'

export const StyledBottomPanel = styled.div`
    background-color:#F2F6FA;
    z-index:300;
    display:block;
    position: fixed;
    bottom:-100%;
    left:300px;
    right:0;
    border-top:1px solid #B5BCC3;

    // opacity: 0;
    // visibility: hidden;

    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    ${(props) => props.isOpen && css`
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        visibility: visible;
        right:0;
        bottom:0;
    `}

    ${(props) => props.isRightPanelOpen && css`
        right:300px;
    `}

    ${(props) => props.isSecondaryNavigationOpen && css`
        left:550px;
    `}

    

`