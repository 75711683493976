import React from 'react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Select,
	Input,
	InputRightElement,
	Switch,
	FormErrorMessage,
	Text,
	Flex,
} from '@chakra-ui/react'

const ControlledSwitch = (props) => {
	const {
		defaultValue,
		control,
		name,
		label,
		description,
		placeholder,
		helperText,
		options,
		scrollToOnFocus,
		errors,
		required,
		children,
	} = props

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				console.log('props, Switch', props)

				const onChange = (e) => {
					props.onChange(!props.value)
				}
				return (
					<FormControl
						isInvalid={errors[name]}
						display="flex"
						alignItems="center"
						justifyContent="space-between">
						<Flex direction="column">
							{label && (
								<FormLabel htmlFor={name} mb="0" fontWeight="bold" color="#47596A">
									{label}
								</FormLabel>
							)}

							{description && (
								<Text fontSize="0.875rem" color="#47596A">
									{description}
								</Text>
							)}

							{errors[name] ? (
								<FormErrorMessage d="block">{errors[name].message}</FormErrorMessage>
							) : (
								helperText && <FormHelperText>{helperText}</FormHelperText>
							)}
						</Flex>

						<Flex ml="1rem">
							<Switch id={name} {...props} size="lg" onChange={onChange} isChecked={props.value} />
						</Flex>
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledSwitch
