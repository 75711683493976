const LANGUAGES = {
    /** Fetch all languages (includes filters) */
    FETCH_REQUESTED: 'LANGUAGES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'LANGUAGES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'LANGUAGES_FETCH_FAILED',

    /** Post a language */
    POST_REQUESTED: 'LANGUAGE_POST_REQUESTED',
    POST_SUCCEEDED:'LANGUAGE_POST_SUCCEEDED',
    POST_FAILED: 'LANGUAGE_POST_FAILED',

    /** Update a language */
    UPDATE_REQUESTED: 'LANGUAGE_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'LANGUAGE_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'LANGUAGE_UPDATE_FAILED',
    
    /** Delete a language */
    DELETE_REQUESTED: 'LANGUAGE_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'LANGUAGE_DELETE_SUCCEEDED',
    DELETE_FAILED: 'LANGUAGE_DELETE_FAILED',
}

export {LANGUAGES}