import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { nexusListSchema, nexusSchema } from './normalizr'
import requests from './requests'
import { NEXUS } from './constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action 
 */
function* handleFetchNexus(action) {
    try {
        const response = yield call(requests.fetchNexus, action.payload)
        const normalizedData = normalize(response.data.data, nexusListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: NEXUS.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: NEXUS.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a nexus
 * @param {*} action 
 */
 function* handlePostNexus(action) {
    try {
        const response = yield call(requests.postNexus, action.payload)
        yield put({ type: NEXUS.POST_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: NEXUS.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a nexus
 * @param {*} action 
 */
 function* handleUpdateNexus(action) {
    try {
        const response = yield call(requests.updateNexus, action.payload)
        
        const normalizedData = normalize([response.data.data], nexusListSchema)
        console.log(response.data)
        yield put({ type: NEXUS.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: NEXUS.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a nexus
 * @param {*} action 
 */
 function* handleDeleteNexus(action) {
    try {
        const response = yield call(requests.deleteNexus, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: NEXUS.DELETE_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: NEXUS.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchNexusFetch(){
    // while(true){
        yield takeLatest(NEXUS.FETCH_REQUESTED, handleFetchNexus)
    // }
}

export function* watchUpdateNexus(){
    // while(true){
        yield takeEvery(NEXUS.UPDATE_REQUESTED, handleUpdateNexus)
    // }
}

export function* watchPostNexus(){
    // while(true){
        yield takeEvery(NEXUS.POST_REQUESTED, handlePostNexus)
    // }
}

export function* watchDeleteNexus(){
    // while(true){  
        yield takeEvery(NEXUS.DELETE_REQUESTED, handleDeleteNexus)
    // }
}