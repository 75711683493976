import React from 'react'

import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers'

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker
// } from "@material-ui/pickers";

const localeMap = {
	en: 'en',
	fr: 'fr',
}

const maskMap = {
	fr: '__/__/____',
	en: '__/__/____',
	ru: '__.__.____',
}

/* ****************************
 * PASSWORD INPUT
 * ************************** */

function DateTimePickerUncontrolled(props) {
	/** Retrieve props from parent */
	const { name, label, required, errors, control, onChange, value } = props
	/** Retrieve utilities methods to track values */
	// const { setValue, getValues } = control
	// /** Let's retrieve the values */
	// const values = getValues()

	// const [selectedDate, handleDateChange] = useState(new Date())
	/* ****************************
	 * ERROR MANAGEMENT
	 * ************************** */

	let isError = false,
		errorMessage = '',
		errorType

	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorType = errors[name].type
		errorMessage = errors[name].message
	}

	/* ****************************
	 * RENDER
	 * ************************** */

	return (
		<>
			{/* <Controller
				name={name}
				control={control}
				as={ */}
			<MuiDateTimePicker
				label={props.label}
				value={value}
				onChange={(newValue) => onChange(newValue)}
				disablePast={props.disablePast}
				minDate={props.minDate}
				// minTime={new Date(0, 0, 0, 8)}
				renderInput={(params) => {
					return <TextField {...params} variant="filled" fullWidth={true} margin={props.margin} />
				}}
			/>
			{/* }
			/> */}

			{/* <Controller
				as={
					<TextField
						margin={props.margin}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={toggleCalendar}
										onMouseDown={toggleCalendar}>
										{isVisible ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				}
				id={name}
				name={name}
				control={control}
				type="date"
				defaultValue="2017-05-24"
				label={label}
				fullWidth={true}
				InputLabelProps={{
					className: required ? 'required-label' : '',
					required: required || false,
				}}
				errors={errors}
				error={isError}
				helperText={errorMessage}
				{...props}
			/> */}
		</>
	)
}

DateTimePickerUncontrolled.defaultProps = {
	margin: 'normal',
	minDate: new Date(),
	disablePast: false,
}

function DateTimePicker(props) {
	/** Retrieve props from parent */
	const { name, label, required, errors, control, onChange, value } = props
	/** Retrieve utilities methods to track values */
	const { setValue, getValues } = control
	// /** Let's retrieve the values */
	const values = getValues()

	// const [selectedDate, handleDateChange] = useState(new Date())
	/* ****************************
	 * ERROR MANAGEMENT
	 * ************************** */

	let isError = false,
		errorMessage = '',
		errorType

	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorType = errors[name].type
		errorMessage = errors[name].message
	}

	/* ****************************
	 * RENDER
	 * ************************** */

	return (
		<>
			<Controller
				name={name}
				control={control}
				as={
					<MuiDateTimePicker
						label={props.label}
						value={values[name]}
						// onChange={(e,newValue) => {onChange(newValue), console.log("CHANGE",newValue)}}
						disablePast={props.disablePast}
						// minDate={props.minDate}
						minDateTime={props.minDateTime}
						// minTime={new Date(0, 0, 0, 8)}
						renderInput={(params) => {
							return <TextField {...params} variant="filled" fullWidth={true} margin={props.margin} />
						}}
					/>
				}
			/>

			{/* <Controller
				as={
					<TextField
						margin={props.margin}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={toggleCalendar}
										onMouseDown={toggleCalendar}>
										{isVisible ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				}
				id={name}
				name={name}
				control={control}
				type="date"
				defaultValue="2017-05-24"
				label={label}
				fullWidth={true}
				InputLabelProps={{
					className: required ? 'required-label' : '',
					required: required || false,
				}}
				errors={errors}
				error={isError}
				helperText={errorMessage}
				{...props}
			/> */}
		</>
	)
}

DateTimePicker.defaultProps = {
	margin: 'normal',
	// minDate: new Date(),
	minDateTime: new Date(),
	disablePast: false,
}

export default DateTimePicker
