import React from 'react'
import { HStack, Text, chakra, useMultiStyleConfig } from '@chakra-ui/react'

const FilterItemPerView = ({ setPageSize, pageSize, sizeOptions }) => {
	const styles = useMultiStyleConfig('Filter')
	return (
		<HStack sx={styles.container}>
			<Text sx={styles.label}>Show</Text>
			<HStack spacing="0.5rem" sx={styles.list}>
				{sizeOptions.map((size, i) => {
					const _styles = size == pageSize ? styles.activeElement : styles.element

					return (
						<chakra.a key={size} sx={_styles} onClick={() => setPageSize(Number(size))}>
							{size}
						</chakra.a>
					)
				})}
			</HStack>
		</HStack>
	)
}

FilterItemPerView.defaultProps = {
	sizeOptions: [10, 20, 30, 40, 50, 100],
}

export default FilterItemPerView
