import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../core/widgets/form/input'
import Select from '../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import SubmitButton from '@bit/vidday.vidday-ui.ui.submit-button'
import styled, { css } from 'styled-components'

import SaveBar from '../../../../../core/widgets/others/SaveBar'
import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import { updateAnnouncement, postAnnouncement } from '../../api/actions'

import schemaAnnouncement, { typeOptions, isEnabledOptions } from './schema'
const Announcement = ({ data, onSubmitted }) => {
	const { timezone, starts, ends } = data
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaAnnouncement),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.id) {
			dispatch(updateAnnouncement(model))
		} else {
			dispatch(postAnnouncement(model))
			reset()
		}
		onSubmitted != undefined && onSubmitted()
	}

	/* ******************************
	 * Obtain available Timezones
	 * **************************** */

	let tzCollection = []
	Object.keys(timeZonesGeo).forEach(function (el) {
		tzCollection.push({ value: timeZonesGeo[el].name, name: timeZonesGeo[el].name })
	})

	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	useEffect(() => {
		// Set timezone automatically
		const autoTimezone = timezone ? timezone : moment.tz.guess(true)
		// Set deadline automatically
		const autoStartDate = starts ? starts : moment().add(2, 'days')
		const autoEndDate = ends ? ends : moment().add(2, 'days')
		// Set initial values
		setValue('starts', autoStartDate)
		setValue('ends', autoEndDate)
		setValue('timezone', autoTimezone)
	}, [timezone, starts, ends])

	useEffect(() => {
		//console.log("FORM CHANGE",moment(deadline).format(),moment(deadline).tz(values.timezone).format())
		setValue('starts', moment(starts).tz(values.timezone))
		setValue('ends', moment(ends).tz(values.timezone))
	}, [values.timezone])

	/** Register uncontrolled components */
	useEffect(() => {
		register({ name: 'timezone' }, { required: true })
		register({ name: 'starts' }, { required: true })
		register({ name: 'ends' }, { required: true })
	}, [register])

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		var formStartDate =
			values?.starts != undefined && values?.starts != null && typeof values?.starts === 'object'
				? values.starts.toISOString()
				: null
		var formEndDate =
			values?.ends != undefined && values?.ends != null && typeof values?.ends === 'object'
				? values.ends.toISOString()
				: null

		var changed = starts != formStartDate || ends != formEndDate || timezone != values.timezone
		setHasChange(changed)
	}, [values])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Announcement Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					margin="normal"
					name="type"
					id="type"
					variant="filled"
					label="Type"
					errors={errors}>
					{typeOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					margin="normal"
					name="enabled"
					id="enabled"
					variant="filled"
					label="Enabled"
					errors={errors}>
					{isEnabledOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					control={control}
					margin="normal"
					name="message"
					id="message"
					variant="filled"
					label="Message"
					errors={errors}
					multiline={true}
				/>

				<Select
					control={control}
					margin="normal"
					name="timezone"
					label="Occasion Timezone"
					errors={errors}
					id="timezone"
					defaultValue={values.timezone || ''}>
					{tzCollection.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.name}
						</MenuItem>
					))}
				</Select>

				<DateTimePicker
					control={control}
					name="starts"
					id="starts"
					variant="filled"
					label="Start Date"
					errors={errors}
				/>

				<DateTimePicker
					control={control}
					name="ends"
					id="ends"
					variant="filled"
					label="Ending Date"
					errors={errors}
				/>
			</InlineItems>
		</form>
	)
}

Announcement.defaultProps = {
	/** Important */
	data: {},
	/** Default text for submit button */
	submitText: 'Apply Code',
}

Announcement.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
	/** Promotion */
	promotion: PropTypes.object.isRequired,
}

export default Announcement
