import styled, {css, keyframes} from 'styled-components'
import {StyledBaseFilter, _baseListStyles, _baseListCheckboxStyles} from '../styles'


export const StyledFilterDropdown = styled(StyledBaseFilter)`
    padding-right:10px;    
    overflow:visible;

    a {
        cursor:pointer;
    }
    a > svg {
        color:#0A2239;
    }
    a:hover > svg {
        color:#0187FF;
    }
    a.current {
        font-weight:500;
        font-size:14px;
        padding-top:4px;

        &:hover {
            border-bottom:1px solid #0187FF;
        }
    }

    ${_baseListStyles};

    ${_baseListCheckboxStyles};
  

    ul.occasions {
        min-width:150px;
        li a > div > svg {
            display:inline-block;
            float: left;
            position: relative;
            top: -3px;
            left: 0;
            margin-right: 6px;
            width:30px;
            height:30px;
        }
    }
`;