import React, { useEffect, useState } from 'react'
import moment from 'moment'

/** Icons */
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

/** Import our table component */
import FactoryTable from '../../../../core/widgets/table/FactoryTable'
import { Heading, Text, Box, Flex } from '@chakra-ui/react'
import { FiltersWrapper } from '../../../../core/widgets/styles'
import FilterDisplayMode from '../../../../core/widgets/filters/FilterDisplayMode'
import VerticalDivider from '../../../../core/widgets/filters/VerticalDivider'
import FilterDropdown from '../../../../core/widgets/filters/FilterDropdown'
import eventTypes from '@bit/vidday.vidday-data.data.event-types'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchEvents } from '../api/actions'
import { filter } from 'lodash'
import { Container, useInterfacePanel, BottomPanel, RightPanel } from '../../../../interface'
import { HR } from '../../../../core/widgets/styles'
import Notes from './tabs/Notes'
import Status from './tabs/Status'
import Actions from './tabs/Actions'
import MediaUrl from './tabs/MediaUrl'
import FactorySubRow from '../../../../core/widgets/table/FactorySubRow'
import socket from '../../../../utils/socket'
import MediaTools from './tabs/MediaTools'
import Automation from './tabs/Automation'
import { values } from 'lodash'
import columns from './columns'
import tabs from './tabs'
import { useSearchEngine, serialize, extractKeys } from '../../../../core'
import SearchEngineProvider, { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'
import { FilterDropdownV2 } from '../../../../core/widgets/filters/FilterDropdown'
import TableProvider from '../../../../contexts/TableProvider'
import { TableFiltersV2, TableResultsV2, TableV2 } from '../../../../core/widgets/table/FactoryTable'
import SearchInput from '../../../../core/widgets/search/SearchInput'
import FilterSearchInput from '../../../../core/widgets/filters/FilterSearchInput'

import TableFilters from '../../../../core/widgets/table/TableFilters'
import TableResults from '../../../../core/widgets/table/TableResults'
import Table from '../../../../core/widgets/table/Table'

const getEvents = (s) => {
	var items = s.entities.events.data || []
	let collection = []
	collection = values(items)
	return collection
}

/**
 * We can overwrite the component that takes care of generating the SubRow view
 * This is needed if we want to provide a more complex logic such as adding sockets
 * Important: make sure to propagate props since it receives its props from the table!
    
 */

const CustomSubView = (props) => {
	/**
	 * Return our factory design,
	 * passing along props we received from our table.
	 * and other props we want... */
	return (
		<>
			<FactorySubRow {...props} />
		</>
	)
}

/**
 * Define available statuses
 */
const statuses = [
	{ value: 'publishing', label: 'Publishing' },
	{ value: 'active', label: 'Active' },
	{ value: 'published', label: 'Published' },
	{ value: 'archived', label: 'Archived' },
	{ value: 'abandoned', label: 'Abandoned' },
]

/**
 * Define Occasion collection
 */
const _occasions = eventTypes.filter((el, i) => el.public === true)
let occasions = []
_occasions.forEach((el, i) => occasions.push({ value: el.type, label: el.label, svg: el.svg }))

/**
 * Define Roles collection
 */
const roles = [
	{ value: 'admin', label: 'Admins' },
	{ value: 'user', label: 'Users' },
	{ value: 'guest', label: 'Guests' },
]

const ListView = (props) => {
	return (
		<Container>
			<Flex w="full" py="1rem" justifyContent="space-between">
				<Heading variant="hero" size="lg" as="h1">
					Events
				</Heading>
				<Box maxW="400px" minW="300px">
					<FilterSearchInput label="Search Events..." tags={['uuid']} />
				</Box>
			</Flex>

			<Box w="full" bg="#F2F6FA" borderRadius="base" mb="0.5rem">
				<Flex justifyContent="space-between">
					<Flex>
						<FilterDisplayMode current="table" />
					</Flex>
					<Flex>
						<FilterDropdownV2 collection={statuses} label="Status" filter="status" />
						<FilterDropdownV2 collection={occasions} label="Occasion" filter="occasion" />
						<VerticalDivider />
						<FilterDropdownV2 collection={roles} label="Role" filter="role" />
					</Flex>
				</Flex>
			</Box>
			<Box pb="1rem" w="full">
				<TableFilters />
				<TableResults />
				<Table tabs={tabs} />
			</Box>
		</Container>
	)
}

/**
 * Setup Search Engine Context
 */
const ListViewRoot = () => {
	const config = {
		action: fetchEvents,
		slice: 'events',
		actionPrefix: 'EVENTS',
	}

	return (
		<SearchEngineProvider config={config}>
			<TableProvider columns={columns} hiddenColumns={['photos', 'contributors', 'status']}>
				<ListView />
			</TableProvider>
		</SearchEngineProvider>
	)
}

export default ListViewRoot
