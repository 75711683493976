import React from 'react' 
import styled, {css} from 'styled-components'


export const InlineItem = styled.div`
    display:block;
    font-size:15px;
    position:relative;
    margin-bottom:10px;
    min-height:15px;
    div.left {
        position:absolute;
        left:0;
        width:150px;
        text-align:right;
        float:left;
        font-weight:500;
    }

    div.right {
        position:relative;
        padding-left:165px;
    }

    clear:both;

    &:after {
        content:'';
        clear:both;
    }

    &:last-child {
        margin-bottom:0;
    }

`



export const InlineItemsWrapper = styled.div`
    display:block;
    position:relative;
    background-color:#F2F6FA;
    border-radius:5px;
    padding:10px;
    margin-bottom:10px;

    h3 { 
        color:#47596A;
        font-size:14px;
        text-transform:uppercase;
        font-weight:500;
        display:block;
        padding-bottom:10px;
        margin-bottom:10px;
        border-bottom:1px solid #DADDE1;
        clear:both;
        &:after {
            content:'';
            clear:both;
        }
        div.right {
            float:right;
            float: right;
            position: relative;
            top: -6px;
            font: inherit;
        }

        &.withActions {
            padding-top:5px;
            padding-bottom:16px;
        }
    }

    ${(props) => props.error && css`
        background-color: rgba(255,28,99, 0.1);

        ${InlineItem} {
            color:rgba(255,28,99,1)
        }
    `} 


`