import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { announcementListSchema } from './normalizr'
import requests from './requests'
import { ANNOUNCEMENTS } from './constants'
import { UI } from '../../../../api/ui/constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action
 */
function* handleFetchAnnouncements(action) {
	yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'info', message: 'Fetching announcements...' })

	try {
		const response = yield call(requests.fetchAnnouncements, action.payload)
		const normalizedData = normalize(response.data.data, announcementListSchema)
		const data = { ...response.data, ...normalizedData }
		delete data.data

		yield put({ type: ANNOUNCEMENTS.FETCH_SUCCEEDED, payload: data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: data.message })
	} catch (e) {
		yield put({ type: ANNOUNCEMENTS.FETCH_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Post a announcement
 * @param {*} action
 */
function* handlePostAnnouncement(action) {
	try {
		const response = yield call(requests.postAnnouncement, action.payload)
		const normalizedData = normalize([response.data.data], announcementListSchema)
		yield put({ type: ANNOUNCEMENTS.POST_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: ANNOUNCEMENTS.POST_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a announcement
 * @param {*} action
 */
function* handleUpdateAnnouncement(action) {
	try {
		const response = yield call(requests.updateAnnouncement, action.payload)

		const normalizedData = normalize([response.data.data], announcementListSchema)
		yield put({ type: ANNOUNCEMENTS.UPDATE_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: ANNOUNCEMENTS.UPDATE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a announcement
 * @param {*} action
 */
function* handleDeleteAnnouncement(action) {
	try {
		const response = yield call(requests.deleteAnnouncement, action.payload)
		// const normalizedData = normalize(response.data, adminUserListSchema)
		yield put({ type: ANNOUNCEMENTS.DELETE_SUCCEEDED, payload: response.data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: ANNOUNCEMENTS.DELETE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.message })
	}
}

/**
 * Users Saga Watcher
 */
export function* watchAnnouncementsFetch() {
	// while(true){
	yield takeLatest(ANNOUNCEMENTS.FETCH_REQUESTED, handleFetchAnnouncements)
	// }
}

export function* watchUpdateAnnouncement() {
	// while(true){
	yield takeEvery(ANNOUNCEMENTS.UPDATE_REQUESTED, handleUpdateAnnouncement)
	// }
}

export function* watchPostAnnouncement() {
	// while(true){
	yield takeEvery(ANNOUNCEMENTS.POST_REQUESTED, handlePostAnnouncement)
	// }
}

export function* watchDeleteAnnouncement() {
	// while(true){
	yield takeEvery(ANNOUNCEMENTS.DELETE_REQUESTED, handleDeleteAnnouncement)
	// }
}
