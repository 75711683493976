import styled, { css } from 'styled-components'

export const _baseListCheckboxStyles = css`
	ul.checkbox {
		li a > svg {
			display: inline-block;
			float: left;
			position: relative;
			top: -3px;
			left: 0;
			margin-right: 6px;
		}
	}
`

export const _baseListStyles = css`
	ul {
		z-index: 1000;
		display: block;
		background-color: white;
		border-radius: 5px;
		padding: 10px;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		top: 33px;
		position: absolute;
		margin: 0;
		list-style: none;
		min-width: 130px;
		left: 0;
		opacity: 0;
		visibility: hidden;

		-webkit-transform: scale(0.5);
		-ms-transform: scale(0.5);
		transform: scale(0.5);
		-webkit-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;

		li {
			padding: 3px 0;
			display: block;
			border-bottom: 1px dotted #b5bcc3;

			a {
				font-size: 14px;
				color: #47596a;
				display: block;
				padding: 5px 0;
			}

			a:hover {
				color: #0187ff;
			}
		}

		li:last-child {
			border-bottom: none;
		}
	}

	&:hover ul {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		opacity: 1;
		visibility: visible;
	}
`

// @keyframes scaleZ {
//     0% {
//       opacity: 0;
//       transform: scale(0);
//     }

//     80% {
//       transform: scale(1.07);
//     }

//     100% {
//       opacity: 1;
//       transform: scale(1);
//     }
//   }

export const StyledBaseFilter = styled.div`
	display: inline-flex;
	background-color: #f2f6fa;
	border-radius: 5px;
	height: 33px;
	padding-top: 4px;
	padding-left: 5px;
	padding-right: 5px;
	position: relative;

	span,
	ul {
		display: inline;
	}

	span {
		float: left;
		font-weight: 500;
		font-size: 12px;
		color: #b5bcc3;
		text-transform: uppercase;
		display: inline;
		top: 6px;
		padding: 0 8px;
		position: relative;
	}

	ul {
		list-style-type: none;
		li {
			display: inline-block;
		}
	}
`
