import React from 'react'
import { PropTypes } from 'prop-types'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { scrollToElement } from '@bit/vidday.vidday-helpers.utils.scroll-to-element'

Object.size = function (obj) {
	var size = 0,
		key
	for (key in obj) {
		if (obj.hasOwnProperty(key)) size++
	}
	return size
}

function FormInput(props) {
	const { name, label, required, errors, control, index, onChange, helperText } = props

	let isError = false
	let errorMessage = '',
		errorType

	// If we've provided an index to the input, we expect it to be part of an Array Field.
	// Therefore, we use this index to find which field to which correspond the error
	// The field name would be composed as `arrayName.index.fieldName`
	if (index !== undefined) {
		/** Obtain field name and key name, wrapping the index */
		let chain = name.split(`[${index}].`)
		// The result array should have 2 segments
		if (chain.length == 2 && chain[0] !== undefined && chain[1] !== undefined) {
			let arrayFieldKey = chain[0],
				fieldKey = chain[1]
			if (
				errors.hasOwnProperty(arrayFieldKey) &&
				errors[arrayFieldKey][index] &&
				errors[arrayFieldKey][index].hasOwnProperty(fieldKey)
			) {
				isError = true
				errorType = errors[arrayFieldKey][index][fieldKey].type
				errorMessage = errors[arrayFieldKey][index][fieldKey].message
			}
		}
	}
	// Otherwise, proceed as a normal (non-nested) input
	else if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorType = errors[name].type
		errorMessage = errors[name].message
	}

	return (
		<Controller
			{...props}
			as={
				<TextField
					margin={props.margin}
					autoComplete={props.autoComplete}
					InputProps={{
						onBlur: (e) => (props.handleBlur ? props.handleBlur(e.currentTarget.value) : () => {}),
						onFocus: (e) => scrollToElement({ current: e.currentTarget }, -120),
					}}
				/>
			}
			// value=""
			id={name}
			name={name}
			control={control}
			// defaultValue=""
			label={label}
			fullWidth={true}
			InputLabelProps={{
				className: required ? 'required-label' : '',
				required: required || false,
			}}
			error={isError}
			helperText={helperText ? errorMessage || helperText || ' ' : false} // This need to be a space within the string so we can render an empty space..
		/>
	)
}

FormInput.defaultProps = {
	/** Add space between form control by default */
	margin: 'normal',
	label: '',
}

FormInput.propTypes = {
	margin: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	errors: PropTypes.object,
}

export default FormInput
