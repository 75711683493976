import { MAIL } from './constants'

const postPublishedNotification = (data) => ({
    type: MAIL.POST_REQUESTED,
    payload:data,
})

export {
    postPublishedNotification,
}





