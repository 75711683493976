import React, { forwardRef, useRef, useState } from 'react'
import {
	InputRightElement,
	Icon,
	FormControl,
	InputGroup,
	Input,
	FormErrorMessage,
	FormHelperText,
} from '@chakra-ui/react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'

const SearchInput = forwardRef(({ onSubmit, value, label, defaultValue }, ref) => {
	const isError = false
	const errorMessage = ''
	const helperText = false

	/** Have we submitted the current value through blur or keydown? */
	const [submitted, setSubmitted] = useState(false)

	/** Reset the submitted status when the value changes */
	const onChange = (e) => {
		if (e.target.value != value) {
			setSubmitted(false)
		}
	}

	/** Trigger Submit when pressing leaving the input  */
	const onBlur = (e) => {
		if (!submitted) {
			// Prevent submit if this value was already submitted
			onSubmit(e)
			setSubmitted(true)
		}
	}

	/** Trigger Submit when pressing enter  */
	const onKeyDown = (e) => {
		var key = e.key
		console.log(key, 'key press', submitted)
		if (key == 'Enter') {
			if (!submitted) {
				// Prevent submit if this value was already submitted
				onSubmit(e)
				setSubmitted(true)
			}
		}
	}

	return (
		<FormControl isInvalid={isError}>
			<InputGroup>
				<Input
					minW="300px"
					ref={ref}
					size="sm"
					borderRadius="full"
					disabled={false}
					id="searchInput"
					placeholder={label ? label : 'Search...'}
					// value={val}
					defaultValue={defaultValue}
					onChange={onChange}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
				/>
				<InputRightElement height="32px" children={<Icon as={SearchRoundedIcon} />} />
			</InputGroup>

			{isError ? (
				<FormErrorMessage d="block" textAlign="left">
					{errorMessage}
				</FormErrorMessage>
			) : (
				helperText && <FormHelperText>{helperText}</FormHelperText>
			)}
		</FormControl>
	)
})

export default SearchInput
