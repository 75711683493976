import React from 'react' 

import {StyledAppView} from './styles'
import {useSecondaryNavigation} from '../SecondaryNavigation'
import {useRightPanel} from '../RightPanel'

const AppView = ({children}) => {

    const {isOpen:isSecondaryNavigationOpen} = useSecondaryNavigation()
    const {isOpen:isRightPanelOpen} = useRightPanel();
    console.log(isRightPanelOpen);
    
    return (
        <StyledAppView 
            isRightPanelOpen={isRightPanelOpen}
            isSecondaryNavigationOpen={isSecondaryNavigationOpen}>
                {children}
        </StyledAppView>
    )
}

export default AppView