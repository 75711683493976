import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../core'
import UnderConstruction from '../../../views/utils/UnderConstruction'
import ListView from './List'
/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.announcements',
	title: 'Announcements',
	path: '/core/announcements',
	parent: 'core',
})

export const announcementsSlice = buildEngineSlice({
	sliceName: 'announcements',
	actionPrefix: 'ANNOUNCEMENTS',
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route path="search/?:params" element={<UnderConstruction />} />
			<Route path=":id/delete" element={<UnderConstruction />} />
			<Route path=":id/edit" element={<UnderConstruction />} />
			<Route path=":id" element={<UnderConstruction />} />
			<Route index element={<ListView />} />
		</Routes>
	)
}
