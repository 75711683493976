/** Import our instance config */
// import instance from '../../../../../api/config'

import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = ['productId', 'countryCode', 'currencyCode', 'price', 'taxCodeCategoryId']
const acceptedKeysPUT = ['countryCode', 'currencyCode', 'price', 'taxCodeCategoryId']
const rejectedKeysPUT = ['productId']

/**
 * Fetch our product prices
 */
const fetchProductPrices = async (params) => {
	const response = await instance.get('/product-prices?' + params)
	return response
}

/**
 * Post a product price
 * @param {*} data
 * @returns
 */
const postProductPrice = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	console.log(filteredData, 'filteredData')
	const response = await instance.post('/product-prices', filteredData)
	return response
}

/**
 * Update a product price
 * @param {*} data
 * @returns
 */
const updateProductPrice = async (data) => {
	const filteredData = pick(data, acceptedKeysPUT)
	const response = await instance.put('/product-prices/' + data.id, filteredData)
	return response
}

/**
 * Delete a product price
 * @param {*} data
 * @returns
 */
const deleteProductPrice = async (id) => {
	const response = await instance.delete('/product-prices/' + id)
	return response
}

const requests = { fetchProductPrices, postProductPrice, updateProductPrice, deleteProductPrice }

export default requests
