import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { currencyListSchema } from './normalizr'
import requests from './requests'
import { CURRENCIES } from './constants'

/**
 * Saga worker
 * Fetch Currencies
 * @param {*} action 
 */
function* handleFetchCurrencies(action) {
    try {
        const response = yield call(requests.fetchCurrencies, action.payload)
        console.log(response, "response currencies")
        const normalizedData = normalize(response.data.data, currencyListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: CURRENCIES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: CURRENCIES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a currency
 * @param {*} action 
 */
 function* handlePostCurrency(action) {
    try {
        const response = yield call(requests.postCurrency, action.payload)
        yield put({ type: CURRENCIES.POST_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: CURRENCIES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a currency
 * @param {*} action 
 */
 function* handleUpdateCurrency(action) {
    try {
        const response = yield call(requests.updateCurrency, action.payload)
        
        const normalizedData = normalize([response.data.data], currencyListSchema)
        
        yield put({ type: CURRENCIES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: CURRENCIES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a currency
 * @param {*} action 
 */
 function* handleDeleteCurrency(action) {
    try {
        const response = yield call(requests.deleteCurrency, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: CURRENCIES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: CURRENCIES.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchCurrenciesFetch(){
    // while(true){
        yield takeLatest(CURRENCIES.FETCH_REQUESTED, handleFetchCurrencies)
    // }
}

export function* watchUpdateCurrency(){
    // while(true){
        yield takeEvery(CURRENCIES.UPDATE_REQUESTED, handleUpdateCurrency)
    // }
}

export function* watchPostCurrency(){
    // while(true){
        yield takeEvery(CURRENCIES.POST_REQUESTED, handlePostCurrency)
    // }
}

export function* watchDeleteCurrency(){
    // while(true){  
        yield takeEvery(CURRENCIES.DELETE_REQUESTED, handleDeleteCurrency)
    // }
}