import styled, {css} from 'styled-components'
import {_baseActionButtonStyles} from '../Button/styles'


// const _baseActionButtonStyles = css`
//     font-size:14px;
//     text-align:center;
//     border:none;
//     background-color:#F2F6FA;
//     padding: 5px 10px;
//     display:inline-flex;
//     height:28px;
//     border-radius:5px;
//     background-color:#0187FF;
//     color:white;
//     font-weight:500;
//     margin-right:5px;
//     position:relative;
//     border:1px solid #0187FF;

//     ${(props) => props.revert && css`
//         background-color:white;
//         border:1px solid #0187FF;
//         color:#0187FF;

//         &:hover {
//             background-color:rgba(1,135,255, 0.1);
//             color:#0187FF;
//         }
//     `}

//     ${(props) => props.delete && css`
//         background-color:white;
//         border:1px solid #FF1C63;
//         color:#FF1C63;

//         svg {
//             color:#FF1C63;
//         }
//         &:hover {
//             background-color:rgba(255,28,99, 0.1);
//             color:#FF1C63;
//         }
//     `}
// `

// const StyledButton = styled.button`
//     ${_baseActionButtonStyles};
// `

export const StyledIconButton = styled.button`
    
    ${_baseActionButtonStyles};

    padding-left:28px;

    svg {
        position:absolute;
        left:5px;
        top:3px;
    }

    ${(props) => props.delete && css`
        svg {
            color:#FF1C63;
        }
    `}

    &.right {
        margin-top:5px;
        float:right;
    }


    
`