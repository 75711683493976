import React from 'react'
import styled, { css } from 'styled-components'
// import PromotionForm from '../../forms/Promotion'
import PromotionForm from '../../src/PromotionForm'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { updatePromotion } from '../../api/actions'

const Edit = ({ row }) => {
	const dispatch = useDispatch()

	const onSubmit = (data) => {
		const values = { id: row.original.id, ...data }
		if (moment(values.expires).isValid()) {
			let momentDate = moment(values.expires)
			let obj = { ...values, expires: momentDate.toISOString() }
			dispatch(updatePromotion(obj))
		}
	}

	return (
		<div>
			{/* <PromotionForm data={row.original} /> */}
			<PromotionForm data={row.original} onSubmit={onSubmit} />
		</div>
	)
}
export default Edit
