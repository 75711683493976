import React from 'react' 
import styled, {css} from 'styled-components'


export const StyledAppView = styled.div`
    background-color:white;
    display:block;
    height:100%;
    position:relative;
    margin-left:300px;
    
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    ${(props) => props.isSecondaryNavigationOpen && css`
        margin-left:550px;
    `}

    ${(props) => props.isRightPanelOpen && css`
        margin-right:300px;
    `} 
    
`