/* **************************************
 *  LIBRARIES
 * ************************************ */

import { combineReducers } from 'redux'
// 'A reducer that keeps track of Router state.'
// import { connectRouter } from 'connected-react-router'
// Import Form Redux from redux-form to keep our forms in sync with the redux state
import { reducer as formReducer } from 'redux-form'

import * as types from '@bit/vidday.vidday-store.constants'
import { splitSlices } from '../core/utils'
// console.log(splitSlices, "split slices")
/* **************************************
 *  LOAD REDUCERS
 * ************************************ */

/** Modules */
// import { reducer as auth } from '@bit/vidday.vidday-store.modules.auth'

/** TEST REDUCER FOR SAGA IMPLEMENTATION */
import usersReducer from '../sagas/reducers'

/** Administration Area */
import { reducer as adminUsers } from '@bit/vidday.vidday-admin-store.modules.users'
import { reducer as adminPromotions } from '@bit/vidday.vidday-admin-store.modules.promotions'
import { reducer as adminAnnouncements } from '@bit/vidday.vidday-admin-store.modules.announcements'
import { reducer as adminEvents, reducerRenderJobs } from '@bit/vidday.vidday-admin-store.modules.events'
import { reducer as modalReducer } from '@bit/vidday.vidday-ui.modal'

import { reducer as entities } from '@bit/vidday.vidday-store.behaviors.entities'
import appsReducer from '../core/reducers/apps'
import interfaceReducer from '../interface/reducer'
import authReducer from '../apps/auth/reducers/auth'
import temporaryUser from '../apps/auth/reducers/temporaryUser'

import formReducers from './forms'
import entitiesReducer from './entities/index'

import engine from '../apps/core/accounts/users/reducer'

/** Import list view reducers */
import { usersSlice } from '../apps/core/accounts/users'
import { announcementsSlice } from '../apps/core/announcements'
import { promotionsSlice } from '../apps/core/promotions'
import { countriesSlice } from '../apps/core/internationalization/countries'
// import { regionsSlice } from '../apps/core/internationalization/regions'
// import { themesSlice } from '../apps/vidday.app/themes'
// import { themeVersionsSlice } from '../apps/vidday.app/themeVersions'
// import { themeCategoriesSlice } from '../apps/vidday.app/themeCategories'
// import { nexusSlice } from '../apps/core/billing/nexus'
// import { invoicesSlice } from '../apps/core/billing/invoices'
// import { currenciesSlice } from '../apps/core/internationalization/currencies'
// import { languagesSlice } from '../apps/core/internationalization/languages'
import { eventsSlice } from '../apps/vidday.app/events'
// import { renderJobsSlice } from '../apps/vidday.app/eventRenderJobs'
// import { productsSlice } from '../apps/core/sales/products'
// import { productPricesSlice } from '../apps/core/sales/productPrices'
// import { taxCodeCategoriesSlice } from '../apps/core/sales/taxCodeCategories'
// import { taxCodeRatesSlice } from '../apps/core/sales/taxCodeRates'
import flashMessageReducer from '../api/ui/reducers'

/* **************************************
 *  BUILD FINAL APPLICATION STATES
 * ************************************ */

const searchEngineSlices = {
	usersSlice,
	announcementsSlice,
	promotionsSlice,
	countriesSlice,
	// regionsSlice,
	// themesSlice,
	// themeVersionsSlice,
	// themeCategoriesSlice,
	// nexusSlice,
	// invoicesSlice,
	// currenciesSlice,
	// languagesSlice,
	eventsSlice,
	// renderJobsSlice,
	// productsSlice,
	// productPricesSlice,
	// taxCodeCategoriesSlice,
	// taxCodeRatesSlice,
}

const engineSlices = splitSlices(searchEngineSlices)

/** Vidday App */
const createRootReducer = (history) =>
	combineReducers({
		/* connected-react-router */
		// 'router': connectRouter(history),

		/** Define Reducers Hooks for forms */
		'forms': formReducers,
		// All Authed Events and Media

		'entities': entitiesReducer,
		/** Authenticated user */
		'auth': authReducer,
		'temporaryUser': temporaryUser,
		/** Interface Reducer */
		'interface': interfaceReducer,
		/** apps Reducer */
		'apps': appsReducer,

		'flashMessages': flashMessageReducer,
		/** TEST FOR SAGA IMPLEMENTATION */
		// sagauser:usersReducer,
		// engine:engine,
		// data:combineReducers({users:usersEntities}),
		'@engine': combineReducers(engineSlices),
	})

export default createRootReducer
