import React from 'react' 
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../../core/widgets/others/SubRowHeader'

const Details = (props) => {
    const data = props.row.original;
    
    const listSocials = [
        {
            name:'Facebook ID',
            content:data.facebookId,
        },
        {
            name:'Google ID',
            content:data.googleId,
        },
        {
            name:'Instagram ID',
            content:data.instagramId,
        },
        {
            name:'Twitter ID',
            content:data.twitterId,
        },
        {
            name:'Yammer ID',
            content:data.yammerId,
        },
    ]

    const listUser = [
        {
            name:'ID',
            content:data.id,
        },
        {
            name:'Role',
            content:data.role,
        },
        {
            name:'Is Active',
            content:data.isActive == false ? 'No' : 'Yes',
        },
        {
            name:'Is New',
            content:data.isNew == false ? 'No' : 'Yes',
        },
        {
            name:'Is Registered',
            content:data.isRegistered == false ? 'No' : 'Yes',
        },
        {
            name:'Email',
            content:data.email,
        },
        {
            name:'Temporary Email',
            content:data.temporaryEmail,
        },
        {
            name:'Blocked',
            content:data.blocked == false ? 'No' : 'Yes',
        },
        {
            name:'First Authentication',
            content:data.firstAuthentication == false ? 'No' : 'Yes',
        },
        {
            name:'Last Active At',
            content:data.lastActive,
        },
        {
            name:'Activated At',
            content:data.activatedAt,
        },
        {
            name:'Updated At',
            content:data.updatedAt,
        }, 
        {
            name:'Created At',
            content:data.createdAt,
        },        
    ]


    return (
        <>
            <SubRowHeader>
                <p>Details</p>  
            </SubRowHeader>

            <InlineItems list={listUser} />
            <InlineItems list={listSocials} />
        </>
    )
}

export default Details