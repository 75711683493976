
import { normalize, schema } from 'normalizr'


const taxCodeCategorySchema = new schema.Entity(
	'taxCodeCategories',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

// Represent all related data coming through the fetch request when loading the user

const taxCodeCategoryListSchema = new schema.Array(taxCodeCategorySchema)

export {taxCodeCategoryListSchema}