import React from 'react' 

import {StyledSelectedRowActions} from './styles'

const SelectedRowActions = ({selectedRows, selectedIds}) => {
    const count = Object.keys(selectedIds).length
    if(count > 0) {
        return (
            <StyledSelectedRowActions>
                <a href="#"><strong>{count}</strong> selected entries</a>
            </StyledSelectedRowActions>
        )
    }
    return null;
}

export default SelectedRowActions