const INVOICES = {
    /** Fetch all nexus (includes filters) */
    FETCH_REQUESTED: 'INVOICES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'INVOICES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'INVOICES_FETCH_FAILED',

    /** Post a nexus */
    POST_REQUESTED: 'INVOICE_POST_REQUESTED',
    POST_SUCCEEDED:'INVOICE_POST_SUCCEEDED',
    POST_FAILED: 'INVOICE_POST_FAILED',

    /** Update a nexus */
    UPDATE_REQUESTED: 'INVOICE_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'INVOICE_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'INVOICE_UPDATE_FAILED',

    /** Delete a nexus */
    DELETE_REQUESTED: 'INVOICE_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'INVOICE_DELETE_SUCCEEDED',
    DELETE_FAILED: 'INVOICE_DELETE_FAILED',


    REFUND_REQUESTED:'INVOICE_REFUND_REQUESTED',
    REFUND_SUCCEEDED:'INVOICE_REFUND_SUCCEEDED',
    REFUND_FAILED: 'INVOICE_REFUND_FAILED',

    REFUND_ITEM_REQUESTED:'INVOICE_REFUND_ITEM_REQUESTED',
    REFUND_ITEM_SUCCEEDED:'INVOICE_REFUND_ITEM_SUCCEEDED',
    REFUND_ITEM_FAILED: 'INVOICE_REFUND_ITEM_FAILED',
}

export {INVOICES}