import {acceptedKeys, extractKeys, serialize} from './searchEngine'

/**
 * Pick util
 * Pass in an object and an array of accepted keys
 * Returns a new object with only keys contained in array
 */
export const pick = (obj, keys) => {
    return keys.map(k => k in obj ? {[k]: obj[k]} : {})
               .reduce((res, o) => Object.assign(res, o), {});
}

/**
 * Reject util
 * Pass in an object and an array of rejected keys
 * Returns a new object with only keys not contained in array
 */
 export const reject = (obj, keys) => {
	return Object.keys(obj)
		.filter(k => !keys.includes(k))
		.map(k => Object.assign({}, {[k]: obj[k]}))
		.reduce((res, o) => Object.assign(res, o), {});
}


/** 
 * Export our utils functions and vars related to the search Engine
 * */
export const searchEngine = {acceptedKeys, extractKeys, serialize}




/**
 * KEEP CODE BELOW WHICH IS START OF WORK TO BE ABLE TO AUTO-GENERATE NESTED REDUCERS
 * REVIEW IN A FEW WEEKS IF STILL NEEDED
 */

/**
 * TO DO : FULLY SUPPORT NESTED REDUCERS WITH ACTIONS BEING PASSED DOWN
 */
 const searchEngineSlices = {
	// usersSlice,
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
 export function isObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}
  
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
					mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}


function expand(str, val = {}) {
	return str.split('.').reduceRight((acc, currentValue) => {
	  return { [currentValue]: acc }
	}, val)
  }

const splitSlices = (slices) => {
	let result = {}
	/** For each slice, determine if we need to deconstruct the string name into a nested object.  */
	Object.values(slices).forEach((el, i) => {
		if(!el.name) throw new Error('Please make sure you\'ve provided a slice with a name.')
		/** Do we need to expand the object? */
		const shouldExpand = el.name.indexOf('.')
		if(shouldExpand != -1) {
			let expanded = expand(el.name, el.reducer);
			result = mergeDeep(result, expanded);
		}
		/** Otherwise, we simply need to add it to our result */
		else {
			result[el.name] = el.reducer;
		}
	});
	return result;
};

export {splitSlices}

// const engineSlices = splitSlices(searchEngineSlices)

