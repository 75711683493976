import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
	/** Define default typo/font */
	typography: {
		fontFamily: ['"Source Sans Pro"', 'Roboto', '"Helvetica Neue"', 'sans-serif'],
	},

	/** Define default zIndex  */
	zIndex: {
		mobileStepper: 1000,
		speedDial: 1050,
		appBar: 1100,
		drawer: 1200,
		modal: 2147483640, // in front of Live Chat
		snackbar: 1400,
		tooltip: 1500,
	},

	/** Default theme colors overwrite go here */
	palette: {
		/** Correspond to the blue color */
		primary: {
			light: 'rgba(1,135,255, 0.1)',
			main: '#0187FF',
		},
		error: {
			light: 'rgba(255,28,99,0.1)',
			main: '#FF1C63',
		},
		green: {
			light: '#04D898', //'rgba(255,28,99,0.1)',
			main: '#04D898',
		},
		// error: {
		// 	light: 'rgba(255,28,99,0.1)',
		// 	main: '#FF1C63',
		// },
	},

	props: {
		/** Styles of <ButtonBase> */
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application 💣!
		},
		/** Styles of <FilledInput> */
		MuiFilledInput: {
			/** Deactivate the underline bar for filled inputs */
			disableUnderline: true,
		},
	},

	overrides: {
		MuiFormControl: {
			marginNormal: {
				marginTop: '4px !important',
				marginBottom: '4px !important',
			},
		},

		MuiFormHelperText: {
			root: {
				// height: 0,
				// '&$error': {
				// 	height: '100% !important'
				// }
			},
			contained: {
				marginLeft: '12px',
				marginRight: '12px',
			},
		},

		MuiTypography: {
			body1: {
				color: 'inherit !important',
			},
		},
		// MuiGrid-root MuiPickersToolbar-dateTitleContainer MuiGrid-container MuiGrid-align-items-xs-flex-end
		MuiPickersToolbar: {
			dateTitleContainer: {
				justifyContent: 'space-between',
			},
		},

		MuiPickersClock: {
			pmButton: {
				maxHeight: '42px',
			},
			amButton: {
				maxHeight: '42px',
			},
		},

		/** Styles for IconButton */
		MuiIconButton: {},

		MuiCheckbox: {
			root: {
				'&:hover, &.Mui-focusVisible': {
					'backgroundColor': 'transparent !important',
					'& > span > svg > path': {
						fill: '#0187ff',
					},
				},
				'&$checked': {
					'& input + *': { backgroundColor: 'transparent' },
					'& input:focus + *': {
						backgroundColor: 'transparent',
					},
				},
			},
		},

		/** Select element */
		MuiSelect: {
			root: {
				/** Align text to the left (instead of being centered) */
				textAlign: 'left',
			},
		},
		/** Styles for <FilledInput> */

		MuiFilledInput: {
			// .MuiFilledInput-input:-webkit-autofill
			input: {
				'&:-webkit-autofill': {
					'border-top-left-radius': 'inherit',
					'border-top-right-radius': 'inherit',
					'border-bottom-left-radius': 'inherit',
					'border-bottom-right-radius': 'inherit',
					'borderRadius': '4px 4px 4px 4px',
				},
				/** Update the background-color when the input is focused */
				'&:focus': {
					'backgroundColor': '#e5f3ff',
					'border-top-left-radius': 'inherit',
					'border-top-right-radius': 'inherit',
					'border-bottom-left-radius': 'inherit',
					'border-bottom-right-radius': 'inherit',
				},
			},
			root: {
				/** Set default border-radius for all sides (opposed to just the top) */
				'borderRadius': '4px 4px 4px 4px',
				/** Update the background-color when the input has an error */
				'&$error': {
					'backgroundColor': 'rgba(255,28,99,0.1)',
					/** Change color of the icon when the input has an error */
					'& .MuiIconButton-root': { color: '#FF1C63' },
					/** Enforce the error color, even when the field is focused */
					'&$focused': {
						'& .MuiIconButton-root': { color: '#FF1C63' },
					},
				},
				/** Update the background-color when the input is focused */
				'&$focused': {
					'backgroundColor': '#e5f3ff',
					/** Change color of the icon when focused */
					'& .MuiIconButton-root': {
						color: '#0187FF',
					},
				},
				/** Override the IconButton styles when inside a Filled Input */
				'& .MuiIconButton-root': {
					borderRadius: 0,
				},
				/** Override the IconButton styles when inside a Filled Input */
				'& .MuiIconButton-root:hover': {
					color: 'black',
				},
			},
			/** reach the 'underline' prop */
			underline: {
				/** remove underline border on mouse hover */
				'&:hover:before': {
					borderBottom: 'none',
				},
				/** remove the default underline border on the :before */
				'&:before': {
					borderBottom: 'none',
				},
			},
			/** react  the 'adornedEnd' prop (icon located at the end of an input) */
			adornedEnd: {
				/** Remove default padding to the right for the icon */
				// paddingRight: 0,
			},
		},
	},
})

export const themeLight = createMuiTheme({
	/** Define default typo/font */
	typography: {
		fontFamily: ['"Source Sans Pro"', 'Roboto', '"Helvetica Neue"', 'sans-serif'],
	},

	/** Define default zIndex  */

	zIndex: {
		mobileStepper: 1000,
		speedDial: 1050,
		appBar: 1100,
		drawer: 1200,
		modal: 2147483640, // Re-position higher than bottom submit buttons bar and chat + header
		snackbar: 1400,
		tooltip: 1500,
	},

	/** Default theme colors overwrite go here */
	palette: {
		/** Correspond to the blue color */
		primary: {
			light: 'rgba(1,135,255, 0.1)',
			main: '#0187FF',
		},
		error: {
			light: 'rgba(255,28,99,0.1)',
			main: '#FF1C63',
		},
		type: 'light',
	},

	props: {
		/** Styles of <ButtonBase> */
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application 💣!
		},
		/** Styles of <FilledInput> */
		MuiFilledInput: {
			/** Deactivate the underline bar for filled inputs */
			disableUnderline: true,
		},
	},

	overrides: {
		MuiFormControl: {
			marginNormal: {
				marginTop: '4px !important',
				marginBottom: '4px !important',
			},
		},

		MuiFormHelperText: {
			root: {
				// height: 0,
				// '&$error': {
				// 	height: '100% !important'
				// }
			},
			contained: {
				marginLeft: '12px',
				marginRight: '12px',
			},
		},

		/** Styles for IconButton */
		MuiIconButton: {},

		MuiCheckbox: {
			root: {
				'color': 'white !important',
				'&:hover, &.Mui-focusVisible': {
					'backgroundColor': 'transparent !important',
					'& > span > svg > path': {
						fill: '#a4cff6',
					},
				},
				'&$checked': {
					'& input + *': { backgroundColor: 'transparent' },
					'& input:focus + *': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiTypography: {
			root: {
				color: 'white',
			},
		},
		/** Select element */
		MuiSelect: {
			root: {
				/** Align text to the left (instead of being centered) */
				textAlign: 'left',
			},
		},
		/** Styles for <FilledInput> */
		MuiFilledInput: {
			input: {
				'&:-webkit-autofill': {
					'border-top-left-radius': 'inherit',
					'border-top-right-radius': 'inherit',
					'border-bottom-left-radius': 'inherit',
					'border-bottom-right-radius': 'inherit',
				},
				/** Update the background-color when the input is focused */
				'&:focus': {
					'backgroundColor': '#e5f3ff',
					'border-top-left-radius': 'inherit',
					'border-top-right-radius': 'inherit',
					'border-bottom-left-radius': 'inherit',
					'border-bottom-right-radius': 'inherit',
				},
			},

			root: {
				'backgroundColor': 'white',
				'opacity': 1,
				'&:hover': {
					backgroundColor: 'white',
				},
				/** Set default border-radius for all sides (opposed to just the top) */
				'borderRadius': '4px 4px 4px 4px',
				/** Update the background-color when the input has an error */
				'&$error': {
					'backgroundColor': '#FFE7EF', //'rgba(255,28,99,0.1)',
					/** Change color of the icon when the input has an error */
					'& .MuiIconButton-root': { color: '#FF1C63' },
					/** Enforce the error color, even when the field is focused */
					'&$focused': {
						'& .MuiIconButton-root': { color: '#FF1C63' },
					},
				},
				/** Update the background-color when the input is focused */
				'&$focused': {
					'backgroundColor': '#e5f3ff', // 'rgba(1,135,255, 0.1)',
					/** Change color of the icon when focused */
					'& .MuiIconButton-root': {
						color: '#0187FF',
					},
				},
				/** Override the IconButton styles when inside a Filled Input */
				'& .MuiIconButton-root': {
					borderRadius: 0,
				},
				/** Override the IconButton styles when inside a Filled Input */
				'& .MuiIconButton-root:hover': {
					color: 'black',
				},
			},
			/** reach the 'underline' prop */
			underline: {
				/** remove underline border on mouse hover */
				'&:hover:before': {
					borderBottom: 'none',
				},
				/** remove the default underline border on the :before */
				'&:before': {
					borderBottom: 'none',
				},
			},
			/** react  the 'adornedEnd' prop (icon located at the end of an input) */
			adornedEnd: {
				/** Remove default padding to the right for the icon */
				// paddingRight: 0,
			},
		},
	},
})

export default theme
