
import axios from 'axios'
import { pick, reject } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = []

const rejectedKeysPOST = ['timezone']
const acceptedKeysPUT = []
const rejectedKeysPUT = ['createdAt', 'updatedAt', 'timezone', 'id']

/**
 * Fetch our announcement
 */
const fetchAnnouncements = async (params) => {
	const response = await instance.get('/announcements?' + params)
	return response
}

/**
 * Post a announcement
 * @param {*} data
 * @returns
 */
const postAnnouncement = async (data) => {
	const filtered = reject(data, rejectedKeysPOST);
	const response = await instance.post('/announcements', filtered)
	return response
}

/**
 * Update a announcement
 * @param {*} data
 * @returns
 */
const updateAnnouncement = async (data) => {
	const filtered = reject(data, rejectedKeysPUT)
	const response = await instance.put('/announcements/' + data.id, filtered)
	return response
}

/**
 * Delete a announcement
 * @param {*} id
 * @returns
 */
const deleteAnnouncement = async (id) => {
	const response = await instance.delete('/announcements/' + id)
	return response
}

const requests = { fetchAnnouncements, postAnnouncement, updateAnnouncement, deleteAnnouncement }

export default requests
