/** Import our instance config */
// import instance from '../../../../../api/config'

import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

// const acceptedKeys = ['slug', 'description', 'duration', 'name', 'group']
// const acceptedKeysPUT = ['']
const rejectedKeysPUT = ['createdAt', 'updatedAt', 'code']

/**
 * Fetch our languages
 */
const fetchLanguages = async (params) => {
	const response = await instance.get('/geo-languages?' + params)
	return response
}

/**
 * Post a language
 * @param {*} data
 * @returns
 */
const postLanguage = async (data) => {
	const response = await instance.post('/geo-languages', data)
	return response
}

/**
 * Update a language
 * @param {*} data
 * @returns
 */
const updateLanguage = async (data) => {
	const filtered = reject(data, rejectedKeysPUT)
	const response = await instance.put('/geo-languages/' + data.code, filtered)
	return response
}

/**
 * Delete a language
 * @param {*} data
 * @returns
 */
const deleteLanguage = async (code) => {
	const response = await instance.delete('/geo-languages/' + code)
	return response
}

const requests = { fetchLanguages, postLanguage, updateLanguage, deleteLanguage }

export default requests
