import React from 'react'
import Core, { buildEngineSlice } from '../../../core'
import { Link, Route, Routes } from 'react-router-dom'
import UnderConstruction from '../../../views/utils/UnderConstruction'
import ListView from './List'

/**
 * Define Module
 */
export const instance = Core.defineModule({
	name: 'vidday.themes',
	title: 'Themes',
	path: '/vidday/themes',
	parent: 'vidday',
})

export const themesSlice = buildEngineSlice({
	sliceName: 'themes',
	actionPrefix: 'THEMES',
	// initialState:{
	//     /** # items per page */
	//     pageSize:30,
	//     /** default # items */
	//     defaultPageSize:30,
	// },
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
