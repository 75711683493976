/** Import our instance config */
// import instance from '../../../../../api/config'

import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

// const acceptedKeys = ['slug', 'description', 'duration', 'name', 'group']
// const acceptedKeysPUT = ['']
const rejectedKeysPUT = ['createdAt', 'updatedAt', 'code']

/**
 * Fetch our currencies
 */
const fetchCurrencies = async (params) => {
	const response = await instance.get('/geo-currencies?' + params)
	return response
}

/**
 * Post a currency
 * @param {*} data
 * @returns
 */
const postCurrency = async (data) => {
	const response = await instance.post('/geo-currencies', data)
	return response
}

/**
 * Update a currency
 * @param {*} data
 * @returns
 */
const updateCurrency = async (data) => {
	const filtered = reject(data, rejectedKeysPUT);
	const response = await instance.put('/geo-currencies/' + data.code, filtered)
	return response
}

/**
 * Delete a currency
 * @param {*} code
 * @returns
 */
const deleteCurrency = async (code) => {
	const response = await instance.delete('/geo-currencies/' + code)
	return response
}

const requests = { fetchCurrencies, postCurrency, updateCurrency, deleteCurrency }

export default requests
