import React from 'react'
import {StyledContainer} from './styles'


const Container = ({children}) => {
    return (
        <StyledContainer>{children}</StyledContainer>
    )
}

export default Container