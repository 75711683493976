import {useEffect, useState} from 'react'

const useCompatibilityHook = () => {
	const [isCompatible, setIsCompatible] = useState(true)
	const [hasWindow, setHasWindow] = useState(true)

	/** Check if the browser is compatible */
	useEffect(() => {
		// This is true when using internet explore 6 - 11
		let isIE = false || !!document.documentMode
		// TODO detect other incompatible browsers here
		// if this isIE is true, set isCompatible to false
		setIsCompatible({ isCompatible: !isIE })
		setHasWindow({ hasWindow: true })
	}, [])

    

	return isCompatible
}

export default useCompatibilityHook