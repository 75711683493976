import React from 'react'

import { useDispatch } from 'react-redux'

/** Table components (react-table) */
import { useTable, useSortBy, usePagination, useRowSelect, useExpanded } from 'react-table'

/** Table components (local) */
import FactorySubRow from '../FactorySubRow'

/** Custom hooks */
import useInterfacePanel from '../../../../interface/hooks/useInterfacePanel'

/** Import Filters */
import FilterItemPerView from '../../filters/FilterItemPerView'
import FilterPageNumber from '../../filters/FilterPageNumber'
import FilterSettingButton from '../../filters/FilterSettingButton'
import VerticalDivider from '../../filters/VerticalDivider'
import ButtonEditColumns from '../../buttons/ButtonEditColumns'
import ResultsParagraph from '../../others/ResultsParagraph'
import ButtonExport from '../../buttons/ButtonExport'
import SelectedRowActions from '../../others/SelectedRowsActions'
import { StyledTable } from '../styles'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import { FiltersWrapper, HR } from '../../styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTableContext } from '../../../../contexts/TableProvider'
import { Flex, HStack } from '@chakra-ui/react'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'

export const TableFiltersV2 = () => {
	const { togglePanel } = useInterfacePanel()
	const dispatch = useDispatch()

	/**
	 * Retrieve table features
	 */
	const {
		allColumns,
		lastPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTableContext()

	console.log(allColumns, lastPage, gotoPage, nextPage, previousPage, setPageSize, pageIndex, pageSize, 'TESTEUR')

	return (
		<Flex w="full" justifyContent="space-between">
			<HStack spacing="0.5rem">
				<FilterItemPerView setPageSize={setPageSize} pageSize={pageSize} />
				<FilterPageNumber
					lastPage={lastPage}
					pageIndex={pageIndex}
					previousPage={previousPage}
					nextPage={nextPage}
					gotoPage={gotoPage}
				/>
			</HStack>
			<HStack spacing="0.5rem">
				<ButtonExport />
				<ButtonEditColumns columns={allColumns} />
				<VerticalDivider />
				<FilterSettingButton onClick={() => togglePanel('rightPanel')} />
			</HStack>
		</Flex>
	)
}

export const TableResultsV2 = () => {
	const { collection } = useSearchEngineContext()
	const {
		selectedFlatRows,
		state: { selectedRowIds },
	} = useTableContext()

	return (
		<Flex w="full" justifyContent="space-between" py="1rem">
			<Flex>
				<ResultsParagraph count={collection.length} />
			</Flex>
			<Flex>
				<SelectedRowActions selectedIds={selectedRowIds} selectedRows={selectedFlatRows} />
			</Flex>
		</Flex>
	)
}

export const TableV2 = ({ tabs, SubRowView }) => {
	/**
	 * Retrieve table features
	 */
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		allColumns,
		visibleColumns,
		/* Instead of using 'rows', we'll use page 
        which has only the rows for the active page */
		page,
		/** Other helpers */
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds, expanded },
	} = useTableContext()

	return (
		<StyledTable>
			<table {...getTableProps()}>
				<thead>
					{
						// Loop over the header rows
						headerGroups.map((headerGroup) => (
							// Apply the header row props
							<tr {...headerGroup.getHeaderGroupProps()}>
								{
									// Loop over the headers in each row
									headerGroup.headers.map((column) => (
										// Apply the header cell props.
										// Add the sorting props to control sorting.
										<th
											{...column.getHeaderProps(column.getSortByToggleProps())}
											style={{ width: column.width + 'px' }}>
											{
												// Render the header
												column.render('Header')
											}
											{/* Add a sort direction indicator */}
											<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
										</th>
									))
								}
							</tr>
						))
					}
				</thead>
				{/* Apply the table body props */}
				<tbody {...getTableBodyProps()}>
					{
						// Loop over the table rows
						page.map((row) => {
							// Prepare the row for display
							prepareRow(row)

							console.log(row.getRowProps().key, 'key here')
							return (
								// Apply the row props
								<React.Fragment key={row.getRowProps().key}>
									<tr {...row.getRowProps()}>
										{
											// Loop over the rows cells
											row.cells.map((cell) => {
												// Apply the cell props
												return (
													<td {...cell.getCellProps()}>
														{
															// Render the cell contents
															cell.render('Cell')
														}
													</td>
												)
											})
										}
									</tr>

									{/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
									{row.isExpanded &&
										(SubRowView ? (
											<SubRowView row={row} visibleColumns={visibleColumns} tabs={tabs} />
										) : (
											<FactorySubRow row={row} visibleColumns={visibleColumns} tabs={tabs} />
										))}
								</React.Fragment>
							)
						})
					}
				</tbody>
			</table>
		</StyledTable>
	)
}

const Table = ({ data, columns, hiddenColumns, tabs, SubRowView }) => {
	const { togglePanel } = useInterfacePanel()

	const dispatch = useDispatch()
	console.log(data, 'data received', togglePanel)

	/**
	 * Memoize our data collection
	 */
	const memoizedData = React.useMemo(() => data, [data])

	/**
	 * Memoize our columns definition
	 */
	const memoizedcolumns = React.useMemo(() => columns, [])

	/**
	 * Create our table instance
	 */
	const tableInstance = useTable(
		{
			/** Pass in the columns */
			columns: memoizedcolumns,
			/** Pass in the data */
			data: memoizedData,
			/** Pass in the initial State */
			initialState: {
				hiddenColumns: hiddenColumns,
			},
			/** Do not auto-reset columns on change */
			autoResetHiddenColumns: false,

			autoResetExpanded: false,
		},

		/** Add sorting capability */
		useSortBy,
		/**  We can useExpanded to track the expanded state */
		useExpanded,
		/** Add Pagination capability */
		usePagination,
		/** Add Row Selection capability */
		useRowSelect,

		/** Append additional column for row selection */
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					width: 15,
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				{
					// Make an expander cell
					Header: () => null, // No header
					id: 'expander', // It needs an ID
					width: 15,
					Cell: ({ row }) => {
						// Use Cell to render an expander for each row.
						// We can use the getToggleRowExpandedProps prop-getter
						// to build the expander.
						return (
							<span {...row.getToggleRowExpandedProps()} className="expandableIcon">
								{row.isExpanded ? (
									<ArrowDownwardIcon fontSize="small" />
								) : (
									<MoreVertIcon fontSize="small" />
								)}
							</span>
						)
					},
				},
				...columns,
			])
		}
	)

	/**
	 * Retrieve table features
	 */
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		allColumns,
		visibleColumns,
		/* Instead of using 'rows', we'll use page 
        which has only the rows for the active page */
		page,
		/** Other helpers */
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds, expanded },
	} = tableInstance

	return (
		<>
			<HR />
			<FiltersWrapper>
				<div className="left">
					<FilterItemPerView setPageSize={setPageSize} pageSize={pageSize} />
					<FilterPageNumber
						pageCount={pageCount}
						pageIndex={pageIndex}
						previousPage={previousPage}
						nextPage={nextPage}
						gotoPage={gotoPage}
					/>
				</div>
				<div className="right">
					<ButtonExport />
					<VerticalDivider />
					<ButtonEditColumns columns={allColumns} />
					<VerticalDivider />
					<FilterSettingButton onClick={() => togglePanel('rightPanel')} />
				</div>
			</FiltersWrapper>
			<HR />
			<FiltersWrapper>
				<div className="left">
					<ResultsParagraph count={data.length} />
				</div>
				<div className="right">
					<SelectedRowActions selectedIds={selectedRowIds} selectedRows={selectedFlatRows} />
				</div>
			</FiltersWrapper>
			<HR />
			<StyledTable>
				<table {...getTableProps()}>
					<thead>
						{
							// Loop over the header rows
							headerGroups.map((headerGroup) => (
								// Apply the header row props
								<tr {...headerGroup.getHeaderGroupProps()}>
									{
										// Loop over the headers in each row
										headerGroup.headers.map((column) => (
											// Apply the header cell props.
											// Add the sorting props to control sorting.
											<th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												style={{ width: column.width + 'px' }}>
												{
													// Render the header
													column.render('Header')
												}
												{/* Add a sort direction indicator */}
												<span>
													{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
												</span>
											</th>
										))
									}
								</tr>
							))
						}
					</thead>
					{/* Apply the table body props */}
					<tbody {...getTableBodyProps()}>
						{
							// Loop over the table rows
							page.map((row) => {
								// Prepare the row for display
								prepareRow(row)
								return (
									// Apply the row props
									<React.Fragment {...row.getRowProps().key}>
										<tr {...row.getRowProps()}>
											{
												// Loop over the rows cells
												row.cells.map((cell) => {
													// Apply the cell props
													return (
														<td {...cell.getCellProps()}>
															{
																// Render the cell contents
																cell.render('Cell')
															}
														</td>
													)
												})
											}
										</tr>

										{/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
										{row.isExpanded &&
											(SubRowView ? (
												<SubRowView row={row} visibleColumns={visibleColumns} tabs={tabs} />
											) : (
												<FactorySubRow row={row} visibleColumns={visibleColumns} tabs={tabs} />
											))}
									</React.Fragment>
								)
							})
						}
					</tbody>
				</table>
			</StyledTable>
			{/* <div>

        <p>Expanded Rows: {Object.keys(selectedRowIds).length}</p>
            <pre>
                <code>{JSON.stringify({ expanded: expanded }, null, 2)}</code>
            </pre>
            
            <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
            <pre>
                <code>
                {JSON.stringify(
                    {
                    selectedRowIds: selectedRowIds,
                    'selectedFlatRows[].original': selectedFlatRows.map(
                        d => d.original
                    ),
                    },
                    null,
                    2
                )}
                </code>
            </pre>
        </div> */}
		</>
	)
}

export default Table
