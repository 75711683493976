// Import globall CSS
import '../public/css/main.css'

/* Import React Libraries */
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createStore } from 'redux'
import { ThemeProvider } from '@material-ui/core/styles'
// import loadable from '@loadable/component'
import { Provider } from 'react-redux'

/* Import our store configuration */
import configureStore from '../universal/store'
// import { ConnectedRouter } from 'connected-react-router'
import { history } from '../universal/history'
/* Import Root Component */
import Root from '../universal/containers/Root'
import CustomRouter from './CustomRouter'
import { Routes, Route } from 'react-router-dom'

// const Root =  loadable(() => import(/* webpackPrefetch: true */ '../universal/containers/Root'))
//import { setupRealtime } from './realtime'
// import * as actions from '../universal/actions'

// React meta tags
import { MetaTagsContext } from 'react-meta-tags'

// Loadable components
import { loadableReady } from '@loadable/component'

// Material ui theme
import theme from '../universal/styles/theme'

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

// Create Redux store with initial state
const store = configureStore(preloadedState)
// console.log("STORE FROM STATE", store.getState())

/* Initialize Sentry, before initializing React */
Sentry.init({
	dsn: 'https://0e78d03edea846659fa5bb92ff951057@o393450.ingest.sentry.io/5799046',
	// release: 'my-project-name@' + process.env.npm_package_version, // don't need this, webpack plughin takes care fo this
	integrations: [
		new Integrations.BrowserTracing({
			// React-router integration
			// routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
		}),
	],
	tracesSampleRate: 0.01,
	ignoreErrors: [
		// Sentry bening error
		'ResizeObserver loop limit exceeded',
		// Random plugins/extensions
		'Extension context invalidated.', // some weird chrom bug
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		"Can't find variable: ZiteReader",
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
	],
	blacklistUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	],
})

function App() {
	React.useEffect(() => {
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles)
		}
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<Provider store={store} key="provider">
				{/* <ConnectedRouter history={history}> */}
				<CustomRouter history={history}>
					<MetaTagsContext>
						<Routes>
							<Route path="*" element={<Root />} />
						</Routes>
					</MetaTagsContext>
				</CustomRouter>
				{/* </Connect edRouter> */}
			</Provider>
		</ThemeProvider>
	)
}

/* Hydrate Root component at target DOM element */
loadableReady(() => {
	const root = document.getElementById('root')
	ReactDOM.hydrate(<App />, root)
})

/* Socket.io stuffs NOT USED */
//setupRealtime(store, actions);
