const Heading = {
	variants: {
		hero: {
			fontWeight: 'bold',
			fontFamily: `'Gazpacho', Georgia, sans-serif`,
		},
	},
}

export default Heading
