
import { normalize, schema } from 'normalizr'


const renderJobSchema = new schema.Entity(
	'renderJobs',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

const renderJobListSchema = new schema.Array(renderJobSchema)

export {renderJobListSchema}