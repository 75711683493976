import React from 'react' 
import Bool from '../../../../core/widgets/others/Bool'
import DateFormat from '../../../../core/widgets/others/DateFormat'


/**
 * Define our Table view columns
 */
 const columns = [
    {
        id: 'type',
        Header: 'Type',
        accessor: 'type', // the raw js date object, for sorting
        width: 120,
        Cell: ({row}) => {
            const {type} = row.original
            if(type == 'promotion') {
                return (<span className="badge green">{'Promotion'}</span>)
            }
            else {
                return (<span className="badge orange">{'Maintenance'}</span>)
            }
        },
    },
    {
        id: 'enabled',
        Header: 'Is Active',
        accessor: 'enabled', // the raw js date object, for sorting
        width: 120,
        Cell: ({row}) => <Bool value={row.original.enabled} />,
    },
    {
        id: 'starts',
        Header: 'Start Date',
        accessor: 'starts', // the raw js date object, for sorting
        Cell: (row) => <DateFormat value={row.value} />,
        width: 150,
    },
    {
        id: 'ends',
        Header: 'Ending Date',
        accessor: 'ends', // the raw js date object, for sorting
        Cell: (row) => <DateFormat value={row.value} />,
        width: 150,
    },

    {
        id: 'timezone',
        Header: 'Timezone',
        accessor: 'timezone', // the raw js date object, for sorting
        Cell: (row) => <div>{row.value}</div>,
        width: 150,
    },
    
  
]


export default columns;