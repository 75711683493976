import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { eventListSchema, eventSchema } from './normalizr'
import requests from './requests'
import { EVENTS } from './constants'
import { UI } from '../../../../api/ui/constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action
 */
function* handleFetchEvents(action) {
	yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'info', message: 'Fetching events...' })
	try {
		const response = yield call(requests.fetchEvents, action.payload)
		const normalizedData = normalize(response.data.data, eventListSchema)
		const data = { ...response.data, ...normalizedData }
		delete data.data
		yield put({ type: EVENTS.FETCH_SUCCEEDED, payload: data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: data.message })
		// yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: 'Events successfully fetched.' })
	} catch (e) {
		yield put({ type: EVENTS.FETCH_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Post a event
 * @param {*} action
 */
function* handlePostEvent(action) {
	try {
		const response = yield call(requests.postEvent, action.payload)
		yield put({ type: EVENTS.POST_SUCCEEDED, payload: response })
	} catch (e) {
		yield put({ type: EVENTS.POST_FAILED, payload: e.message })
	}
}

/**
 * Saga worker
 * Update a event
 * @param {*} action
 */
function* handleUpdateEvent(action) {
	try {
		const response = yield call(requests.updateEvent, action.payload)

		const normalizedData = normalize([response.data.data], eventListSchema)
		yield put({ type: EVENTS.UPDATE_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: EVENTS.UPDATE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a event
 * @param {*} action
 */
function* handleDeleteEvent(action) {
	try {
		const response = yield call(requests.deleteEvent, action.payload)
		// const normalizedData = normalize(response.data, adminUserListSchema)
		yield put({ type: EVENTS.DELETE_SUCCEEDED, payload: response })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: response.message })
	} catch (e) {
		yield put({ type: EVENTS.DELETE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Theme Versions Saga Watcher
 */
export function* watchEventsFetch() {
	// while(true){
	yield takeLatest(EVENTS.FETCH_REQUESTED, handleFetchEvents)
	// }
}

export function* watchUpdateEvent() {
	// while(true){
	yield takeEvery(EVENTS.UPDATE_REQUESTED, handleUpdateEvent)
	// }
}

export function* watchPostEvent() {
	// while(true){
	yield takeEvery(EVENTS.POST_REQUESTED, handlePostEvent)
	// }
}

export function* watchDeleteEvent() {
	// while(true){
	yield takeEvery(EVENTS.DELETE_REQUESTED, handleDeleteEvent)
	// }
}
