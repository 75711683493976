import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    registerApp as registerAppAction,
    registerApplications as registerApplicationsAction,
    setCurrentApp as setCurrentAppAction,
} from '../reducers/apps'

const useApps = () => {

    const dispatch = useDispatch()
    
    /**
     * State
     */
    const registeredApps = useSelector( s => s.apps.registeredApps, shallowEqual)
    const currentApp = useSelector( s => s.apps.currentApp, shallowEqual)
    const currentAppId = useSelector( s => s.apps.currentAppId)

    /**
     * Handlers
     */
    const registerApp = useCallback((instance) => {
        dispatch(registerAppAction(instance))
    }, [])

    const registerApplications = useCallback((instances) => {
        dispatch(registerApplicationsAction(instances))
    }, [])

    const setCurrentApp = useCallback((instances) => {
        dispatch(setCurrentAppAction(instances))
    }, [])


    return {
        /** Return our handlers */
        registerApp, 
        registerApplications, 
        setCurrentApp, 
        /** Return our data */
        registeredApps,
        currentApp,
        currentAppId,
    };
}

export default useApps;