import React from 'react'
import styled, { css } from 'styled-components'
import IconButton from '../../../../../../core/widgets/buttons/ButtonIcon'

import ButtonIcon from '../../../../../../core/widgets/buttons/ButtonIcon'
import Button from '../../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../../core/widgets/others/SubRowHeader'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'

const Actions = ({ row, goToTab }) => {
	return (
		<div>
			<SubRowHeader>
				<p>
					<span className="badge">{row.original.alpha2Code}</span> {row.original.name}
				</p>
				<div className="actions">
					<ButtonIcon unavailable revert onClick={() => {}}>
						<AspectRatioIcon fontSize="small" /> Open
					</ButtonIcon>
				</div>
			</SubRowHeader>

			<InlineItems title="Actions">
				<ButtonIcon edit onClick={() => goToTab('edit')}>
					Edit
				</ButtonIcon>
				<ButtonIcon delete>Delete</ButtonIcon>
			</InlineItems>
		</div>
	)
}
export default Actions
