import {createSlice} from '@reduxjs/toolkit'

import { RENDER_JOBS } from './constants'


const initialState = {
    data:null,
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}


const eventRenderJobsSlice = createSlice({
    initialState: initialState,
    name:'eventRenderJobs',
    extraReducers: {
        /**
         * Events
         */
        [RENDER_JOBS.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.renderJobs){
                state.data = action.payload.entities.renderJobs
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        /** TO-DO : THIS BELOW SHOULD BE NORMALIZED FROM THE ACTION THAT EMIT IT !  */
        [RENDER_JOBS.POST_SUCCEEDED]: (state, action) => {
            state.data[action.payload.data.id] = action.payload.data;
        },

        /** TO-DO : THIS BELOW SHOULD BE NORMALIZED FROM THE ACTION THAT EMIT IT !  */
        [RENDER_JOBS.UPDATE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                state.data[action.payload.data.id] = action.payload.data
            }
        },

        /** TO-DO : THIS BELOW SHOULD BE NORMALIZED FROM THE ACTION THAT EMIT IT !  */
        [RENDER_JOBS.DELETE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                delete state.data[action.payload.data.id]
            }
        },
        // case types.LOAD_ADMIN_RENDER_JOBS_SUCCESS:
		// 	if (action.payload && action.payload.entities && action.payload.entities.event_render_jobs) {
		// 		return Object.assign({}, state, action.payload.entities.event_render_jobs)
		// 	}

		// case types.ADD_ADMIN_RENDER_JOB_SUCCESS:
		// 	// console.log("UPDATE PAYLOAD", action.payload)
		// 	if (action.payload && action.payload.data) {
		// 		let renderJobId = action.payload.data.id
		// 		state[renderJobId] = action.payload.data
		// 		return Object.assign({}, state)
		// 	}

		// case types.UPDATE_ADMIN_RENDER_JOB_SUCCESS:
		// 	// console.log("UPDATE PAYLOAD", action.payload)
		// 	if (action.payload && action.payload.data) {
		// 		let renderJobId = action.payload.data.id
		// 		state[renderJobId] = action.payload.data
		// 		return Object.assign({}, state)
		// 	}

		// case types.DELETE_ADMIN_RENDER_JOB_SUCCESS:
		// 	// console.log("ACTION", action, "STATE", state)
		// 	// let newState = state
		// 	// if (action.payload && action.payload.id){
		// 	// 		remove the deleted id entry
		// 	// }
		// 	return Object.assign({}, state)

    },
    reducers:{},
})


export default eventRenderJobsSlice.reducer