import React from 'react'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
// import { updateEvent, addRenderJob, deleteRenderJob } from '@bit/vidday.vidday-admin-store.modules.events'
// import { regenerateImage } from '@bit/vidday.vidday-store.modules.media'
// import { publishedNotification } from '@bit/vidday.vidday-admin-store.workers.mail'
// import { compressViddayPackage } from '@bit/vidday.vidday-admin-store.workers.vidday-package'
import { ordinalSuffixOf } from '@bit/vidday.vidday-helpers.utils.ordinal-suffix-of'
import { handleCopy } from '@bit/vidday.vidday-helpers.utils.handle-copy'
import { getProfileName } from '@bit/vidday.vidday-helpers.getters.get-profile-name'

import { getRecipients } from '@bit/vidday.vidday-helpers.getters.get-recipients'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import ButtonIcon from '../../../../../core/widgets/buttons/ButtonIcon'
import Button from '../../../../../core/widgets/buttons/Button'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../core/widgets/others/SubRowHeader'
import MediaUrlForm from './MediaUrl'
import { updateEvent } from '../../api/actions'
import { postPublishedNotification } from '../../../../../api/mail/actions'
import { compressViddayPackage } from '../../../../../api/vidday-package/actions'
import { postRegenerateImage } from '../../../media/api/actions'

const Actions = (props) => {
	const { row } = props
	/** Retrieve our row data */
	const data = row.original
	/** use Dispatch hook */
	const dispatch = useDispatch()

	const handleResetOccupied = () => {
		let postData = {
			eventId: data.key || data.id,
			uuid: data.uuid,
			occupied: false,
		}
		// Change occupied:false
		dispatch(updateEvent(postData))
	}

	const handlePublishedNotification = (id) => {
		dispatch(postPublishedNotification(id))
	}

	/** IMPORTANT : THIS SHOULD BE REFACTORED TO SIMPLIFY REQUEST
	 * " REGENERATE ALL IMAGES FOR THAT EVENT ID (TO BE HAPPENING IN BACKEND)"
	 */
	const handleRegenAllImages = () => {
		const images = data.media.filter((med) => med.mimeType.indexOf('image') > -1)
		images.forEach(function (image) {
			const imageData = { data: { croppedLocation: image.location, ...image } }
			dispatch(postRegenerateImage(imageData))
		})
	}

	const handleMediaPackage = (eventUuid) => {
		dispatch(compressViddayPackage(eventUuid))
	}

	var eventAdmin = data.creator && data.creator.profile ? getProfileName(data.creator.profile, true) : null
	var eventAdminEmail = data.creator ? data.creator.email : null
	var recipients = '' //data.recipients ? getRecipients(data.recipients, true).names : ''
	var occasion = data.occasion.charAt(0).toUpperCase() + data.occasion.slice(1).toLowerCase()
	var nthYears = ordinalSuffixOf(data.years)
	var title = `${data.uuid} - ${nthYears} ${occasion} VidDay for ${recipients}`

	const userListItems = [
		{
			name: 'Full Name',
			content: eventAdmin,
		},
		{
			name: 'Email',
			content: eventAdminEmail,
		},
	]

	return (
		<div>
			<SubRowHeader>
				<p>
					<span className="badge">{row.original.uuid}</span> {title}
					<a onClick={(e) => handleCopy(e, title, true)}>
						<FileCopyIcon fontSize="small" />
					</a>
				</p>
				<div className="actions">
					<ButtonIcon revert onClick={() => {}}>
						<AspectRatioIcon fontSize="small" /> Open
					</ButtonIcon>
				</div>
			</SubRowHeader>

			<InlineItems title="Actions">
				<Button revert onClick={() => handleResetOccupied(data.key)}>
					Reset Occupied Event
				</Button>
				<Button revert onClick={() => handlePublishedNotification(data.key)}>
					Send Published Notice
				</Button>
				<Button onClick={() => handleRegenAllImages(data.key)}>Regenerate all images</Button>
				<Button onClick={() => handleMediaPackage(data.uuid)}>Start Media Package</Button>
			</InlineItems>
			{/* 
			<MediaUrlForm {...props} />

			<InlineItems title="Users" list={userListItems} /> */}
		</div>
	)
}
export default Actions
