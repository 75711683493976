const REGIONS = {
    /** Fetch all regions (includes filters) */
    FETCH_REQUESTED: 'REGIONS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'REGIONS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'REGIONS_FETCH_FAILED',

    /** Post a region */
    POST_REQUESTED: 'REGION_POST_REQUESTED',
    POST_SUCCEEDED:'REGION_POST_SUCCEEDED',
    POST_FAILED: 'REGION_POST_FAILED',

    /** Update a region */
    UPDATE_REQUESTED: 'REGION_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'REGION_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'REGION_UPDATE_FAILED',

    /** Delete a region */
    DELETE_REQUESTED: 'REGION_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'REGION_DELETE_SUCCEEDED',
    DELETE_FAILED: 'REGION_DELETE_FAILED',
}

export {REGIONS}