import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { themeVersionListSchema, themeVersionSchema } from './normalizr'
import requests from './requests'
import { THEME_VERSIONS } from './constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action 
 */
function* handleFetchThemeVersions(action) {
    try {
        const response = yield call(requests.fetchThemeVersions, action.payload)
        const normalizedData = normalize(response.data.data, themeVersionListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: THEME_VERSIONS.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: THEME_VERSIONS.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a themeVersion
 * @param {*} action 
 */
 function* handlePostThemeVersion(action) {
    try {
        const response = yield call(requests.postThemeVersion, action.payload)
        const normalizedData = normalize([response.data.data], themeVersionListSchema)
        
        yield put({ type: THEME_VERSIONS.POST_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: THEME_VERSIONS.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a themeVersion
 * @param {*} action 
 */
 function* handleUpdateThemeVersion(action) {
    try {
        const response = yield call(requests.updateThemeVersion, action.payload)
        const normalizedData = normalize([response.data.data], themeVersionListSchema)
        yield put({ type: THEME_VERSIONS.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: THEME_VERSIONS.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a themeVersion
 * @param {*} action 
 */
 function* handleDeleteThemeVersion(action) {
    try {
        const response = yield call(requests.deleteThemeVersion, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: THEME_VERSIONS.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: THEME_VERSIONS.DELETE_FAILED, payload:e })
    }
}


/**
 * Theme Versions Saga Watcher
 */
export function* watchThemeVersionsFetch(){
    // while(true){
        yield takeLatest(THEME_VERSIONS.FETCH_REQUESTED, handleFetchThemeVersions)
    // }
}

export function* watchUpdateThemeVersion(){
    // while(true){
        yield takeEvery(THEME_VERSIONS.UPDATE_REQUESTED, handleUpdateThemeVersion)
    // }
}

export function* watchPostThemeVersion(){
    // while(true){
        yield takeEvery(THEME_VERSIONS.POST_REQUESTED, handlePostThemeVersion)
    // }
}

export function* watchDeleteThemeVersion(){
    // while(true){  
        yield takeEvery(THEME_VERSIONS.DELETE_REQUESTED, handleDeleteThemeVersion)
    // }
}