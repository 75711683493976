import React from 'react'
import loadable from '@loadable/component'
const FactoryTabs = loadable(() => import(/* webpackPrefetch: true */ '../FactoryTabs'), { ssr: false })
// import FactoryTabs from '../FactoryTabs'

/**
 * Factory Sub-Row view (it will render the Factory tabs and their sub-views)
 * @param {object}
 * @returns
 */
const FactorySubRow = ({ visibleColumns, row, includeRawTab, tabs }) => {
	return (
		<tr className="expandedRow">
			<td colSpan={visibleColumns.length}>
				<FactoryTabs row={row} tabs={tabs} includeRawTab={includeRawTab} />
			</td>
		</tr>
	)
}
FactorySubRow.defaultProps = {
	includeRawTab: true,
	tabs: [],
	row: null,
	visibleColumns: [],
}

export default FactorySubRow
