import React, { useEffect, useState, useRef } from 'react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Input,
	InputRightElement,
	FormErrorMessage,
	Icon,
	Spinner,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { verifySingleEmail } from '../../../apps/core/tools/api/actions'

import PropTypes from 'prop-types'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'

const checkSendgridInvalidity = (verification) => {
	if (verification && verification.result) {
		console.log(verification, 'verification Sendgrid')
		const { verdict, checks } = verification.result
		console.log(!verdict === 'Valid', "!verdict === 'Valid'")
		console.log(!verdict === 'Risky', "!verdict === 'Risky'")
		console.log(verdict === 'Invalid', "verdict === 'Invalid'")
		console.log(!checks?.domain?.has_mx_or_a_record, '!checks?.domain?.has_mx_or_a_record')
		console.log(!checks?.domain?.has_valid_address_syntax, '!checks?.domain?.has_valid_address_syntax')
		console.log(checks?.domain?.is_suspected_disposable_address, 'checks?.domain?.is_suspected_disposable_address')
		console.log(checks?.additional?.has_known_bounces, 'checks?.additional?.has_known_bounces')

		return (
			!verdict === 'Valid' ||
			!verdict === 'Risky' ||
			verdict === 'Invalid' ||
			!checks?.domain?.has_mx_or_a_record ||
			!checks?.domain?.has_valid_address_syntax ||
			checks?.domain?.is_suspected_disposable_address ||
			checks?.additional?.has_known_bounces
		)
	} else return true
}

const checkReacherInvalidity = (verification) => {
	if (verification) {
		console.log(verification, 'verification Reacher')
		const { mx, syntax, smtp, is_reachable, misc } = verification
		console.log(!mx?.accepts_mail, '!mx?.accepts_mail')
		console.log(!syntax?.is_valid_syntax, '!syntax?.is_valid_syntax')
		console.log(
			!smtp?.can_connect_smtp && !is_reachable === 'unknown',
			'(!smtp?.can_connect_smtp && !is_reachable === "unknown")'
		)
		console.log(
			!smtp?.is_deliverable && !is_reachable === 'unknown',
			'(!smtp?.is_deliverable && !is_reachable === "unknown")'
		)
		console.log(is_reachable === 'invalid', 'is_reachable === "invalid"')
		console.log(misc?.is_disposable, 'misc?.is_disposable')

		return (
			!mx?.accepts_mail ||
			!syntax?.is_valid_syntax ||
			(!smtp?.can_connect_smtp && !is_reachable === 'unknown') ||
			(!smtp?.is_deliverable && !is_reachable === 'unknown') ||
			is_reachable === 'invalid' ||
			misc?.is_disposable
		)
	} else return true
}

const ControlledInputEmail = (props) => {
	const {
		// react-hook-form attributes
		control,
		errors,
		defaultValue,
		setError,
		// Base input attributes
		name,
		label,
		placeholder,
		helperText,
		// Additional styling props
		readOnly,
		// rightElement,
		textAlign,
		// For verified email, pass in following props
		formState,
		provider,
		primaryEmail,
		source,
		verify,
		// Verify this..... maybe passed through control?
		clearErrors,

		onBlur,
		// Rest of props, whatever this might be...
		...rest
	} = props

	/**
	 * Email Specific State management
	 */
	const inputRef = useRef()
	const dispatch = useDispatch()
	const [validEmail, setValidEmail] = useState(false)

	/**
	 * Error Management
	 */
	let isError = false
	let errorMessage = ''
	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}

	/**
	 * Clear errors on mount
	 */
	useEffect(() => {
		if (clearErrors) clearErrors()
		// dispatch({ type: 'RESET_VERIFY_EMAIL' })
	}, [])

	/**
	 * Handle verification of valid email address
	 */
	useEffect(() => {
		/** If we're enforcing verification... */
		if (verify && formState && formState.verification && Object.keys(formState.verification).length > 0) {
			/** If our provider is Sendgrid */
			if (provider == 'sendgrid') {
				const isSendgridInvalid = checkSendgridInvalidity(formState.verification)
				console.log(isSendgridInvalid, 'isSendgridInvalid')
				if (isSendgridInvalid) {
					setError(name, {
						type: 'error',
						message: `Invalid email, please check your entry.`,
					})
					setValidEmail(false)
				} else {
					setValidEmail(true)
				}
			} else if (provider == 'reacher') {
				const isReacherInvalid = checkReacherInvalidity(formState.verification)
				console.log(isReacherInvalid, 'isReacherInvalid')
				if (isReacherInvalid) {
					setError(name, {
						type: 'error',
						message: `Invalid email, please check your entry.`,
					})
					setValidEmail(false)
				} else {
					setValidEmail(true)
				}
			} else if (formState.verification.mx?.accepts_mail && formState.verification.is_reachable === 'timeout') {
				setValidEmail(true)
			}
		} else {
			if (clearErrors) {
				clearErrors()
			}
			setValidEmail(false)
		}
	}, [formState?.verification, verify, provider])

	/**
	 * Validate email string
	 */
	useEffect(() => {
		let delayVerifyTimer
		if (verify && inputRef.current?.value != formState.verification?.email) {
			const email = inputRef?.current?.value
			const userPrimaryEmail = primaryEmail || ''
			const emailReg =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			if (email === '') {
				if (errorMessage == 'Incomplete/Incorrect email id') {
					setError(name, {
						type: 'error',
						message: `Please enter your email`,
					})
				}
			} else if (emailReg.test(String(email).toLowerCase())) {
				if (email === userPrimaryEmail) {
					setError(name, {
						type: 'error',
						message: `New email can not be same as primary email`,
					})
				} else {
					if (clearErrors) clearErrors()
					// Set timer
					delayVerifyTimer = setTimeout(() => {
						dispatch(verifySingleEmail({ email: email, provider: provider, source: source }))
					}, 1000) // for slow typers
				}
			} else {
				setError(name, {
					type: 'error',
					message: `Incomplete/Incorrect email id`,
				})
			}
		}
		// Reset timer
		return () => {
			clearTimeout(delayVerifyTimer)
		}
	}, [inputRef.current?.value])

	let rightElement

	if (verify && formState && formState.status.isVerifying) {
		rightElement = <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="blue.500" size="sm" />
	} else {
		if (validEmail) {
			rightElement = <Icon color="#0066CC" as={MarkEmailReadRoundedIcon} />
		} else {
			rightElement = <Icon color="gray.200" as={EmailRoundedIcon} />
		}
	}
	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				const _onBlur = (e) => {
					console.log(props, e, 'onBlur')
					onBlur(props.value)
				}

				return (
					<FormControl isInvalid={isError} {...rest}>
						{label && (
							<FormLabel htmlFor={name} textAlign={textAlign ? textAlign : 'left'}>
								{label}
							</FormLabel>
						)}

						<InputGroup>
							<Input
								disabled={readOnly}
								id={name}
								placeholder={placeholder}
								{...props}
								onBlur={onBlur ? _onBlur : props.onBlur}
								ref={inputRef}
							/>
							<InputRightElement aria-label="Email Status" children={rightElement} />
						</InputGroup>

						{isError ? (
							<FormErrorMessage d="block" textAlign={textAlign ? textAlign : 'left'}>
								{errorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledInputEmail
