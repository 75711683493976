import React, { useEffect, useState } from 'react'
import { FormErrorMessage, useDisclosure } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import Calendar from 'react-calendar'
import Modal from './Modal'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Input,
	InputRightElement,
} from '@chakra-ui/react'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment-timezone'

const ControlledDate = ({ control, name, label, placeholder, helperText, errors, useTime, autoOpen, allowPast }) => {
	const formatDate = (date) =>
		date ? moment(date).format(useTime ? 'dddd, MMMM Do YYYY, h:mm:ss a' : 'dddd, MMMM Do YYYY') : ''

	const modal = useDisclosure()
	const { setValue, getValues } = control

	/** Retrieve values of the form */
	const values = getValues()
	const formValue = values[name]
	/**
	 * Set date value and user-friendly date
	 * to be used for the input */
	const [date, setDate] = useState()
	const [formattedDate, setFormattedDate] = useState()

	/**
	 * We want to update the date and formatted date
	 * when receiving a value from react-hook-form
	 * */
	useEffect(() => {
		if (formValue) {
			setDate(moment(formValue).toDate())
			setFormattedDate(formatDate(formValue))
		}
	}, [formValue])

	useEffect(() => {
		if (autoOpen) {
			modal.onOpen()
		}
	}, [autoOpen])
	/**
	 * Callback handler to update the value
	 * given a new value from the datepicker
	 * @param {*} val
	 */
	const handleDateChange = (val) => {
		/** 1. `val` here, is a Date() string, so let's save it */
		setDate(val)
		/** 2. We need to format the new date as user-friendly and save it */
		const formatted_date = formatDate(val)
		setFormattedDate(formatted_date)
		/** 3. Last, we need to convert this val to a moment object and update form state */
		const newMomentDate = moment(val)
		setValue(name, newMomentDate)
	}

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={(props, { invalid, isTouched, isDirty }) => {
					return (
						<FormControl isInvalid={errors[name]}>
							{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
							<InputGroup>
								{/* <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" /> */}
								<Input
									id={name}
									placeholder={placeholder}
									{...props}
									value={formattedDate}
									onClick={modal.onOpen}
								/>
								<InputRightElement
									children={<EventNoteRoundedIcon color="gray.300" onClick={modal.onOpen} />}
								/>
							</InputGroup>
							{errors[name] ? (
								<FormErrorMessage d="block">{errors[name].message}</FormErrorMessage>
							) : (
								helperText && <FormHelperText>{helperText}</FormHelperText>
							)}
						</FormControl>
					)
				}}
			/>
			<Modal
				isOpen={modal.isOpen}
				onClose={modal.onClose}
				value={date}
				formattedValue={formattedDate}
				onChange={(val) => handleDateChange(val)}
				useTime={useTime}
				allowPast={allowPast}
			/>
		</>
	)
}

export default ControlledDate

const DateTime = (props) => {
	return <ControlledDate {...props} useTime={true} />
}

export { DateTime }
