import React, { useMemo, createContext, useReducer, useContext, useState, useEffect } from 'react'
import { useSearchEngine } from '../core'
import { values } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { useSearchEngineContext } from './SearchEngineProvider'
/** Table components (react-table) */
import { useTable, useSortBy, usePagination, useRowSelect, useExpanded } from 'react-table'
import IndeterminateCheckbox from '../core/widgets/table/IndeterminateCheckbox'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { chakra, Icon } from '@chakra-ui/react'
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded'
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded'

/** Build Current Event Context */
const TableContext = createContext()

/** Define Current Event Context Provider  */
const TableProvider = ({ columns, hiddenColumns, children }) => {
	const { collection } = useSearchEngineContext()

	/**
	 * Memoize our data collection
	 */
	const memoizedData = useMemo(() => collection, [collection])

	/**
	 * Memoize our columns definition
	 */
	const memoizedcolumns = useMemo(() => columns, [])

	/**
	 * Create our table instance
	 */
	const tableInstance = useTable(
		{
			/** Pass in the columns */
			columns: memoizedcolumns,
			/** Pass in the data */
			data: memoizedData,
			/** Pass in the initial State */
			initialState: {
				hiddenColumns: hiddenColumns,
			},
			/** Do not auto-reset columns on change */
			autoResetHiddenColumns: false,

			autoResetExpanded: false,
		},

		/** Add sorting capability */
		useSortBy,
		/**  We can useExpanded to track the expanded state */
		useExpanded,
		/** Add Pagination capability */
		// usePagination,
		/** Add Row Selection capability */
		useRowSelect,

		/** Append additional column for row selection */
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					width: 15,
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				{
					// Make an expander cell
					Header: () => null, // No header
					id: 'expander', // It needs an ID
					width: 15,
					Cell: ({ row }) => {
						// Use Cell to render an expander for each row.
						// We can use the getToggleRowExpandedProps prop-getter
						// to build the expander.
						return (
							<chakra.span {...row.getToggleRowExpandedProps()} d="flex">
								{row.isExpanded ? (
									<Icon boxSize="1rem" as={UnfoldLessRoundedIcon} />
								) : (
									<Icon boxSize="1rem" as={UnfoldMoreRoundedIcon} />
								)}
							</chakra.span>
							// <span {...row.getToggleRowExpandedProps()} className="expandableIcon">
							// 	{row.isExpanded ? (
							// 		<ArrowDownwardIcon fontSize="small" />
							// 	) : (
							// 		<MoreVertIcon fontSize="small" />
							// 	)}
							// </span>
						)
					},
				},
				...columns,
			])
		}
	)

	console.log(tableInstance, 'table instance')

	// Pass table instance as new object
	return <TableContext.Provider value={{ table: tableInstance }}>{children}</TableContext.Provider>
}

export default TableProvider

export const useTableContext = () => useContext(TableContext).table
