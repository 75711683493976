import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { invoiceListSchema, invoiceSchema, invoiceItemListSchema } from './normalizr'
import requests from './requests'
import { INVOICES } from './constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action 
 */
function* handleFetchInvoices(action) {
    try {
        const response = yield call(requests.fetchInvoices, action.payload)
        const normalizedData = normalize(response.data.data, invoiceListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: INVOICES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: INVOICES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a invoice
 * @param {*} action 
 */
 function* handlePostInvoice(action) {
    try {
        const response = yield call(requests.postInvoice, action.payload)
        const normalizedData = normalize([response.data.data], invoiceListSchema)
        
        yield put({ type: INVOICES.POST_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: INVOICES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a invoice
 * @param {*} action 
 */
 function* handleUpdateInvoice(action) {
    try {
        const response = yield call(requests.updateInvoice, action.payload)
        const normalizedData = normalize([response.data.data], invoiceListSchema)
        
        yield put({ type: INVOICES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: INVOICES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a invoice
 * @param {*} action 
 */
 function* handleDeleteInvoice(action) {
    try {
        const response = yield call(requests.deleteInvoice, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: INVOICES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: INVOICES.DELETE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Refund a invoice
 * @param {*} action 
 */
 function* handleRefundInvoice(action) {
    try {
        const response = yield call(requests.refundInvoice, action.payload)
        const normalizedData = normalize([response.data.data], invoiceListSchema)
        
        yield put({ type: INVOICES.REFUND_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: INVOICES.REFUND_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Refund an invoice item
 * @param {*} action 
 */
 function* handleRefundInvoiceItem(action) {
    try {
        const response = yield call(requests.refundInvoiceItem, action.payload)
        const normalizedData = normalize([response.data.data], invoiceItemListSchema)
        
        yield put({ type: INVOICES.REFUND_ITEM_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: INVOICES.REFUND_ITEM_FAILED, payload:e })
    }
}




/**
 * Users Saga Watcher
 */
export function* watchInvoicesFetch(){
    // while(true){
        yield takeLatest(INVOICES.FETCH_REQUESTED, handleFetchInvoices)
    // }
}

export function* watchUpdateInvoice(){
    // while(true){
        yield takeEvery(INVOICES.UPDATE_REQUESTED, handleUpdateInvoice)
    // }
}

export function* watchPostInvoice(){
    // while(true){
        yield takeEvery(INVOICES.POST_REQUESTED, handlePostInvoice)
    // }
}

export function* watchDeleteInvoice(){
    // while(true){  
        yield takeEvery(INVOICES.DELETE_REQUESTED, handleDeleteInvoice)
    // }
}

export function* watchRefundInvoice(){
    // while(true){
        yield takeEvery(INVOICES.REFUND_REQUESTED, handleRefundInvoice)
    // }
}

export function* watchRefundInvoiceItem(){
    // while(true){
        yield takeEvery(INVOICES.REFUND_ITEM_REQUESTED, handleRefundInvoiceItem)
    // }
}