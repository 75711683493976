import React from 'react'
import { StyledIconButton } from './styles'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
/**
 * Default IconButton.
 * @param {*}
 * @returns
 */
const IconButton = ({ children, ...rest }) => {
	let Alternate

	if (rest.delete) {
		Alternate = (
			<StyledIconButton {...rest}>
				<DeleteIcon fontSize="small" /> {children}
			</StyledIconButton>
		)
	} else if (rest.edit) {
		Alternate = (
			<StyledIconButton {...rest}>
				<EditIcon fontSize="small" /> {children}
			</StyledIconButton>
		)
	} else if (rest.create) {
		Alternate = (
			<StyledIconButton {...rest}>
				<AddRoundedIcon fontSize="small" /> {children}
			</StyledIconButton>
		)
	} else {
		Alternate = <StyledIconButton {...rest}>{children}</StyledIconButton>
	}

	return Alternate
}

export default IconButton
