const CURRENCIES = {
    /** Fetch all currencies (includes filters) */
    FETCH_REQUESTED: 'CURRENCIES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'CURRENCIES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'CURRENCIES_FETCH_FAILED',

    /** Post a currency */
    POST_REQUESTED: 'CURRENCY_POST_REQUESTED',
    POST_SUCCEEDED:'CURRENCY_POST_SUCCEEDED',
    POST_FAILED: 'CURRENCY_POST_FAILED',

    /** Update a currency */
    UPDATE_REQUESTED: 'CURRENCY_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'CURRENCY_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'CURRENCY_UPDATE_FAILED',
    
    /** Delete a currency */
    DELETE_REQUESTED: 'CURRENCY_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'CURRENCY_DELETE_SUCCEEDED',
    DELETE_FAILED: 'CURRENCY_DELETE_FAILED',
}

export {CURRENCIES}