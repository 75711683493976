import React from 'react'

import { useDispatch } from 'react-redux'

/** Table components (react-table) */
import { useTable, useSortBy, usePagination, useRowSelect, useExpanded } from 'react-table'

/** Table components (local) */
import FactorySubRow from '../FactorySubRow'

/** Custom hooks */
import useInterfacePanel from '../../../../interface/hooks/useInterfacePanel'

/** Import Filters */
import FilterItemPerView from '../../filters/FilterItemPerView'
import FilterPageNumber from '../../filters/FilterPageNumber'
import FilterSettingButton from '../../filters/FilterSettingButton'
import VerticalDivider from '../../filters/VerticalDivider'
import ButtonEditColumns from '../../buttons/ButtonEditColumns'
import ResultsParagraph from '../../others/ResultsParagraph'
import ButtonExport from '../../buttons/ButtonExport'
import SelectedRowActions from '../../others/SelectedRowsActions'
import { StyledTable } from '../styles'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import { FiltersWrapper, HR } from '../../styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTableContext } from '../../../../contexts/TableProvider'
import { chakra, Icon, Flex, HStack, keyframes, Box } from '@chakra-ui/react'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded'

const animateGradient = keyframes`
	0%{
		background-position-x: 200%;
	}
	100%{
		background-position-x: 0%;
	}
`

const Table = ({ tabs, SubRowView }) => {
	/**
	 * Retrieve table features
	 */
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		allColumns,
		visibleColumns,
		/* Instead of using 'rows', we'll use page 
        which has only the rows for the active page */
		rows,
		page,
		/** Other helpers */
		canPreviousPage,
		canNextPage,
		pageOptions,

		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds, expanded },
	} = useTableContext()

	return (
		<StyledTable>
			<table {...getTableProps()}>
				<thead>
					{
						// Loop over the header rows
						headerGroups.map((headerGroup) => (
							// Apply the header row props
							<tr {...headerGroup.getHeaderGroupProps()}>
								{
									// Loop over the headers in each row
									headerGroup.headers.map((column) => (
										// Apply the header cell props.
										// Add the sorting props to control sorting.
										<th
											{...column.getHeaderProps(column.getSortByToggleProps())}
											style={{ width: column.width + 'px' }}>
											{
												// Render the header
												column.render('Header')
											}
											{/* Add a sort direction indicator */}
											<chakra.span d="inline" pos="relative" top="1px" left="3px">
												{column.isSorted ? (
													column.isSortedDesc ? (
														<Icon
															pos="absolute"
															boxSize="1.125rem"
															color="gray.300"
															as={ExpandCircleDownRoundedIcon}
														/>
													) : (
														<Icon
															pos="absolute"
															color="gray.300"
															boxSize="1.125rem"
															transform="rotate(180deg)"
															as={ExpandCircleDownRoundedIcon}
														/>
													)
												) : (
													''
												)}
											</chakra.span>
											{/* <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span> */}
										</th>
									))
								}
							</tr>
						))
					}
				</thead>
				{/* Apply the table body props */}
				<tbody {...getTableBodyProps()}>
					{
						// Loop over the table rows
						rows.map((row) => {
							// Prepare the row for display
							prepareRow(row)

							const p = row.getRowProps()
							console.log(p, 'p here', row)

							/**
							 * THESE SHOULD BE FOR EVENT TABLE ONLY !
							 * */

							// Do we want highlighting?
							const isPublishing = row.values.status && row.values.status == 'publishing'

							// Do we have render jobs?
							const hasRenderJobs = row.values.renderJobs && row.values.renderJobs.length > 0

							// Default background color
							let highlightColor = 'inherit'
							let gradient = null

							let animationStyles = {
								backgroundSize: '200% 200%',
								animation: `${animateGradient} 3s linear infinite`,
							}

							if (isPublishing && hasRenderJobs) {
								const job = row.values.renderJobs[0]

								// If we don't know the job state yet
								// if (job.jobState == null) {
								// 	highlightColor = '#E8E8E8' // light gray
								// 	gradient = 'linear-gradient(90deg, #E8E8E8, #ffffff, #E8E8E8)'
								// }
								// If the job state isn't yet finished
								if (job.jobState != 'finished') {
									highlightColor = 'var(--vidday-colors-yellow-200)' // yellow
									gradient = 'linear-gradient(90deg, #FFE4B8, #fff, #FFE4B8)'
								}
								// If the job state is finished
								// else if (job.jobState == 'finished') {
								// 	highlightColor = 'var(--vidday-colors-green-50)' // green
								// 	gradient = 'linear-gradient(90deg, #9FEABF, #fff, #9FEABF)'
								// } else {
								// }
							} else {
								highlightColor = 'inherit'
							}

							if (row.isExpanded) {
								highlightColor = '#F2F6FA'
							}

							const styles =
								isPublishing && hasRenderJobs
									? {
											...{ backgroundColor: highlightColor },
											...{ background: gradient },
											...animationStyles,
									  }
									: { backgroundColor: highlightColor }

							const rowStyles = row.isExpanded ? { backgroundColor: highlightColor } : styles

							/**
							 * END OF CUSTOM HACK
							 * */

							return (
								// Apply the row props
								<React.Fragment key={row.getRowProps().key}>
									<chakra.tr {...row.getRowProps()} sx={rowStyles}>
										{
											// Loop over the rows cells
											row.cells.map((cell) => {
												// Apply the cell props
												return (
													<td {...cell.getCellProps()}>
														{
															// Render the cell contents
															cell.render('Cell')
														}
													</td>
												)
											})
										}
									</chakra.tr>

									{/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}

									{row.isExpanded &&
										(SubRowView ? (
											<SubRowView row={row} visibleColumns={visibleColumns} tabs={tabs} />
										) : (
											<FactorySubRow row={row} visibleColumns={visibleColumns} tabs={tabs} />
										))}
								</React.Fragment>
							)
						})
					}
				</tbody>
			</table>
		</StyledTable>
	)
}

export default Table
