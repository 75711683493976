import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { promotionListSchema } from './normalizr'
import requests from './requests'
import { PROMOTIONS } from './constants'
import { UI } from '../../../../api/ui/constants'

/**
 * Saga worker
 * Fetch Promotions
 * @param {*} action
 */
function* handleFetchPromotions(action) {
	yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'info', message: 'Fetching promotions...' })

	try {
		const response = yield call(requests.fetchPromotions, action.payload)
		const normalizedData = normalize(response.data.data, promotionListSchema)
		const data = { ...response.data, ...normalizedData }
		delete data.data
		yield put({ type: PROMOTIONS.FETCH_SUCCEEDED, payload: data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: data.message })
	} catch (e) {
		yield put({ type: PROMOTIONS.FETCH_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Post a promotion
 * @param {*} action
 */
function* handlePostPromotion(action) {
	try {
		const response = yield call(requests.postPromotion, action.payload)
		const normalizedData = normalize([response.data.data], promotionListSchema)

		yield put({ type: PROMOTIONS.POST_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: PROMOTIONS.POST_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a promotion
 * @param {*} action
 */
function* handleUpdatePromotion(action) {
	try {
		const response = yield call(requests.updatePromotion, action.payload)

		const normalizedData = normalize([response.data.data], promotionListSchema)

		yield put({ type: PROMOTIONS.UPDATE_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: PROMOTIONS.UPDATE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a promotion
 * @param {*} action
 */
function* handleDeletePromotion(action) {
	try {
		const response = yield call(requests.deletePromotion, action.payload)
		// const normalizedData = normalize(response.data, adminUserListSchema)
		yield put({ type: PROMOTIONS.DELETE_SUCCEEDED, payload: response.data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: PROMOTIONS.DELETE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.message })
	}
}

/**
 * Users Saga Watcher
 */
export function* watchPromotionsFetch() {
	// while(true){
	yield takeLatest(PROMOTIONS.FETCH_REQUESTED, handleFetchPromotions)
	// }
}

export function* watchUpdatePromotion() {
	// while(true){
	yield takeEvery(PROMOTIONS.UPDATE_REQUESTED, handleUpdatePromotion)
	// }
}

export function* watchPostPromotion() {
	// while(true){
	yield takeEvery(PROMOTIONS.POST_REQUESTED, handlePostPromotion)
	// }
}

export function* watchDeletePromotion() {
	// while(true){
	yield takeEvery(PROMOTIONS.DELETE_REQUESTED, handleDeletePromotion)
	// }
}
