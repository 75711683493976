
import { normalize, schema } from 'normalizr'


const regionSchema = new schema.Entity(
	'regions',
	{},
	{
		idAttribute: (data) => data.code,
	}
)


const countrySchema = new schema.Entity(
	'countries',
	{
		regions: [regionSchema],
	},
	{
		idAttribute: (data) => data.alpha2Code,
	}
)
// Represent all related data coming through the fetch request when loading the user

const countryListSchema = new schema.Array(countrySchema)

export {countryListSchema}