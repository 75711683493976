/** Import our instance config */
// import instance from '../../../../../api/config'
import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeysPUT = []
const rejectedKeysPUT = ['createdAt', 'updatedAt', 'userId']


/**
 * Update a user profile
 * @param {*} data
 * @returns
 */
const updateUserProfile = async (data) => {
    const filteredData = reject(data, rejectedKeysPUT);
	const response = await instance.put('/user-profiles/' + data.id, filteredData)
	return response
}

const requests = { updateUserProfile }

export default requests
