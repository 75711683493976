import { USERS } from './constants'

const fetchUsers = (params) => ({
	type: USERS.FETCH_REQUESTED,
	payload: params,
})

const setUsers = (users) => ({
	type: USERS.FETCH_SUCCEEDED,
	payload: users,
})
const setError = (error) => ({
	type: USERS.FETCH_FAILED,
	payload: error,
})

const postUser = (data) => ({
	type: USERS.POST_REQUESTED,
	payload: data,
})

const updateUser = (data) => ({
	type: USERS.UPDATE_REQUESTED,
	payload: data,
})

const updateUserRole = (data) => ({
	type: USERS.UPDATE_ROLE_REQUESTED,
	payload: data,
})

const deleteUser = (id) => ({
	type: USERS.DELETE_REQUESTED,
	payload: id,
})

const resendVerificationEmail = (email) => ({
	type: USERS.RESEND_VERIFICATION_EMAIL_REQUESTED,
	payload: email,
})

export { fetchUsers, setUsers, setError, updateUser, updateUserRole, resendVerificationEmail }
