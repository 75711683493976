import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Core from '../../core'
import UnderConstruction from '../../views/utils/UnderConstruction'
import loadable from '@loadable/component'

const Dashboard = loadable(() => import(/* webpackPrefetch: true */ './views/Dashboard'))

/**
 * Define module
 */
export const instance = Core.defineApp({
	name: 'user',
	title: 'User App',
	path: '/user',
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance
	const location = useLocation()
	console.log(location, 'location user router')

	return (
		<Routes>
			<Route index element={<UnderConstruction />} />
			<Route path="dashboard" element={<Dashboard />} />
		</Routes>
	)
}
