import React, { useEffect } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core from '../../../core'

import { instance as countries } from './countries'
import { instance as regions } from './regions'
import { instance as currencies } from './currencies'
import { instance as languages } from './languages'

import { Router as RegionsRoutes } from './regions'
import { Router as CountriesRouter } from './countries'
import { Router as CurrenciesRoutes } from './currencies'
import { Router as LanguagesRoutes } from './languages'

/**
 * Define module
 */
export const internationalization = Core.defineModule({
	name: 'core.internationalization',
	title: 'Internationalization',
	path: '/core/international',
	parent: 'core',
})

export const instance = Core.combineApps(internationalization, [countries]) //, regions, currencies, languages

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance
	console.log('countries here (internationalization)')
	return (
		<Routes>
			<Route path="countries/*" element={<CountriesRouter />} />
		</Routes>
	)
}

// <>
// 	{/* <LanguagesRoutes />
//     <CurrenciesRoutes />
//     <RegionsRoutes />
//     <CountriesRoutes /> */}
// </>
