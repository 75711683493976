import React from 'react' 
import {StyledBottomPanel} from './styles'
import {useSelector} from 'react-redux'
import {useRightPanel} from '../RightPanel'
import {useSecondaryNavigation} from '../SecondaryNavigation'

import { createPortal } from 'react-dom';
import usePortal from '../../hooks/usePortal';
import useSSR from 'use-ssr'



/**
 * Hook that intends to retrieve the bottom 
 * Panel information from the reducer
 */
export const useBottomPanel = () => {
    const isOpen = useSelector(s => s.interface.panels.bottomPanel)
    return {isOpen}
}

/**
 * Bottom Panel Component. 
 * This component is already set as a Portal.
 * @param {*} children 
 * @returns 
 */
const BottomPanel = ({children, ...rest}) => {
    /** Check if we're using browser or server-side rendering */
    const { isServer, isBrowser } = useSSR()
    
    /** Target container to which we want to append to Portal */
    const target = usePortal("app-layout");
    
    /** Retrieve current reducer state for this panel */
    const {isOpen} = useBottomPanel()

    /** Retrieve current reducer state of the right panel */
    const {isOpen:isRightPanelOpen} = useRightPanel()

    /** Retrieve current reducer state of the secondary navigation */
    const {isOpen:isSecondaryNavigationOpen} = useSecondaryNavigation()
    
    /** Finally, let's build our portal */
    return isBrowser ? createPortal(
        (
            <StyledBottomPanel {...rest}  
                id="BottomPanel" 
                isOpen={isOpen} 
                isSecondaryNavigationOpen={isSecondaryNavigationOpen} 
                isRightPanelOpen={isRightPanelOpen}>
                    {children}
            </StyledBottomPanel>
        ),
        target,
    ) : null;
}


export default BottomPanel;