import React from 'react'
import { FormErrorMessage } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Select,
	Input,
	InputRightElement,
} from '@chakra-ui/react'

const ControlledSelect = (props) => {
	const {
		defaultValue,
		control,
		name,
		label,
		placeholder,
		helperText,
		options,
		scrollToOnFocus,
		errors,
		required,
		children,
	} = props

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				console.log(props, 'props')
				return (
					<FormControl isInvalid={errors[name]}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
						<Select id={name} placeholder={placeholder} {...props}>
							{options}
						</Select>
						{errors[name] ? (
							<FormErrorMessage d="block">{errors[name].message}</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>

					// <FormControl isInvalid={errors[name]}>
					// 	<FormLabel htmlFor={name} textAlign="center">
					// 		How much would you like to add?
					// 	</FormLabel>
					// 	<InputGroup>
					// 		<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />
					// 		<Input id={name} placeholder="Enter amount" {...props} />
					// 		{/* <InputRightElement children={<CheckIcon color='green.500' />} /> */}
					// 	</InputGroup>
					// 	{errors[name] ? (
					// 		<FormErrorMessage d="block">{errors[name].message}</FormErrorMessage>
					// 	) : (
					// 		<FormHelperText>Please enter an amount</FormHelperText>
					// 	)}
					// </FormControl>
				)
			}}
		/>
	)
}

export default ControlledSelect
