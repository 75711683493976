import styled from 'styled-components'

export const StyledParagraph = styled.p`
    position:relative;
    display:inline-block;
    font-size:16px;
    color:#B5BCC3;

    strong {
        font-weight:600;
        color: #0A2239;
    }
`;