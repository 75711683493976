import { MAIL, TOOL } from './constants'

const verifySingleEmail = (data) => ({
	type: MAIL.POST_VERIFY_REQUESTED,
	payload: data,
})

const changeEventCreator = (data) => ({
	type: TOOL.CHANGE_EVENT_CREATOR_REQUESTED,
	payload: data,
})

const mergeDuplicateAccount = (data) => ({
	type: TOOL.MERGE_DUPLICATE_ACCOUNT_REQUESTED,
	payload: data,
})

const mergeEvents = (data) => ({
	type: TOOL.MERGE_EVENTS_REQUESTED,
	payload: data,
})

const unsubscribeUser = (data) => ({
	type: TOOL.UNSUBSCRIBE_USER_REQUESTED,
	payload: data,
})

const transferEvent = (data) => ({
	type: TOOL.TRANSFER_EVENT_REQUESTED,
	payload: data,
})

export { verifySingleEmail, changeEventCreator, mergeDuplicateAccount, mergeEvents, unsubscribeUser, transferEvent }
