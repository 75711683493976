import React, {useState, useEffect, useCallback} from 'react'
import { automationTemplates } from '@bit/vidday.vidday-data.data.automation-templates'


/**
 * Return existing theme of an event given the themeTemplateId set on the event data.
 * @param {string} themeTemplateId 
 * @returns 
 */
// TODO : LINK DEFAULT THEME SCHEMA !!
const useRetrieveEventTheme = (themeTemplateId) => {
    const [theme, setCurrentTheme] = useState({label:''})

    /** Set a callback if we want to manually overwrite the 
     * current theme (this won't save on the event model!) */
    const handleSetCurrentTheme = useCallback((themeId)=> {
        let item = themeId 
                    ? automationTemplates.filter(t => t.id == themeId)[0] 
                    : automationTemplates.filter((theme) => theme.default)[0]
        setCurrentTheme(item);
    },[])

    /** Update current theme whenever it changes on the event model */
    useEffect(() => {
        let item;
        /** If we have a template id set on the event */
        if(themeTemplateId){
            item = automationTemplates.filter(t => t.id == themeTemplateId)[0]
        }
        /** Otherwise, return default */
        else { 
            item = automationTemplates.filter(t => t.default)[0]
        }
        setCurrentTheme(item)
        
    }, [themeTemplateId])

    return [theme, handleSetCurrentTheme];
}

export default useRetrieveEventTheme;