import React from 'react'
import Bool from '../../../../core/widgets/others/Bool'
import DateFormat from '../../../../core/widgets/others/DateFormat'

/**
 * Define our Table view columns
 */
const columns = [
	{
		id: 'id',
		Header: 'ID',
		accessor: 'id',
		width: 65,
	},
	{
		id: 'code',
		Header: 'Code',
		accessor: 'code',
		width: 100,
		Cell: ({ row }) => {
			return <span className="badge green">{row.original.code}</span>
		},
	},

	{
		id: 'isActive',
		Header: 'Is Active',
		accessor: 'isActive',
		width: 50,
		Cell: ({ row }) => <Bool value={row.original.isActive} />,
	},

	{
		id: 'type',
		Header: 'Type',
		accessor: 'type',
		width: 50,
		Cell: ({ row }) => {
			return <span className="badge">{row.original.type == 'percent' ? '%' : '$'}</span>
		},
	},
	{
		id: 'value',
		Header: 'Value',
		accessor: 'value',
		width: 100,
		Cell: ({ row }) => {
			const { type, value } = row.original
			return (
				<span className="badge">
					{type == 'percent'
						? value + '%'
						: type == 'amount'
						? '$' + value / 100
						: type == 'gratuit'
						? 'FREE'
						: 'Unknown'}
				</span>
			)
		},
	},

	{
		id: 'useCount',
		Header: '# used',
		accessor: 'useCount',
		width: 100,
		Cell: ({ row }) => {
			return <span className="badge">{row.original.useCount}</span>
		},
	},
]

export default columns
