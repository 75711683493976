import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
import { useDispatch } from 'react-redux'
import SubmitButton from '@bit/vidday.vidday-ui.ui.submit-button'
import styled, { css } from 'styled-components'
import { updateEvent } from '@bit/vidday.vidday-admin-store.modules.events'
import MenuItem from '@material-ui/core/MenuItem'
import { yupResolver } from '@hookform/resolvers/yup'
import { postPublishedMedia } from '@bit/vidday.vidday-admin-store.modules.media'
import Input from '../../../../../core/widgets/form/input'
import Select from '../../../../../core/widgets/form/select'
import Button from '../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import { postTheme, updateTheme } from '../../api/actions'

/** Define initial Values */
const initialValues = {
	id: '',
	label: '',
	class: '',
	desc: '',
	templateDir: '',
	projectFile: '',
	video: '',
	thumb: '',
	poster: '',
	icon: '',
	duration: '',
	uploadDate: '',
	selectable: false,
}

const ThemeForm = ({ data, onSubmitted }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		//resolver: yupResolver(schemaPromoCode),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		/** Construct our new object */
		const model = { ...data, ...values }

		if (model.id) {
			dispatch(updateTheme(model))
		} else {
			dispatch(postTheme(model))
			reset()
		}

		onSubmitted != undefined && onSubmitted()
	}

	const options = [
		{
			value: true,
			label: 'Yes',
		},
		{
			value: false,
			label: 'No',
		},
	]

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Theme Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Input
					size="small"
					control={control}
					name="name"
					id="name"
					variant="filled"
					label="Name"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="label"
					id="label"
					variant="filled"
					label="Label"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="class"
					id="class"
					variant="filled"
					label="CSS Class"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="slug"
					id="slug"
					variant="filled"
					label="Slug"
					errors={errors}
				/>

				<Select
					control={control}
					name="default"
					id="default"
					variant="filled"
					label="Is Default"
					size="small"
					errors={errors}
					options={options}>
					{options.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="description"
					id="description"
					variant="filled"
					label="Description"
					errors={errors}
					multiline={true}
				/>

				<Input
					size="small"
					control={control}
					name="thumbnail"
					id="thumbnail"
					variant="filled"
					label="Thumbnail path"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="poster"
					id="poster"
					variant="filled"
					label="Poster img path"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="icon"
					id="icon"
					variant="filled"
					label="Icon"
					errors={errors}
				/>
				{/* 
                <Input
					size="small"
					control={control}
					name="duration"
					id="duration"
					variant="filled"
					label="Duration"
					errors={errors}
				/>    */}

				{/* <Select
					control={control}
					name="default"
					id="selectable"
					variant="filled"
					label="Selectable"
					size="small"
					errors={errors}
					options={options}
				>
					{options.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                            {el.label}
                        </MenuItem>
                    ))}
				</Select>     */}
			</InlineItems>
		</form>
	)
}

ThemeForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default ThemeForm
