import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../../core'
import UnderConstruction from '../../../../views/utils/UnderConstruction'
// import {instance as locations} from './locations'
// import {instance as regions} from './regions'
// import {instance as taxes} from './taxes'
// import {instance as currencies} from './currencies'
// import {instance as languages} from './languages'
import ListView from './List'

// import {Routes as RegionsRoutes} from './regions'
// import {Routes as LocationsRoutes} from './locations'
// import {Routes as TaxesRoutes} from './taxes'
// import {Routes as CurrenciesRoutes} from './currencies'
// import {Routes as LanguagesRoutes} from './languages'

/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.sales.taxCodeCategories',
	title: 'Tax Categories',
	path: '/core/sales/taxcategories',
	parent: 'core.sales',
})

export const taxCodeCategoriesSlice = buildEngineSlice({
	sliceName: 'taxCodeCategories',
	actionPrefix: 'TAX_CODE_CATEGORIES',
	initialState: {
		pageSize: 20,
		sortBy: 'id',
	},
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
