/* **************************************
 *  LIBRARIES
 * ************************************ */

import React from 'react'
import { createGlobalStyle } from 'styled-components'

/* **************************************
 *  STYLES
 * ************************************ */

const Globals = createGlobalStyle`
  :root {
  	  /* Default Widths */
  	  --global-font-family: Verdana, sans-serif;
      --global-text-color: #222;

      /* Default Widths */
      --global-page-width: 924px;
      --global-modal-width: 712px;
      --global-modal-min-height: 360px;
      --global-form-wrapper-width: 320px;

      /* Base Font Size */
      --global-font-size: 18;
      --global-font-size-large: 24;
      --global-font-size-small: 10;
      --global-font-size-mobile: 14;
      --global-line-height: 24;

      /* Base Sizing Padding Margin s x 4 = px */
      --global-s1: 4px; /* .25rem */
      --global-s2: 8px; /* .5rem */
      --global-s3: 12px; /* .75rem */
      --global-s4: 16px; /* 1rem */
      --global-s5: 20px; /* 1.25rem */
      --global-s6: 24px; /* 1.5rem */
      --global-s7: 28px; /* 1.75rem */
      --global-s8: 32px; /* 2rem */
      --global-s9: 36px; /* 2.5rem */
      --global-s10: 40px; /* 2rem */
      --global-s12: 48px; /* 3rem */
      --global-s16: 64px; /* 4rem */
      --global-s20: 80px; /* 5rem */
      --global-s24: 96px; /* 6rem */
      --global-s28: 112px; /* 7rem */
      --global-s32: 128px; /* 8rem */
      --global-s36: 144px; /* 9rem */

  	/* Font Weight */
  	--global-font-weight-light: 100;
  	--global-font-weight-book: 300;
  	--global-font-weight-medium: 500;
    --global-font-weight-semi-bold: 700;
    --global-font-weight-bold: 700;
    --global-font-weight-black: 900;

  	/* Base Shadow */
  	--global-base-shadow: 1px 3px 8px rgba(0,0,0,.3);

  	/* Password Strenght Score */
  	--global-score1: #FF1C63;
  	--global-score2: #FF6B24;
  	--global-score3: #FFAF2F;
  	--global-score4: #04D898;

  	/* Default Colors */

  	/* RED */
  	--global-color-red: #FF1C63;
  	--global-color-red-light: #FF3272;
  	--global-color-red-dark: #e5195a;

    /* ORANGE */
  	--global-color-orange: #FF6B24;
  	--global-color-orange-light: #FF3272;
  	--global-color-orange-dark: #e5195a;

  	/* YELLOW */
  	--global-color-yellow: #FFAF2F;
  	--global-color-yellow-light: #FFB643;
  	--global-color-yellow-dark: #e59d29;

  	/* GREEN */
  	--global-color-green: #04D898;
  	--global-color-green-light: #1DDBA2;
    --global-color-green-dark: #04bf87;
    
    /* NAVY BLUE */
    --global-color-navy: #0A2239;
    
    /* BLUE */
  	--global-color-blue: #0187FF;
  	--global-color-blue-light: #41BEFF;
  	--global-color-blue-dark: #027be5;

  	/* PURPLE */
  	--global-color-purple: #5C50FB;
  	--global-color-purple-light: #6B61FA;
  	--global-color-purple-dark: #4940c7;


  	/* Alert, Warning, Success, Information */
  	--global-color-alert: var(--global-color-red);
  	--global-color-warning: var(--global-color-yellow);
  	--global-color-success: var(--global-color-green);
  	--global-color-info: var(--global-color-blue);


    /* Greyscale Palette (Darker to Lighter) */
    --global-grayscale-1:#0A2239;
    --global-grayscale-2:#47596A;
    --global-grayscale-3:#6C7A88;
    --global-grayscale-4:#B5BCC3;
    --global-grayscale-5:#DADDE1;
    --global-grayscale-6:#E8ECF0;
    --global-grayscale-7:#F2F6FA;
    --global-color-grey: var(--global-grayscale-2);
    
    
    /* Shade Tones */
  	--global-color-grey-extra-light: #F6F6F8;
  	--global-color-grey-light: #EDEEF1;
  	--global-color-grey-medium: #B5BCC3;
  	--global-color-grey-dark: #6C7A88;
    --global-color-grey-extra-dark: #0A2239;

  	/* Input colors */
  	--global-input-background-color: #F6F6F8; /* use #E6E8EB with border */
    --global-input-field-color: #F6F6F8; /* use #E6E8EB with border */

  	/* Stripes colors */
    --global-stripe-color: #E6E8EB;
    
    /* Body Background */
    /* White to silver */
    --global-background: #E8ECF0
  }
`

export { Globals }

export default Globals
