import styled from 'styled-components'

const SubRowHeader = styled.div`
    padding-bottom:15px;
    display:block;
    font-size:18px; 
    font-weight:500; 
    border-bottom:1px dotted #cccccc;
    margin-bottom:10px;
    clear:both;

    &:after {
        content:'';
        clear:both;
    }
    a:hover {
        cursor:pointer;
    }

    p a {
        svg {
            position:relative;
            top:4px;
            margin:0 5px;
        }
    }

    div.actions {
        float:right;
    }
`


export default SubRowHeader