
import React, {useEffect} from 'react' 
import styled from 'styled-components'
import Button from '../../buttons/Button'
import IconButton from '../../buttons/ButtonIcon'
import {useInterfacePanel, Modal} from '../../../../interface'
import useLockBodyScroll from '../../../../hooks/ui/useLockBodyScroll'
const Centered = styled.div`
    text-align:center;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);

    p {
        display:block;
        margin-bottom:20px;
        font-style:italic;
    }

    strong { 
        font-weight:500;
    }
`;

const DeleteModal = ({ name, onClose, onDelete, ...rest}) => {
    
    useLockBodyScroll()

    const {closePanel} = useInterfacePanel()
    const closeModal = () => closePanel('modal')

    const handleDelete = () => {
        /** Trigger delete callback handler */
        onDelete();
        /** Trigger closure of modal */
        closeModal()
    }

    const handleClose = () => {
        /** Trigger closure of the modal */
        closeModal()
        /** Trigger onClose callback handler */
        onClose()
    }

    return (
        <Modal>
            <Centered>
                <h1>Are you sure you want to delete this {name} ?</h1>
                <p><strong>This action cannot be undone.</strong> Proceed with care.</p>
                <IconButton delete onClick={() => handleDelete()}>Delete</IconButton>
                <Button onClick={() => handleClose()}>Close</Button>
            </Centered>
        </Modal>
    )
}

export default DeleteModal