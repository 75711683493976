import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {
    togglePanel as togglePanelAction, 
    openPanel as openPanelAction, 
    closePanel as closePanelAction,
} from '../reducer'

const useInterfacePanel = () => {
    const dispatch = useDispatch() 
    
    const togglePanel = useCallback((name) => {
        dispatch(togglePanelAction(name))
    }, []);

    const openPanel = useCallback((name) => {
        dispatch(openPanelAction(name))
    }, []);

    const closePanel = useCallback((name) => {
        dispatch(closePanelAction(name))
    }, []);

    console.log({togglePanel, openPanel, closePanel})
    return {togglePanel, openPanel, closePanel};

}

export default useInterfacePanel