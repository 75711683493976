import styled from 'styled-components'

export const StyledTable = styled.div`
	table {
		border-collapse: collapse;
		width: 100%;
		td,
		th {
			border: none;
			border-bottom: 1px solid #e8ecf0;
			padding: 10px 8px;
		}

		th {
			cursor: pointer;
			font-size: 14px;
			font-weight: 500;
			border-bottom: 2px solid #b5bcc3;
			padding-top: 12px;
			padding-bottom: 12px;
			text-align: left;
			background-color: transparent;
			color: #6c7a88;
		}

		// tr:nth-child(even){background-color: #f1f1f1;}

		tr:hover td {
			background-color: #f2f6fa;
		}
		tr:hover th {
			background-color: transparent;
		}

		td {
			color: #47596a;
			font-size: 14px;
		}

		tr span.expandableIcon {
			position: relative;
			width: 20px;
			display: block;

			svg {
				position: absolute;
				top: -15px;
				color: #b1c2d3;
			}

			&:hover svg {
				color: #6c7a88;
			}
		}
		tr:hover span.expandableIcon svg {
			color: #6c7a88;
		}

		tr.expandedRow {
			background-color: white; //#F2F6FA;
			td {
				padding: 0 0;
				background: rgb(241, 241, 241);
				background: linear-gradient(
					0deg,
					rgba(241, 241, 241, 1) 0%,
					rgba(255, 255, 255, 1) 5%,
					rgba(255, 255, 255, 0) 100%
				);
			}

			&:hover td {
				background-color: white;
			}
		}

		span.badge {
			border-radius: 5px;
			background-color: #b5bcc3; //#F2F6FA;//#47596A;
			color: white;
			font-size: 12px;
			padding: 3px 8px;
			font-weight: 500;
			position: relative;
			top: -2px;
			margin-right: 5px;

			&.green {
				background-color: #04d898;
			}

			&.blue {
				background-color: #41beff;
			}

			&.orange {
				background-color: #ff6b24;
			}

			&.error {
				background-color: #ff1c63;
				color: white;
			}
		}

		span.status {
			border-radius: 5px;
			background-color: #04d898; //#F2F6FA;//#47596A;
			color: white;
			font-size: 12px;
			padding: 3px 3px;
			font-weight: 500;
			position: relative;
			top: -2px;
			margin-right: 5px;
		}
	}
`

export default StyledTable
