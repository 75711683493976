import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../../core/widgets/form/input'
import Select from '../../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../../core/widgets/buttons/Button'
import { updateCurrency, postCurrency } from '../../api/actions'
import schemaCurrency from './schema'

const Currency = ({ data }) => {
	const { timezone, expires, ends } = data
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaCurrency),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.code) {
			dispatch(updateCurrency(model))
		} else {
			dispatch(postCurrency(model))
			reset()
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Currency Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Input
					size="small"
					control={control}
					name="code"
					id="code"
					variant="filled"
					label="Code"
					errors={errors}
					disabled={true}
				/>
				<Input
					size="small"
					control={control}
					name="label"
					id="label"
					variant="filled"
					label="Label"
					errors={errors}
				/>
				<Input
					size="small"
					control={control}
					name="labelFull"
					id="labelFull"
					variant="filled"
					label="Label (Full)"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="symbol"
					id="symbol"
					variant="filled"
					label="Symbol"
					errors={errors}
				/>
			</InlineItems>
		</form>
	)
}

Currency.defaultProps = {
	/** Important */
	data: {},
}

Currency.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default Currency
