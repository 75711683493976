import * as Yup from 'yup'

const productSchema = Yup.object().shape({
	
	productId: Yup.string().required('You need to select a product'),
	price: Yup.number().required('You need to indicate a price'),
	countryCode: Yup.string().optional().nullable().default(null),
    currencyCode: Yup.string().when('countryCode', (countryCode, schema) => {
		if(countryCode){
			return schema.required('Please select a currency.')
        }
        else {
			return schema.nullable().notRequired().default(null)
		}
	}),
	taxCodeCategoryId: Yup.string().optional().nullable().default(null),
})

export default productSchema
