import { combineReducers } from 'redux'

import { reducer as Login } from '../apps/auth/forms/Login/reducer'
import { reducer as ForgotPassword } from '../apps/auth/forms/ForgotPassword/reducer'
import { reducer as ResetPassword } from '../apps/auth/forms/ResetPassword/reducer'

/** Export forms reducers */
const formReducers = combineReducers({
	ForgotPassword,
	Login,
	ResetPassword,
})

export default formReducers
