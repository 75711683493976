/* eslint-disable no-console */
import { createSlice, createAction } from '@reduxjs/toolkit'

import * as types from '@bit/vidday.vidday-store.constants'
import { makeActionCreator } from '@bit/vidday.vidday-helpers.utils.make-action-creator'


export const availablePanels = ['navigation','secondary', 'rightPanel', 'bottomPanel']



// const registerApplications = createAction('interface/REGISTER_APPLICATIONS')

/** Define our initial apps State */
const initialState = {
    /** Registered applications, those used through navigation panel. 
     * Ultimately, all apps are loaded by default for now, but not all apps 
     * need to be visible through navigation (auth app for instance)
     */
    registeredApps: [],
    currentApp: null,
    currentAppId: null,
}

const appsSlice = createSlice({
	initialState: initialState,
	name: 'apps',
	extraReducers: {},
	reducers: {
        /** 
         * Action creator to register our applications in one shot
         * */
		registerApplications: (state, action) => {
			state.registeredApps = action.payload
		},
        /** 
         * Action creator to register our application individually
         * */
        registerApp: (state, action) => {
            const index = state.registeredApps.findIndex(el => el.name == action.payload?.name)
			/** Update existing if it already exist with new one received */
            if(index != -1) { state.registeredApps[index] = action.payload  }
            /** Or register a new one */
            else { state.registeredApps.push(action.payload) }
		},
        /**
         * Set current app
         * @param {string} id of the app (name)
         */
        setCurrentApp:(state, action) => {
            const index = state.registeredApps.findIndex(el => el.name == action.payload)
            
            if(index != -1) { 
                state.currentAppId = state.registeredApps[index].name
                state.currentApp = state.registeredApps[index]
            }
           
            else { 
                state.currentAppId = action.payload
                state.currentApp = null; // We couldnt find an app...
            }
		},
	},
})

export default appsSlice.reducer

export {initialState}

// Export our actions
export const { registerApplications, registerApp, setCurrentApp} = appsSlice.actions
