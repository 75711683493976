import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../core'
import UnderConstruction from '../../../views/utils/UnderConstruction'
import ListView from './List'

/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'vidday.themeCategories',
	title: 'Theme Categories',
	path: '/vidday/themecategories',
	parent: 'vidday',
})

export const themeCategoriesSlice = buildEngineSlice({
	sliceName: 'themeCategories',
	actionPrefix: 'THEME_CATEGORIES',
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
