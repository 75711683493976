import * as Yup from 'yup'

export const typeOptions = [
	{ value: 'percent', label: 'Percentage (%)' },
	{ value: 'amount', label: 'Amount ($) in cents' },
]

export const isEnabledOptions = [
	{ value: true, label: 'Active' },
	{ value: false, label: 'Deactivate' },
]

const schemaPromotion = Yup.object().shape({
	code: Yup.string().required(),
	isActive: Yup.boolean().required(),
	type: Yup.string().required().oneOf(['percent', 'amount']),
	value: Yup.number().positive().integer().required(),
	maxCount: Yup.number().positive().notRequired(),
	timezone: Yup.string().required(),
	expires: Yup.string().required(),
})

export default schemaPromotion
