import React from 'react' 
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../core/widgets/others/SubRowHeader'

const Details = (props) => {
    const data = props.row.original;
    
    const listStatus = [
        {
            name:'Is enabled',
            content:data.enabled == true ? 'Yes' : 'No',
        },
        {
            name:'Type',
            content:data.type,
        },
        {
            name:'Message',
            content:data.message,
        },
    ]

    const listDetails = [
        {
            name:'ID',
            content:data.id,
        },
        {
            name:'Timezone',
            content:data.timezone,
        },
        {
            name:'Start Date',
            content:data.starts,
        },
        {
            name:'Ending Date',
            content:data.ends,
        },
    ]


    return (
        <>
            <SubRowHeader>
                <p>Details</p>  
            </SubRowHeader>

            <InlineItems list={listStatus} />
            <InlineItems list={listDetails} />
        </>
    )
}

export default Details