import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { taxCodeRateListSchema } from './normalizr'
import requests from './requests'
import { TAX_CODE_RATES } from './constants'

/**
 * Users Saga worker
 * Fetch tax code rates
 * @param {*} action 
 */
function* handleFetchTaxCodeRates(action) {
    try {
        const response = yield call(requests.fetchTaxCodeRates, action.payload)
        const normalizedData = normalize(response.data.data, taxCodeRateListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: TAX_CODE_RATES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: TAX_CODE_RATES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a tax code rate
 * @param {*} action 
 */
 function* handlePostTaxCodeRate(action) {
    try {
        const response = yield call(requests.postTaxCodeRate, action.payload)
        const normalizedData = normalize([response.data.data], taxCodeRateListSchema)
        yield put({ type: TAX_CODE_RATES.POST_SUCCEEDED, payload:normalizedData})
    }
    catch(e){
        yield put({type: TAX_CODE_RATES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a tax code rate
 * @param {*} action 
 */
 function* handleUpdateTaxCodeRate(action) {
    try {
        const response = yield call(requests.updateTaxCodeRate, action.payload)
        
        const normalizedData = normalize([response.data.data], taxCodeRateListSchema)
        console.log(response.data)
        yield put({ type: TAX_CODE_RATES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: TAX_CODE_RATES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a tax code rate
 * @param {*} action 
 */
 function* handleDeleteTaxCodeRate(action) {
    try {
        const response = yield call(requests.deleteTaxCodeRate, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: TAX_CODE_RATES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: TAX_CODE_RATES.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchTaxCodeRatesFetch(){
    // while(true){
        yield takeLatest(TAX_CODE_RATES.FETCH_REQUESTED, handleFetchTaxCodeRates)
    // }
}

export function* watchUpdateTaxCodeRate(){
    // while(true){
        yield takeEvery(TAX_CODE_RATES.UPDATE_REQUESTED, handleUpdateTaxCodeRate)
    // }
}

export function* watchPostTaxCodeRate(){
    // while(true){
        yield takeEvery(TAX_CODE_RATES.POST_REQUESTED, handlePostTaxCodeRate)
    // }
}

export function* watchDeleteTaxCodeRate(){
    // while(true){  
        yield takeEvery(TAX_CODE_RATES.DELETE_REQUESTED, handleDeleteTaxCodeRate)
    // }
}