import React, { useState } from 'react'
import IconButton from '../../../../../core/widgets/buttons/ButtonIcon'
import { useDispatch } from 'react-redux'
import ButtonIcon from '../../../../../core/widgets/buttons/ButtonIcon'
import Button from '../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../core/widgets/others/SubRowHeader'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import { useInterfacePanel } from '../../../../../interface'
import { deleteAnnouncement } from '../../api/actions'
import DeleteModal from '../../../../../core/widgets/modals/DeleteModal'

const Actions = ({ row, goToTab }) => {
	const dispatch = useDispatch()

	const { openPanel } = useInterfacePanel()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)

	const displayDeleteModal = () => {
		setIsDeleteOpen(true)
		openPanel('modal')
	}

	const onClose = () => {
		setIsDeleteOpen(false)
	}

	const onDelete = () => {
		/** Delete the entity here from db */
		dispatch(deleteAnnouncement(row.original.id))
		onClose()
	}

	return (
		<div>
			<SubRowHeader>
				<p>
					<span className="badge">{row.original.type}</span> {row.original.message}
				</p>
				<div className="actions">
					<ButtonIcon revert onClick={() => {}}>
						<AspectRatioIcon fontSize="small" /> Open
					</ButtonIcon>
				</div>
			</SubRowHeader>

			<InlineItems title="Actions">
				<ButtonIcon edit onClick={() => goToTab('edit')}>
					Edit
				</ButtonIcon>
				<ButtonIcon delete onClick={() => displayDeleteModal()}>
					Delete
				</ButtonIcon>
				{isDeleteOpen && <DeleteModal name="Announcement" onDelete={onDelete} onClose={onClose} />}
			</InlineItems>
		</div>
	)
}
export default Actions
