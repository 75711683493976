import { createSlice } from '@reduxjs/toolkit'

import { PROMOTIONS } from './constants'

const initialState = {
	data: {},
	ids: [],
	isFetching: false,
	hasError: false,
	errorMessage: '',
}

const promotionsSlice = createSlice({
	initialState: initialState,
	name: 'promotions',
	extraReducers: {
		[PROMOTIONS.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[PROMOTIONS.FETCH_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities) {
				state.data = action.payload.entities.promotions || {}
				state.ids = action.payload.result
				state.isFetching = false
				state.hasError = false
			}
		},
		[PROMOTIONS.FETCH_FAILED]: (state, action) => {
			state.hasError = true
			state.isFetching = false
			state.errorMessage = 'An error occured while fetching.'
		},

		[PROMOTIONS.UPDATE_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities && action.payload.entities.promotions) {
				var item = state.data[action.payload.result[0]]
				// If we found an item, update it
				if (item) {
					state.data[action.payload.result[0]] = action.payload.entities.promotions[action.payload.result[0]]
				}
			}
		},
		[PROMOTIONS.POST_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities && action.payload.entities.promotions) {
				state.data[action.payload.result[0]] = action.payload.entities.promotions[action.payload.result[0]]
			}
		},
		[PROMOTIONS.DELETE_SUCCEEDED]: (state, action) => {
			var item = state.data[action.payload.data.id]
			// If we found an item, update it
			if (item) {
				delete state.data[action.payload.data.id]
			}
		},
	},
	reducers: {},
})

export default promotionsSlice.reducer
