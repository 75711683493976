/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import { pick } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = ['themeId']
const acceptedKeysPUT = ['themeId']

/**
 * Fetch our theme categories
 */
const fetchThemeCategories = async (params) => {
	const response = await instance.get('/theme-categories?' + params)
	return response
}

/**
 * Post a theme category
 * @param {*} data
 * @returns
 */
const postThemeCategory = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/theme-categories', filteredData)
	return response
}

/**
 * Update a theme category
 * @param {*} data
 * @returns
 */
const updateThemeCategory = async (data) => {
	const filteredData = pick(data, acceptedKeysPUT)
	const response = await instance.put('/theme-categories/' + data.id, filteredData)
	return response
}

/**
 * Delete a theme category
 * @param {*} data
 * @returns
 */
const deleteThemeCategory = async (id) => {
	const response = await instance.delete('/theme-categories/' + id)
	return response
}

const requests = { fetchThemeCategories, postThemeCategory, updateThemeCategory, deleteThemeCategory }

export default requests
