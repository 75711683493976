import React from 'react'

import { StyledButton } from './styles'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const ButtonEditColumns = ({ columns }) => {
	/**
	 * If the columns contain the selection item, remove it
	 * */
	let filteredColumns = columns.filter((el) => el.id != 'selection')

	/**
	 * Build our collection of items view
	 */
	const vals = filteredColumns.map((column) => {
		/** Handle toggle of the column */
		const handleToggle = (e) => {
			e.preventDefault()
			if (column.isVisible) {
				column.toggleHidden(true)
			} else column.toggleHidden(false)
		}

		return (
			<li key={column.id} isActive={column.isVisible}>
				<a href="#" onClick={(e) => handleToggle(e)}>
					{column.isVisible ? (
						<CheckBoxIcon fontSize="small" />
					) : (
						<CheckBoxOutlineBlankIcon fontSize="small" />
					)}{' '}
					{column.id}
				</a>
			</li>
		)
	})

	/**
	 * Build our list view
	 */
	const itemsView = <ul className="checkbox">{vals}</ul>

	return (
		<StyledButton>
			<SyncAltIcon /> columns
			{itemsView}
		</StyledButton>
	)
}

export default ButtonEditColumns
