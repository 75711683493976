import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
// import { normalize } from 'normalizr'
// import { eventListSchema, eventSchema } from './normalizr'
import requests from './requests'
import { VIDDAY_PACKAGE } from './constants'

/**
 * Saga worker
 * Post regenerate an image 
 * @param {*} action 
 */
 function* handleCompressViddayPackage(action) {
    try {
        const response = yield call(requests.postRegenerateImage, action.payload)
        yield put({ type: VIDDAY_PACKAGE.COMPRESS_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: VIDDAY_PACKAGE.COMPRESS_FAILED, payload:e })
    }
}

export function* watchCompressViddayPackage(){
    // while(true){
        yield takeEvery(VIDDAY_PACKAGE.COMPRESS_REQUESTED, handleCompressViddayPackage)
    // }
}
