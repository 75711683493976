import { THEME_CATEGORIES } from './constants'


/** THEME VERSIONS */
const fetchThemeCategories = (params) => ({
    type: THEME_CATEGORIES.FETCH_REQUESTED,
    payload:params,
})

const setThemeCategories = (themes) => ({
    type: THEME_CATEGORIES.FETCH_SUCCEEDED,
    payload:themes,
})

const postThemeCategory = (data) => ({
    type: THEME_CATEGORIES.POST_REQUESTED,
    payload:data,
})

const updateThemeCategory = (data) => ({
    type: THEME_CATEGORIES.UPDATE_REQUESTED,
    payload:data,
})

const deleteThemeCategory = (id) => ({
    type: THEME_CATEGORIES.DELETE_REQUESTED,
    payload:id,
})

export {
    fetchThemeCategories,
    setThemeCategories,
    postThemeCategory,
    updateThemeCategory,
    deleteThemeCategory,
}





