import React from 'react' 
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../../core/widgets/others/SubRowHeader'
const commaSplit = (collection) => {
    let result = collection.map((el, i, col) => {
        if((i+1) != col.length){
           return  el+', ';
        } else return el
    })

    return result
}
const Details = (props) => {
    const data = props.row.original;
    
    const listCurrencies = [
        {
            name:'Default Currency',
            content:data.defaultCurrencyCode,
        },
        {
            name:'Allowed Currencies',
            content: commaSplit(data.allowedCurrencies),
        },
      
    ]

    const listDetails = [
        {
            name:'ID',
            content:data.id,
        },
        {
            name:'Country',
            content:data.name,
        },

        {
            name:'Region',
            content:data.region,
        },
        {
            name:'Sub-region',
            content:data.subregion,
        },

        {
            name:'timezone(s)',
            content:commaSplit(data.timezones),
        },
        
        {
            name:'Calling Code(s)',
            content:commaSplit(data.callingCodes),
        },
    ]

    // const listUser = [
    //     {
    //         name:'ID',
    //         content:data.id,
    //     },
    //     {
    //         name:'Role',
    //         content:data.role,
    //     },
    //     {
    //         name:'Is Active',
    //         content:data.isActive == false ? 'No' : 'Yes',
    //     },
    //     {
    //         name:'Is New',
    //         content:data.isNew == false ? 'No' : 'Yes',
    //     },
    //     {
    //         name:'Is Registered',
    //         content:data.isRegistered == false ? 'No' : 'Yes',
    //     },
    //     {
    //         name:'Email',
    //         content:data.email,
    //     },
    //     {
    //         name:'Temporary Email',
    //         content:data.temporaryEmail,
    //     },
    //     {
    //         name:'Blocked',
    //         content:data.blocked == false ? 'No' : 'Yes',
    //     },
    //     {
    //         name:'First Authentication',
    //         content:data.firstAuthentication == false ? 'No' : 'Yes',
    //     },
    //     {
    //         name:'Last Active At',
    //         content:data.lastActive,
    //     },
    //     {
    //         name:'Activated At',
    //         content:data.activatedAt,
    //     },
    //     {
    //         name:'Updated At',
    //         content:data.updatedAt,
    //     }, 
    //     {
    //         name:'Created At',
    //         content:data.createdAt,
    //     },        
    // ]


    return (
        <>
            <SubRowHeader>
                <p>Details</p>  
            </SubRowHeader>

            <InlineItems title="Currencies" list={listCurrencies} />
            <InlineItems title="Country Details" list={listDetails} />
        </>
    )
}

export default Details