import * as Yup from 'yup'

const taxCodeCategorySchema = Yup.object().shape({
	name: Yup.string().required('You need to provide a name.'),
	code: Yup.string().required('You need to provide a code for the category.'),
	zone: Yup.string().required('You need to select a zone.').oneOf(['USA','EU', 'Australia']),
    description: Yup.string().optional().default(''),
})

export default taxCodeCategorySchema
