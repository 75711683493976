import qs from 'qs'

/** Keys that should be included in final URL params serialization */
export const acceptedKeys = ['searchQuery', 'currentPage', 'pageSize', 'filters', 'sortBy', 'sortByDirection']

/**
 * Method that accept an object of properties and check it against an array of keys.
 * Only keys of the object present in the array will be returned in final Object
 * @param {object} obj Object to filter
 * @param {array} acceptedKeyArray array of accepted keys (strings)
 */
export const extractKeys = (obj, acceptedKeyArray = acceptedKeys) => {
	let finalObj = {}
	for (var p in obj) {
		if (obj.hasOwnProperty(p) && acceptedKeyArray.includes(p)) {
			finalObj[p] = obj[p]
		}
	}
	return finalObj
}

/**
 * Method that serialize an object and convert it to an encoded URI string,
 * with support for nested objects (being flattened at same level).
 * @param {object} obj
 */
export const serialize = (obj) => {
	// var str = [];
	// for (var p in obj){
	//     /** Support nested object */
	//     if(typeof obj[p] == 'object'){
	//         for (var j in obj[p]){
	//             if (obj[p].hasOwnProperty(j)) {
	//                 str.push(encodeURIComponent(j) + "=" + encodeURIComponent(obj[p][j]));
	//             }
	//         }
	//     }
	//     /** Support for first level keys */
	//     else {
	//         if (obj.hasOwnProperty(p)) {
	//             str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	//         }
	//     }
	// }
	// return str.join("&");
	return qs.stringify(obj)
}
