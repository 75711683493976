const Divider = {
	baseStyle: {
		borderColor: '#DADDE1',
		// borderRadius: 'full',
		// fontWeight: 'semibold', // Normally, it is "semibold"
	},
	variants: {
		dashed: {
			borderStyle: 'dashed',
		},
	},
	defaultProps: {},
}

export default Divider
