
import { normalize, schema } from 'normalizr'


const taxCodeRateSchema = new schema.Entity(
	'taxCodeRates',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

// Represent all related data coming through the fetch request when loading the user

const taxCodeRateListSchema = new schema.Array(taxCodeRateSchema)

export {taxCodeRateListSchema}