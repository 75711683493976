import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
import productSchema from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../../core/widgets/form/input'
import Select from '../../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../../core/widgets/buttons/Button'
import { updateProduct, postProduct } from '../../api/actions'

const ProductForm = ({ data, onSubmitted }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(productSchema),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		console.log(data, values, 'submit product')
		/** Construct our new object */
		const model = { ...data, ...values }
		model.slug == '' && (model.slug = null)
		model.description == '' && (model.description = null)
		model.duration == '' && (model.duration = null)

		if (model.id) {
			dispatch(updateProduct(model))
		} else {
			dispatch(postProduct(model))
			reset()
		}
		//trigger submit callback if any
		onSubmitted != undefined && onSubmitted()
	}

	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	// useEffect(() => {
	// 	// Set timezone automatically
	// 	// const autoTimezone = timezone ? timezone : moment.tz.guess(true)
	// 	// // Set deadline automatically
	// 	// const autoStartDate = starts ? starts : moment().add(2, 'days')
	//     // const autoEndDate = ends ? ends : moment().add(2, 'days')
	// 	// // Set initial values
	// 	// setValue('starts', autoStartDate)
	//     // setValue('ends', autoEndDate)
	// 	// setValue('timezone', autoTimezone)
	// }, [timezone, starts, ends])

	// useEffect(() => {
	//console.log("FORM CHANGE",moment(deadline).format(),moment(deadline).tz(values.timezone).format())
	// setValue('starts', moment(starts).tz(values.timezone))
	// setValue('ends', moment(ends).tz(values.timezone))
	// }, [values.timezone])

	/** Register uncontrolled components */
	useEffect(() => {
		// register({ name: 'timezone' }, { required: true })
		// register({ name: 'starts' }, { required: true })
		// register({ name: 'ends' }, { required: true })
	}, [register])

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	const groups = [
		{ value: 'video', label: 'Video' },
		{ value: 'addon', label: 'Add-on' },
		{ value: 'theme', label: 'Theme' },
		{ value: 'giftcard', label: 'Gift Card' },
		{ value: 'marketplace', label: 'Market Place' },
	]

	console.log(errors, 'errors')
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Product Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					margin="normal"
					name="group"
					id="group"
					variant="filled"
					label="Product Group"
					errors={errors}>
					{groups.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="name"
					id="name"
					variant="filled"
					label="Product Name"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="slug"
					id="slug"
					variant="filled"
					label="Slug"
					helperText="useful to provide alternate conditions or target a specific product (ie. 'qualityCheck')"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="description"
					id="description"
					variant="filled"
					label="Description"
					errors={errors}
					multiline={true}
				/>

				<Input
					size="small"
					control={control}
					name="duration"
					id="duration"
					variant="filled"
					label="Duration"
					helperText="This is only applicable for video product"
					errors={errors}
				/>

				{/* 
            <Select
                control={control}
                margin="normal"
                name="allowedCurrencies"
                id="type"
                variant="filled"
                multiple={true}
                label="Allowed Currencies"
                errors={errors} >
                {groups.map((el, i) => (
                    <MenuItem key={i} value={el.value}>
                        {el.label}
                    </MenuItem>
                ))}
            </Select> */}
			</InlineItems>
		</form>
	)
}

ProductForm.defaultProps = {
	/** Important */
	data: {
		name: '',
		group: '',
		slug: null,
		description: null,
		duration: null,
	},
}

ProductForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default ProductForm
