import styled, {css} from 'styled-components'

import {StyledBaseButton} from '../styles'
import {_baseListStyles, _baseListCheckboxStyles} from '../../filters/styles'

export const StyledButton = styled(StyledBaseButton)`
    font-weight:500;
    font-size:12px;
    color:#B5BCC3;
    text-transform:uppercase;
    display: inline-flex;
    padding: 0 8px;
    position: relative;

    height: 23px;
    padding-top: 10px;
    padding-left: 36px;
    padding-right:10px;


    svg {
        left:8px;
        top: 4px;
        float:left;
        position: absolute;
        color:#B5BCC3;
    }

    span, ul {
        display:inline;
    }
    

    ${_baseListStyles};

    ${_baseListCheckboxStyles};

    ul a {
        text-transform:none;
        font-weight:400;
    }

    ul a {
        cursor:pointer;
    }
    ul a > svg {
        color:#0A2239;
    }
    ul a:hover > svg {
        color:#0187FF;
    }
`;