import * as Yup from 'yup'

const productSchema = Yup.object().shape({
	name: Yup.string().required('You need to provide a name.'),
	slug: Yup.string().optional().nullable().default(null),
    description: Yup.string().optional().nullable().default(null),
	group: Yup.string().required('You need to select a group.').oneOf(['video','addon', 'theme', 'giftcard', 'marketplace']),
    duration: Yup.number().when('group', (g, schema) => {
        console.log(g, schema, 'hello there')
		if(g == 'video'){
			return schema.required('Please indicate a duration.')
        }
        else {
			return schema.nullable().notRequired().default(null)
		}
	}),
})

export default productSchema
