const linkStyles = {
	d: 'flex',
	alignItems: 'center',
	svg: {
		color: '#B5BCC3',
	},
	_hover: {
		cursor: 'pointer',
		color: '#0187FF',
		svg: {
			color: '#0187FF',
		},
	},
}

const activeLinkStyles = {
	color: '#0187FF',
	svg: {
		color: '#0187FF',
	},
}

const Filter = {
	parts: ['container', 'label', 'list', 'element', 'activeElement'],
	baseStyle: () => ({
		container: {
			h: '2rem',
			spacing: '0.5rem',
			alignItems: 'center',
			px: '0.5rem',
			bg: '#F2F6FA',
			borderRadius: 'base',
		},
		label: {
			color: '#b5bcc3',
			fontSize: '0.75rem',
			textTransform: 'uppercase',
		},
		list: {
			alignItems: 'center',
		},
		element: { ...linkStyles },
		activeElement: { ...linkStyles, ...activeLinkStyles },
	}),
	sizes: {},
	variants: {},
	defaultProps: {},
}

export default Filter
