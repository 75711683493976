import axios from 'axios'
import config from '../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

/** Export default instance used by apps/modules */
export default instance
