import React, {useEffect, useState} from 'react' 
import {StyledSecondaryNavigation} from './styles'
import {useSelector} from 'react-redux'
import { createPortal } from 'react-dom';
import usePortal from '../../hooks/usePortal';
import useSSR from 'use-ssr'

/**
 * Hook that intends to retrieve the secondary Navigation 
 * Panel information from the reducer
 */
export const useSecondaryNavigation = () => {
    const isOpen = useSelector(s => s.interface.panels.secondary)
    return {isOpen}
}

/**
 * Secondary Navigation Wrapper. 
 * This component is already set as a Portal.
 * @param {*} children 
 * @returns 
 */
const SecondaryNavigation = ({children, ...rest}) => {
    /** Check if we're using browser or server-side rendering */
    const { isServer, isBrowser } = useSSR()
    
    /** Target container to which we want to append to Portal */
    const target = usePortal("app-layout");
    
    /** Retrieve current reducer state for this panel */
    const {isOpen} = useSecondaryNavigation()

    /** Finally, let's build our portal */
    return isBrowser ? createPortal(
        (
            <StyledSecondaryNavigation 
                {...rest}  
                id="SecondaryNavigation" 
                isOpen={isOpen}>
                    {children}
            </StyledSecondaryNavigation>
        ),
        target,
    ) : null;

   
}

export default SecondaryNavigation;