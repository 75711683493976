const USERS = {
	/** Fetch all users (includes filters) */
	FETCH_REQUESTED: 'USERS_FETCH_REQUESTED',
	FETCH_SUCCEEDED: 'USERS_FETCH_SUCCEEDED',
	FETCH_FAILED: 'USERS_FETCH_FAILED',

	/** Post a user */
	POST_REQUESTED: 'USERS_POST_REQUESTED',
	POST_SUCCEEDED: 'USERS_POST_SUCCEEDED',
	POST_FAILED: 'USERS_POST_FAILED',

	/** Post a user as admin */
	POST_AS_ADMIN_REQUESTED: 'USER_POST_AS_ADMIN_REQUESTED',
	POST_AS_ADMIN_SUCCEEDED: 'USER_POST_AS_ADMIN_SUCCEEDED',
	POST_AS_ADMIN_FAILED: 'USER_POST_AS_ADMIN_FAILED',

	/** Update a user */
	UPDATE_REQUESTED: 'USER_UPDATE_REQUESTED',
	UPDATE_SUCCEEDED: 'USER_UPDATE_SUCCEEDED',
	UPDATE_FAILED: 'USER_UPDATE_FAILED',

	/** Update a user role */
	UPDATE_ROLE_REQUESTED: 'UPDATE_ROLE_REQUESTED',
	UPDATE_ROLE_SUCCEEDED: 'UPDATE_ROLE_SUCCEEDED',
	UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',

	/** Delete a user */
	DELETE_REQUESTED: 'USER_DELETE_REQUESTED',
	DELETE_SUCCEEDED: 'USER_DELETE_SUCCEEDED',
	DELETE_FAILED: 'USER_DELETE_FAILED',

	RESEND_VERIFICATION_EMAIL_REQUESTED: 'USER_RESEND_VERIFICATION_EMAIL_REQUESTED',
	RESEND_VERIFICATION_EMAIL_SUCCEEDED: 'USER_RESEND_VERIFICATION_EMAIL_SUCCEEDED',
	RESEND_VERIFICATION_EMAIL_FAILED: 'USER_RESEND_VERIFICATION_EMAIL_FAILED',
}

export { USERS }
