export default function generateUID(max) {
	// I generate the UID from two parts here
	// to ensure the random number provide enough bits.

	// TODO: Check against stop words like "fuck,shit,sex etc."

	function randomizer(num, replaceRegEx) {
		var firstPart = (Math.random() * 46656) | 0
		var secondPart = (Math.random() * 46656) | 0
		var thirdPart = (Math.random() * 46656) | 0
		var fourthPart = (Math.random() * 46656) | 0

		firstPart = firstPart.toString(36)
		secondPart = secondPart.toString(36)
		thirdPart = thirdPart.toString(36)
		fourthPart = fourthPart.toString(36)

		let uuid =
			firstPart.slice(-(4 / num)).replace(replaceRegEx, '') +
			secondPart.slice(-(4 / num)).replace(replaceRegEx, '') +
			thirdPart.slice(-(4 / num)).replace(replaceRegEx, '') +
			fourthPart.slice(-(4 / num)).replace(replaceRegEx, '')

		return uuid.slice(-num)
	}

	var replaceRegEx = /[i,I,l,L,o,O,]/gi // Remove these chars
	let uid = randomizer(max, replaceRegEx)

	return uid
}
