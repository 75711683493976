import {createSlice} from '@reduxjs/toolkit'

import { PRODUCTS } from './constants'

const initialState = {
    data:{},
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}

const productsSlice = createSlice({
    initialState: initialState,
    name:'products',
    extraReducers: {
        [PRODUCTS.FETCH_REQUESTED]: (state, action) => {
            state.isFetching = true
        },
        [PRODUCTS.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.products){
                state.data = action.payload.entities.products
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        
        [PRODUCTS.FETCH_FAILED]: (state, action) => {
            state.hasError = true
            state.isFetching = false
            state.errorMessage = 'An error occured while fetching.'
        },

        [PRODUCTS.UPDATE_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.products){
                var item = state.data[action.payload.result[0]]
                // If we found an item, update it
                if(item){
                    state.data[action.payload.result[0]] = action.payload.entities.products[action.payload.result[0]]
                }
            }
        },

        [PRODUCTS.POST_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.products){
                state.data[action.payload.result[0]] = action.payload.entities.products[action.payload.result[0]]
            }
        },
        [PRODUCTS.DELETE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                delete state.data[action.payload.data.id]
            }
        },
    },
    reducers:{},
})


export default productsSlice.reducer