import React, {useState, useEffect} from 'react'  
import socket from '../../../../../utils/socket'
import moment from 'moment'
import { totalVideoLength } from '@bit/vidday.vidday-helpers.utils.total-video-length'

import { Progress } from '@bit/vidday.vidday-ui.ui.progress'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../core/widgets/others/SubRowHeader'
import Button from '../../../../../core/widgets/buttons/Button'
import ButtonIcon from '../../../../../core/widgets/buttons/ButtonIcon'
import { media } from '../../../../../../config.public'


const CustomAdmin = (props) => {

    const data = props.row.original;

    const [showBeta] = useState(true)
    const [mediaPackageProgress, setMediaPackageProgress] = useState(null) 
    const [progressPercentage, setProgressPercentage] = useState(null) 
    const [mediaPackageLink, setMediaPackageLink] = useState(null) 
    const [mediaErrors, setMediaErrors] = useState([]) 
    const [disablePublishBtn, setDisablePublishBtn ] = useState(false);
    const [message, setMessage] = useState(null)
   
    /** Custom logic */
    useEffect(() => {
        if (data.package) {
            setMediaPackageLink(data.package)
            setDisablePublishBtn(false)
		}

        // connect to room for this event uuid
        socket.emit('joinroom', data.uuid)
        // Handle disconnects and reconnecting to required room
        socket.on('disconnect', () => {
            console.log(`Lost socket.io connection. Leaving room ${data.uuid}.`)
        })
        socket.on('reconnect', () => {
            console.log(`Reconnecting to socket.io server...`)
        })
        socket.on('connect', () => {
            console.log('Connection established; rejoining room : ', `${data.uuid}`)
            socket.emit('joinroom', data.uuid)
        })

        socket.on('publishDownloadRequest', (socketData) => {
            if (message != socketData.message && socketData.eventUuid == data.uuid) {
                setMessage(socketData.message)
            }
        })
        socket.on('publishDownloadProgress', (socketData) => {
            if (progressPercentage != socketData.percentage && socketData.eventUuid == data.uuid) {
                setProgressPercentage(socketData.percentage)
                setMessage(socketData.message)
            }
        })
        socket.on('mediaPackageProgress', (socketData) => {
            //console.log("Package Build Media:",socketData)
            if (socketData.eventUuid == data.uuid) {
        
                setProgressPercentage(socketData.percentage)
                setMessage(socketData.message)

                if (socketData.link) {
                    setMediaPackageLink(socketData.link)
                }
                if (socketData.media) {
                    setMediaErrors([...mediaErrors, socketData.media])
                    
                }
            }
        })
        socket.on('uploadFileToS3', (socketData) => {
            if (progressPercentage != socketData.percentage && socketData.eventUuid == data.uuid) {
                setProgressPercentage(socketData.percentage)
                setMessage(socketData.message)
            }
        })
        socket.on('publishCreatingScreenshot', (socketData) => {
            if (socketData.eventUuid == data.uuid) {
                setMessage(socketData.message)
            }
        })
        socket.on('publishUploadingScreenshot', (socketData) => {
            if (socketData.eventUuid == data.uuid) {
                setMessage(socketData.message)
            }
        })
        socket.on('publishUploadComplete', (socketData) => {
            if (this.state.message != socketData.message && socketData.eventUuid == data.uuid) {
                setMessage(socketData.message)
                setProgressPercentage(null)
                setDisablePublishBtn(false)
            }
        })

        return () => {
            // leave room for this job uid
            if (data.uuid !== undefined) {
                socket.emit('leaveroom', data.uuid)
            }
        }
    }, [])

    
    const publishedDate = data.publishedDate ? moment(data.publishedDate).format('llll') : '-'

    const publishedMediaLink =
        data.publishedMedia && data.publishedMedia.location ? data.publishedMedia.location : null
   

    var videoLength = data.length || totalVideoLength(data.media, true)

    const mediaErrorTemplate = [{
        "backgroundEligible": true,
        "claimToken": null,
        "contributorId": "ff0fc390-3d71-44be-8a88-bee53e1072ae",
        "convertPublishingMediaJobId": "1614371264697-42o70y",
        "convertPublishingMediaJobStatus": "COMPLETE",
        "convertPublishingMediaJobStatusMessage": null,
        "convertPublishingMediaJobTimestamp": "2021-02-26T20:28:26.434Z",
        "convertSourceMediaJobId": "1614052994877-e0mepi",
        "convertSourceMediaJobStatus": "COMPLETE",
        "convertSourceMediaJobStatusMessage": null,
        "convertSourceMediaJobTimestamp": "2021-02-23T04:03:26.161Z",
        "createdAt": "2021-02-23T04:03:09.133Z",
        "duration": 9.809,
        "endTime": null,
        "eventId": "6d2e3209-8dcc-400b-ab0c-34c31369e099",
        "hasUsableAudio": true,
        "hasUsableVideo": false,
        "id": "6fbb2788-d53d-4a05-aecd-baee5de53e7d",
        "isCoverPhoto": false,
        "key": "g-385cnf/874cccd22054eab69e8af7efca5ea0dc_1614052961142",
        "location": "https://s3.us-west-2.amazonaws.com/vidday.v2.media.dev/g-385cnf/874cccd22054eab69e8af7efca5ea0dc_1614052961142_transcoded_360.mp4",
        "mediaUuid": "874cccd22054eab69e8af7efca5ea0dc",
        "medium": "Upload",
        "mimeType": "video/mp4",
        "muteAudio": false,
        "muteBackgroundMusic": false,
        "name": "874cccd22054eab69e8af7efca5ea0dc_1614052961142",
        "occupied": false,
        "resolution": null,
        "rotation": null,
        "sortOrder": -1,
        "sourceExt": ".mp4",
        "sourceHasAudio": true,
        "sourceHasVideo": true,
        "sourceHeight": 270,
        "sourceRotation": null,
        "sourceWidth": 480,
        "spotlight": false,
        "startTime": null,
        "text": null,
        "thumbnail": "https://s3.us-west-2.amazonaws.com/vidday.v2.media.dev/g-385cnf/874cccd22054eab69e8af7efca5ea0dc_1614052961142_thumbnail.0000004.jpg",
        "updatedAt": "2021-02-23T04:03:09.133Z",
      }]

    var listOfErrorFiles = mediaErrorTemplate.map((media) => { //mediaErrors
        const mediaErrorList = [
            {
                name:'type',
                content:media.mimeType,
            },
            {
                name:'UUID',
                content:media.mediaUuid,
            },
            {
                name:'File',
                content:media.key,
            },
            {
                name:'Sort Order',
                content:media.sortOrder,
            },
            {
                name:'Preview',
                content:<img width="100px" height="100px" src={media.thumbnail} />,
            },
        ]

        return (<InlineItems error list={mediaErrorList} />)
          
    })

    

    const list = [
        {
            name:'Published date',
            content:publishedDate,
        },
        {
            name:'Final Vidday Link',
            content: publishedMediaLink ? (
                <a href={publishedMediaLink} title="DOWNLOAD VidDay">
                    {publishedMediaLink}
                </a>) : '-',
        },

        {
            name:'Package Download Link',
            content: mediaPackageLink ? (
                <a href={mediaPackageLink} title="DOWNLOAD PACKAGE">
                {mediaPackageLink}
            </a>) : '-',
        },
    ]

    const listStatus = [
        {
            name:'Message',
            content:message || '-',
        },
        {
            name:'Progress',
            content: (progressPercentage != null  ? <Progress value={progressPercentage} size={50} /> : '-'),
        },
    ]

    return (
        <>
            <SubRowHeader>
                <p>Media {' '}
                    {data.paid && <span className="badge green">✓ paid</span>}
                    <span className="badge">time: {videoLength}</span>
                </p>
            </SubRowHeader>

            <InlineItems title="Publication" list={list} />

            <InlineItems title="Status" list={listStatus} />

            <SubRowHeader>
                <p>Media Errors <span className="badge error">{mediaErrors.length} errors</span></p>
            </SubRowHeader>

            {listOfErrorFiles && <div>{listOfErrorFiles}</div>}
        </>
    )
}


export default CustomAdmin;