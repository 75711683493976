import sc from 'states-cities-db'

export const COUNTRIES = sc.getCountries()

// Upgraded, using full collection of countries passed to ot
export const getCountryTelCode = (country, countries) =>
	country && countries.find(({ value }) => value === country).prefix

// Original, using external library
// export const getCountryTelCode = (country) => country && COUNTRIES.find(({ iso2 }) => iso2 === country).prefix
