import { mode } from '@chakra-ui/theme-tools'

const Input = {
	// baseStyle: (props) => {

	//     return {
	//         color: mode('inherit', 'gray.200')(props),
	//     }
	// },
	variants: {
		outline: (props) => {
			return {
				field: {
					bg: mode('rgba(232,236,240,0.5)', 'rgba(232,236,240,0.5)')(props),
					color: mode('gray.800', 'gray.800')(props),
					borderColor: mode('gray.200', 'gray.200')(props),
					_hover: {
						borderColor: mode('gray.300', 'gray.300')(props),
					},
					_placeholder: {
						color: mode('gray.400', 'gray.400')(props),
					},
				},
				addon: {},
			}
		},
	},
}

export default Input
