import { call, put, takeEvery } from 'redux-saga/effects'
import requests from './requests'
import { MAIL, TOOL } from './constants'
import { UI } from '../../../../api/ui/constants'

/** Saga to handle verify email address */
function* handleVerifySingleEmail(action) {
	try {
		const response = yield call(requests.verifySingleEmail, action.payload)
		yield put({ type: MAIL.POST_VERIFY_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: MAIL.POST_VERIFY_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.response?.data?.message || e.message })
	}
}
export function* verifySingleEmail() {
	yield takeEvery(MAIL.POST_VERIFY_REQUESTED, handleVerifySingleEmail)
}

/** Update the creator account linked to an event */
function* handleChangeEventCreator(action) {
	try {
		const response = yield call(requests.changeEventCreator, action.payload)
		console.log(response.data, 'response.data!')
		yield put({ type: TOOL.CHANGE_EVENT_CREATOR_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.CHANGE_EVENT_CREATOR_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.response?.data?.message || e.message })
	}
}
export function* changeEventCreator() {
	yield takeEvery(TOOL.CHANGE_EVENT_CREATOR_REQUESTED, handleChangeEventCreator)
}

/** Merge Duplicate Account/User */
function* handleMergeDuplicateAccount(action) {
	try {
		const response = yield call(requests.mergeDuplicateAccount, action.payload)
		console.log(response.data, 'response.data!')
		yield put({ type: TOOL.MERGE_DUPLICATE_ACCOUNT_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.MERGE_DUPLICATE_ACCOUNT_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.response?.data?.message || e.message })
	}
}
export function* mergeDuplicateAccount() {
	yield takeEvery(TOOL.MERGE_DUPLICATE_ACCOUNT_REQUESTED, handleMergeDuplicateAccount)
}

/** Merge Events  */
function* handleMergeEvents(action) {
	try {
		const response = yield call(requests.mergeEvents, action.payload)

		yield put({ type: TOOL.MERGE_EVENTS_SUCCEEDED, payload: { data: response.data.data } })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })

		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.MERGE_EVENTS_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.response?.data?.message || e.message })
	}
}
export function* mergeEvents() {
	yield takeEvery(TOOL.MERGE_EVENTS_REQUESTED, handleMergeEvents)
}

/** Unsubscribe User  */
function* handleUnsubscribeUser(action) {
	try {
		const response = yield call(requests.unsubscribeUser, action.payload)

		yield put({ type: TOOL.UNSUBSCRIBE_USER_SUCCEEDED, payload: { data: response.data.data } })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })

		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.UNSUBSCRIBE_USER_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.response?.data?.message || e.message })
	}
}
export function* unsubscribeUser() {
	yield takeEvery(TOOL.UNSUBSCRIBE_USER_REQUESTED, handleUnsubscribeUser)
}

/** Transfer Event  */
function* handleTransferEvent(action) {
	try {
		const response = yield call(requests.transferEvent, action.payload)

		yield put({ type: TOOL.TRANSFER_EVENT_SUCCEEDED, payload: { data: response.data.data } })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })

		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.TRANSFER_EVENT_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'error', message: e.response?.data?.message || e.message })
	}
}
export function* transferEvent() {
	yield takeEvery(TOOL.TRANSFER_EVENT_REQUESTED, handleTransferEvent)
}
