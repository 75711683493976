/** Import our instance config */

import axios from 'axios'
import { pick } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = []
const acceptedKeysPUT = []

/**
 * Fetch our invoices
 */
const fetchInvoices = async (params) => {
	const response = await instance.get('/invoices?' + params)
	return response
}

/**
 * Post an invoice
 * @param {*} data
 * @returns
 */
const postInvoice = async (data) => {
	const response = await instance.post('/invoices', data)
	return response
}

/**
 * Update an invoice
 * @param {*} data
 * @returns
 */
const updateInvoice = async (data) => {
	const response = await instance.put('/invoices/' + data.id, data)
	return response
}

/**
 * Delete an invoice
 * @param {*} data
 * @returns
 */
const deleteInvoice = async (id) => {
	const response = await instance.delete('/invoices/' + id)
	return response
}


/**
 * Refund an invoice
 * @param {*} data
 * @returns
 */
 const refundInvoice = async (data) => {
	const response = await instance.post('/invoices/' + data.id +'/refund', data)
	return response
}


/**
 * Refund an item of invoice
 * @param {*} data
 * @returns
 */
 const refundInvoiceItem = async (data) => {
	const response = await instance.post('/invoice-items/' + data.id +'/refund', data)
	return response
}

const requests = { fetchInvoices, postInvoice, updateInvoice, deleteInvoice, refundInvoice, refundInvoiceItem}

export default requests
