import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PromotionForm from '../../../apps/core/promotions/src/PromotionForm'
import { updatePromotion, postPromotion } from '../../../apps/core/promotions/api/actions'
import {
	Box,
	Modal,
	Icon,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'

const ModalPromotionForm = ({ onClose, isOpen }) => {
	const dispatch = useDispatch()

	const onSubmit = (data) => {
		let obj = { ...data }
		console.log(data, 'data received from form')
		if (!data.expires) {
			obj = { ...data, expires: null }
		} else if (moment(data.expires).isValid()) {
			let momentDate = moment(data.expires)
			obj = { ...data, expires: momentDate.toISOString() }
		}

		dispatch(postPromotion(obj))

		onClose()
	}

	const data = {
		code: '',
		isActive: '',
		type: '',
		value: '',
		maxCount: '',
		expires: '',
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="full">
			<ModalOverlay />

			<ModalContent borderRadius="0">
				<ModalCloseButton />
				<ModalBody>
					<Box maxW="600px" textAlign="left" mx="auto">
						<Box w="full" mb="2rem">
							<Heading size="lg" variant="hero">
								Create a promotion
							</Heading>
							<Text>Start a new promotion so vidday users can redeem their code.</Text>
						</Box>
						<Box>
							<PromotionForm data={data} onSubmit={onSubmit} />
						</Box>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalPromotionForm
