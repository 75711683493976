import {createSlice} from '@reduxjs/toolkit'

import { PRODUCT_PRICES } from './constants'

const initialState = {
    data:null,
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}

const productPricesSlice = createSlice({
    initialState: initialState,
    name:'productPrices',
    extraReducers: {
        [PRODUCT_PRICES.FETCH_REQUESTED]: (state, action) => {
            state.isFetching = true
        },
        [PRODUCT_PRICES.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.productPrices){
                state.data = action.payload.entities.productPrices
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        
        [PRODUCT_PRICES.FETCH_FAILED]: (state, action) => {
            state.hasError = true
            state.isFetching = false
            state.errorMessage = 'An error occured while fetching.'
        },

        [PRODUCT_PRICES.UPDATE_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.productPrices){
                var item = state.data[action.payload.result[0]]
                // If we found an item, update it
                if(item){
                    state.data[action.payload.result[0]] = action.payload.entities.productPrices[action.payload.result[0]]
                }
            }
        },

        [PRODUCT_PRICES.POST_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.productPrices){
                state.data[action.payload.result[0]] = action.payload.entities.productPrices[action.payload.result[0]]
            }
        },

        [PRODUCT_PRICES.DELETE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                delete state.data[action.payload.data.id]
            }
        },
    },
    reducers:{},
})


export default productPricesSlice.reducer