import * as Yup from 'yup'

export const typeOptions = [
	{ value: 'percent', label: 'Percentage (%)' },
	{ value: 'amount', label: 'Amount ($) in cents' },
	{ value: 'gratuit', label: 'Gratuit/Free' },
]

export const isEnabledOptions = [
	{ value: true, label: 'Active' },
	{ value: false, label: 'Deactivate' },
]

const schemaPromotion = Yup.object().shape({
	code: Yup.string().required(),
	isActive: Yup.boolean().required(),
	type: Yup.string().required().oneOf(['percent', 'amount', 'gratuit']),
	// value: Yup.number().positive().integer().required(),
	value: Yup.number().when('type', {
		is: (val) => val != 'gratuit',
		then: (schema) => schema.positive().integer().required(),
		otherwise: (schema) =>
			schema
				.notRequired()
				.nullable(true)
				.default(0)
				.transform((v, o) => 0),

		// .nullable(true)
		// .default(null)
		// .transform((v, o) => (o === '' ? null : v)),
	}),
	maxCount: Yup.number().positive().notRequired(),
	// timezone: Yup.string().required(),
	expires: Yup.string()
		.notRequired()
		.nullable()
		.default(null)
		.transform((v) => (v == '' ? null : v)),
})

export default schemaPromotion
