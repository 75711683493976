import { createBrowserHistory, createMemoryHistory } from 'history'

let history = null

// If in-broswer, initialize with browser history; if ssr, initalize memory history
if (typeof window === 'undefined') {
	history = createMemoryHistory()
	// console.info('HISTORY : using memory history (ssr)')
} else {
	history = createBrowserHistory()
	// console.info('HISTORY : using browser history')
}

export { history }
