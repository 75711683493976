import styled from 'styled-components'

export const StyledNavigation = styled.div`
	z-index: 1390;
	display: block;
	width: 300px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: #0a2239;
`
