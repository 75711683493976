import React from 'react'
import { StyledModuleNav, StyledModuleItem, StyledModuleItemChild, StyledSection } from './styles'
import { Link, useLocation } from 'react-router-dom'

const ModuleNav = ({ section, list }) => {
	// const match = useRouteMatch()
	const location = useLocation()

	// console.log(section, list, 'section+list');
	const listView = list.map((el, i) => {
		let childrenList
		/** If we have nested items */
		if (el.children) {
			childrenList = el.children.map((child, j) => {
				var isChildActive = child.target && child.target == location.pathname
				return (
					<li key={'x-' + i}>
						<StyledModuleItemChild to={child.target || ''} isActive={isChildActive}>
							{child.title}
						</StyledModuleItemChild>
					</li>
				)
			})
		}
		var isActive = el.target && el.target == location.pathname
		return (
			<li key={i}>
				<StyledModuleItem to={el.target || ''} isActive={isActive}>
					{el.Icon}
					{el.title}
				</StyledModuleItem>
				{el.children && el.children.length > 0 && <ul className="sub">{childrenList}</ul>}
			</li>
		)
	})

	return (
		<>
			{section && <StyledSection>{section}</StyledSection>}
			<StyledModuleNav>{listView}</StyledModuleNav>
		</>
	)
}

export default ModuleNav
