import styled, {css} from 'styled-components'

export const StyledRightPanel = styled.div`
    background-color:white;
    z-index:300;
    display:block;
    width:300px;
    position: fixed;
    top:0;
    bottom:0;
    right:-300px;
    border-left:1px solid #B5BCC3;

    // opacity: 0;
    // visibility: hidden;

    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    ${(props) => props.isOpen && css`
        opacity: 1;
        visibility: visible;
        right:0;
    `}

`