import React from 'react' 
import Actions from './tabs/Actions'
import Edit from './tabs/Edit'
import Details from './tabs/Details'

/**
 * Define our sub-row view tabs and corresponding sub-views
 */
const tabs = [
    {
        id:'actions',
        title:'Actions',
        view: Actions,
    },
    {
        id:'details',
        title:'Details',
        view: Details,
    },
    {
        id:'edit',
        title:'Edit',
        view: Edit,
    },
]

export default tabs;