import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from '../../../../../core/widgets/form/select'
import SaveBar from '../../../../../core/widgets/others/SaveBar'
import { useDispatch } from 'react-redux'

import SubmitButton from '@bit/vidday.vidday-ui.ui.submit-button'
import styled, { css } from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../core/widgets/others/InlineItems'

import { updateEvent } from '@bit/vidday.vidday-admin-store.modules.events'
import { addFlashMessage } from '@bit/vidday.vidday-store.behaviors.flash-messages'

import { eventStatusTypes } from '@bit/vidday.vidday-data.data.event-status-types'

/** Define initial Values */
const initialValues = { notes: '' }

const Status = ({ row }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: initialValues,
		//resolver: yupResolver(schemaPromoCode),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (data) => {
		console.log(data)

		let postData = {
			eventId: row.original.id, // data.key || data.id,
			uuid: row.original.uuid,
			status: data['status'], //event.currentTarget.parentNode.getElementsByTagName('textarea')[0].value.toString(), // TODO : Escape this for safety
		}
		// if changing back to re-open event set to false payment
		if (data['status'] == 'active' && row.original.paid == true) {
			postData.paid = false
			postData.contributorsNotified = false
		}
		if (data['status'] == 'published' && !row.original.publishedMedia) {
			dispatch(addFlashMessage('info', 'Event does not contain any published media'))
		} else {
			dispatch(updateEvent(postData))
		}
	}
	const options = eventStatusTypes

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Status"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					margin="none"
					name="status"
					id="status"
					variant="filled"
					label="Status"
					size="small"
					errors={errors}
					options={options}>
					{options.map((el, i) => (
						<MenuItem key={i} value={el.type}>
							{el.label}
						</MenuItem>
					))}
				</Select>
			</InlineItems>
		</form>
	)
}

Status.defaultProps = {
	/** Default text for submit button */
	submitText: 'Apply Code',
}

Status.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
	/** Promotion */
	promotion: PropTypes.object.isRequired,
}

export default Status
