import React, { useEffect } from 'react'
import { Link, Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { filter } from 'lodash'
import UnderConstruction from '../../views/utils/UnderConstruction'
import Core from '../../core'
import { instance as events, Router as EventsRoutes } from './events'
import { instance as media, Router as MediaRoutes } from './media'
import { instance as themes, Router as ThemesRoutes } from './themes'
import { instance as themeVersions, Router as ThemeVersionsRoutes } from './themeVersions'
import { instance as themeCategories, Router as ThemeCategoriesRoutes } from './themeCategories'
import { instance as renderJobs, Router as RenderJobsRoutes } from './eventRenderJobs'

/** Load icons */
import MovieFilterRoundedIcon from '@mui/icons-material/MovieFilterRounded'
import PermMediaRoundedIcon from '@mui/icons-material/PermMediaRounded'
import CakeRoundedIcon from '@mui/icons-material/CakeRounded'
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded'
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded'
import useApps from '../../core/hooks/useApps'
// import { setCurrentApp } from '../../core/reducers/apps'
// console.log(ThemesRoutes, ThemeVersionsRoutes, "THEMES ROUTES")
/**
 * Define module
 */
export const vidday = Core.defineApp({
	name: 'vidday',
	title: 'Vidday App',
	path: '/vidday',
})

export const instance = Core.combineApps(vidday, [events, media, themes, themeVersions, themeCategories, renderJobs])

/**
 * Define our navigation items
 */
export const navigationItems = [
	{ title: 'Events', Icon: <MovieFilterRoundedIcon />, target: `${instance.path}/events` },
	// { title: 'Media', Icon: <PermMediaRoundedIcon />, target: `${instance.path}/media` },
	// {
	// 	title: 'Themes',
	// 	Icon: <CakeRoundedIcon />,
	// 	target: `${instance.path}/themes`,
	// 	children: [
	// 		{ title: 'Themes', Icon: null, target: `${instance.path}/themes` },
	// 		{ title: 'Versions', Icon: null, target: `${instance.path}/themeversions` },
	// 		{ title: 'Categories', Icon: null, target: `${instance.path}/themecategories` },
	// 	],
	// },
	// { title: 'Reports', Icon: <AssessmentRoundedIcon />, target: `${instance.path}/reports` },
	// { title: 'Render Jobs', Icon: <TrackChangesRoundedIcon />, target: `${instance.path}/renderjobs` },
]

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { registerApp, setCurrentApp } = useApps()
	const { path } = instance
	/** register our application */
	useEffect(() => {
		registerApp(instance)
		setCurrentApp(instance.name)
	}, [])
	const location = useLocation()

	console.log(instance, setCurrentApp, 'hereherehere')
	console.log('location vidday app', location)

	return (
		<Routes>
			<Route index element={<Navigate to="events" />} />
			<Route path="events/*" element={<EventsRoutes />} />

			{/* <RenderJobsRoutes />
			<ThemeCategoriesRoutes />
			<ThemeVersionsRoutes />
			<ThemesRoutes />
			<EventsRoutes />
			<MediaRoutes /> */}
			{/* <Switch>
                <Route path={path} component={UnderConstruction} />
            </Switch> */}
		</Routes>
	)
}
