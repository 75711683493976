/** Import our instance config */
import instance from '../../../../../api/config'


/** 
 * Fetch our nexus
 */
const fetchNexus = async (params) => {
    const response = await instance.get('/geo-nexus?'+params);
    return response;
}

/**
 * Post a nexus
 * @param {*} data 
 * @returns 
 */
const postNexus = async (data) => {
    const response = await instance.post('/geo-nexus', data);
    return response;
}


/**
 * Update a nexus
 * @param {*} data 
 * @returns 
 */
 const updateNexus = async (data) => {
    const response = await instance.put('/geo-nexus/'+data.id, data);
    return response;
}

/**
 * Delete a nexus
 * @param {*} id 
 * @returns 
 */
 const deleteNexus = async (id) => {
    const response = await instance.delete('/geo-nexus/'+id);
    return response;
}


const requests = {fetchNexus, postNexus, updateNexus, deleteNexus}

export default requests