import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../core'
import UnderConstruction from '../../../views/utils/UnderConstruction'
import ListView from './List'
/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.tools',
	title: 'Tools',
	path: '/core/tools',
	parent: 'core',
})

// export const promotionsSlice = buildEngineSlice({
// 	sliceName: 'promotions',
// 	actionPrefix: 'PROMOTIONS',
// 	initialState: {
// 		sortBy: 'code',
// 		pageSize: 20,
// 	},
// })

/**
 * Routes of the Tools Module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			{/* <Route path="search/:params" element={<UnderConstruction />} />
			<Route path=":id/delete" element={<UnderConstruction />} />
			<Route path=":id/edit" element={<UnderConstruction />} />
			<Route path=":id" element={<UnderConstruction />} /> */}
			<Route index element={<ListView />} />
			<Route path="*" element={<ListView />} />
		</Routes>
	)
}
