import React from 'react'
import { FormErrorMessage } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Input,
	InputRightElement,
} from '@chakra-ui/react'

const ControlledInput = (props) => {
	const {
		rightElement,
		control,
		name,
		label,
		placeholder,
		helperText,
		errors,
		defaultValue,
		onBlur,
		index,
		readOnly,
		...rest
	} = props

	/**
	 * If we have provided an index, then it means we're using a FieldArray
	 */
	let isError = false
	let errorMessage = ''
	if (index !== undefined) {
		/** Obtain field name and key name, wrapping the index */
		let chain = name.split(`[${index}].`)
		// The result array should have 2 segments
		if (chain.length == 2 && chain[0] !== undefined && chain[1] !== undefined) {
			let arrayFieldKey = chain[0],
				fieldKey = chain[1]
			if (
				errors.hasOwnProperty(arrayFieldKey) &&
				errors[arrayFieldKey][index] &&
				errors[arrayFieldKey][index].hasOwnProperty(fieldKey)
			) {
				isError = true
				errorMessage = errors[arrayFieldKey][index][fieldKey].message
			}
		}
	}
	// Otherwise, proceed as a normal (non-nested) input
	else if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}

	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				const handleBlur = (e) => {
					onBlur && onBlur(e.currentTarget.value)
					props.onBlur(e)
				}

				return (
					<FormControl isInvalid={isError} {...rest}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}

						<InputGroup>
							{/* <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" /> */}
							<Input
								disabled={readOnly}
								id={name}
								placeholder={placeholder}
								{...props}
								onBlur={handleBlur}
							/>
							{rightElement && <InputRightElement children={rightElement} />}
						</InputGroup>

						{isError ? (
							<FormErrorMessage d="block" textAlign="left">
								{errorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledInput
