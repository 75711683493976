import React, { useState, useEffect } from 'react'
import { Container, BottomPanel, RightPanel } from '../../../../interface'
import {
	Divider,
	Flex,
	Box,
	Heading,
	Text,
	HStack,
	Icon,
	Button,
	useDisclosure,
	SimpleGrid,
	Link,
	VStack,
} from '@chakra-ui/react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { Routes, Route, useNavigate } from 'react-router-dom'

import queues from '../data'

const toolStyles = {
	p: '1rem',
	borderRadius: 'md',
	_hover: {
		backgroundColor: 'gray.50',
	},
}

const Queue = ({ icon, title, children, target }) => {
	const navigate = useNavigate()

	return (
		<Box sx={toolStyles}>
			<Heading as="h3" size="md" mb="0.5rem">
				{title}
			</Heading>
			<VStack alignItems="flex-start" spacing="0.25rem">
				{children.map((el, i) => (
					<Link href={el.target} target="_blank" _hover={{ color: 'link' }}>
						{el.title}
					</Link>
				))}
			</VStack>
		</Box>
	)
}

const ListView = (props) => {
	return (
		<Container>
			<Flex w="full" py="1rem" justifyContent="space-between">
				<Heading variant="hero" size="lg" as="h1">
					Queues
				</Heading>
			</Flex>

			<Box pb="1rem">
				<SimpleGrid columns={[1, 1, 2, 2, 4]} spacing="2rem">
					{queues.map((el, i) => (
						<Queue {...el} key={i} />
					))}
				</SimpleGrid>
			</Box>

			{/* <Routes>
				<Route
					index
					element={
						
					}
				/>
				<Route path="event-creator" element={<ChangeEventCreator />} />
				<Route path="merge-accounts" element={<MergeAccounts />} />
				<Route path="merge-events" element={<MergeEvents />} />
				<Route path="unsubscribe-user" element={<UnsubscribeUser />} />
			</Routes> */}
		</Container>
	)
}

export default ListView
