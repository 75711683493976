const MAIL = {
	// POST_INVITE_REQUESTED: 'POST_RSVP_INVITE_REQUESTED',
	// POST_INVITE_SUCCEEDED: 'POST_RSVP_INVITE_SUCCEEDED',
	// POST_INVITE_FAILED: 'POST_RSVP_INVITE_FAILED',

	// POST_REMINDER_REQUESTED: 'POST_RSVP_REMINDER_REQUESTED',
	// POST_REMINDER_SUCCEEDED: 'POST_RSVP_REMINDER_SUCCEEDED',
	// POST_REMINDER_FAILED: 'POST_RSVP_REMINDER_FAILED',

	// POST_PUBLISHED_REQUESTED: 'POST_PUBLISHED_REQUESTED',
	// POST_PUBLISHED_SUCCEEDED: 'POST_PUBLISHED_SUCCEEDED',
	// POST_PUBLISHED_FAILED: 'POST_PUBLISHED_FAILED',

	POST_VERIFY_REQUESTED: 'POST_VERIFY_REQUESTED',
	POST_VERIFY_SUCCEEDED: 'POST_VERIFY_SUCCEEDED',
	POST_VERIFY_FAILED: 'POST_VERIFY_FAILED',
}

const TOOL = {
	CHANGE_EVENT_CREATOR_REQUESTED: 'CHANGE_EVENT_CREATOR_REQUESTED',
	CHANGE_EVENT_CREATOR_SUCCEEDED: 'CHANGE_EVENT_CREATOR_SUCCEEDED',
	CHANGE_EVENT_CREATOR_FAILED: 'CHANGE_EVENT_CREATOR_FAILED',

	MERGE_DUPLICATE_ACCOUNT_REQUESTED: 'MERGE_DUPLICATE_ACCOUNT_REQUESTED',
	MERGE_DUPLICATE_ACCOUNT_SUCCEEDED: 'MERGE_DUPLICATE_ACCOUNT_SUCCEEDED',
	MERGE_DUPLICATE_ACCOUNT_FAILED: 'MERGE_DUPLICATE_ACCOUNT_FAILED',

	MERGE_EVENTS_REQUESTED: 'MERGE_EVENTS_REQUESTED',
	MERGE_EVENTS_SUCCEEDED: 'MERGE_EVENTS_SUCCEEDED',
	MERGE_EVENTS_FAILED: 'MERGE_EVENTS_FAILED',

	UNSUBSCRIBE_USER_REQUESTED: 'UNSUBSCRIBE_USER_REQUESTED',
	UNSUBSCRIBE_USER_SUCCEEDED: 'UNSUBSCRIBE_USER_SUCCEEDED',
	UNSUBSCRIBE_USER_FAILED: 'UNSUBSCRIBE_USER_FAILED',

	TRANSFER_EVENT_REQUESTED: 'TRANSFER_EVENT_REQUESTED',
	TRANSFER_EVENT_SUCCEEDED: 'TRANSFER_EVENT_SUCCEEDED',
	TRANSFER_EVENT_FAILED: 'TRANSFER_EVENT_FAILED',
}

export { MAIL, TOOL }
