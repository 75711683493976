import { THEME_VERSIONS } from './constants'


/** THEME VERSIONS */
const fetchThemeVersions = (params) => ({
    type: THEME_VERSIONS.FETCH_REQUESTED,
    payload:params,
})

const setThemeVersions = (themes) => ({
    type: THEME_VERSIONS.FETCH_SUCCEEDED,
    payload:themes,
})

const postThemeVersion = (data) => ({
    type: THEME_VERSIONS.POST_REQUESTED,
    payload:data,
})

const updateThemeVersion = (data) => ({
    type: THEME_VERSIONS.UPDATE_REQUESTED,
    payload:data,
})

const deleteThemeVersion = (id) => ({
    type: THEME_VERSIONS.DELETE_REQUESTED,
    payload:id,
})

export {
    fetchThemeVersions,
    setThemeVersions,
    postThemeVersion,
    updateThemeVersion,
    deleteThemeVersion,
}





