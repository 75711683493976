import React, { useEffect, useState } from 'react'
import { updateEvent, addRenderJob, deleteRenderJob } from '@bit/vidday.vidday-admin-store.modules.events'
import { useDispatch } from 'react-redux'
import useRetrieveEventTheme from '../hooks/useRetrieveEventTheme'
import { getMediaUrl } from '../../../../../utils/getMediaUrl'
import moment from 'moment'
import { postPublishedMedia } from '@bit/vidday.vidday-admin-store.modules.media'
import socket from '../../../../../utils/socket'
import RenderJobTasks from '@bit/vidday.vidday-admin-ui.interface.render-job-tasks'
import { Progress } from '@bit/vidday.vidday-ui.ui.progress'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../core/widgets/others/SubRowHeader'
import Button from '../../../../../core/widgets/buttons/Button'
import ButtonIcon from '../../../../../core/widgets/buttons/ButtonIcon'

import styled from 'styled-components'

const EmptyData = styled.p`
	display: block;
	padding: 10px 10px;
	border-radius: 5px;
	border: 1px dashed #dadde1;
	font-size: 16px;
	font-style: italic;
	color: #c1c1c1;
	text-align: center;
`

const getTasks = () => {
	let tasks = [
		'created',
		'queued',
		'started',
		'render:setup',
		'render:prerender',
		'render:script',
		'render:dorender',
		'render:postrender',
		'render:cleanup',
		'finished',
	]

	return tasks
}

const IndividualRenderJob = ({ job }) => {
	const dispatch = useDispatch()
	/** Set up our component state */
	const [renderProgress, setRenderProgress] = useState(null)
	const [message, setMessage] = useState(null)
	const [renderJobState, setRenderJobState] = useState(null)
	const [outputKey, setOutputKey] = useState(null)
	const [preview, setPreview] = useState(null)
	const [jobParts, setJobParts] = useState([])
	const [completedTasks, setCompletedTasks] = useState([])

	useEffect(() => {
		/** Set initial state after receiving job */
		let completed_tasks = getCompletedTasks(job.jobState)
		setRenderJobState(job.jobState)
		setRenderProgress(job.renderProgress)
		setPreview(job.preview)
		setOutputKey(job.outputKey)
		setCompletedTasks(completed_tasks)
		setJobParts({})

		// console.log("*********mounted NEW ONE***********", job, self.state)
		console.log('connecting socket to room ', job.jobUid)
		// leave socket room for this job uid
		if (job.jobUid !== undefined) {
			socket.emit('joinroom', job.jobUid)
		}
		// Handle disconnects and reconnecting to required room
		socket.on('disconnect', () => {
			if (job.jobUid !== undefined) {
				socket.emit('joinroom', job.jobUid)
				console.log(`Lost socket.io connection. Leaving room : ${job.jobUid}`)
			}
		})
		socket.on('reconnect', () => {
			console.log(`Reconnecting to socket.io server...`)
		})
		socket.on('connect', () => {
			if (job.jobUid !== undefined) {
				console.log(`Connection established; rejoining room : ${job.jobUid}`)
				socket.emit('joinroom', job.jobUid)
			}
		})

		//
		socket.on('renderJobState', (socketData) => {
			// filter by jobUid
			if (socketData.jobUid == job.jobUid) {
				// Get computed values
				let completed_tasks = getCompletedTasks(socketData.state)
				let job_parts = jobParts

				if (socketData.state == 'error') {
					completed_tasks = ['error']
				}

				if (socketData.jobPart) {
					job_parts[socketData.jobPart] = socketData.progress
				}

				setMessage(socketData.message)
				setRenderJobState(socketData.state)
				setRenderProgress(socketData.progress)
				setPreview(socketData.preview)
				setOutputKey(socketData.outputKey)
				setCompletedTasks(completed_tasks)
				setJobParts(job_parts)
			}
		})

		return () => {
			// leave socket room for this job uid
			if (job.jobUid !== undefined) {
				socket.emit('leaveroom', job.jobUid)
			}
		}
	}, [])

	/** Handler to retrieve completed tasks */
	const getCompletedTasks = (jobState) => {
		let completedTasks = ['created']
		let tasks = getTasks()
		let taskIndex = tasks.indexOf(jobState)
		/** If we did pass a job and the job is defined */
		if (jobState !== null && taskIndex > -1) {
			completedTasks = tasks.slice(0, taskIndex + 1)
		} else {
			console.error('Job :' + jobState + ' is not part of existing tasks list.')
		}
		return completedTasks
	}

	/** Handle to delete a render job */
	const handleDeleteJob = () => {
		dispatch(deleteRenderJob(job.id))
		alert('Deleting JOB ID #' + job.id)
	}

	const handleAutomationPublish = () => {
		let mediaUrl = getMediaUrl(job.outputKey)

		let postData = {
			eventId: job.eventId,
			uuid: job.eventUuid,
			// url: formData.get('url'),
			url: mediaUrl,
		}

		dispatch(postPublishedMedia(postData))
	}

	const handleReview = () => {
		let mediaUrl = getMediaUrl(job.outputKey)
		let win = window.open(mediaUrl, '_blank')
		win.focus()
	}

	let outputLocation = getMediaUrl(outputKey)

	const jobCreatedDate = job.createdAt ? moment(job.createdAt).format('llll') : ''

	const list = [
		{
			name: 'Job UID',
			content: job.jobUid,
		},
		{
			name: 'Created at',
			content: jobCreatedDate,
		},
		{
			name: 'Current job state',
			content: renderJobState ? renderJobState : job.jobState,
		},
	]

	/** Add output location if available */
	outputKey &&
		list.push({
			name: 'Output location',
			content: (
				<a target="_blank" href={outputLocation}>
					{outputLocation}
				</a>
			),
		})

	const listAdvanced = [
		{
			name: 'ami ID',
			content: job.amiId,
		},
		{
			name: 'instance ID',
			content: job.instanceId,
		},
		{
			name: 'instance Type',
			content: job.instanceType,
		},
		{
			name: 'Retries',
			content: job.retries,
		},
		{
			name: 'Archive',
			content: job.archive == true ? 'Yes' : 'No',
		},
	]

	const listTemplate = [
		{
			name: 'Project File',
			content: job.projectFile,
		},
		{
			name: 'Template Directory',
			content: job.templateDir,
		},
		{
			name: 'Theme ID',
			content: job.themeId,
		},
		{
			name: 'Thumbnail key',
			content: job.thumbnailKey,
		},
	]

	return (
		<div>
			<InlineItems title="Actions">
				<Button
					disabled={renderJobState != 'finished' && renderJobState != 'render:cleanup'}
					onClick={() => handleReview()}>
					Review
				</Button>

				<Button
					disabled={renderJobState != 'finished' && renderJobState != 'render:cleanup'}
					onClick={() => handleAutomationPublish()}>
					Force Publish
				</Button>
				<ButtonIcon delete onClick={() => handleDeleteJob()}>
					Delete
				</ButtonIcon>
			</InlineItems>
			<InlineItems list={list}>
				{renderJobState && renderJobState != 'finished' && renderJobState != 'error' && (
					<div>
						<RenderJobTasks
							tasks={() => getTasks()}
							completedTasks={completedTasks}
							currentTask={renderJobState}
						/>
					</div>
				)}
			</InlineItems>

			<InlineItems title="Advanced" list={listAdvanced} />

			<InlineItems title="Template" list={listTemplate} />

			{renderProgress != null && (renderJobState == 'render:dorender' || renderJobState == 'render:postrender') && (
				<div>
					<p>Rendering progress</p>
					<div style={{ display: 'block', width: '100%', margin: 'auto' }}>
						{Object.values(jobParts).map((value, index) => {
							return (
								<span style={{ display: 'inline-block', margin: '0 16px' }}>
									<Progress value={value} size={50} />
									<small>
										Part {index + 1}/{Object.values(jobParts).length}
									</small>
								</span>
							)
						})}
					</div>
				</div>
			)}

			{/* <button 
                disabled={renderJobState != 'finished' && renderJobState != 'render:cleanup'}
				onClick={() => this.handleReview()}>
                    Review   
                </button>

                <button 
                disabled={renderJobState != 'finished' && renderJobState != 'render:cleanup'}
				onClick={() => this.handleAutomationPublish()}>
                    Force Publish   
                </button>
                <button onClick={() => this.handleDelete()}>
                    Delete
                </button> */}
		</div>
	)
}

const RenderJobs = (props) => {
	const data = props.row.original

	/** Use dispatch hook */
	const dispatch = useDispatch()

	/** Retrieve existing theme */
	const [theme] = useRetrieveEventTheme(data.themeTemplateId)

	/** Handler to start the render job */
	const handleStartRender = () => {
		let postData = {
			uuid: data.uuid,
			preview: false,
			template: theme.id, // here template is just the theme id
		}
		console.log('Starting [FULL] Render Job for Event :', postData)
		dispatch(addRenderJob(postData))
	}

	/** Default View of the job list */
	let jobList = () => <p>No Render Jobs Found</p>

	let renderJobExample = [
		{
			amiId: 'ami-00fc7dc0da6052a8e',
			archive: false,
			createdAt: '',
			createdBy: '6d289c99-ba44-441a-9ff8-640b372f77fc',
			dispatched: true,
			eventId: 'b57b4056-cd49-4d65-aa3a-7c41267dbd09',
			eventMediaId: null,
			eventUuid: 'o-q7nc1w',
			id: 'f0e2ae84-45cd-4c34-a3cd-35525ab7fe27',
			instanceId: 'i-0ab35727abc990dca',
			instanceType: 'c5.12xlarge',
			jobState: 'finished',
			jobUid: '5fkc-mdkRcFXH4mySg9zR',
			outputKey: 'o-q7nc1w/published/o-q7nc1w_Shirleen_2021-03-18T22_49_00.812Z.mp4',
			preview: false,
			projectFile: 'VidDay - Collage - v12 Normal.aepx',
			renderProgress: null,
			retries: 0,
			templateDir: 'VidDay - Collage - v12',
			themeId: 'collage-v12-normal',
			thumbnailKey: 'o-q7nc1w/publishedo-q7nc1w_Shirleen_2021-03-18T22_49_00.812Z_thumbnail.jpg',
			updatedAt: '',
		},
	]

	if (data.renderJobs) {
		console.log(data.renderJobs, 'renderJobs')
		let jobs = [...data.renderJobs]

		jobList = jobs
			.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
			.map((job, index) => <IndividualRenderJob job={job} />)
		// .map((job, index) => <AdminEventRenderJob key={index} {...this.props} job={job} socket={socket} />)
	}

	console.log(data.renderJobs, 'render jobs', jobList)
	const list = [
		{
			name: 'Current Theme',
			content: theme.label,
		},
	]

	return (
		<div>
			<SubRowHeader>
				<p>Automation (βeta) </p>
				<div className="actions">
					<Button onClick={() => handleStartRender()}>Start Render</Button>
				</div>
			</SubRowHeader>

			<InlineItems list={list} />

			<SubRowHeader>
				<p>Jobs</p>
			</SubRowHeader>
			{jobList && jobList.length > 0 ? jobList : <EmptyData>There is no job pending.</EmptyData>}
		</div>
	)
}

export default RenderJobs
