import React, { useState, useEffect } from 'react'

/** Icons */
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

/** Import our table component */
import FactoryTable from '../../../../core/widgets/table/FactoryTableSS'
import { FiltersWrapper } from '../../../../core/widgets/styles'
import FilterDisplayMode from '../../../../core/widgets/filters/FilterDisplayMode'
import FilterDropdown, { FilterDropdownV2 } from '../../../../core/widgets/filters/FilterDropdown'
import eventTypes from '@bit/vidday.vidday-data.data.event-types'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Container, BottomPanel, RightPanel } from '../../../../interface'
import { HR } from '../../../../core/widgets/styles'
import { values } from 'lodash'
import columns from './columns'
import tabs from './tabs'
import { useSearchEngine } from '../../../../core'
import { fetchPromotions } from '../api/actions'
import PromotionForm from '../forms/Promotion'
import ButtonIcon from '../../../../core/widgets/buttons/ButtonIcon'

import { useInterfacePanel } from '../../../../interface'
import SearchEngineProvider, { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'
import TableProvider from '../../../../contexts/TableProvider'
import { Divider, Flex, Box, Heading, HStack, Icon, Button, useDisclosure } from '@chakra-ui/react'
import FilterSearchInput from '../../../../core/widgets/filters/FilterSearchInput'
import { TableFiltersV2, TableResultsV2, TableV2 } from '../../../../core/widgets/table/FactoryTable'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import ModalPromotionForm from '../../../../ui/molecules/ModalPromotionForm'

import TableFilters from '../../../../core/widgets/table/TableFilters'
import TableResults from '../../../../core/widgets/table/TableResults'
import Table from '../../../../core/widgets/table/Table'

const getPromotions = (s) => {
	var items = s.entities.promotions.data || {}
	let collection = []
	collection = values(items)
	return collection
}

const statuses = [
	{ value: true, label: 'Enabled' },
	{ value: false, label: 'Disabled' },
]

const ListView = (props) => {
	const promotionModal = useDisclosure()

	return (
		<Container>
			<ModalPromotionForm isOpen={promotionModal.isOpen} onClose={promotionModal.onClose} />
			<Flex w="full" py="1rem" justifyContent="space-between">
				<Heading variant="hero" size="lg" as="h1">
					Promotions
				</Heading>
				<HStack maxH="2rem" spacing="1rem" divider={<Divider orientation="vertical" />}>
					<Flex>
						<Button variant="solid" size="xs" maxH="2rem" px="0.75rem" onClick={promotionModal.onOpen}>
							<Icon fontSize="1rem !important" width="1rem" as={AddCircleRoundedIcon} mr="0.25rem" />
							Create
						</Button>
					</Flex>
					<Flex>
						<FilterSearchInput label="Search Promotions..." tags={['code']} />
					</Flex>
				</HStack>
			</Flex>

			<Box w="full" bg="#F2F6FA" borderRadius="base" mb="0.5rem">
				<Flex justifyContent="space-between">
					<Flex>
						<FilterDisplayMode current="table" />
					</Flex>
					<Flex>
						<FilterDropdownV2 collection={statuses} label="Status" filter="enabled" />
					</Flex>
				</Flex>
			</Box>

			<TableFilters />
			<TableResults />
			<Table tabs={tabs} />
		</Container>
	)
}

/**
 * Setup Search Engine Context
 */
const ListViewRoot = () => {
	const config = {
		action: fetchPromotions,
		slice: 'promotions',
		actionPrefix: 'PROMOTIONS',
	}

	return (
		<SearchEngineProvider config={config}>
			<TableProvider columns={columns} hiddenColumns={[]}>
				<ListView />
			</TableProvider>
		</SearchEngineProvider>
	)
}

export default ListViewRoot
