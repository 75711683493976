import React, { useEffect } from 'react'
import { useAuthContext } from '../contexts/AuthProvider'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

/**
 * Route Component to protect access to the Private Event Routes
 * This include all routes under /event/:uuid *
 * @param {*} props
 * @returns
 */
const AdminProtectedRoute = ({ children }) => {
	/** Retrieve the event from the state */
	const { isAdmin, isAuthenticated, isAuthenticating } = useAuthContext()
	// const { isAdmin, isAuthenticating } = auth

	console.log('auth', isAdmin)
	const location = useLocation()

	console.log('isAdmin: ', isAdmin, ' isAuthenticating: ', isAuthenticating)
	if (!isAuthenticated && !isAuthenticating && !isAdmin) {
		console.log('NOT AUTHORIZED!')
		return <Navigate to="/auth/login" state={{ from: location }} replace={true} /> // replace={true} /> //replace={true} <>Not Authorized</>
	}

	console.log('returning router from protected route')
	return children

	// return <>Checking...</>
}

export default AdminProtectedRoute
