import React from 'react' 
import styled, {css} from 'styled-components'
import CountryForm from '../../forms/Country'

const Edit = ({row}) => {
    return (
        <div>
            <CountryForm data={row.original} />
        </div>
    )
}
export default Edit