import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
import productPriceSchema from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../../core/widgets/form/input'
import Select from '../../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../../core/widgets/buttons/Button'
import { updateProductPrice, postProductPrice } from '../../api/actions'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'

const getProducts = (s) => {
	var items = s.entities.products.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const getCountries = (s) => {
	var items = s.entities.countries.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const getCurrencies = (s) => {
	var items = s.entities.currencies.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const getTaxCodeCategories = (s) => {
	var items = s.entities.taxCodeCategories.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const ProductPriceForm = ({ data, onSubmitted }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	const products = useSelector((s) => getProducts(s))
	const countries = useSelector((s) => getCountries(s))
	const currencies = useSelector((s) => getCurrencies(s))
	const taxCodeCategories = useSelector((s) => getTaxCodeCategories(s))

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(productPriceSchema),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.id) {
			dispatch(updateProductPrice(model))
		} else {
			dispatch(postProductPrice(model))
			reset()
		}

		//trigger submit callback if any
		onSubmitted != undefined && onSubmitted()
	}

	useEffect(() => {
		let c = countries.findIndex((el, i) => el.alpha2Code == values.countryCode)

		if (c != -1) {
			let currency = countries[c].defaultCurrencyCode

			if (currency) setValue('currencyCode', currency)
		}
	}, [values.countryCode])
	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	// useEffect(() => {
	// 	// Set timezone automatically
	// 	// const autoTimezone = timezone ? timezone : moment.tz.guess(true)
	// 	// // Set deadline automatically
	// 	// const autoStartDate = starts ? starts : moment().add(2, 'days')
	//     // const autoEndDate = ends ? ends : moment().add(2, 'days')
	// 	// // Set initial values
	// 	// setValue('starts', autoStartDate)
	//     // setValue('ends', autoEndDate)
	// 	// setValue('timezone', autoTimezone)
	// }, [timezone, starts, ends])

	// useEffect(() => {
	//console.log("FORM CHANGE",moment(deadline).format(),moment(deadline).tz(values.timezone).format())
	// setValue('starts', moment(starts).tz(values.timezone))
	// setValue('ends', moment(ends).tz(values.timezone))
	// }, [values.timezone])

	/** Register uncontrolled components */
	useEffect(() => {
		// register({ name: 'timezone' }, { required: true })
		// register({ name: 'starts' }, { required: true })
		// register({ name: 'ends' }, { required: true })
	}, [register])

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	const productOptions = []
	products.forEach((el) =>
		productOptions.push({ value: el.id, label: el.name + (el.slug ? ' (' + el.slug + ')' : '') })
	)

	const countryOptions = []
	countries.forEach((el) => countryOptions.push({ value: el.alpha2Code, label: el.name }))

	const currencyOptions = []
	currencies.forEach((el) => currencyOptions.push({ value: el.code, label: el.label }))

	const taxCodeCategoryOptions = []
	taxCodeCategories.forEach((el) =>
		taxCodeCategoryOptions.push({ value: el.id, label: '[' + el.zone + '] ' + el.name + ' (' + el.code + ')' })
	)

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Product Price Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					margin="normal"
					name="productId"
					id="productId"
					variant="filled"
					label="Product"
					errors={errors}>
					{productOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label} {el.slug ? '(' + el.slug + ')' : ''}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					margin="normal"
					name="countryCode"
					id="countryCode"
					variant="filled"
					label="Country"
					errors={errors}>
					{countryOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					margin="normal"
					name="currencyCode"
					id="currencyCode"
					variant="filled"
					label="Currency"
					errors={errors}>
					{currencyOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="price"
					id="price"
					variant="filled"
					label="Price (in $cents)"
					errors={errors}
				/>

				<Select
					control={control}
					margin="normal"
					name="taxCodeCategoryId"
					id="taxCodeCategoryId"
					variant="filled"
					label="Tax Code Category"
					errors={errors}>
					{taxCodeCategoryOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				{/* 
            <Select
                control={control}
                margin="normal"
                name="allowedCurrencies"
                id="type"
                variant="filled"
                multiple={true}
                label="Allowed Currencies"
                errors={errors} >
                {groups.map((el, i) => (
                    <MenuItem key={i} value={el.value}>
                        {el.label}
                    </MenuItem>
                ))}
            </Select> */}
			</InlineItems>
		</form>
	)
}

ProductPriceForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default ProductPriceForm
