
import { normalize, schema } from 'normalizr'



const invoiceItemSchema = new schema.Entity(
	'invoice_items',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

const invoiceItemListSchema = new schema.Array(invoiceItemSchema)

const invoiceSchema = new schema.Entity(
	'invoices',
	{},
	{
		idAttribute: (data) => data.id,
	}
)
// Represent all related data coming through the fetch request when loading the user

const invoiceListSchema = new schema.Array(invoiceSchema)

export {invoiceListSchema, invoiceItemListSchema}