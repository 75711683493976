import styled from 'styled-components'

export const StyledFilterSettingButton = styled.div`
    position:relative;
    display:inline-flex;
    height:33px;
    padding-top:4px;
    svg {
        color:#0A2239;
    }

    a:hover svg {
        color:#0187FF;
    }
`;