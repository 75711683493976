const THEME_VERSIONS = {
    /** Fetch all themes versions (includes filters) */
    FETCH_REQUESTED: 'THEME_VERSIONS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'THEME_VERSIONS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'THEME_VERSIONS_FETCH_FAILED',

    /** Post a theme version */
    POST_REQUESTED: 'THEME_VERSION_POST_REQUESTED',
    POST_SUCCEEDED:'THEME_VERSION_POST_SUCCEEDED',
    POST_FAILED: 'THEME_VERSION_POST_FAILED',

    /** Update a theme version */
    UPDATE_REQUESTED: 'THEME_VERSION_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'THEME_VERSION_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'THEME_VERSION_UPDATE_FAILED',

    /** Delete a theme version */
    DELETE_REQUESTED: 'THEME_VERSION_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'THEME_VERSION_DELETE_SUCCEEDED',
    DELETE_FAILED: 'THEME_VERSION_DELETE_FAILED',
}

export {THEME_VERSIONS}