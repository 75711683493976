/** Import our instance config */
// import instance from '../../../../../api/config'
import axios from 'axios'
import { pick } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

/**
 * Fetch our users
 */
const fetchUsers = async (params) => {
	const response = await instance.get('/users?' + params)
	return response
}

/**
 * Post a user (won't be admin unless a {role} has been provided)
 * @param {*} data
 * @returns
 */
const postUser = async (data) => {
	const response = await instance.post('/users', data)
	return response
}

/**
 * Post a user with an admin role
 * @param {*} data
 * @returns
 */
const postUserAsAdmin = async (data) => {
	const postedData = { ...data, role: 'admin' }
	const response = await instance.post('/users', postedData)
	return response
}

/**
 * Update a user
 * @param {*} data
 * @returns
 */
const updateUser = async (data) => {
	const response = await instance.put('/users/' + data.id, data)
	return response
}

/**
 * Update a user role
 * @param {*} data
 * @returns
 */
const updateUserRole = async (data) => {
	const response = await instance.put('/users/' + data.id + '/change-role', data)
	return response
}

/**
 * Delete a user
 * @param {*} id
 * @returns
 */
const deleteUser = async (id) => {
	const response = await instance.delete('/account/users/' + id)
	return response
}

const resendVerificationEmail = async (email) => {
	const data = { email: email }
	const response = await instance.post('/auth/resend-verification', data)
	return response
}

const requests = {
	fetchUsers,
	postUser,
	postUserAsAdmin,
	updateUser,
	deleteUser,
	updateUserRole,
	resendVerificationEmail,
}

export default requests
