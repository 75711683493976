import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { themeListSchema, themeSchema } from './normalizr'
import requests from './requests'
import { THEMES } from './constants'

/**
 * Themes Saga worker
 * @param {*} action 
 */
function* handleFetchThemes(action) {
    try {
        const response = yield call(requests.fetchThemes, action.payload)
        const normalizedData = normalize(response.data.data, themeListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: THEMES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: THEMES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a theme
 * @param {*} action 
 */
 function* handlePostTheme(action) {
    try {
        const response = yield call(requests.postTheme, action.payload)
        const normalizedData = normalize([response.data.data], themeListSchema)
        yield put({ type: THEMES.POST_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: THEMES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a theme
 * @param {*} action 
 */
 function* handleUpdateTheme(action) {
    try {
        const response = yield call(requests.updateTheme, action.payload)
        
        const normalizedData = normalize([response.data.data], themeListSchema)
        console.log(response.data)
        yield put({ type: THEMES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: THEMES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a theme
 * @param {*} action 
 */
 function* handleDeleteTheme(action) {
    try {
        const response = yield call(requests.deleteTheme, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: THEMES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: THEMES.DELETE_FAILED, payload:e })
    }
}


/**
 * Theme Versions Saga Watcher
 */
export function* watchThemesFetch(){
    // while(true){
        yield takeLatest(THEMES.FETCH_REQUESTED, handleFetchThemes)
    // }
}

export function* watchUpdateTheme(){
    // while(true){
        yield takeEvery(THEMES.UPDATE_REQUESTED, handleUpdateTheme)
    // }
}

export function* watchPostTheme(){
    // while(true){
        yield takeEvery(THEMES.POST_REQUESTED, handlePostTheme)
    // }
}

export function* watchDeleteTheme(){
    // while(true){  
        yield takeEvery(THEMES.DELETE_REQUESTED, handleDeleteTheme)
    // }
}