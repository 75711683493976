/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import { pick } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2/admin`,
})

const acceptedKeys = ['']
const acceptedKeysPUT = ['']

/**
 * Fetch our render jobs
 */
const fetchRenderJobs = async (params) => {
	const response = await instance.get('/renderjobs?' + params)
	return response
}

/**
 * Post a render job
 * @param {*} data
 * @returns
 */
const postRenderJob = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/renderjobs', filteredData)
	return response
}

/**
 * Update a render job
 * @param {*} data
 * @returns
 */
const updateRenderJob = async (data) => {
	const filteredData = pick(data, acceptedKeysPUT)
	const response = await instance.put('/renderjobs/' + data.id, filteredData)
	return response
}

/**
 * Delete a render job
 * @param {*} id
 * @returns
 */
const deleteRenderJob = async (id) => {
	const response = await instance.delete('/renderjobs/' + id)
	return response
}

const requests = { fetchRenderJobs, postRenderJob, updateRenderJob, deleteRenderJob }

export default requests
