import { MEDIA } from './constants'


const postRegenerateImage = (data) => ({
    type: MEDIA.POST_REGENERATE_IMAGE_REQUESTED,
    payload:data,
})



export {
    postRegenerateImage,
}





