import { mode } from '@chakra-ui/theme-tools'

const FormLabel = {
	baseStyle: (props) => {
		return {
			color: mode('inherit', 'gray.800')(props),
		}
	},
}

export default FormLabel
