import { TAX_CODE_CATEGORIES } from './constants'

const fetchTaxCodeCategories = (params) => ({
    type: TAX_CODE_CATEGORIES.FETCH_REQUESTED,
    payload:params,
})

const setTaxCodeCategories = (events) => ({
    type: TAX_CODE_CATEGORIES.FETCH_SUCCEEDED,
    payload:events,
})

const setError = (error) => ({
    type: TAX_CODE_CATEGORIES.FETCH_FAILED,
    payload:error,
})


const postTaxCodeCategory = (data) => ({
    type: TAX_CODE_CATEGORIES.POST_REQUESTED,
    payload:data,
})

const updateTaxCodeCategory = (data) => ({
    type: TAX_CODE_CATEGORIES.UPDATE_REQUESTED,
    payload:data,
})

const deleteTaxCodeCategory = (id) => ({
    type: TAX_CODE_CATEGORIES.DELETE_REQUESTED,
    payload:id,
})


export {
    fetchTaxCodeCategories,
    setTaxCodeCategories,
    setError,
    postTaxCodeCategory,
    updateTaxCodeCategory,
    deleteTaxCodeCategory,
}





