const NEXUS = {
    /** Fetch all nexus (includes filters) */
    FETCH_REQUESTED: 'NEXUS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'NEXUS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'NEXUS_FETCH_FAILED',

    /** Post a nexus */
    POST_REQUESTED: 'NEXUS_POST_REQUESTED',
    POST_SUCCEEDED:'NEXUS_POST_SUCCEEDED',
    POST_FAILED: 'NEXUS_POST_FAILED',

    /** Update a nexus */
    UPDATE_REQUESTED: 'NEXUS_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'NEXUS_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'NEXUS_UPDATE_FAILED',

    /** Delete a nexus */
    DELETE_REQUESTED: 'NEXUS_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'NEXUS_DELETE_SUCCEEDED',
    DELETE_FAILED: 'NEXUS_DELETE_FAILED',
}

export {NEXUS}