import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core from '../../../../core'
import UnderConstruction from '../../../../views/utils/UnderConstruction'

/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.accounts.profiles',
	title: 'User Profiles',
	path: '/core/accounts/profiles',
	parent: 'core.accounts',
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<UnderConstruction />} />
		</Routes>
	)
}
