import React from 'react'
import Core from '../../../core'
import { Link, Route, Routes } from 'react-router-dom'
import UnderConstruction from '../../../views/utils/UnderConstruction'
import ListView from './List'
/**
 * Define Module
 */
export const instance = Core.defineModule({
	name: 'vidday.media',
	title: 'Media',
	path: '/vidday/media',
	parent: 'vidday',
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
