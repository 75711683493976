import React from 'react' 
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../../core/widgets/others/SubRowHeader'

const Profile = (props) => {
    const data = props.row.original;
    
    const listProfile = [
        {
            name:'Profile ID',
            content:data.profile.id,
        },
        
        {
            name:'First Name',
            content:data.profile.firstName,
        },
        {
            name:'Last Name',
            content:data.profile.lastName,
        },
        {
            name:'birthday',
            content:data.profile.birthday,
        },
        {
            name:'SMS/Phone',
            content:data.profile.sms,
        },
        {
            name:'language',
            content:data.profile.language,
        },
        {
            name:'Created At',
            content:data.profile.createdAt,
        },  
    ]

    const listNotifications = [
        {
            name:'Event Activity',
            content:data.profile.notifications.eventActivity == false ? 'No' : 'Yes',
        }, 
        {
            name:'Event Progress',
            content:data.profile.notifications.eventProgress == false ? 'No' : 'Yes',
        }, 
        {
            name:'Promotions',
            content:data.profile.notifications.promos == false ? 'No' : 'Yes',
        },  
    ]

    return (
        <>
            <SubRowHeader>
                <p>Profile</p>  
            </SubRowHeader>
            <InlineItems list={listProfile} />
            <InlineItems list={listNotifications} />
        </>
    )
}

export default Profile