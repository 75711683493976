const THEME_CATEGORIES = {
    /** Fetch all themes categories (includes filters) */
    FETCH_REQUESTED: 'THEME_CATEGORIES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'THEME_CATEGORIES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'THEME_CATEGORIES_FETCH_FAILED',

    /** Post a theme category */
    POST_REQUESTED: 'THEME_CATEGORY_POST_REQUESTED',
    POST_SUCCEEDED:'THEME_CATEGORY_POST_SUCCEEDED',
    POST_FAILED: 'THEME_CATEGORY_POST_FAILED',

    /** Update a theme category */
    UPDATE_REQUESTED: 'THEME_CATEGORY_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'THEME_CATEGORY_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'THEME_CATEGORY_UPDATE_FAILED',

    /** Delete a theme category */
    DELETE_REQUESTED: 'THEME_CATEGORY_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'THEME_CATEGORY_DELETE_SUCCEEDED',
    DELETE_FAILED: 'THEME_CATEGORY_DELETE_FAILED',
}

export {THEME_CATEGORIES}