import React from 'react' 
import {useLocation} from 'react-router-dom'

const UnderConstruction = () => {
    let location = useLocation();
    
    return (
        <div>
            <h1>Page under construction.</h1>
            <p><strong>{location.pathname}</strong></p>
            <p>This section is under construction, stay tuned for more updates.</p>
        </div>
    )
}

export default UnderConstruction