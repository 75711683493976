import { REGIONS } from './constants'

const fetchRegions = (params) => ({
    type: REGIONS.FETCH_REQUESTED,
    payload:params,
})

const setRegions = (countries) => ({
    type: REGIONS.FETCH_SUCCEEDED,
    payload:countries,
})
const setError = (error) => ({
    type: REGIONS.FETCH_FAILED,
    payload:error,
})

const postRegion = (data) => ({
    type: REGIONS.POST_REQUESTED,
    payload:data,
})

const updateRegion = (data) => ({
    type: REGIONS.UPDATE_REQUESTED,
    payload:data,
})

export {
    fetchRegions,
    setRegions,
    setError,
    postRegion,
    updateRegion,
}





