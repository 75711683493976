import React from 'react'
import { StyledNavigation } from './styles'
import { useSelector } from 'react-redux'
/**
 * Hook that intends to retrieve the main navigation
 * Panel information from the reducer
 */
export const useNavigationPanel = () => {
	const isOpen = useSelector((s) => s.interface.panels.navigation)
	return { isOpen }
}

const NavigationPanel = ({ children, ...rest }) => {
	const { isOpen } = useNavigationPanel()

	// console.log(children, rest, "navigation panel....")
	return <StyledNavigation {...rest}>{children}</StyledNavigation>
}

export default NavigationPanel
