import React from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { updateAnnouncement } from '../../api/actions'
import AnnouncementForm from '../../src/AnnouncementForm'
import moment from 'moment-timezone'

const Edit = ({ row }) => {
	const dispatch = useDispatch()

	const onSubmit = (data) => {
		const values = { id: row.original.id, ...data }
		if (moment(values.starts).isValid()) {
			let startsDate = moment(values.starts)
			let endsDate = moment(values.ends)

			let obj = {
				...values,
				starts: startsDate.toISOString(),
				ends: endsDate.toISOString(),
			}

			dispatch(updateAnnouncement(obj))
		}
	}

	return (
		<div>
			<AnnouncementForm data={row.original} onSubmit={onSubmit} />
		</div>
	)
}
export default Edit
