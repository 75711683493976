import React from 'react'
import { chakra, Divider, SimpleGrid, Button, Flex } from '@chakra-ui/react'
import schemaAnnouncement from './schema'
import Input from '../../../../../ui/molecules/Input'
import Switch from '../../../../../ui/molecules/Switch'
import Select from '../../../../../ui/molecules/Select'
import Date from '../../../../../ui/molecules/Date'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { typeOptions } from './schema'

export const isEnabledOptions = [
	{ value: true, label: 'Active' },
	{ value: false, label: 'Deactivate' },
]

const Form = ({ data, onSubmit }) => {
	/** Form Hook */
	const methods = useForm({
		defaultValues: data,
		// mode: 'onBlur',
		resolver: yupResolver(schemaAnnouncement), //account && account.isRegistered && !account.isActive ? schemaEmail : schema
	})

	const { watch, control, errors, handleSubmit, formState } = methods

	const values = watch()

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			<SimpleGrid columns={[1, 2]} spacing="1rem">
				<Select
					control={control}
					errors={errors}
					name="type"
					placeholder="Type"
					label="Type"
					defaultValue={values.occasion || ''}
					options={typeOptions.map((el, i) => (
						<option key={i} value={el.value}>
							{el.label}
						</option>
					))}
				/>
				<Switch
					errors={errors}
					control={control}
					name="enabled"
					label="Is it active?"
					description="If active, users can redeem the code."
				/>
			</SimpleGrid>

			<Input control={control} errors={errors} name="message" label="Message" />

			<SimpleGrid columns={[1, 2]} spacing="1rem">
				<Date control={control} errors={errors} name="starts" label="Starting Date" useTime allowPast />
				<Date control={control} errors={errors} name="ends" label="Ending Date" useTime allowPast />
			</SimpleGrid>

			<Flex w="full" my="1rem" justifyContent="center">
				<Button type="submit" variant="solid" minW="150px" isLoading={formState.isSubmitting}>
					Save
				</Button>
			</Flex>
		</chakra.form>
	)
}

export default Form
