const TAX_CODE_RATES = {
    /** Fetch all tax code rates (includes filters) */
    FETCH_REQUESTED: 'TAX_CODE_RATES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'TAX_CODE_RATES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'TAX_CODE_RATES_FETCH_FAILED',

    /** Post an tax code rate */
    POST_REQUESTED: 'TAX_CODE_RATE_POST_REQUESTED',
    POST_SUCCEEDED:'TAX_CODE_RATE_POST_SUCCEEDED',
    POST_FAILED: 'TAX_CODE_RATE_POST_FAILED',

    /** Update an tax code rate */
    UPDATE_REQUESTED: 'TAX_CODE_RATE_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'TAX_CODE_RATE_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'TAX_CODE_RATE_UPDATE_FAILED',

    /** Delete an tax code rate */
    DELETE_REQUESTED: 'TAX_CODE_RATE_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'TAX_CODE_RATE_DELETE_SUCCEEDED',
    DELETE_FAILED: 'TAX_CODE_RATE_DELETE_FAILED',
}

export {TAX_CODE_RATES}