import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { renderJobListSchema, renderJobSchema } from './normalizr'
import requests from './requests'
import { RENDER_JOBS } from './constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action 
 */
function* handleFetchRenderJobs(action) {
    try {
        const response = yield call(requests.fetchRenderJobs, action.payload)
        const normalizedData = normalize(response.data.data, renderJobListSchema)
        yield put({ type: RENDER_JOBS.FETCH_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: RENDER_JOBS.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a renderJob
 * @param {*} action 
 */
 function* handlePostRenderJob(action) {
    try {
        const response = yield call(requests.postRenderJob, action.payload)
        yield put({ type: RENDER_JOBS.POST_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: RENDER_JOBS.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a renderJob
 * @param {*} action 
 */
 function* handleUpdateRenderJob(action) {
    try {
        const response = yield call(requests.updateRenderJob, action.payload)
        
        const normalizedData = normalize([response.data.data], renderJobListSchema)
        console.log(response.data)
        yield put({ type: RENDER_JOBS.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: RENDER_JOBS.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a renderJob
 * @param {*} action 
 */
 function* handleDeleteRenderJob(action) {
    try {
        const response = yield call(requests.deleteRenderJob, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: RENDER_JOBS.DELETE_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: RENDER_JOBS.DELETE_FAILED, payload:e })
    }
}


/**
 * Theme Versions Saga Watcher
 */
export function* watchRenderJobsFetch(){
    // while(true){
        yield takeLatest(RENDER_JOBS.FETCH_REQUESTED, handleFetchRenderJobs)
    // }
}

export function* watchUpdateRenderJob(){
    // while(true){
        yield takeEvery(RENDER_JOBS.UPDATE_REQUESTED, handleUpdateRenderJob)
    // }
}

export function* watchPostRenderJob(){
    // while(true){
        yield takeEvery(RENDER_JOBS.POST_REQUESTED, handlePostRenderJob)
    // }
}

export function* watchDeleteRenderJob(){
    // while(true){  
        yield takeEvery(RENDER_JOBS.DELETE_REQUESTED, handleDeleteRenderJob)
    // }
}