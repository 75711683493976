import styled from 'styled-components'

const VerticalDivider = styled.div`
    display:inline-flex;
    height: 33px;
    border: none;
    border-left: 1px solid #DADDE1;
    position: relative;
    margin:0 10px;
`;

export default VerticalDivider;