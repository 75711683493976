import React from 'react' 
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../../core/widgets/others/SubRowHeader'

const Location = (props) => {
    const data = props.row.original;
    const profile = [
        {
            name:'Country',
            content:data.profile.countryCode || '-',
        },
        {
            name:'Region',
            content:data.profile.regionCode || '-',
        },
        {
            name:'Postal Code',
            content:data.profile.postalCode || '-',
        },
    ]
    const list = [
        {
            name:'IP',
            content:data.profile.location?.ip,
        },
        {
            name:'City',
            content:data.profile.location?.city,
        },
        {
            name:'Continent code',
            content:data.profile.location?.continent_code,
        },
        {
            name:'Country',
            content:data.profile.location?.country,
        },
        {
            name:'Country Name',
            content:data.profile.location?.country_name,
        },
        {
            name:'Postal Code',
            content:data.profile.location?.postal,
        },
        {
            name:'Region',
            content:data.profile.location?.region,
        },
        {
            name:'Region Code',
            content:data.profile.location?.region_code,
        },
        {
            name:'Timezone',
            content:data.profile.location?.timezone,
        },
        {
            name:'UTC Offset',
            content:data.profile.location?.utc_offset,
        },
        {
            name:'Country Population',
            content:data.profile.location?.country_population,
        },
        {
            name:'Country Area',
            content:data.profile.location?.country_area,
        },
        {
            name:'Country Calling Code',
            content:data.profile.location?.country_calling_code,
        },
        {
            name:'Country Capital',
            content:data.profile.location?.country_capital,
        },
        {
            name:'Currency',
            content:data.profile.location?.currency,
        },
        {
            name:'Currency Name',
            content:data.profile.location?.currency_name,
        },
        {
            name:'Is part of EU?',
            content:data.profile.location?.in_eu == false ? 'No' : 'Yes',
        },
        {
            name:'Languages of country',
            content:data.profile.location?.languages,
        },
        {
            name:'Latitude',
            content:data.profile.location?.latitude,
        },
        {
            name:'Longitude',
            content:data.profile.location?.longitude,
        },
       
    ]


    return (
        <>
            <SubRowHeader>
                <p>Location</p>  
            </SubRowHeader>
            <InlineItems title="Location set by user" list={profile} />
            
            <InlineItems title="Last IP location" list={list} />
        </>
    )
}

export default Location