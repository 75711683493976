import React from 'react'
import { Controller } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { StyledInputLabel } from '../styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

const MuiSelect = (props) => {
	const { label, name, options, required, errorobj, keyValue, keyLabel, value } = props
	let isError = false
	let errorMessage = ''
	if (errorobj && errorobj.hasOwnProperty(name)) {
		isError = true
		errorMessage = errorobj[name].message
	}

	const formattedOptions = []
	options.forEach((el, i) => {
		formattedOptions.push({ name: el[keyLabel], value: el[keyValue] })
	})

	return (
		<FormControl fullWidth={true} error={isError} defaultValue={value}>
			<StyledInputLabel htmlFor={name}>
				{label} {required ? <span className="req-label">*</span> : null}
			</StyledInputLabel>
			<Select id={name}>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{formattedOptions.map((item, i) => (
					<MenuItem key={item.value} value={item.value}>
						{item.name}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	)
}

const FormSelect = ({ name, label, control, defaultValue, children, errors, required, multiple, disabled, helperText, ...props }) => {
	const labelId = `${name}-label`

	let isError = false
	let errorMessage = ''
	if (errors && errors.hasOwnProperty(name)) {
		isError = true
		errorMessage = errors[name].message
	}
	console.log(props, "props select")
	return (
		<FormControl disabled={disabled} variant="filled" margin="normal" fullWidth={true} error={isError} {...props}>
			<InputLabel htmlFor={name} id={labelId}>
				{label} {required ? <span className="req-label">*</span> : null}
			</InputLabel>

			<Controller
				as={
					<Select 
					multiple={multiple} labelId={labelId} label={label}>
						{children}
					</Select>
				}
				defaultValue={defaultValue}
				name={name}
				control={control}
				InputLabelProps={{
					className: required ? 'required-label' : '',
					required: required || false,
				}}
				error={isError}
				helperText={helperText ? errorMessage || helperText || ' ' : false} // This need to be a space within the string so we can render an empty space..
			/>
		</FormControl>
	)
}
//   export default ReactHookFormSelect;

export default FormSelect
