
import { normalize, schema } from 'normalizr'


const currencySchema = new schema.Entity(
	'currencies',
	{
		// regions: [regionSchema],
	},
	{
		idAttribute: (data) => data.code,
	}
)
// Represent all related data coming through the fetch request when loading the user

const currencyListSchema = new schema.Array(currencySchema)

export {currencyListSchema}