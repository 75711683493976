import React from 'react' 
import styled from 'styled-components'

export const StyledOverflowY = styled.div`
    overflow-y:scroll;
    display:block;
    bottom:50px;
    top:239px;
    left:0;
    right:0;
    position:absolute;
`;

export const Logo = styled.a`
    content:'';
    display:block;
    width:114px;
    height:34px;
    background-image: url('/assets/images/admin/logo/logo.svg');
	background-position: center;
	background-repeat: no-repeat;
    background-size:114px 34px;
`

// export const StyledNavigation = styled.div`
//     z-index:2000;
//     display:block;
//     width:300px;
//     position: fixed;
//     top:0;
//     bottom:0;
//     left:0;
//     background-color:#0A2239;
// `

export const Seperator = styled.div`
    display:block;
    position:relative;    
    margin:16px;
    clear:both;
    overflow:visible;
`

export const AlignRight = styled.div`
    display:inline-block;
    float:right;
    clear:both;
`

export const AlignLeft = styled.div`
    display:inline-block;
    float:left;
    clear:both;
`

export const FixRight = styled.div`
    display:inline-block;
    position:absolute;
    right:0;
    top:0;
`