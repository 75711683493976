const Container = {
	baseStyle: {},
	variants: {
		main: () => {
			return {
				pb: '1rem',
				px: [0, '1rem'],
			}
		},
	},
	defaultProps: {},
}

export default Container
