import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../../core'
import UnderConstruction from '../../../../views/utils/UnderConstruction'
import ListView from './List'

/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.accounts.users',
	title: 'Users',
	path: '/core/accounts/users',
	parent: 'core.accounts',
})

export const usersSlice = buildEngineSlice({
	sliceName: 'users',
	actionPrefix: 'USERS',
	initialState: {
		sortBy: 'id',
	},
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
