import { PRODUCT_PRICES } from './constants'

const fetchProductPrices = (params) => ({
    type: PRODUCT_PRICES.FETCH_REQUESTED,
    payload:params,
})

const setProductPrices = (events) => ({
    type: PRODUCT_PRICES.FETCH_SUCCEEDED,
    payload:events,
})

const setError = (error) => ({
    type: PRODUCT_PRICES.FETCH_FAILED,
    payload:error,
})


const postProductPrice = (data) => ({
    type: PRODUCT_PRICES.POST_REQUESTED,
    payload:data,
})

const updateProductPrice = (data) => ({
    type: PRODUCT_PRICES.UPDATE_REQUESTED,
    payload:data,
})

const deleteProductPrice = (id) => ({
    type: PRODUCT_PRICES.DELETE_REQUESTED,
    payload:id,
})


export {
    fetchProductPrices,
    setProductPrices,
    setError,
    postProductPrice,
    updateProductPrice,
    deleteProductPrice,
}





