/* **************************************
 *  LIBRARIES
 * ************************************ */

import * as Sentry from '@sentry/react'
import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
// import { routerMiddleware } from 'connected-react-router'

import { apiMiddleware } from 'redux-api-middleware'
import modalRefHandler from './modal'

/** Saga Imports */
import createSagaMiddleware from 'redux-saga'
import { logger } from 'redux-logger'
import rootSaga from '../sagas'

// import { generateUID } from '@bit/vidday.vidday-helpers.utils.generate-uid'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import createRootReducer from '../reducers'

const sagaMiddleware = createSagaMiddleware()
/* **************************************
 *  STORE ENHANCER FOR MODALS
 * ************************************ */

// export const modalRefStore = {}

// function modalRefHandler({ getState }) {
// 	return (next) => (action) => {
// 		switch (action.type) {
// 			case 'modal_ADD_MODAL':
// 				const id = generateUID()
// 				action.id = id
// 				modalRefStore[id] = action.refs
// 				break
// 			default:
// 				break
// 		}
// 		const returnValue = next(action)
// 		return returnValue
// 	}
// }

/* **************************************
 *  CONFIG STORE
 * ************************************ */
export const history = createBrowserHistory()

export default function configureStore(initialState = {}) {
	/* Sentry redux integration */
	const sentryReduxEnhancer = Sentry.createReduxEnhancer({
		// Optionally pass options
	})

	/* Define all middlewares for redux */
	const middlewares = [
		// routerMiddleware(history), // connected router middleware
		thunkMiddleware,
		promiseMiddleware(),
		apiMiddleware,
		modalRefHandler,

		//LogRocket.reduxMiddleware()
	]

	/* Apply redux middleware */
	const toolChain = [applyMiddleware(...middlewares), applyMiddleware(sagaMiddleware, logger), sentryReduxEnhancer]
	// const toolChain = [applyMiddleware(...middlewares)]

	/* compose the store, and pass our root reducer and initial state */
	// const store = compose(composeWithDevTools(...toolChain), sentryReduxEnhancer)(createStore)(rootReducer, initialState)
	const store = compose(composeWithDevTools(...toolChain))(createStore)(createRootReducer(history), initialState)

	/** Run sagas */
	sagaMiddleware.run(rootSaga)

	// store.dispatch({type:'USERS_FETCH_REQUESTED', payload:'searchQuery=my%20search&currentPage=1&pageSize=10&status=inactive&occasion=birthday&id=myid&sortBy=id&sortByDirection=asc'})
	return store
}
