import React, { useState, useEffect, useReducer, useCallback } from 'react'

/** Import our table component */
// import FactoryTable from '../../../../../core/widgets/table/FactoryTable'
import FactoryTable from '../../../../../core/widgets/table/FactoryTableSS'

import { FiltersWrapper } from '../../../../../core/widgets/styles'
import FilterDisplayMode from '../../../../../core/widgets/filters/FilterDisplayMode'
import FilterDropdown from '../../../../../core/widgets/filters/FilterDropdown'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Container, BottomPanel, RightPanel } from '../../../../../interface'
import { HR } from '../../../../../core/widgets/styles'
import { fetchCountries, postCountry } from '../api/actions' // from '@bit/vidday.vidday-admin-store.modules.users'
import { values } from 'lodash'
import columns from './columns'
import tabs from './tabs'
import { useSearchEngine, serialize, extractKeys } from '../../../../../core'
import axios from 'axios'
import FilterItemPerView from '../../../../../core/widgets/filters/FilterItemPerView'
import FilterPageNumber from '../../../../../core/widgets/filters/FilterPageNumber'
import ButtonExport from '../../../../../core/widgets/buttons/ButtonExport'
import VerticalDivider from '../../../../../core/widgets/filters/VerticalDivider'
import FilterSettingButton from '../../../../../core/widgets/filters/FilterSettingButton'
import SearchEngineProvider, { useSearchEngineContext } from '../../../../../contexts/SearchEngineProvider'
import { Box, Flex, Heading } from '@chakra-ui/react'
import FilterSearchInput from '../../../../../core/widgets/filters/FilterSearchInput'
import { TableFiltersV2, TableResultsV2, TableV2 } from '../../../../../core/widgets/table/FactoryTable'
import TableProvider from '../../../../../contexts/TableProvider'
import TableFilters from '../../../../../core/widgets/table/TableFilters'
import TableResults from '../../../../../core/widgets/table/TableResults'
import Table from '../../../../../core/widgets/table/Table'

const getCountries = (s) => {
	var items = s.entities.countries.data || {}
	let collection = []
	collection = values(items)
	return collection
}

const ListView = (props) => {
	const fetchCountriesFromOrigin = async () => {
		let response = axios.get('https://restcountries.com/v2/all')

		response.then(
			(response) => {
				let data = response.data
				if (data.length > 0) {
					data.forEach((el, i) => {
						var model = { ...el }
						delete model.latlng

						/** Flatten lat and long */
						model.latitude = el.latlng[0]
						model.longitude = el.latlng[1]
						/** Set default currency */
						model.allowedCurrencies = ['USD']

						console.log('NEW COUNTRY MODEL: ', model)
						// dispatch(postCountry(model))
					})
				}
				console.log('COUNTRIES:', response)
			},
			(error) => {
				console.log('COUNTRIES Errors:', error)
			}
		)
	}

	return (
		<Container>
			<Flex w="full" py="1rem" justifyContent="space-between">
				<Heading variant="hero" size="lg" as="h1">
					Countries
				</Heading>
				<Box maxW="400px" minW="300px">
					<FilterSearchInput label="Search Users..." />
				</Box>
			</Flex>

			<Box w="full" bg="#F2F6FA" borderRadius="base" mb="0.5rem">
				<Flex justifyContent="space-between">
					<Flex>
						<FilterDisplayMode current="table" />
					</Flex>
					<Flex>
						{/* <FilterDropdownV2 collection={roles} label="Role" filter="role" />
						<VerticalDivider />
						<FilterDropdownV2 collection={socialPlatforms} label="Medium" filter="medium" />
						<VerticalDivider />
						<FilterDropdownV2 collection={statuses} label="Status" filter="status" /> */}
					</Flex>
				</Flex>
			</Box>

			<TableFilters />
			<TableResults />
			<Table tabs={tabs} />
		</Container>
	)
}

/**
 * Setup Search Engine Context
 */
const ListViewRoot = () => {
	const config = {
		action: fetchCountries,
		slice: 'countries',
		actionPrefix: 'COUNTRIES',
	}

	return (
		<SearchEngineProvider config={config}>
			<TableProvider columns={columns} hiddenColumns={[]}>
				<ListView />
			</TableProvider>
		</SearchEngineProvider>
	)
}

export default ListViewRoot
