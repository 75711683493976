

import * as Yup from 'yup'

const schemaCurrency = Yup.object().shape({
    code: Yup.string().required(),
    label: Yup.string().required(),
    labelFull: Yup.string().required(),
    symbol: Yup.string().required(),
})

export default schemaCurrency
