import { TAX_CODE_RATES } from './constants'

const fetchTaxCodeRates = (params) => ({
    type: TAX_CODE_RATES.FETCH_REQUESTED,
    payload:params,
})

const setTaxCodeRates = (events) => ({
    type: TAX_CODE_RATES.FETCH_SUCCEEDED,
    payload:events,
})

const setError = (error) => ({
    type: TAX_CODE_RATES.FETCH_FAILED,
    payload:error,
})


const postTaxCodeRate = (data) => ({
    type: TAX_CODE_RATES.POST_REQUESTED,
    payload:data,
})

const updateTaxCodeRate = (data) => ({
    type: TAX_CODE_RATES.UPDATE_REQUESTED,
    payload:data,
})

const deleteTaxCodeRate = (id) => ({
    type: TAX_CODE_RATES.DELETE_REQUESTED,
    payload:id,
})


export {
    fetchTaxCodeRates,
    setTaxCodeRates,
    setError,
    postTaxCodeRate,
    updateTaxCodeRate,
    deleteTaxCodeRate,
}





