import React from 'react'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../core/widgets/others/SubRowHeader'

const Details = (props) => {
	const data = props.row.original

	const listStatus = [
		{
			name: 'Is Active',
			content: data.isActive == true ? 'Yes' : 'No',
		},
		{
			name: 'Code',
			content: data.code,
		},
		{
			name: 'Type',
			content: data.type,
		},
		{
			name: 'Value',
			content: data.type == 'percent' ? data.value + '%' : '$' + data.value / 100,
		},
		{
			name: 'Max Count',
			content: data.maxCount == null ? '∞' : data.maxCount,
		},
		{
			name: 'Used',
			content: data.useCount > 1 ? data.useCount + ' times' : data.useCount + ' time',
		},
		{
			name: 'Expires on',
			content: data.expires,
		},
	]

	const listDetails = [
		{
			name: 'ID',
			content: data.id,
		},
		{
			name: 'Timezone',
			content: data.timezone,
		},
		{
			name: 'Creation Date',
			content: data.createdAt,
		},
		{
			name: 'Last Update',
			content: data.updatedAt,
		},
	]

	return (
		<>
			<SubRowHeader>
				<p>Details</p>
			</SubRowHeader>

			<InlineItems list={listStatus} />
			<InlineItems list={listDetails} />
		</>
	)
}

export default Details
