import { VIDDAY_PACKAGE } from './constants'

const compressViddayPackage = (uuid) => ({
    type: VIDDAY_PACKAGE.COMPRESS_REQUESTED,
    payload:uuid,
})

export {
    compressViddayPackage,
}





