const initialState = {
	status: {
		isFetching: false,
		error: false,
		message: '',
	},
}

export const combineFormState = (actionType = undefined, reducer = {}) => {
	let result = reducer

	/** Combine initial state */
	result.initialState = Object.assign(reducer.initialState || {}, initialState)

	/** Combine reducers */
	result.reducers = Object.assign(reducer.reducers || {}, {
		reset: () => result.initialState,
	})

	/** combine extraReducers */
	result.extraReducers = Object.assign(reducer.extraReducers || {}, {
		[actionType + '_REQUEST']: (state) => {
			state.status.isFetching = true
		},
		[actionType + '_SUCCESS']: (state, action) => {
			state.status.isFetching = false
			state.status.error = false
			state.status.message = action?.payload?.message || initialState?.status?.message
			// state.status.message = (action.payload && action.payload.message) || initialState.status.message || ''
			// Additional Data
			state.status = { ...state.status, ...action.payload }
		},
		[actionType + '_FAILURE']: (state, action) => {
			state.status.isFetching = false
			state.status.error = true
			// state.status.message = action.payload.message
			state.status.message = (action.payload && action.payload.message) || ''
			// Additional Data
			state.status = { ...state.status, ...action.payload }
		},
	})

	return result
}
