const PROMOTIONS = {
    /** Fetch all promotions (includes filters) */
    FETCH_REQUESTED: 'PROMOTIONS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'PROMOTIONS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'PROMOTIONS_FETCH_FAILED',

    /** Post a promotion */
    POST_REQUESTED: 'PROMOTION_POST_REQUESTED',
    POST_SUCCEEDED:'PROMOTION_POST_SUCCEEDED',
    POST_FAILED: 'PROMOTION_POST_FAILED',

    /** Update a promotion */
    UPDATE_REQUESTED: 'PROMOTION_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'PROMOTION_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'PROMOTION_UPDATE_FAILED',
    
    /** Delete a promotion */
    DELETE_REQUESTED: 'PROMOTION_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'PROMOTION_DELETE_SUCCEEDED',
    DELETE_FAILED: 'PROMOTION_DELETE_FAILED',
}

export {PROMOTIONS}