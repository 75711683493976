import React, { useState, useEffect } from 'react'
import { Container, BottomPanel, RightPanel } from '../../../../interface'
import { Divider, Flex, Box, Heading, Text, HStack, Icon, Button, useDisclosure, SimpleGrid } from '@chakra-ui/react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { Routes, Route, useNavigate } from 'react-router-dom'

import tools from '../data'

import MergeAccounts from '../src/MergeAccounts'
import MergeEvents from '../src/MergeEvents'
import UnsubscribeUser from '../src/UnsubscribeUser'
import ChangeEventCreator from '../src/ChangeEventCreator'
import TransferEvent from '../src/TransferEvent'

const toolStyles = {
	borderColor: 'transparent',
	borderWidth: '1px',
	borderStyle: 'solid',
	p: '1rem',
	borderRadius: 'md',
	_hover: {
		cursor: 'pointer',
		borderColor: 'blue.500',
		backgroundColor: 'blue.50',
	},
}

const Tool = ({ icon, title, description, target }) => {
	const navigate = useNavigate()

	return (
		<Box sx={toolStyles} onClick={() => navigate(target)}>
			<Icon as={icon} />
			<Heading as="h3" size="md">
				{title}
			</Heading>
			<Text>{description}</Text>
		</Box>
	)
}

const ListView = (props) => {
	return (
		<Container>
			<Flex w="full" py="1rem" justifyContent="space-between">
				<Heading variant="hero" size="lg" as="h1">
					Tools
				</Heading>
			</Flex>

			<Routes>
				<Route
					index
					element={
						<Box pb="1rem">
							<SimpleGrid columns={[1, 1, 2, 2, 4]} spacing="2rem">
								{tools.map((el, i) => (
									<Tool {...el} key={i} />
								))}
							</SimpleGrid>
						</Box>
					}
				/>
				<Route path="event-creator" element={<ChangeEventCreator />} />
				<Route path="merge-accounts" element={<MergeAccounts />} />
				<Route path="merge-events" element={<MergeEvents />} />
				<Route path="unsubscribe-user" element={<UnsubscribeUser />} />
				<Route path="transfer-event" element={<TransferEvent />} />
			</Routes>
		</Container>
	)
}

export default ListView

// import React, { Component } from 'react'
// import styled from 'styled-components'
// // Components/Views
// import MergeAccounts from './forms/MergeAccounts'
// import UnsubscribeForm from './forms/UnsubscribeForm'
// import MergeEvents from './forms/MergeEvents'
// import ChangeEventCreator from './forms/ChangeEventCreator'
// import DuplicatePublishedMedia from './forms/DuplicatePublishedMedia'
// // STYLED COMPONENTS
// const StyledSection = styled.div`
// 	display: block;
// 	margin: 32px 0;
// 	border-bottom: 1px solid #edeef1;
// `
// class ManagementTools extends Component {
// 	constructor(props) {
// 		super(props)
// 		this.state = {}
// 	}
// 	render() {
// 		const { dispatch } = this.props
// 		return (
// 			<>
// 				<StyledSection>
// 					<MergeAccounts dispatch={dispatch} />
// 				</StyledSection>
// 				<StyledSection>
// 					<MergeEvents dispatch={dispatch} />
// 				</StyledSection>
// 				<StyledSection>
// 					<ChangeEventCreator dispatch={dispatch} />
// 				</StyledSection>
// 				<StyledSection>
// 					<DuplicatePublishedMedia dispatch={dispatch} />
// 				</StyledSection>
// 				<StyledSection>
// 					<UnsubscribeForm dispatch={dispatch} />
// 				</StyledSection>
// 			</>
// 		)
// 	}
// }
// export default ManagementTools
