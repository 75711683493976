/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { loadUserEntities } from '@bit/vidday.vidday-store.modules.auth'
import AuthProvider, { useAuthContext } from '../contexts/AuthProvider'
import AdminProtectedRoute from '../routes/AdminProtectedRoute'
import { Router as AuthRouter } from '../apps/auth'
import AppLayout from './AppLayout'
// import useAuthedUser from '../hooks/useAuthedUser'
import useApps from '../core/hooks/useApps'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

// import AppLayout from './AppLayout'
// import {Routes as AuthRoutes} from '../apps/auth'
import instances from '../apps'
// import ProtectedRoute from '../views/utils/ProtectedRoute'
// import AdminOnly from '../views/utils/AdminOnly'

const UnAuthorized = () => <h1>Not authorized. Waiting...</h1>
/**
 * Root layout Container
 * @param {*} props
 */

const RootLayout = (props) => {
	/** Register applications into redux */
	const { registerApplications } = useApps()

	/** Retrieve Authenticated user */
	// const authedUser = useAuthedUser()
	// const { isAuthenticated, user } = authedUser
	/** Dispatch hook */
	const dispatch = useDispatch()
	const { isAuthenticated, user } = useAuthContext()

	/** Load entities if we have a user when component mount */
	useEffect(() => {
		if (isAuthenticated && user && user?.id !== undefined && user?.id !== null) {
			dispatch(loadUserEntities(user?.id))
		}
	}, [isAuthenticated])

	useEffect(() => {
		registerApplications(instances)
	}, [])

	console.log('isAuthenticated', isAuthenticated, user, 'RootLayout')
	return (
		<Routes>
			<Route path="auth/*" element={<AuthRouter />} />

			<Route
				path="*"
				element={
					<AdminProtectedRoute>
						<AppLayout />
					</AdminProtectedRoute>
				}
			/>
		</Routes>
	)
	// <Switch>
	// 		<Route path="/auth" component={AuthRoutes} />
	// 		<ProtectedRoute
	// 			{...props}
	// 			authedUser={authedUser}
	// 			path="*"
	// 			render={(p) => (
	// 				<AdminOnly
	// 					{...props}
	// 					{...p}
	// 					unauthorizedComponent={UnAuthorized}
	// 					authorizedComponent={AppLayout}
	// 				/>
	// 			)}
	// 		/>
	// 		{/* <Route path="*" component={AppLayout} /> */}
	// 	</Switch>
}

export default RootLayout
