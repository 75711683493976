import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { adminUserListSchema } from '@bit/vidday.vidday-admin-store.middlewares.normalizr'
import requests from './requests'
import { USER_PROFILES } from './constants'


/**
 * Saga worker
 * Update user profile
 * @param {*} action 
 */
 function* handleUpdateUserProfile(action) {
    try {
        const response = yield call(requests.updateUserProfile, action.payload)
        console.log(response.data, "response user profile");
        // const normalizedData = normalize([response.data.data], adminUserListSchema)
        yield put({ type: USER_PROFILES.UPDATE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: USER_PROFILES.UPDATE_FAILED, payload:e })
    }
}

export function* watchUpdateUserProfile(){
    // while(true){
        yield takeEvery(USER_PROFILES.UPDATE_REQUESTED, handleUpdateUserProfile)
    // }
}