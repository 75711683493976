import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../../core'
import UnderConstruction from '../../../../views/utils/UnderConstruction'
import ListView from './List'

/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.internationalization.currencies',
	title: 'Currencies',
	path: '/core/international/currencies',
	parent: 'core.internationalization',
})

export const currenciesSlice = buildEngineSlice({
	sliceName: 'currencies',
	actionPrefix: 'CURRENCIES',
	initialState: {
		pageSize: 20,
		sortBy: 'code',
	},
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
