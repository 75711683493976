import React, { useState, useEffect } from 'react'
import TimeColumn from './TimeColumn'
import { MeridiemColumn } from './TimeColumn'
import { createUseStyles } from 'react-jss'

// type TimeitProps = {
//   onChange?: (value: string) => any;
//   defaultValue?: string;
//   minuteExclude?: Array<number>;
//   hourExclude?: Array<number>;
//   notShowExclude?: boolean;
// };

const useStyles = createUseStyles(
	{
		wrapper: {
			'display': 'flex',
			'direction': 'ltr',
			'justifyContent': 'center',
			'&>*': {
				margin: '0 5px',
			},
			'&>:last-child': {
				marginRight: '0',
			},
			'&>:first-child': {
				marginLeft: '0',
			},
		},
	},
	{
		name: 'timeit',
	}
)

const Timeit = ({ onChange, defaultValue, useMeridiem, minuteExclude, hourExclude, notShowExclude }) => {
	//: TimeitProps
	const classes = useStyles()

	const [hour, setHour] = useState(
		defaultValue
			? parseInt(defaultValue.split(':')[0]) > 12
				? (parseInt(defaultValue.split(':')[0]) - 12).toString()
				: defaultValue.split(':')[0]
			: '00'
	)
	const [minute, setMinute] = useState(defaultValue ? defaultValue.split(':')[1] : '00')

	const determineMeridiem = (val) => {
		let m = parseInt(val) >= 12 ? 'PM' : 'AM'
		return m
	}

	const [meridiem, setMeridiem] = useState(defaultValue ? determineMeridiem(defaultValue.split(':')[0]) : 'PM') // convert first 2 string caracter to number

	// console.log(
	// 	defaultValue,
	// 	'default value',
	// 	meridiem,
	// 	'meridiem',
	// 	defaultValue.split(':')[0],
	// 	parseInt(defaultValue.split(':')[0])
	// )
	useEffect(() => {
		onChange && onChange(`${hour}:${minute} ${meridiem}`)
	}, [hour, minute, meridiem])

	return (
		<div className={classes.wrapper}>
			<TimeColumn
				notShowExclude={notShowExclude}
				start={1}
				end={12}
				value={hour}
				setValue={setHour}
				exclude={hourExclude}
			/>
			<TimeColumn
				notShowExclude={notShowExclude}
				start={0}
				end={59}
				value={minute}
				setValue={setMinute}
				exclude={minuteExclude}
			/>
			<MeridiemColumn
				notShowExclude={notShowExclude}
				start="AM"
				end="PM"
				value="PM"
				setValue={setMeridiem}
				exclude={minuteExclude}
			/>
		</div>
	)
}

export default Timeit
