/* **************************************
 *  LIBRARIES
 * ************************************ */

import { createSlice } from '@reduxjs/toolkit'
import * as types from '@bit/vidday.vidday-store.constants'


const initialState = {
	// success: null,
	isRegistering: false,
	email: null,
	account: null,
	isFetchingData: null,
}

const temporaryUserSlice = createSlice({
	initialState: initialState,
	name: 'auth.temporaryUser',
	extraReducers: {
        [types.CHECK_ACCOUNT_EMAIL_REQUEST]: (state, action) => {
            state.email = action.payload
            state.isFetchingData = true
        },

        [types.CHECK_ACCOUNT_EMAIL_SUCCESS]: (state, action) => {
            // state.success = action.payload.success || null
			state.message = action.payload.message || null
			state.account = action.payload.data || null
			state.isFetchingData = false
        },
        [types.CHECK_ACCOUNT_EMAIL_FAILURE]: (state) => {
            // state.success = null
			state.message = null
			state.account = null
			state.isFetchingData = false
        },
        [types.RESET_TEMPORARY_USER]: () => initialState,
       
    },
    reducers:{},
})


export default temporaryUserSlice.reducer
export const temporaryUserState = initialState
