/* **************************************
 *  LIBRARIES
 * ************************************ */

/* eslint-disable no-console */
import { createSlice, createAction } from '@reduxjs/toolkit'

import * as types from '@bit/vidday.vidday-store.constants'
import { makeActionCreator } from '@bit/vidday.vidday-helpers.utils.make-action-creator'

/* **************************************
 *  INITIAL STATE
 * ************************************ */

const initialState = {
	/** Is the user authenticated */
	isAuthenticated: false,
	/** Is the user authenticating */
	isAuthenticating: false,
	/** Request status text */
	statusText: null,
	/** Request status type */
	statusType: null,
	/** Is the token valid? */
	isValidToken: null,
	/** Is activation required? */
	isActivationRequired: false,

	role: null,
	/** User id */
	userId: null,
	/** User information */
	user: null,
	/** User profile */
	profile: null,
}

const authSlice = createSlice({
	initialState: initialState,
	name: 'auth',
	extraReducers: {
		/** Set the user profile upon success */
		[types.LOAD_PROFILE_SUCCESS]: (state, action) => {
			state.profile = action.payload?.data
		},

		/** Update the user profile upon success */
		[types.UPDATE_PROFILE_SUCCESS]: (state, action) => {
			state.profile = action.payload.data
		},

		/** Set new token received as invalid */
		[types.ACTIVATE_ACCOUNT_FAILURE]: (state, action) => {
			state.isValidToken = false
		},

		/** Set new authenticated user */
		[types.SET_AUTHENTICATED_USER]: (state, action) => {
			state.userId = action.user.id
			state.user = action.user
			state.role = action.user.role
			state.isAuthenticated = true
			state.isAuthenticating = false
		},

		/** Update state upon successful account activation */
		[types.ACTIVATE_ACCOUNT_SUCCESS]: (state, action) => {
			state.statusText = action.message || 'authenticated user set'
			state.statusType = 'success'
			state.isValidToken = true
		},

		/** Update state upon requestion to login */
		[types.LOGIN_USER_REQUEST]: (state, action) => {
			state.isAuthenticating = true
			state.statusText = null
		},

		/** Update state upon failure to signin */
		[types.LOGIN_USER_FAILURE]: (state, action) => {
			state.isAuthenticating = false
			state.isAuthenticated = false
			state.user = initialState.user
			state.role = null
			state.profile = initialState.profile
			state.userId = initialState.userId
			state.statusText = action.payload.error
			state.statusType = 'error'
			state.isActivationRequired = action.payload.activationRequired
		},

		/** static response */
		['RESET_ACTIVATION_REQUIRED_VARIABLE']: (state, action) => {
			state.isActivationRequired = action.payload.activationRequired
		},

		/** Clear state upon successful logout */
		[types.LOGOUT_SUCCESS]: (state, action) => {
			state = Object.assign({}, initialState)
		},
	},
	reducers: {},
})

export default authSlice.reducer
export const authState = initialState
// Export our actions
// export const { clearEvent, setModalOpen, setShowMediaViewer } = authSlice.actions
