import React from 'react'
import ReorderIcon from '@mui/icons-material/Reorder'
import ViewListIcon from '@mui/icons-material/ViewList'
import ViewComfyIcon from '@mui/icons-material/ViewComfy'
import { HStack, Text, chakra, Icon, useMultiStyleConfig } from '@chakra-ui/react'
import styles from '../../../../ui/theme/styles'

const displays = [
	{ id: 'table', icon: ReorderIcon },
	{ id: 'inline', icon: ViewListIcon, disabled: true },
	{ id: 'gallery', icon: ViewComfyIcon, disabled: true },
]

const disabledLinkStyles = {
	cursor: 'not-allowed',
	svg: {
		color: '#B5BCC3',
	},
	_hover: {
		svg: {
			color: '#B5BCC3',
		},
	},
}

const FilterDisplayMode = ({ current }) => {
	const styles = useMultiStyleConfig('Filter')

	return (
		<HStack sx={styles.container}>
			<Text sx={styles.label}>Display</Text>
			<HStack spacing="0.25rem" sx={styles.list}>
				{displays.map((el, i) => {
					const _styles = el.id == current ? styles.activeElement : styles.element
					const disabled = el.disabled ? disabledLinkStyles : {}
					return (
						<chakra.a sx={{ ..._styles, ...disabled }}>
							<Icon as={el.icon} />
						</chakra.a>
					)
				})}
			</HStack>
		</HStack>
	)
}

export default FilterDisplayMode
