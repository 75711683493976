import { PROMOTIONS } from './constants'

const fetchPromotions = (params) => ({
    type: PROMOTIONS.FETCH_REQUESTED,
    payload:params,
})

const setPromotions = (promotions) => ({
    type: PROMOTIONS.FETCH_SUCCEEDED,
    payload:promotions,
})

const setError = (error) => ({
    type: PROMOTIONS.FETCH_FAILED,
    payload:error,
})

const postPromotion = (data) => ({
    type: PROMOTIONS.POST_REQUESTED,
    payload:data,
})

const updatePromotion = (data) => ({
    type: PROMOTIONS.UPDATE_REQUESTED,
    payload:data,
})

const deletePromotion = (id) => ({
    type: PROMOTIONS.DELETE_REQUESTED,
    payload:id,
})

export {
    fetchPromotions,
    setPromotions,
    setError,
    postPromotion,
    updatePromotion,
    deletePromotion,
}





