
import { normalize, schema } from 'normalizr'


const productSchema = new schema.Entity(
	'products',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

// Represent all related data coming through the fetch request when loading the user

const productListSchema = new schema.Array(productSchema)

export {productListSchema}