import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { themeCategoryListSchema, themeCategorySchema } from './normalizr'
import requests from './requests'
import { THEME_CATEGORIES } from './constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action 
 */
function* handleFetchThemeCategories(action) {
    try {
        const response = yield call(requests.fetchThemeCategories, action.payload)
        const normalizedData = normalize(response.data.data, themeCategoryListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: THEME_CATEGORIES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: THEME_CATEGORIES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a themeCategory
 * @param {*} action 
 */
 function* handlePostThemeCategory(action) {
    try {
        const response = yield call(requests.postThemeCategory, action.payload)
        const normalizedData = normalize([response.data.data], themeCategoryListSchema)
        yield put({ type: THEME_CATEGORIES.POST_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: THEME_CATEGORIES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a themeCategory
 * @param {*} action 
 */
 function* handleUpdateThemeCategory(action) {
    try {
        const response = yield call(requests.updateThemeCategory, action.payload)
        const normalizedData = normalize([response.data.data], themeCategoryListSchema)
        yield put({ type: THEME_CATEGORIES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: THEME_CATEGORIES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a themeCategory
 * @param {*} action 
 */
 function* handleDeleteThemeCategory(action) {
    try {
        const response = yield call(requests.deleteThemeCategory, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: THEME_CATEGORIES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: THEME_CATEGORIES.DELETE_FAILED, payload:e })
    }
}


/**
 * Theme Versions Saga Watcher
 */
export function* watchThemeCategoriesFetch(){
    // while(true){
        yield takeLatest(THEME_CATEGORIES.FETCH_REQUESTED, handleFetchThemeCategories)
    // }
}

export function* watchUpdateThemeCategory(){
    // while(true){
        yield takeEvery(THEME_CATEGORIES.UPDATE_REQUESTED, handleUpdateThemeCategory)
    // }
}

export function* watchPostThemeCategory(){
    // while(true){
        yield takeEvery(THEME_CATEGORIES.POST_REQUESTED, handlePostThemeCategory)
    // }
}

export function* watchDeleteThemeCategory(){
    // while(true){  
        yield takeEvery(THEME_CATEGORIES.DELETE_REQUESTED, handleDeleteThemeCategory)
    // }
}