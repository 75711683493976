const COUNTRIES = {
    /** Fetch all countries (includes filters) */
    FETCH_REQUESTED: 'COUNTRIES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'COUNTRIES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'COUNTRIES_FETCH_FAILED',

    /** Post a country */
    POST_REQUESTED: 'COUNTRY_POST_REQUESTED',
    POST_SUCCEEDED:'COUNTRY_POST_SUCCEEDED',
    POST_FAILED: 'COUNTRY_POST_FAILED',

    /** Update a country */
    UPDATE_REQUESTED: 'COUNTRY_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'COUNTRY_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'COUNTRY_UPDATE_FAILED',

    /** Delete a country */
    DELETE_REQUESTED: 'COUNTRY_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'COUNTRY_DELETE_SUCCEEDED',
    DELETE_FAILED: 'COUNTRY_DELETE_FAILED',
}

export {COUNTRIES}