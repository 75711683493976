import React from 'react'

import { StyledButton } from './styles'
import GetAppIcon from '@mui/icons-material/GetApp'
const ButtonExport = () => {
	return (
		<StyledButton>
			<GetAppIcon />
		</StyledButton>
	)
}

export default ButtonExport
