

import { normalize, schema } from 'normalizr'

const eventMediaSchema = new schema.Entity(
	'media',
	{},
	{
		idAttribute: (media) => media,
	}
)

const recipientSchema = new schema.Entity(
	'recipients',
	{},
	{
		idAttribute: (recipient) => recipient.id,
	}
)

const invoiceSchema = new schema.Entity(
	'invoices',
	{},
	{
		idAttribute: (invoice) => invoice.id,
	}
)


const eventSchema = new schema.Entity(
	'events',
	{
		invoices: [invoiceSchema],
		recipients: [recipientSchema],
		medias: [eventMediaSchema],
	},
	{
		idAttribute: (eventObject) => eventObject.id,
	}
)
const eventListSchema = new schema.Array(eventSchema)

export {eventListSchema}