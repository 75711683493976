import React from 'react'
import Core from '../../../core'
import { Link, Route, Routes } from 'react-router-dom'
import { instance as users } from './users'
import { instance as profiles } from './profiles'
import UnderConstruction from '../../../views/utils/UnderConstruction'
import { Router as UsersRouter } from './users'
/**
 * Define Module
 */
const accounts = Core.defineModule({
	name: 'core.accounts',
	title: 'Accounts',
	path: '/core/accounts',
	parent: 'core',
})

export const instance = Core.combineApps(accounts, [users, profiles])

/**
 * Routes of the Home App module
 */
export const Router = () => {
	const { path } = instance

	return (
		<Routes>
			<Route path="users/*" element={<UsersRouter />} />
		</Routes>
	)
}
