import styled from 'styled-components'


export const StyledBaseButton = styled.a`
    display:inline-flex;
    background-color:#F2F6FA;
    border-radius:5px;
    height:29px;
    padding-top: 4px;
    padding-left:5px;
    padding-right:5px;
    position:relative;
    margin-right:5px;
    position:relative;
    line-height:12px;

    &:hover {
        cursor:pointer;
        color:#0187FF;
        > svg {
            color:#0187FF;
        }
       
    }
    
    
`;