import { createSlice } from '@reduxjs/toolkit'

import { EVENTS } from './constants'
import { MEDIA } from '../../media/api/constants'

const initialState = {
	data: null,
	ids: [],
	isFetching: false,
	hasError: false,
	errorMessage: '',
	/** Publishing */
	publishing: {},
	/** Packaging */
	packaging: {},
	/** Media */
	medias: {},
	/** Recipients */
	recipients: {},
}

const eventsSlice = createSlice({
	initialState: initialState,
	name: 'events',
	extraReducers: {
		/**
		 * Events
		 */
		[EVENTS.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[EVENTS.FETCH_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities) {
				state.data = action.payload.entities.events || {} // if no result, set as empty object
				state.medias = action.payload.entities.medias || {} // if no result, set as empty object
				state.recipients = action.payload.entities.recipients || {} // if no result, set as empty object
				state.ids = action.payload.result
				state.isFetching = false
				state.hasError = false
			}
		},
		[EVENTS.FETCH_FAILED]: (state, action) => {
			state.hasError = true
			state.isFetching = false
			state.errorMessage = 'An error occured while fetching.'
			state.medias = {}
			state.recipients = {}
		},

		/**
		 * publishing
		 */
		[MEDIA.UPLOAD_PUBLISHED_REQUESTED]: (state, action) => {
			state.publishing.isLoading = true
			state.publishing.showProgress = true
			state.publishing.percentage = 0
		},

		[MEDIA.UPLOAD_PUBLISHED_SUCCEEDED]: (state, action) => {
			if (action.payload.percentage) {
				state.publishing.isLoading = false
				state.publishing.showProgress = false
				state.publishing.percentage = 0
				state.publishing.editMode = false
				state.publishing.data = action.payload.data
			}
		},

		[MEDIA.UPLOAD_PUBLISHED_FAILED]: (state, action) => {
			if (action.payload.percentage) {
				state.publishing.isLoading = false
				state.publishing.showProgress = false
				state.publishing.percentage = 0
				state.publishing.statusType = action.payload.statusType
				state.publishing.statusMessage = action.payload.message
			}
		},

		[MEDIA.UPLOAD_PUBLISHED_PROGRESS]: (state, action) => {
			state.publishing.isLoading = true
			state.publishing.showProgress = action.payload.percentage == 100 ? false : true
			state.publishing.percentage = action.payload.percentage
		},

		/**
		 * packaging
		 */
		[MEDIA.COMPRESS_VIDDAY_PACKAGE_REQUESTED]: (state, action) => {
			state.packaging.isLoading = true
			state.packaging.showProgress = true
		},

		[MEDIA.COMPRESS_VIDDAY_PACKAGE_SUCCEEDED]: (state, action) => {
			if (action.payload.percentage) {
				state.packaging.isLoading = false
				state.packaging.showProgress = false
			}
		},
		[MEDIA.COMPRESS_VIDDAY_PACKAGE_FAILED]: (state, action) => {
			if (action.payload.percentage) {
				state.packaging.isLoading = false
				state.packaging.showProgress = false
			}
		},
	},
	reducers: {},
})

// const eventsSlice = createSlice({
//     initialState: initialState,
//     name:'events',
//     extraReducers: {
//         /**
//          * Events
//          */
//         [EVENTS.FETCH_REQUESTED]: (state, action) => {
//             state.isFetching = true
//         },
//         [EVENTS.FETCH_SUCCEEDED]: (state, action) => {
//             if(action.payload && action.payload.entities && action.payload.entities.events){
//                 state.data = action.payload.entities.events
//                 state.ids = action.payload.result
//                 state.isFetching = false
//                 state.hasError = false
//                 /** Fetch sub-entities */
//                 state.media = action.payload.entities.medias
//                 state.recipients = action.payload.entities.recipients
//             }
//         },
//         [EVENTS.FETCH_FAILED]: (state, action) => {
//             state.hasError = true
//             state.isFetching = false
//             state.errorMessage = 'An error occured while fetching.'
//         },

//         /**
//          * publishing
//          */
//         [MEDIA.UPLOAD_PUBLISHED_REQUESTED]: (state, action) => {
//             state.publishing.isLoading = true;
//             state.publishing.showProgress = true;
//             state.publishing.percentage = 0;
//         },

//         [MEDIA.UPLOAD_PUBLISHED_SUCCEEDED]: (state, action) => {
//             if (action.payload.percentage) {
//                 state.publishing.isLoading = false;
//                 state.publishing.showProgress = false;
//                 state.publishing.percentage = 0;
//                 state.publishing.editMode = false;
// 				state.publishing.data = action.payload.data;
//             }
//         },

//         [MEDIA.UPLOAD_PUBLISHED_FAILED]: (state, action) => {
//             if (action.payload.percentage) {
//                 state.publishing.isLoading = false;
//                 state.publishing.showProgress = false;
//                 state.publishing.percentage = 0;
//                 state.publishing.statusType = action.payload.statusType;
//                 state.publishing.statusMessage = action.payload.message;
//             }
//         },

//         [MEDIA.UPLOAD_PUBLISHED_PROGRESS]: (state, action) => {
//             state.publishing.isLoading = true;
//             state.publishing.showProgress = action.payload.percentage == 100 ? false : true;
//             state.publishing.percentage = action.payload.percentage;
//         },

//         /**
//          * packaging
//          */
//         [MEDIA.COMPRESS_VIDDAY_PACKAGE_REQUESTED]: (state, action) => {
//             state.packaging.isLoading = true;
//             state.packaging.showProgress = true;
//         },

//         [MEDIA.COMPRESS_VIDDAY_PACKAGE_SUCCEEDED]: (state, action) => {
//             if (action.payload.percentage) {
//                 state.packaging.isLoading = false;
//                 state.packaging.showProgress = false;
//             }
//         },
//         [MEDIA.COMPRESS_VIDDAY_PACKAGE_FAILED]: (state, action) => {
//             if (action.payload.percentage) {
//                 state.packaging.isLoading = false;
//                 state.packaging.showProgress = false;
//             }
//         },

//         /**
//          * Media
//          */

//     },
//     reducers:{},
// })

// const publishing = createSlice({
//     initialState: initialState,
//     name:'publishing',
//     extraReducers: {
//         // [EVENTS.FETCH_REQUESTED]: (state, action) => {
//         //     state.isFetching = true
//         // },
//         // [EVENTS.FETCH_SUCCEEDED]: (state, action) => {
//         //     if(action.payload && action.payload.entities && action.payload.entities.events){
//         //         state.data = action.payload.entities.events
//         //         state.ids = action.payload.result
//         //         state.isFetching = false
//         //         state.hasError = false
//         //     }
//         // },
//         // [EVENTS.FETCH_FAILED]: (state, action) => {
//         //     state.hasError = true
//         //     state.isFetching = false
//         //     state.errorMessage = 'An error occured while fetching.'
//         // },
//     },
//     reducers:{},
// })

export default eventsSlice.reducer
