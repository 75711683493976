// eslint-disable-next-line no-unused-vars
import { all, fork } from 'redux-saga/effects'
/** Import our users saga */
import * as usersSagas from '../apps/core/accounts/users/api/sagas'
import * as userProfilesSagas from '../apps/core/accounts/profiles/api/sagas'
import * as announcementsSagas from '../apps/core/announcements/api/sagas'
import * as promotionsSagas from '../apps/core/promotions/api/sagas'
import * as toolsSagas from '../apps/core/tools/api/sagas'

import * as countriesSagas from '../apps/core/internationalization/countries/api/sagas'
import * as regionsSagas from '../apps/core/internationalization/regions/api/sagas'
import * as currenciesSagas from '../apps/core/internationalization/currencies/api/sagas'
import * as languagesSagas from '../apps/core/internationalization/languages/api/sagas'

import * as themesSagas from '../apps/vidday.app/themes/api/sagas'
import * as themeVersionsSagas from '../apps/vidday.app/themeVersions/api/sagas'
import * as themeCategoriesSagas from '../apps/vidday.app/themeCategories/api/sagas'
import * as nexusSagas from '../apps/core/billing/nexus/api/sagas'
import * as invoicesSagas from '../apps/core/billing/invoices/api/sagas'
import * as eventsSagas from '../apps/vidday.app/events/api/sagas'
import * as renderJobsSagas from '../apps/vidday.app/eventRenderJobs/api/sagas'
import * as mailSagas from '../api/mail/sagas'
import * as viddayPackageSagas from '../api/vidday-package/sagas'
import * as productsSagas from '../apps/core/sales/products/api/sagas'
import * as productPricesSagas from '../apps/core/sales/productPrices/api/sagas'
import * as taxCodeCategoriesSagas from '../apps/core/sales/taxCodeCategories/api/sagas'
import * as taxCodeRatesSagas from '../apps/core/sales/taxCodeRates/api/sagas'

export default function* rootSaga() {
	yield all(
		[
			...Object.values(usersSagas),
			...Object.values(userProfilesSagas),
			...Object.values(countriesSagas),
			...Object.values(announcementsSagas),
			...Object.values(promotionsSagas),
			...Object.values(toolsSagas),
			...Object.values(regionsSagas),
			...Object.values(themesSagas),
			...Object.values(themeVersionsSagas),
			...Object.values(themeCategoriesSagas),
			...Object.values(nexusSagas),
			...Object.values(invoicesSagas),
			...Object.values(currenciesSagas),
			...Object.values(languagesSagas),
			...Object.values(eventsSagas),
			...Object.values(renderJobsSagas),
			...Object.values(mailSagas),
			...Object.values(viddayPackageSagas),
			...Object.values(productsSagas),
			...Object.values(productPricesSagas),
			...Object.values(taxCodeCategoriesSagas),
			...Object.values(taxCodeRatesSagas),
		].map(fork)
	)

	// yield all([
	// 	usersSaga(),
	// ])
}
