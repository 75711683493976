import { COUNTRIES } from './constants'

const fetchCountries = (params) => ({
    type: COUNTRIES.FETCH_REQUESTED,
    payload:params,
})

const setCountries = (countries) => ({
    type: COUNTRIES.FETCH_SUCCEEDED,
    payload:countries,
})
const setError = (error) => ({
    type: COUNTRIES.FETCH_FAILED,
    payload:error,
})

const postCountry = (data) => ({
    type: COUNTRIES.POST_REQUESTED,
    payload:data,
})

const updateCountry = (data) => ({
    type: COUNTRIES.UPDATE_REQUESTED,
    payload:data,
})

export {
    fetchCountries,
    setCountries,
    setError,
    postCountry,
    updateCountry,
}





