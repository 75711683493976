import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../core'
import ListView from './List'

/**
 * Define Module
 */
export const instance = Core.defineModule({
	name: 'vidday.events',
	title: 'Events',
	path: '/vidday/events',
	parent: 'vidday',
})

export const eventsSlice = buildEngineSlice({
	sliceName: 'events',
	actionPrefix: 'EVENTS',
	initialState: {
		filters: {
			status: 'publishing',
		},
	},
})

/**
 * Routes of the Vidday App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
