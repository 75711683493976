
import { combineReducers } from 'redux'

/** Administration Area */
import usersReducer from '../../apps/core/accounts/users/api/entities'
import countriesReducer from '../../apps/core/internationalization/countries/api/entities'
import regionsReducer from '../../apps/core/internationalization/regions/api/entities'
import announcementsReducer from '../../apps/core/announcements/api/entities'
import promotionsReducer from '../../apps/core/promotions/api/entities'

import themesReducer from '../../apps/vidday.app/themes/api/entities'
import themeVersionsReducer from '../../apps/vidday.app/themeVersions/api/entities'
import themeCategoriesReducer from '../../apps/vidday.app/themeCategories/api/entities'
import nexusReducer from '../../apps/core/billing/nexus/api/entities'
import invoicesReducer from '../../apps/core/billing/invoices/api/entities'
import currenciesReducer from '../../apps/core/internationalization/currencies/api/entities'
import languagesReducer from '../../apps/core/internationalization/languages/api/entities'
import eventsReducer from '../../apps/vidday.app/events/api/entities'
import eventRenderJobsReducer from '../../apps/vidday.app/eventRenderJobs/api/entities'
import productsReducer from '../../apps/core/sales/products/api/entities'
import productPricesReducer from '../../apps/core/sales/productPrices/api/entities'
import taxCodeCategoriesReducer from '../../apps/core/sales/taxCodeCategories/api/entities'
import taxCodeRatesReducer from '../../apps/core/sales/taxCodeRates/api/entities'

/* **************************************
 *  BUILD FINAL APPLICATION STATES
 * ************************************ */

/** Administration Area */
const entitiesReducer = combineReducers({
	events: eventsReducer,
	eventRenderJobs:eventRenderJobsReducer,
	/** Core App */
	users: usersReducer,
	countries:countriesReducer,
	regions:regionsReducer,
	announcements: announcementsReducer,
	promotions: promotionsReducer,
	
	nexus: nexusReducer,
	// invoices....
	invoices:invoicesReducer,
	/** VidDay App */
	themes:themesReducer,
	themeVersions:themeVersionsReducer,
	themeCategories:themeCategoriesReducer,

	currencies: currenciesReducer,
	languages: languagesReducer,
	
	/** Sales Module */
	products: productsReducer,
	productPrices: productPricesReducer,
	taxCodeCategories: taxCodeCategoriesReducer,
	taxCodeRates: taxCodeRatesReducer,
	/** OLD */
	// events: adminEvents,
	// promotions: adminPromotions,
	// announcements: adminAnnouncements,
	// eventRenderJobs: reducerRenderJobs,
})

export default entitiesReducer;
