import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { StyledFilterDropdown } from './styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import EventIcon from '@mui/icons-material/Event'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'

// export const statuses = [
// 	{ value: '', label: 'All' },
// 	{ value: 'publishing', label: 'Publishing' },
// 	{ value: 'active', label: 'Active' },
// 	{ value: 'published', label: 'Published' },
// 	{ value: 'archived', label: 'Archived' },
// 	{ value: 'abandoned', label: 'Abandoned' },
// ]

const all = { value: undefined, label: 'All' }
/**
 * Filtering selector
 */
export const FilterDropdownV2 = ({ label, collection, filter }) => {
	const options = [all, ...collection]

	console.log('FilterDropdownV2', label, collection, filter)
	const {
		state: { filters },
		setFilter,
	} = useSearchEngineContext()

	/** Retrieve current value */
	const value = filters[filter]
	/** Retrieve item for "all" (default) */
	const emptyItem = options.find((el) => el.value === undefined)
	/** Retrieve active item */
	const activeItem = options.find((el) => el.value === value)
	/** What item should we use for the display value? */
	const current = activeItem ? activeItem : emptyItem

	/** Determine if we should show svg */
	const [hasSVG] = useState(options[1].svg ? true : false)

	/** Build collection of items */
	const items = options.map((el, i) => {
		/** Create an empty object that will be passed to our set filter method */
		const obj = {}
		/** Assign the filter key and corresponding value */
		obj[filter] = el.value
		/** Let's build our svg renderer */
		function createMarkup() {
			return { __html: el.svg }
		}
		console.log(el, 'el here')
		return (
			<li key={i}>
				<a
					href="#"
					onClick={() => {
						setFilter(obj)
					}}>
					{hasSVG ? (
						<div dangerouslySetInnerHTML={createMarkup()} />
					) : (
						<CheckBoxOutlineBlankIcon fontSize="small" />
					)}{' '}
					{el.label}
				</a>
			</li>
		)
	})

	return (
		<StyledFilterDropdown>
			<a>
				<KeyboardArrowDownIcon />
			</a>
			<span>{label}</span>
			<a className="current">{current.label}</a>
			<ul className={hasSVG ? 'occasions' : 'checkbox'}>{items}</ul>
		</StyledFilterDropdown>
	)
}

const FilterDropdown = ({
	useCheckbox = false,
	label = 'Status',
	useOccasions = false,
	collection = [],
	current = { value: '', label: 'Label' },
	onClick,
}) => {
	console.log(collection, 'collection')
	let itemsView

	if (useCheckbox) {
		const vals = collection.map((el, i) => (
			<li key={i}>
				<a
					href="#"
					onClick={() => {
						onClick(el.value)
						console.log('clicked!')
					}}>
					<CheckBoxOutlineBlankIcon fontSize="small" /> {el.label}
				</a>
			</li>
		))

		itemsView = <ul className="checkbox">{vals}</ul>
	} else if (useOccasions) {
		const vals = collection.map((el, i) => {
			function createMarkup() {
				return { __html: el.svg }
			}

			return (
				<li key={i}>
					<a href="#">
						{' '}
						<div dangerouslySetInnerHTML={createMarkup()} />
						{el.label}
					</a>
				</li>
			)
		})

		itemsView = <ul className="occasions">{vals}</ul>
	} else {
		const vals = collection.map((el, i) => (
			<li key={i}>
				<a href="#">{el.label}</a>
			</li>
		))

		itemsView = <ul>{vals}</ul>
	}

	return (
		<StyledFilterDropdown>
			<a>
				<KeyboardArrowDownIcon />
			</a>
			<span>{label}</span>
			<a className="current">{current.label}</a>

			{/* <ul>
                <li><a href="#">All</a></li>
                <li><a href="#">Publishing</a></li>
                <li><a href="#">Active</a></li>
                <li><a href="#">Published</a></li>
                <li><a href="#">Archived</a></li>
                <li><a href="#">Abandoned</a></li>
            </ul> */}

			{itemsView}
		</StyledFilterDropdown>
	)
}

export default FilterDropdown
