import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../core/widgets/form/input'
import Select from '../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../core/widgets/buttons/Button'
import { updatePromotion, postPromotion } from '../../api/actions'
import schemaPromotion, { typeOptions, isEnabledOptions } from './schema'

const Promotion = ({ data, onSubmitted }) => {
	const { timezone, expires, ends } = data
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaPromotion),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.id) {
			dispatch(updatePromotion(model))
		} else {
			dispatch(postPromotion(model))
			reset()
		}
		onSubmitted != undefined && onSubmitted()
	}

	/* ******************************
	 * Obtain available Timezones
	 * **************************** */

	let tzCollection = []
	Object.keys(timeZonesGeo).forEach(function (el) {
		tzCollection.push({ value: timeZonesGeo[el].name, name: timeZonesGeo[el].name })
	})

	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	useEffect(() => {
		// Set timezone automatically
		const autoTimezone = timezone ? timezone : moment.tz.guess(true)
		// Set deadline automatically
		const autoExpireDate = expires ? expires : moment().add(2, 'days')
		// Set initial values
		setValue('expires', autoExpireDate)
		setValue('timezone', autoTimezone)
	}, [timezone, expires])

	useEffect(() => {
		//console.log("FORM CHANGE",moment(deadline).format(),moment(deadline).tz(values.timezone).format())
		setValue('expires', moment(expires).tz(values.timezone))
	}, [values.timezone])

	/** Register uncontrolled components */
	useEffect(() => {
		register({ name: 'timezone' }, { required: true })
		register({ name: 'expires' }, { required: true })
	}, [register])

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		var formExpireDate =
			values?.expires != undefined && values?.expires != null && typeof values?.expires === 'object'
				? values.expires.toISOString()
				: null

		var changed = expires != formExpireDate || timezone != values.timezone
		setHasChange(changed)
	}, [values])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Promotion Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Input
					size="small"
					control={control}
					name="code"
					id="code"
					variant="filled"
					label="Code"
					errors={errors}
					disabled={data.id ? true : false}
				/>

				<Select
					control={control}
					margin="normal"
					name="isActive"
					id="isActive"
					variant="filled"
					label="Is Active"
					errors={errors}>
					{isEnabledOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					margin="normal"
					name="type"
					id="type"
					variant="filled"
					label="Type"
					errors={errors}>
					{typeOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="value"
					id="value"
					variant="filled"
					label="Value"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="maxCount"
					id="maxCount"
					variant="filled"
					label="Max Count"
					helperText="Leave blank for unlimited use"
					errors={errors}
				/>

				<Select
					control={control}
					margin="normal"
					name="timezone"
					label="Occasion Timezone"
					errors={errors}
					id="timezone"
					defaultValue={values.timezone || ''}>
					{tzCollection.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.name}
						</MenuItem>
					))}
				</Select>

				<DateTimePicker
					control={control}
					name="expires"
					id="expires"
					variant="filled"
					label="Expires On"
					errors={errors}
				/>
			</InlineItems>
		</form>
	)
}

Promotion.defaultProps = {
	/** Important */
	data: {},
}

Promotion.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default Promotion
