import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { productPriceListSchema } from './normalizr'
import requests from './requests'
import { PRODUCT_PRICES } from './constants'

/**
 * Users Saga worker
 * Fetch product prices
 * @param {*} action 
 */
function* handleFetchProductPrices(action) {
    try {
        const response = yield call(requests.fetchProductPrices, action.payload)
        const normalizedData = normalize(response.data.data, productPriceListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;

        yield put({ type: PRODUCT_PRICES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: PRODUCT_PRICES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a product price
 * @param {*} action 
 */
 function* handlePostProductPrice(action) {
    try {
        const response = yield call(requests.postProductPrice, action.payload)
        const normalizedData = normalize([response.data.data], productPriceListSchema)
        yield put({ type: PRODUCT_PRICES.POST_SUCCEEDED, payload:normalizedData})
    }
    catch(e){
        yield put({type: PRODUCT_PRICES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a product price
 * @param {*} action 
 */
 function* handleUpdateProductPrice(action) {
    try {
        const response = yield call(requests.updateProductPrice, action.payload)
        
        const normalizedData = normalize([response.data.data], productPriceListSchema)
        console.log(response.data)
        yield put({ type: PRODUCT_PRICES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: PRODUCT_PRICES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a product price
 * @param {*} action 
 */
function* handleDeleteProductPrice(action) {
    try {
        const response = yield call(requests.deleteProductPrice, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: PRODUCT_PRICES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: PRODUCT_PRICES.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchProductPricesFetch(){
    // while(true){
        yield takeLatest(PRODUCT_PRICES.FETCH_REQUESTED, handleFetchProductPrices)
    // }
}

export function* watchUpdateProductPrice(){
    // while(true){
        yield takeEvery(PRODUCT_PRICES.UPDATE_REQUESTED, handleUpdateProductPrice)
    // }
}

export function* watchPostProductPrice(){
    // while(true){
        yield takeEvery(PRODUCT_PRICES.POST_REQUESTED, handlePostProductPrice)
    // }
}

export function* watchDeleteProductPrice(){
    // while(true){  
        yield takeEvery(PRODUCT_PRICES.DELETE_REQUESTED, handleDeleteProductPrice)
    // }
}