import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { countryListSchema, countrySchema } from './normalizr'
import requests from './requests'
import { COUNTRIES } from './constants'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action 
 */
function* handleFetchCountries(action) {
    try {
        const response = yield call(requests.fetchCountries, action.payload)
        console.log("COUNTRIES:", response.data)
        const normalizedData = normalize(response.data.data, countryListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: COUNTRIES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: COUNTRIES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a country
 * @param {*} action 
 */
 function* handlePostCountry(action) {
    try {
        const response = yield call(requests.postCountry, action.payload)
        yield put({ type: COUNTRIES.POST_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: COUNTRIES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a country
 * @param {*} action 
 */
 function* handleUpdateCountry(action) {
    try {
        const response = yield call(requests.updateCountry, action.payload)
        
        const normalizedData = normalize([response.data.data], countryListSchema)
        console.log(response.data)
        yield put({ type: COUNTRIES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: COUNTRIES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a country
 * @param {*} action 
 */
 function* handleDeleteCountry(action) {
    try {
        const response = yield call(requests.deleteCountry, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: COUNTRIES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: COUNTRIES.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchCountriesFetch(){
    // while(true){
        yield takeLatest(COUNTRIES.FETCH_REQUESTED, handleFetchCountries)
    // }
}

export function* watchUpdateCountry(){
    // while(true){
        yield takeEvery(COUNTRIES.UPDATE_REQUESTED, handleUpdateCountry)
    // }
}

export function* watchPostCountry(){
    // while(true){
        yield takeEvery(COUNTRIES.POST_REQUESTED, handlePostCountry)
    // }
}

export function* watchDeleteCountry(){
    // while(true){  
        yield takeEvery(COUNTRIES.DELETE_REQUESTED, handleDeleteCountry)
    // }
}