const THEMES = {
    /** Fetch all themes (includes filters) */
    FETCH_REQUESTED: 'THEMES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'THEMES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'THEMES_FETCH_FAILED',

    /** Post a theme */
    POST_REQUESTED: 'THEME_POST_REQUESTED',
    POST_SUCCEEDED:'THEME_POST_SUCCEEDED',
    POST_FAILED: 'THEME_POST_FAILED',

    /** Update a theme */
    UPDATE_REQUESTED: 'THEME_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'THEME_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'THEME_UPDATE_FAILED',

    /** Delete a theme */
    DELETE_REQUESTED: 'THEME_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'THEME_DELETE_SUCCEEDED',
    DELETE_FAILED: 'THEME_DELETE_FAILED',
}



export {THEMES}