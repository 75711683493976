import React, { useState } from 'react'
import { Box, Text, Heading, chakra, Flex, Button } from '@chakra-ui/react'
import tools from '../../data'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import Input from '../../../../../ui/molecules/Input'
import InputEmail from '../../../../../ui/molecules/InputEmail'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import { transferEvent } from '../../api/actions'
import BackButton from '../molecules/BackButton'
const tool = tools.find((el) => el.id == 'transfer-event')

const TransferEvent = () => {
	const dispatch = useDispatch()

	const [submitted, setSubmitted] = useState(false)

	const { watch, control, register, handleSubmit, setError, clearErrors, errors, formState, reset } = useForm({
		defaultValues: {
			eventId: '',
			newOwnerEmail: '',
		},
		resolver: yupResolver(schema),
	})

	const onSubmit = (data) => {
		dispatch(transferEvent(data))
		// Clear form
		reset()
		// Let user know we've submitted the request.
		setSubmitted(true)
		// Reset the form so it can be submitted again after a while.
		setTimeout(() => {
			setSubmitted(false)
		}, 2000)
	}

	return (
		<Box w="full">
			<Box w="full" mb="1rem">
				<BackButton />
				<Heading size="md" as="h3">
					{tool.title}
				</Heading>
				<Text>{tool.description}</Text>
			</Box>

			<Box
				maxW="700px"
				mb="1rem"
				bg="gray.50"
				p="0.5rem"
				borderRadius="base"
				fontSize="0.875rem"
				color="gray.500">
				<Text>1) Enter event ID</Text>
				<Text>2) Enter email address of new owner of the event.</Text>
			</Box>

			<chakra.form w="full" maxW="300px" onSubmit={handleSubmit(onSubmit)} pb="1rem">
				<Input control={control} errors={errors} name="eventId" label="Event ID" placeholder="Enter event id" />

				<InputEmail
					control={control}
					errors={errors}
					name="newOwnerEmail"
					label="New owner email"
					placeholder="Enter new owner email"
				/>

				<Flex w="full" my="1rem" justifyContent="flex-start">
					<Button type="submit" variant="solid" minW="150px" isLoading={formState.isSubmitting}>
						Transfer Event
					</Button>
				</Flex>
			</chakra.form>
		</Box>
	)
}

export default TransferEvent
