
import { normalize, schema } from 'normalizr'


const productPriceSchema = new schema.Entity(
	'productPrices',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

// Represent all related data coming through the fetch request when loading the user

const productPriceListSchema = new schema.Array(productPriceSchema)

export {productPriceListSchema}