import { LANGUAGES } from './constants'

const fetchLanguages = (params) => ({
    type: LANGUAGES.FETCH_REQUESTED,
    payload:params,
})

const setLanguages = (promotions) => ({
    type: LANGUAGES.FETCH_SUCCEEDED,
    payload:promotions,
})

const setError = (error) => ({
    type: LANGUAGES.FETCH_FAILED,
    payload:error,
})

const postLanguage = (data) => ({
    type: LANGUAGES.POST_REQUESTED,
    payload:data,
})

const updateLanguage = (data) => ({
    type: LANGUAGES.UPDATE_REQUESTED,
    payload:data,
})

const deleteLanguage = (code) => ({
    type: LANGUAGES.DELETE_REQUESTED,
    payload:code,
})

export {
    fetchLanguages,
    setLanguages,
    setError,
    postLanguage,
    updateLanguage,
    deleteLanguage,
}





