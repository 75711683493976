import styled, {css} from 'styled-components' 



export const _baseActionButtonStyles = css`
    font-size:14px;
    text-align:center;
    background-color:#F2F6FA;
    padding: 5px 10px;
    display:inline-flex;
    height:28px;
    border-radius:5px;
    background-color:#0187FF;
    color:white;
    font-weight:500;
    margin-right:5px;
    position:relative;
    border:1px solid #0187FF;
    &:hover {
        -moz-box-shadow:    inset 0 0 5px rgba(0,97,183, 0.8);
        -webkit-box-shadow: inset 0 0 5px rgba(0,97,183, 0.8);
        box-shadow:         inset 0 0 5px rgba(0,97,183, 0.8);
    }
    ${(props) => props.revert && css`
        background-color:white;
        border:1px solid #0187FF;
        color:#0187FF;

        &:hover {
            background-color:rgba(1,135,255, 0.1);
            color:#0187FF;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none; 
        }
    `}

    ${(props) => props.delete && css`
        background-color:white;
        border:1px solid #FF1C63;
        color:#FF1C63;

        svg {
            color:#FF1C63;
        }
        &:hover {
            background-color:rgba(255,28,99, 0.1);
            color:#FF1C63;
            -moz-box-shadow:    inset 0 0 5px rgba(255,28,99, 0.2);
            -webkit-box-shadow: inset 0 0 5px rgba(255,28,99, 0.2);
            box-shadow:         inset 0 0 5px rgba(255,28,99, 0.2);
        }
    `}
`

export const StyledButton = styled.button`
    ${_baseActionButtonStyles};
`

