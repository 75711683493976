// import { red } from '@mui/material/colors'
import { mode } from '@chakra-ui/theme-tools'

const Button = {
	baseStyle: {
		borderRadius: 'full',
		fontWeight: 'semibold', // Normally, it is "semibold"
	},
	sizes: {
		xlg: {
			fontSize: '19px',
			h: 16,
			minW: 16,
			px: 8,
		},
		lg: {
			fontSize: '18px',
			h: '3.25rem',
		},
		md: {
			fontSize: '16px',
			h: '3rem',
		},
		sm: {
			fontSize: '14px',
			h: '2.75rem',
		},
		xs: {
			fontSize: '12px',
			h: 9,
		},
	},
	variants: {
		solid: {},
		outline: {},
		ghost: {},
		link: {
			fontWeight: 'bold',
			color: 'link',
		},
		cashContributionButton: (p) => {
			let baseStyles = {
				border: '1px solid #0066CC',
				borderRadius: 'full',
				h: '32px',
				minW: '78px',
				outline: '0',
				fontWeight: '600',
				color: '#0066CC',
				backgroundColor: 'white',
				fontSize: '0.875rem',
				_hover: {
					cursor: 'pointer',
				},
			}

			if (p.isLighten) {
				baseStyles = {
					...baseStyles,
					...{
						borderColor: 'gray.400',
						color: 'gray.400',
						backgroundColor: 'white',
						_hover: {
							cursor: 'pointer',
							borderColor: '#0066CC',
							color: '#0066CC',
						},
					},
				}
			}

			if (p.isActive) {
				baseStyles = {
					...baseStyles,
					...{
						borderColor: '#0066CC',
						color: '#FFFFFF',
						backgroundColor: '#0066CC',
						_hover: {
							cursor: 'default',
						},
					},
				}
			}

			return baseStyles
		},
		/**
		 * IconButton with light blue bg
		 */
		iconCta: (p) => {
			// console.log(p, "P")
			return {
				'width': '3rem',
				'backgroundColor': 'rgba(205, 231, 255, 0.8)',
				'&:hover': {
					backgroundColor: 'blue.50',
				},
				'svg': {
					fill: '#0066CC',
				},
			}
		},
		/**
		 * IconButton with light blue bg
		 */
		iconWhite: (p) => {
			// console.log(p, "P")
			return {
				'width': '3rem',
				'backgroundColor': 'whiteAlpha.800',
				'&:hover': {
					backgroundColor: 'white',
				},
				'svg': {
					fill: '#0066CC',
				},
			}
		},
		/**
		 * IconButton with light border, used for menu
		 */
		iconMenu: (p) => {
			return {
				'width': '2rem',
				'backgroundColor': 'white',
				'boxShadow': 'base',
				'&:hover': {
					backgroundColor: 'rgba(205, 231, 255, 0.8)',
				},
				'svg': {
					fill: '#0066CC',
				},
			}
		},

		/**
		 * IconButton with light border, used for menu
		 */
		pageItem: (p) => {
			return {
				'width': '2rem',
				'maxW': '2rem',
				'minW': '2rem',
				'height': '2rem',
				'backgroundColor': p.isActive ? '#0066CC' : 'transparent',
				'boxShadow': p.isActive ? 'base' : 'none',
				'color': p.isActive ? 'white' : '#47596A',
				'&:hover': {
					backgroundColor: p.isActive ? 'blue.700' : 'rgba(205, 231, 255, 0.8)',
				},
			}
		},
		playButton: (p) => {
			return {
				backgroundColor: 'white',
				color: '#0066CC',
			}
		},

		impersonateButton: (p) => {
			return {
				backgroundColor: 'white',
				color: '#FF1C63',
				px: '0.75rem',
			}
		},

		pageArrow: (p) => {
			return {
				'width': '2rem',
				'maxW': '2rem',
				'minW': '2rem',
				'height': '2rem',
				'borderRadius': '25px',
				'lineHeight': '0.5rem',
				'backgroundColor': p.theme == 'dark' ? 'rgba(255,255,255,0.16)' : '#E8ECF0',
				'&:hover': {
					backgroundColor: p.theme == 'dark' ? 'rgba(255,255,255,0.26)' : 'blackAlpha.300',
				},
				'svg': {
					verticalAlign: 'middle',
					fill: p.theme == 'dark' ? 'white' : '#6C7A88',
					w: '1rem',
					h: '1rem',
				},
			}
		},
		control: (props) => {
			return {
				d: 'flex',
				flexDirection: 'column',
				borderRadius: 'md',
				backgroundColor: mode('rgb(232, 235, 240)', 'rgba(255, 255, 255, 0.1)')(props),
				color: mode(props.color || '#0066CC', props.color || 'rgba(255,255,255,0.6)')(props), //p.color ? p.color : darkMode ? 'rgba(255,255,255,0.6)' : '#0066CC',
				fontSize: '0.725rem',
				// px:'0.5rem',
				// fontWeight:'bold',
				h: '60px',
				w: ['48%', '5.25rem'],
				minW: ['3.75rem', '5.25rem'],

				svg: {
					fill: mode(props.color || '#0066CC', props.color || 'rgba(255,255,255,0.6)')(props),
					//p.color ? p.color : darkMode ? 'rgba(255,255,255,0.6)' : '#0066CC',
				},
				_hover: {
					color: mode('#0066CC', 'white')(props),
					svg: {
						fill: mode(props.color || '#0066CC', props.color || 'white')(props),
					},
				},
				// 	alignItems="center"
				// justifyContent="center"
			}
		},
		mediaButton: (p) => {
			const red =
				p.colorScheme && p.colorScheme == 'red'
					? {
							'backgroundColor': '#FF1C63',
							'svg': {
								fill: 'white',
							},
							'&:hover svg': {
								fill: '#0066CC',
							},
					  }
					: {}
			// console.log(p, 'p')
			return {
				mb: '0.5rem',
				w: '4rem',
				h: '4rem',
				boxShadow: 'base',
				_hover: {
					backgroundColor: 'blue.50',
				},
				// color: '#0066CC',
				svg: {
					fill: '#0066CC',
				},
				...red,
			}
		},
		mediaGalleryButton: (p) => {
			const red =
				p.colorScheme && p.colorScheme == 'red'
					? {
							'color': '#FF1C63',
							'svg': {
								fill: '#FF1C63',
							},
							'&:hover svg': {
								fill: '#0066CC',
							},
					  }
					: {}
			// console.log(p, 'p')
			return {
				backgroundColor: '#E8ECF0',
				borderRadius: 'md',
				h: '2rem',
				maxH: '2rem',
				// boxShadow: 'base',
				fontSize: '0.875rem',
				color: '#0066CC',
				px: ['0.5rem', '1rem'],
				_hover: {
					backgroundColor: 'blue.50',
				},
				// color: '#0066CC',
				svg: {
					w: '20px',
					fill: '#0066CC',
				},
				...red,
			}
		},
		download: (p) => {
			return {
				backgroundColor: '#E8ECF0',
				borderRadius: 'md',
				h: '3rem',
				maxH: '3rem',
				// boxShadow: 'base',
				fontSize: '0.875rem',
				color: '#0066CC',
				px: '1rem',

				_hover: {
					cursor: 'pointer',
					backgroundColor: 'blue.50',
				},
				// color: '#0066CC',
				svg: {
					w: '20px',
					fill: '#0066CC',
					mr: '0.5rem',
				},
				...red,
			}
		},
	},
	// variants:{
	//     'nav-bar': {
	//         backgroundColor: 'rgb(232, 235, 240)',
	//         w:"80px",
	//         display: 'flex',
	//         flexDirection:'column',
	//         alignItems:'center',
	//     }
	// },
	defaultProps: {
		colorScheme: 'blue',
	},
}

export default Button
