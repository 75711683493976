import React from 'react'

import { StyledFilterSettingButton } from './styles'
import SettingsIcon from '@mui/icons-material/Settings'

const FilterSettingButton = ({ onClick }) => {
	return (
		<StyledFilterSettingButton>
			<a href="#" onClick={onClick}>
				<SettingsIcon />
			</a>
		</StyledFilterSettingButton>
	)
}

export default FilterSettingButton
