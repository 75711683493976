import {createSlice} from '@reduxjs/toolkit'

import { TAX_CODE_RATES } from './constants'

const initialState = {
    data:{},
    ids:[],
    isFetching:false,
    hasError:false,
    errorMessage:'',
}

const taxCodeRatesSlice = createSlice({
    initialState: initialState,
    name:'taxCodeRates',
    extraReducers: {
        [TAX_CODE_RATES.FETCH_REQUESTED]: (state, action) => {
            state.isFetching = true
        },
        [TAX_CODE_RATES.FETCH_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.taxCodeRates){
                state.data = action.payload.entities.taxCodeRates
                state.ids = action.payload.result
                state.isFetching = false
                state.hasError = false
            }
        },
        
        [TAX_CODE_RATES.FETCH_FAILED]: (state, action) => {
            state.hasError = true
            state.isFetching = false
            state.errorMessage = 'An error occured while fetching.'
        },

        [TAX_CODE_RATES.UPDATE_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.taxCodeRates){
                var item = state.data[action.payload.result[0]]
                // If we found an item, update it
                if(item){
                    state.data[action.payload.result[0]] = action.payload.entities.taxCodeRates[action.payload.result[0]]
                }
            }
        },

        [TAX_CODE_RATES.POST_SUCCEEDED]: (state, action) => {
            if(action.payload && action.payload.entities && action.payload.entities.taxCodeRates){
                state.data[action.payload.result[0]] = action.payload.entities.taxCodeRates[action.payload.result[0]]
            }
        },

        [TAX_CODE_RATES.DELETE_SUCCEEDED]: (state, action) => {
            var item = state.data[action.payload.data.id]
            // If we found an item, update it
            if(item){
                delete state.data[action.payload.data.id]
            }
        },
    },
    reducers:{},
})


export default taxCodeRatesSlice.reducer