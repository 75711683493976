
const RENDER_JOBS = {
    /** Fetch all render jobs (includes filters) */
    FETCH_REQUESTED: 'RENDER_JOBS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'RENDER_JOBS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'RENDER_JOBS_FETCH_FAILED',

    /** Post a render job */
    POST_REQUESTED: 'RENDER_JOBS_POST_REQUESTED',
    POST_SUCCEEDED:'RENDER_JOBS_POST_SUCCEEDED',
    POST_FAILED: 'RENDER_JOBS_POST_FAILED',

    /** Update a render job */
    UPDATE_REQUESTED: 'RENDER_JOBS_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'RENDER_JOBS_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'RENDER_JOBS_UPDATE_FAILED',
    /** Delete a render job */
    DELETE_REQUESTED: 'RENDER_JOBS_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'RENDER_JOBS_DELETE_SUCCEEDED',
    DELETE_FAILED: 'RENDER_JOBS_DELETE_FAILED',
}

export {RENDER_JOBS}