import * as Yup from 'yup'

const taxCodeRateSchema = Yup.object().shape({
	categoryId: Yup.string().required('You need to provide a category.'),
	rate: Yup.string().required('You need to provide a rate.'),
	countryCode: Yup.string().required('You need to select a country.'),
    inclusive: Yup.boolean().required('You need to indicate a tax method.').oneOf([true, false]),
})

export default taxCodeRateSchema
