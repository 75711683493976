import styled from 'styled-components'


export const StyledBool = styled.span`
    display:inline-block;
    border-radius:25px;
    color:white;
    width:24px;
    font-size:12px;
    /* padding:3px 0px; */
    font-weight:500;
    position:relative;
    
    margin-right:5px;
    text-align:center;

    svg {
        width: 16px;
        height:16px;
        position:relative;
        top:1px;
        color:white;
    }
    
    &.yes {
        background-color:#04D898; 
    }

    &.no {
        background-color:#FF1C63; 
    }
`;
