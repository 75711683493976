import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PromotionForm from '../../../apps/core/promotions/src/PromotionForm'
import AnnouncementForm from '../../../apps/core/announcements/src/AnnouncementForm'
import { updateAnnouncement, postAnnouncement } from '../../../apps/core/announcements/api/actions'
import {
	Box,
	Modal,
	Icon,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'

const ModalAnnouncementForm = ({ onClose, isOpen }) => {
	const dispatch = useDispatch()

	const onSubmit = (data) => {
		if (moment(data.starts).isValid()) {
			let startsDate = moment(data.starts)
			let endsDate = moment(data.ends)

			let obj = {
				...data,
				starts: startsDate.toISOString(),
				ends: endsDate.toISOString(),
			}

			dispatch(postAnnouncement(obj))
		}

		onClose()
	}

	const data = {
		type: '',
		enabled: '',
		message: '',
		starts: '',
		ends: '',
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="full">
			<ModalOverlay />

			<ModalContent borderRadius="0">
				<ModalCloseButton />
				<ModalBody>
					<Box maxW="600px" textAlign="left" mx="auto">
						<Box w="full" mb="2rem">
							<Heading size="lg" variant="hero">
								Create an Announcement
							</Heading>
							<Text>Publish an annoucement to let user know the website status.</Text>
						</Box>
						<Box>
							<AnnouncementForm data={data} onSubmit={onSubmit} />
						</Box>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalAnnouncementForm
