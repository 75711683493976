import React from 'react'
import Bool from '../../../../../core/widgets/others/Bool'
import DateFormat from '../../../../../core/widgets/others/DateFormat'

/**
 * Define our Table view columns
 */
const columns = [
	{
		id: 'email',
		width: 100,
		Header: 'Email',
		accessor: 'email', // String-based value accessors!
		Cell: ({ row }) => {
			const { email } = row.original
			return <span className="badge blue">{email}</span>
		},
	},

	{
		id: 'country',
		width: 100,
		Header: 'Country',
		accessor: 'profile.location.country_name', // String-based value accessors!
		Cell: ({ row }) => {
			if (row.original.profile) {
				const { location } = row.original.profile
				return <span className="badge">{location?.country_name}</span>
			}
			return <>-</>
		},
	},

	{
		id: 'firstName',
		width: 120,
		Header: 'Name',
		accessor: 'profile.firstName', // String-based value accessors!
	},
	{
		id: 'lastName',
		width: 120,
		Header: 'Name',
		accessor: 'profile.lastName', // String-based value accessors!
	},
	{
		id: 'role',
		width: 50,
		Header: 'Role',
		accessor: 'role',
		Cell: ({ row }) => {
			const { role } = row.original
			return <span className="badge">{role}</span>
		},
	},
	{
		id: 'isActive',
		width: 50,
		Header: 'Is Active',
		// accessor: (d) => d.isActive.toString(),
		Cell: ({ row }) => {
			return <Bool value={row.original.isActive} />
		},
	},

	// {
	//     id: 'isNew',
	//     width: 50,
	//     Header: 'Is New',
	//     // accessor: (d) => d.isActive.toString(),
	//     Cell: ({row}) => {
	//         return (<Bool value={row.original.isNew} />)
	//     },
	// },

	{
		id: 'isRegistered',
		width: 50,
		Header: 'Is Registered',
		// accessor: (d) => d.isActive.toString(),
		Cell: ({ row }) => {
			return <Bool value={row.original.isRegistered} />
		},
	},

	{
		id: 'activatedAt',
		width: 160,
		Header: 'Activated at',
		accessor: 'activatedAt',
		Cell: ({ row }) => <DateFormat value={row.original.activatedAt} />,
	},
]

export default columns
