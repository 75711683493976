import React, { useState, useEffect, useRef } from 'react'
import { FormErrorMessage, Image } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Input,
	InputRightElement,
	Select,
	Flex,
	Icon,
	Box,
	InputLeftAddon,
} from '@chakra-ui/react'

import Flag from 'react-world-flags'
import { AsYouType } from 'libphonenumber-js'
import { COUNTRIES, getCountryTelCode } from './countries'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

// console.log(COUNTRIES, 'PHONE: COUNTRIES')
const options = COUNTRIES.map(({ name, iso2 }) => ({
	label: name,
	value: iso2,
}))

const PhoneNumber = ({
	name,
	size,
	label,
	control,
	setError,
	clearErrors,
	errors,
	helperText,
	// value,
	// country,
	// options,
	// onChange,
	placeholder,
	countries,
	defaultValue,
	...rest
}) => {
	/**
	 * Handle Error Messages
	 * */
	let isError = false
	let errorMessage = ''
	let countryErrorMessage = ''

	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}

	if (errors && errors.hasOwnProperty('smsCountryCode') === true) {
		isError = true
		countryErrorMessage = errors['smsCountryCode'].message
	}

	/**
	 * Retrieve control handlers
	 * */
	const { setValue, getValues } = control

	/**
	 * Retrieve values of the form
	 * */
	const values = getValues()
	const value = values[name]
	const valueCountryCode = values['smsCountryCode']
	const selectRef = useRef()

	/**
	 * Handler to programmatically update the input value
	 * */
	const onChange = (val) => {
		setValue(name, val)
	}

	/**
	 * Manage Input state
	 * */
	const [isSelectInvalid, setIsSelectInvalid] = useState(false)
	let [number, setNumber] = useState(value || '')
	let [selectedCountry, setSelectedCountry] = useState(valueCountryCode || '')
	let [countryPrefix, setCountryPrefix] = useState(getCountryTelCode(valueCountryCode, countries) || '')
	let [countryObject, setCountryObject] = useState(countries.find((el) => el.value == valueCountryCode) || '')

	useEffect(() => {
		if (value) {
			setNumber(value)
		}
	}, [value])

	useEffect(() => {
		if (valueCountryCode) {
			let obj = countries.find((el) => el.value == valueCountryCode)
			setCountryObject(obj) // set country object
			setCountryPrefix(obj.prefix)
			setSelectedCountry(valueCountryCode)
			setIsSelectInvalid(false)
		}

		// console.log('SMS: useEffect(valueCountryCode)', valueCountryCode)
	}, [valueCountryCode])

	const onCountryChange = (e) => {
		let val = e.target.value
		let code = getCountryTelCode(val, countries)
		// let parsedNumber = new AsYouType().input(`${code}${number}`)
		setCountryPrefix(code) // save phone prefix
		setSelectedCountry(val) // save Alpha 2 country code
		setCountryObject(countries.find((el) => el.value == val)) // Save a reference to the country object
		setValue('smsCountryCode', val) // Set the value of the `smsCountryCode` in react-hook-form
		// onChange(parsedNumber) // We don't want to save
	}

	const onPhoneNumberChange = (e) => {
		let val = e.target.value
		// let parsedNumber = new AsYouType().input(`${countryPrefix}${value}`)
		setNumber(val) // save value in local state
		onChange(val) // save value in react-hook-form
	}

	const handleFocusOnSelect = () => {
		if (!valueCountryCode) {
			setIsSelectInvalid(true)
			setError('smsCountryCode', { type: 'error', message: 'Please select a country first.' })
		} else clearErrors('smsCountryCode')
	}

	useEffect(() => {
		if (valueCountryCode) {
			clearErrors('smsCountryCode')
		}
	}, [valueCountryCode])

	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				return (
					<FormControl isInvalid={isError}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}

						<InputGroup size={size} {...rest} position="relative" pl="80px">
							<InputLeftElement width={'4rem'} maxW="64px">
								<Select
									top="0"
									left="0"
									zIndex={1}
									bottom={0}
									opacity={0}
									minW="82px"
									name="smsCountryCode"
									position="absolute"
									value={selectedCountry}
									onChange={onCountryChange}
									height="var(--vidday-sizes-10)"
									iconColor={isSelectInvalid || countryErrorMessage ? 'red' : 'inherit'}>
									<option value="" />
									{options.map((option) => (
										<option value={option.value}>{option.label}</option>
									))}
								</Select>
								<Flex pl={2} width="100%" alignItems="center">
									{!selectedCountry && isSelectInvalid && (
										<Box mr="4px" width="50%" flex={1}>
											<Box w="40px" h="30px" bg="red.100" borderRadius="base"></Box>
										</Box>
									)}
									{selectedCountry && (
										<Box mr="4px" width="50%" flex={1}>
											<Image
												maxW="40px"
												src={countryObject && countryObject.flag}
												alt={countryObject.value}
												boxShadow="base"
											/>
											{/* <Flag height="1rem" code={selectedCountry || ''} /> */}
										</Box>
									)}
								</Flex>
							</InputLeftElement>
							<InputLeftAddon children={countryPrefix} />
							<Input
								isReadOnly={!valueCountryCode && !countryErrorMessage}
								pl="0.5rem"
								name={name}
								type="tel"
								{...props}
								onClick={handleFocusOnSelect}
								value={number}
								placeholder={placeholder}
								onChange={onPhoneNumberChange}
							/>

							<InputRightElement width="2.5rem">
								<Icon as={LocalPhoneIcon} />
							</InputRightElement>
						</InputGroup>

						{isError ? (
							<FormErrorMessage d="block" textAlign="left">
								{errorMessage || countryErrorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default PhoneNumber
