import styled from 'styled-components' 
import React from 'react' 

const StyledSaveBar = styled.div`
    display:block;
    text-align:right;
    border-bottom:1px solid #DADDE1;
    padding:8px 0;
    button {
        font-size:14px;
        text-align:center;
        border:none;
        background-color:#F2F6FA;
        padding: 5px 14px;
        display:inline-block;
        height:28px;
        border-radius:5px;
        color:#0187FF;

        &:hover {
            background-color:#0187FF;
            color:white;
        }
    }
`

const SaveBar = ({isSubmitting}) => {
    return (
        <StyledSaveBar>
            <button>Save</button>
        </StyledSaveBar>
    )
}

export default SaveBar;