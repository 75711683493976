import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../core/widgets/form/input'
import Select from '../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../core/widgets/buttons/Button'
import { updateThemeVersion, postThemeVersion } from '../../api/actions'
import { useSelector } from 'react-redux'
import { values } from 'lodash'

const getThemes = (s) => {
	var items = s.entities.themes.data || {}
	let collection = []
	collection = values(items)
	return collection
}

const ThemeVersionForm = ({ data }) => {
	console.log(data, 'data')
	const { timezone, starts, ends } = data
	const dispatch = useDispatch()

	const themesCollection = useSelector((s) => getThemes(s))
	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		//resolver: yupResolver(schemaPromoCode),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		console.log(data)
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.id) {
			dispatch(updateThemeVersion(model))
		} else {
			dispatch(postThemeVersion(model))
			reset()
		}
	}

	const currencyOptions = [
		{ value: 'USD', label: 'U.S. Dollar (USD)' },
		{ value: 'CAD', label: 'Canadian Dollar (CAD)' },
		{ value: 'EUR', label: 'Euro (EUR)' },
		{ value: 'GBP', label: 'Pound sterling (GBP)' },
		{ value: 'AUD', label: 'Australian dollars (AUD)' },
	]

	const isEnabledOptions = [
		{ value: true, label: 'Enabled' },
		{ value: false, label: 'Disabled' },
	]

	console.log(themesCollection, 'collection of themes')
	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	// useEffect(() => {
	// 	// Set timezone automatically
	// 	// const autoTimezone = timezone ? timezone : moment.tz.guess(true)
	// 	// // Set deadline automatically
	// 	// const autoStartDate = starts ? starts : moment().add(2, 'days')
	//     // const autoEndDate = ends ? ends : moment().add(2, 'days')
	// 	// // Set initial values
	// 	// setValue('starts', autoStartDate)
	//     // setValue('ends', autoEndDate)
	// 	// setValue('timezone', autoTimezone)
	// }, [timezone, starts, ends])

	// useEffect(() => {
	//console.log("FORM CHANGE",moment(deadline).format(),moment(deadline).tz(values.timezone).format())
	// setValue('starts', moment(starts).tz(values.timezone))
	// setValue('ends', moment(ends).tz(values.timezone))
	// }, [values.timezone])

	/** Register uncontrolled components */
	useEffect(() => {
		// register({ name: 'timezone' }, { required: true })
		// register({ name: 'starts' }, { required: true })
		// register({ name: 'ends' }, { required: true })
	}, [register])

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	const options = [
		{
			value: true,
			label: 'Yes',
		},
		{
			value: false,
			label: 'No',
		},
	]

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Theme Version Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					disabled={data.id ? true : false}
					margin="normal"
					name="themeId"
					id="themeId"
					variant="filled"
					label="Theme"
					errors={errors}>
					{themesCollection.map((el, i) => (
						<MenuItem key={i} value={el.id}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="version"
					id="version"
					variant="filled"
					label="Version (ie. 1, 2...)"
					errors={errors}
					disabled={data.id ? true : false}
				/>

				<Input
					size="small"
					control={control}
					name="templateDir"
					id="templateDir"
					variant="filled"
					label="Template Directory"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="projectFile"
					id="projectFile"
					variant="filled"
					label="Project File"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="video"
					id="video"
					variant="filled"
					label="Video"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="duration"
					id="duration"
					variant="filled"
					label="Duration"
					errors={errors}
				/>

				<Select
					control={control}
					name="selectable"
					id="selectable"
					variant="filled"
					label="Selectable"
					size="small"
					errors={errors}
					options={options}>
					{options.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					name="beta"
					id="beta"
					variant="filled"
					label="Beta"
					size="small"
					errors={errors}
					options={options}>
					{options.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					name="default"
					id="default"
					variant="filled"
					label="Default"
					size="small"
					errors={errors}
					options={options}>
					{options.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>
			</InlineItems>
		</form>
	)
}

ThemeVersionForm.defaultProps = {
	/** Important */
	data: {},
}

ThemeVersionForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default ThemeVersionForm

// var ThemeVersion = thinky.createModel('theme_versions', {
// 	id: type.string(),
// 	updatedAt: type.date().default(null),
// 	createdAt: type.date().default(null),
// 	createdBy: type.string().required(),
// 	//
// 	themeId: type.string().required(), // theme this is a version of
// 	versionNumber: type.number().required(), // e.g. 1, 2, ...
// 	templateDir: type.string().required(), // ae template folder
// 	projectFile: type.string().required(), // ae template file
// 	selectable: type.boolean().default(false), // makes this version visible in theme selector
// 	beta: type.boolean().default(true), // beta / in testing
// 	default: type.boolean().default(false), // marks this version as default
// })
