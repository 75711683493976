// Should be hosted here any default configuration related
// to this project and used throughout the Frontend components (client side)
// NO SENSITIVE INFORMATION

module.exports = {
	stripe: {
		publishableKey: process.env.STRIPE_KEY,
	},

	auth: {
		loginURL: '/auth/login',
		loginRedirect: '/user/dashboard',
	},

	media: {
		baseUrl: process.env.MEDIA_BASE_URL,
	},

	uppy: {
		companionUrl: process.env.UPPY_COMPANION_URL,
	},

	api: {
		protocol: process.env.VIDDAY_API_PROTOCOL,
		host: process.env.VIDDAY_API_HOST,
		port: process.env.VIDDAY_API_PORT,
		baseUri: process.env.VIDDAY_API_BASE_URI,
		baseUrl: `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}:${process.env.VIDDAY_API_PORT}${process.env.VIDDAY_API_BASE_URI}`,
		baseUrl: `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}:${process.env.VIDDAY_API_PORT}${process.env.VIDDAY_API_BASE_URI}`,
	},
	app: {
		host: `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}:${process.env.VIDDAY_API_PORT}`,
	},
}
