/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import { pick } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = [
	'themeId',
	'version',
	'templateDir',
	'projectFile',
	'video',
	'uploadDate',
	'duration',
	'selectable',
	'beta',
	'default',
]
const acceptedKeysPUT = [
	'templateDir',
	'projectFile',
	'video',
	'uploadDate',
	'duration',
	'selectable',
	'beta',
	'default',
]

/**
 * Fetch our theme versions
 */
const fetchThemeVersions = async (params) => {
	const response = await instance.get('/theme-versions?' + params)
	return response
}

/**
 * Post a theme version
 * @param {*} data
 * @returns
 */
const postThemeVersion = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/theme-versions', filteredData)
	return response
}

/**
 * Update a theme version
 * @param {*} data
 * @returns
 */
const updateThemeVersion = async (data) => {
	const filteredData = pick(data, acceptedKeysPUT)
	const response = await instance.put('/theme-versions/' + data.id, filteredData)
	return response
}

/**
 * Delete a theme version
 * @param {*} data
 * @returns
 */
const deleteThemeVersion = async (id) => {
	const response = await instance.delete('/theme-versions/' + id)
	return response
}

const requests = { fetchThemeVersions, postThemeVersion, updateThemeVersion, deleteThemeVersion }

export default requests
