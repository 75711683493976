import { ANNOUNCEMENTS } from './constants'

const fetchAnnouncements = (params) => ({
    type: ANNOUNCEMENTS.FETCH_REQUESTED,
    payload:params,
})

const setAnnouncements = (users) => ({
    type: ANNOUNCEMENTS.FETCH_SUCCEEDED,
    payload:users,
})

const setError = (error) => ({
    type: ANNOUNCEMENTS.FETCH_FAILED,
    payload:error,
})

const postAnnouncement = (data) => ({
    type: ANNOUNCEMENTS.POST_REQUESTED,
    payload:data,
})

const updateAnnouncement = (data) => ({
    type: ANNOUNCEMENTS.UPDATE_REQUESTED,
    payload:data,
})

const deleteAnnouncement = (id) => ({
    type: ANNOUNCEMENTS.DELETE_REQUESTED,
    payload:id,
})

export {
    fetchAnnouncements,
    setAnnouncements,
    setError,
    postAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
}





