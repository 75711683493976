import React from 'react'
import { StyledAppRoot } from '../../../../interface/components/AppRoot/styles'
import { StyledBool } from './styles'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

const Bool = (props) => {
	const { value, ...rest } = props

	let icon = null
	if (value == true) {
		icon = <CheckRoundedIcon />
	} else {
		icon = <ClearRoundedIcon />
	}

	return (
		<StyledBool {...rest} className={value == true ? 'yes' : 'no'}>
			{icon}
		</StyledBool>
	)
}

export default Bool
