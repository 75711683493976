const Text = {
	baseStyle: {},
	sizes: {},
	variants: {
		mediaButtonLabel: (p) => {
			return {
				textAlign: 'center',
				fontSize: '0.75rem',
				color: '#4A555F',
			}
		},
		mediaGalleryText: (p) => {
			return {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
				padding: '0 4px',
				color: '#131920',
				fontSize: '95%',
				lineHeight: '140%',
				textOverflow: 'ellipsis',
				wordBreak: 'break-word',
				overflow: 'hidden',
			}
		},
	},
	defaultProps: {},
}

export default Text
