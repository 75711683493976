import React from 'react' 

import {StyledParagraph} from './styles'

const ResultsParagraph = ({count = 0}) => {
    return (
        <StyledParagraph>
            <strong>{count}</strong> results found.
        </StyledParagraph>
    )
}

export default ResultsParagraph