import React, { useEffect, useState } from 'react'

import { StyledAppSelector, StyledLabel, StyledAppLink, StyledControlButton } from './styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import useApps from '../../../core/hooks/useApps'
import { useNavigate } from 'react-router-dom'
import { Box, Icon, Text, Flex, HStack, chakra, VStack } from '@chakra-ui/react'
/**
 * Select Button
 * @param {*} props
 */
export const DropDownIcon = (props) => {
	return (
		<StyledControlButton {...props}>
			<ExpandMoreIcon />
		</StyledControlButton>
	)
}

const listStyles = {
	mt: '0.5rem',
	ml: '2rem',
	boxShadow: 'base',
	py: '0.5rem',
	alignItems: 'flex-start',
	borderTop: '1px dotted rgba(255,255,255,0.2)',
}

const listLinkStyles = {
	color: 'rgba(255,255,255,0.5)',
	_hover: {
		cursor: 'pointer',
	},
}

const AppSelector = ({ activeId, apps }) => {
	const navigate = useNavigate()
	const { setCurrentApp } = useApps()
	// console.log(apps, "apps", typeof apps)
	const currentItem = apps.find((el) => el.name == activeId)
	/** Filter our collection of apps so we only keep those excluding the current item */
	const filteredApps = apps.filter((el) => el.name != activeId && (el.name == 'vidday' || el.name == 'home'))
	// console.log(currentItem, filteredApps, "aap selector?", activeId, apps);
	const handleClick = (e, el) => {
		e && e.preventDefault()
		setCurrentApp(el.name)
		console.log('name')
		navigate(el.path)
	}

	const [displayApps, setDisplayApps] = useState(false)

	/** Automatically set vidday app as default on mount if no active app provided */
	useEffect(() => {
		if (!activeId) {
			setCurrentApp('vidday')
		}
	}, [])

	return (
		<Box pos="relative" p="1rem" overflow="visible" bg="rgba(255,255,255, 0.1)">
			<Flex justify="space-between" alignItems="center">
				<Text fontSize="0.75rem" color="rgba(255,255,255, 0.7)" textTransform="uppercase">
					Current App
				</Text>
				<Icon as={MoreHorizIcon} color="rgba(255,255,255, 0.7)" />
			</Flex>
			<HStack w="full" onClick={() => setDisplayApps((s) => !s)}>
				<Icon as={ExpandMoreIcon} color="white" />
				<Text color="white" fontWeight="bold">
					{currentItem ? currentItem.title : 'No selection'}
				</Text>
			</HStack>

			{displayApps && (
				<VStack sx={listStyles}>
					{filteredApps.map((el, i) => (
						<chakra.a sx={listLinkStyles} onClick={(e) => handleClick(e, el)}>
							{el.title}
						</chakra.a>
					))}
				</VStack>
			)}

			{/* <StyledAppLink>
					<ExpandMoreIcon />
					{currentItem ? currentItem.title : 'No selection'}
					<ul>
						{filteredApps.map((el, i) => (
							<li key={i}>
								<a onClick={(e) => handleClick(e, el)}>{el.title}</a>
							</li>
						))}
					</ul>
				</StyledAppLink> */}
		</Box>
	)
}
AppSelector.defaultProps = {
	activeId: null,
	apps: [],
}

export default AppSelector
