import { media as mediaConfig } from '../../config.public'

export function getMediaUrl(key) {
	if (key == undefined) {
		return null
	}

	// Retrieve location
	let url = `${mediaConfig.baseUrl}${key}`

	return url
}
