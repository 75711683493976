import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import requests from './requests'
import { MAIL } from './constants'


/**
 * Saga worker
 * Post published notifications
 * @param {*} action 
 */
 function* handlePublishedNotification(action) {
    try {
        const response = yield call(requests.postPublishedNotification, action.payload)
        yield put({ type: MAIL.POST_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: MAIL.POST_FAILED, payload:e })
    }
}


export function* watchPostPublishedNotification(){
    // while(true){
        yield takeEvery(MAIL.POST_REQUESTED, handlePublishedNotification)
    // }
}