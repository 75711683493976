/* eslint-disable no-console */
import { createSlice, createAction } from '@reduxjs/toolkit'

/** Define our initial interface State */
const defaultState = {
	/** Search query */
	searchQuery: undefined,
	/** Processing State */
	loading: true,
	/** Current Page index */
	currentPage: 0,
	/** # items per page */
	pageSize: 10,
	/** default # items */
	defaultPageSize: 10,
	/** Filters by keys */
	filters: {},
	/** Sort By */
	sortBy: 'id',
	/** Sort By Direction */
	sortByDirection: 'asc',
}

const defaultConfig = {
	enginePrefix: 'Engine@',
	/** Name used for our slice */
	sliceName: undefined,
	/** prefix used for actions (in order to dispatch
	 * actions to their corresponding slice) */
	actionPrefix: undefined,
	/** Initial State (will be merged with initial State) */
	initialState: defaultState,
	/** Extra reducers */
	extraReducers: {},
	/** Reducers */
	reducers: {},
}

const reducers = {
	['search']: (state, action) => {
		state.searchQuery = action.payload
	},

	/** Go to next page */
	['nextPage']: (state, action) => {
		state.currentPage = state.currentPage + 1
	},

	/** Go to previous page */
	['prevPage']: (state, action) => {
		state.currentPage = state.currentPage - 1
	},

	/** Set the current page size */
	['setPageSize']: (state, action) => {
		state.pageSize = action.payload
	},

	/** Set the default page Size */
	['setDefaultPageSize']: (state, action) => {
		state.defaultPageSize = action.payload
	},

	/** Go to specific page */
	['goToPage']: (state, action) => {
		state.currentPage = action.payload
	},

	/** Sort by attributes */
	['sortBy']: (state, action) => {
		state.sortBy = action.payload.sortBy
		state.sortByDirection = action.payload.sortByDirection
	},

	/** Sort by attributes */
	['setFilter']: (state, action) => {
		/** Add new filter (will replace existing one if any) */
		var filters = { ...state.filters, ...action.payload }
		/** Set new collection of filters */
		state.filters = filters
	},
}

const buildEngineSlice = (config = defaultConfig) => {
	/** Ensure config has been provided */
	if (!config.sliceName) throw new Error('You need to provide a slice name')
	if (!config.actionPrefix) throw new Error('You need to define the prefix for the reducer actions')

	/** Merge config */
	const _config = { ...defaultConfig, ...config }

	/** Merge initial state */
	const _state = { ...defaultState, ...config.initialState }

	/** Host our newly formatted reducers */
	let extraReducers = {}

	/** De-construct configs */
	const { enginePrefix, actionPrefix } = _config

	/** Map our new reducers object */
	Object.keys(reducers).forEach((el, i) => {
		extraReducers[`${enginePrefix + actionPrefix}/${el}`] = reducers[el]
	})

	/** If we passed custom reducers, merge them */
	const _extraReducers = { ...extraReducers, ..._config.extraReducers }

	/** If we defined reducers, merge them */
	const _reducers = { ...defaultConfig.reducers, ..._config.reducers }

	/** Build our slice */
	return createSlice({
		initialState: _state,
		name: _config.sliceName,
		extraReducers: _extraReducers,
		reducers: _reducers,
	})
}
export default buildEngineSlice
