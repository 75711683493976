const ANNOUNCEMENTS = {
    /** Fetch all announcements (includes filters) */
    FETCH_REQUESTED: 'ANNOUNCEMENTS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'ANNOUNCEMENTS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'ANNOUNCEMENTS_FETCH_FAILED',

    /** Post a announcement */
    POST_REQUESTED: 'ANNOUNCEMENT_POST_REQUESTED',
    POST_SUCCEEDED:'ANNOUNCEMENT_POST_SUCCEEDED',
    POST_FAILED: 'ANNOUNCEMENT_POST_FAILED',

    /** Update a announcement */
    UPDATE_REQUESTED: 'ANNOUNCEMENT_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'ANNOUNCEMENT_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'ANNOUNCEMENT_UPDATE_FAILED',

    /** Delete a announcement */
    DELETE_REQUESTED: 'ANNOUNCEMENT_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'ANNOUNCEMENT_DELETE_SUCCEEDED',
    DELETE_FAILED: 'ANNOUNCEMENT_DELETE_FAILED',
}

export {ANNOUNCEMENTS}