/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import { pick, reject } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}`,
})

const acceptedKeys = ['']
const acceptedKeysPUT = ['']
const rejectedKeysPUT = ['publishedMedia']

/**
 * Fetch our events
 */
const fetchEvents = async (params) => {
	const response = await instance.get('/v2/events?' + params)
	return response
}

/**
 * Post a event
 * @param {*} data
 * @returns
 */
const postEvent = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/v2/events', filteredData)
	return response
}

/**
 * Update a event
 * @param {*} data
 * @returns
 */
const updateEvent = async (data) => {
	const filteredData = reject(data, rejectedKeysPUT)
	const response = await instance.put('/v2/events/' + data.id, filteredData)
	return response
}

/**
 * Delete a event
 * @param {*} data
 * @returns
 */
const deleteEvent = async (id) => {
	const response = await instance.delete('/v2/events/' + id)
	return response
}

const requests = { fetchEvents, postEvent, updateEvent, deleteEvent }

export default requests
