/* **************************************
 *  LIBRARIES
 * ************************************ */

import { zero } from '@bit/vidday.vidday-ui.mixins.zero'
import { center } from '@bit/vidday.vidday-ui.mixins.center'
import { cf } from '@bit/vidday.vidday-ui.mixins.cf'
import { breakpoint } from '@bit/vidday.vidday-ui.mixins.breakpoint'
import { fontSize } from '@bit/vidday.vidday-ui.mixins.font-size'
import { shadow } from '@bit/vidday.vidday-ui.mixins.shadow'
import styled, { css, createGlobalStyle } from 'styled-components'

/* **************************************
 *  STYLES
 * ************************************ */

// This file should technically be called LAYOUT

const BaseStyle = createGlobalStyle`
  html{
    ${zero()};
    height: 100%;
  }

  body {
    ${zero()};
    background-color:white; //#0A2239;
    height: 100%;
    font-family: "Source Sans Pro", sans-serif;
    text-align: center;
    color: var(--global-color-grey-extra-dark);
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    backface-visibility: hidden;
  }

  .app-root {
    height: 100%;
    width: 100%;
    display: block;
  }

  .app-view {
    position: relative;
    max-width: 100%;
  }


  // TODO - this should be part of the HOC <Wrapper/> component
  #wrapper {
    
    margin: 0 auto;
    width:100%;
    overflow: visible;
    // transform: translateX(0);
    min-height: calc(100vh - 70px);

    ${breakpoint.mobile`
      padding-: var(--global-s4); 0;
      &.push {
          transform: translateX(100%);
      }
      &.superadmin {
          max-width: calc(100%);
      }
    `};

    ${breakpoint.tablet`
      padding: var(--global-s4);
    `};
  }

  .app-content {
    ${cf()};
    min-height: calc(100vh - 88px);
  }

  .container {
      ${center('560px')};
  }

  .display_block {
    ${cf()};
    display: block;
    width: 100%;
  }


  
.color_red {
  color: var(--global-color-red) !important;
}
.bg_red {
  background-color: var(--global-color-red) !important;
}
.color_green {
  color: var(--global-color-green) !important;
}
.bg_green {
  background-color: var(--global-color-green) !important;
}
.color_blue {
  color: var(--global-color-blue) !important;
}
.color_blue_light {
  color: var(--global-color-blue-light) !important;
}
.bg_blue {
  background-color: var(--global-color-blue) !important;
}
.color_white {
  color: #fff;
}
.bg_white {
  background-color: #fff !important;
}

.cf {
	display:block;
	${cf()}
}

.display_none {
  display: none !important;
}
.shadow{
	${shadow()};
}



body {}

button {}

h1, h2, h3, h4, h5, h6 {
    // margin: var(--global-s2) 0;
    color: var(--global-grayscale-1);
}

h1 {
    font-size: 32px;
    line-height:36px;        
    font-weight: 700;
    ${breakpoint.tablet`
        font-size:50px;
        line-height:54px;
    `}
}

h2 {
    font-size: 28px;
    line-height:34px;
    font-weight:700;
    ${breakpoint.tablet`
        font-size:32px;
        line-height:36px;
    `}
}

h3 {
    font-size: 24px;
    line-height:28px;
    font-weight:700;
    ${breakpoint.tablet`
        font-size:26px;
        line-height:36px;
    `}
}

h4 {
    font-size:20px;
    line-height:25px;
    font-weight:700;
}

h5 {
    font-size:18px;
    line-height:20px;
    font-weight:700;
    text-transform:uppercase;
}

h6 {
    font-size:17px;
    line-height:23px;
    font-weight:700;
}

p {
    color: var(--global-grayscale-2);
    font-size:18px;
    line-height:25px;

    &.medium {
        font-size:17px;
        line-height:23px;
    }
    &.small {
        font-size:15px;
        line-height:20px;
    }
}

ol, ul {
    margin: var(--global-s2) 0;
}

p, li {
    margin: var(--global-s2) 0 ;
}

p, a, li {
    margin: var(--global-s1) 0 ;
    display: inline-block;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    ${fontSize(16)};
}

a {
  color: var(--global-color-blue);
  text-decoration: none;
}

a.alert {
  color: var(--global-color-alert);
}

strong {
    font-weight: 700;
}


// TODO  These should be exported styles from a BIT
.title {
  text-align:left;
  margin: var(--global-s4) 0 calc(var(--global-s4)/2);
  ${breakpoint.mobile`
    margin: var(--global-s4) 0 calc(var(--global-s4)/2);
  `};
}

.tiny {
  font-weight: 200;
  font-size: 14px;
}

}


`

export { BaseStyle }
export default BaseStyle
