import styled, {css} from 'styled-components'


export const StyledSecondaryNavigation = styled.div`
    background-color:#0187FF;
    z-index:300;
    display:block;
    width:250px;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    // border-right:1px solid #B5BCC3;

    opacity: 0;
    visibility: hidden;

    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    ${(props) => props.isOpen && css`
        opacity: 1;
        visibility: visible;
        left:300px;
    `}
    

`