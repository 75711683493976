import { EVENTS } from './constants'

const fetchEvents = (params) => ({
    type: EVENTS.FETCH_REQUESTED,
    payload:params,
})

const setEvents = (events) => ({
    type: EVENTS.FETCH_SUCCEEDED,
    payload:events,
})
const setError = (error) => ({
    type: EVENTS.FETCH_FAILED,
    payload:error,
})


const postEvent = (data) => ({
    type: EVENTS.POST_REQUESTED,
    payload:data,
})

const updateEvent = (data) => ({
    type: EVENTS.UPDATE_REQUESTED,
    payload:data,
})

const deleteEvent = (id) => ({
    type: EVENTS.DELETE_REQUESTED,
    payload:id,
})


export {
    fetchEvents,
    setEvents,
    setError,
    postEvent,
    updateEvent,
    deleteEvent,
}





