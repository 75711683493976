import React, { useEffect } from 'react'
import { Link, Route, useLocatio, Routes } from 'react-router-dom'
import Core from '../../core'
import UnderConstruction from '../../views/utils/UnderConstruction'
import useApps from '../../core/hooks/useApps'

/**
 * Define module
 */
export const instance = Core.defineApp({
	name: 'home',
	title: 'Home App',
	path: '/home',
})

/**
 * Define our navigation items
 */
export const navigationItems = [{ title: 'Blog', Icon: <></>, target: `${instance.path}/blog` }]

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { registerApp, setCurrentApp } = useApps()
	const location = useLocation()

	const { path } = instance
	// /** register our application */
	// useEffect(() => {
	//     registerApp(instance);
	//     setCurrentApp(instance.name)
	// }, [])

	return (
		<Routes>
			<Route path={path} element={<UnderConstruction />} />
		</Routes>
	)
}
