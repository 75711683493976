import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded'
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded'
import UnsubscribeRoundedIcon from '@mui/icons-material/UnsubscribeRounded'

const queues = [
	{
		id: 'tremendous',
		icon: PermContactCalendarRoundedIcon,
		title: 'Tremendous',
		children: [
			{
				title: 'tremendous:webhooks',
				target: 'https://share.vidday.com/admin/queues/queue/tremendous%3Awebhooks',
			},
		],
	},

	{
		id: 'stripe',
		icon: PermContactCalendarRoundedIcon,
		title: 'Stripe',
		children: [
			{ title: 'stripe:webhooks', target: 'https://share.vidday.com/admin/queues/queue/stripe%3Awebhooks' },
		],
	},

	{
		id: 'acutrack',
		icon: PermContactCalendarRoundedIcon,
		title: 'Acutrack',
		children: [
			{ title: 'acutrack:orders', target: 'https://share.vidday.com/admin/queues/queue/acutrack%3Aorders' },
			{ title: 'acutrack:artwork', target: 'https://share.vidday.com/admin/queues/queue/acutrack%3Aartwork' },
			{ title: 'acutrack:iso', target: 'https://share.vidday.com/admin/queues/queue/acutrack%3Aiso' },
		],
	},

	{
		id: 'sendgrid',
		icon: PermContactCalendarRoundedIcon,
		title: 'Sendgrid',
		children: [
			{ title: 'sendgrid:webhooks', target: 'https://share.vidday.com/admin/queues/queue/sendgrid%3Awebhooks' },
			{ title: 'sendgrid:email', target: 'https://share.vidday.com/admin/queues/queue/sendgrid%3Aemail' },
		],
	},
]

export default queues
