import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { taxCodeCategoryListSchema } from './normalizr'
import requests from './requests'
import { TAX_CODE_CATEGORIES } from './constants'

/**
 * Users Saga worker
 * Fetch tax code categories
 * @param {*} action 
 */
function* handleFetchTaxCodeCategories(action) {
    try {
        const response = yield call(requests.fetchTaxCodeCategories, action.payload)
        const normalizedData = normalize(response.data.data, taxCodeCategoryListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;
        yield put({ type: TAX_CODE_CATEGORIES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: TAX_CODE_CATEGORIES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a tax code category
 * @param {*} action 
 */
 function* handlePostTaxCodeCategory(action) {
    try {
        const response = yield call(requests.postTaxCodeCategory, action.payload)
        const normalizedData = normalize([response.data.data], taxCodeCategoryListSchema)
        yield put({ type: TAX_CODE_CATEGORIES.POST_SUCCEEDED, payload:normalizedData})
    }
    catch(e){
        yield put({type: TAX_CODE_CATEGORIES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a tax code category
 * @param {*} action 
 */
 function* handleUpdateTaxCodeCategory(action) {
    try {
        const response = yield call(requests.updateTaxCodeCategory, action.payload)
        
        const normalizedData = normalize([response.data.data], taxCodeCategoryListSchema)
        console.log(response.data)
        yield put({ type: TAX_CODE_CATEGORIES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: TAX_CODE_CATEGORIES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a tax code category
 * @param {*} action 
 */
 function* handleDeleteTaxCodeCategory(action) {
    try {
        const response = yield call(requests.deleteTaxCodeCategory, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: TAX_CODE_CATEGORIES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: TAX_CODE_CATEGORIES.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchTaxCodeCategoriesFetch(){
    // while(true){
        yield takeLatest(TAX_CODE_CATEGORIES.FETCH_REQUESTED, handleFetchTaxCodeCategories)
    // }
}

export function* watchUpdateTaxCodeCategory(){
    // while(true){
        yield takeEvery(TAX_CODE_CATEGORIES.UPDATE_REQUESTED, handleUpdateTaxCodeCategory)
    // }
}

export function* watchPostTaxCodeCategory(){
    // while(true){
        yield takeEvery(TAX_CODE_CATEGORIES.POST_REQUESTED, handlePostTaxCodeCategory)
    // }
}

export function* watchDeleteTaxCodeCategory(){
    // while(true){  
        yield takeEvery(TAX_CODE_CATEGORIES.DELETE_REQUESTED, handleDeleteTaxCodeCategory)
    // }
}