import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Core from '../../core'
import UnderConstruction from '../../views/utils/UnderConstruction'
import loadable from '@loadable/component'

const ForgotPassword = loadable(() => import(/* webpackPrefetch: true */ './forms/ForgotPassword'))
const ResetPassword = loadable(() => import(/* webpackPrefetch: true */ './forms/ResetPassword'))
const LoginForm = loadable(() => import(/* webpackPrefetch: true */ './forms/Login'))
const Activate = loadable(() => import(/* webpackPrefetch: true */ './views/Activate'))

/**
 * Define module
 */
export const instance = Core.defineApp({
	name: 'auth',
	title: 'Auth App',
	path: '/auth',
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance
	console.log(path, 'path 2 here')

	return (
		<Routes>
			<Route path="login" element={<LoginForm />} />
			<Route path="activate/:token" element={<Activate />} />
			<Route path="forgot" element={<ForgotPassword />} />
			<Route path="reset/:token" element={<ResetPassword />} />
			{/* <Route index element={<UnderConstruction />} /> */}
		</Routes>
	)
}
