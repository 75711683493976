/** Import our instance config */
// import instance from '../../../../../api/config'

import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const rejectedKeysPUT = ['code', 'countryCode', 'createdAt', 'id', 'updatedAt']

/** 
 * Fetch our regions
 */
const fetchRegions = async (params) => {
    const response = await instance.get('/geo-regions?'+params);
    return response;
}

/**
 * Post a region
 * @param {*} data 
 * @returns 
 */
const postRegion = async (data) => {
    const response = await instance.post('/geo-regions', data);
    return response;
}


/**
 * Update a region
 * @param {*} data 
 * @returns 
 */
 const updateRegion = async (data) => {
    const filtered = reject(data, rejectedKeysPUT);
    const response = await instance.put('/geo-regions/'+data.id, filtered);
    return response;
}

/**
 * Delete a region
 * @param {*} data 
 * @returns 
 */
 const deleteRegion = async (id) => {
    const response = await instance.delete('/geo-regions/'+id);
    return response;
}


const requests = {fetchRegions, postRegion, updateRegion, deleteRegion}

export default requests