const MEDIA = {
    /** Fetch all events (includes filters) */
    FETCH_REQUESTED: 'MEDIA_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'MEDIA_FETCH_SUCCEEDED',
    FETCH_FAILED: 'MEDIA_FETCH_FAILED',

    /** Upload a published media */
    UPLOAD_PUBLISHED_REQUESTED: 'MEDIA_UPLOAD_PUBLISHED_REQUESTED',
    UPLOAD_PUBLISHED_SUCCEEDED:'MEDIA_UPLOAD_PUBLISHED_SUCCEEDED',
    UPLOAD_PUBLISHED_FAILED: 'MEDIA_UPLOAD_PUBLISHED_FAILED',

    /** Track upload progress */
    UPLOAD_PUBLISHED_PROGRESS: 'MEDIA_UPLOAD_PUBLISHED_PROGRESS',

    /** Compress Vidday Media Package */
    /** MOVED TO VIDDAY_PACKAGE */
    // COMPRESS_VIDDAY_PACKAGE_REQUESTED: 'COMPRESS_VIDDAY_PACKAGE_REQUESTED',
    // COMPRESS_VIDDAY_PACKAGE_SUCCEEDED: 'COMPRESS_VIDDAY_PACKAGE_SUCCEEDED',
    // COMPRESS_VIDDAY_PACKAGE_FAILED: 'COMPRESS_VIDDAY_PACKAGE_FAILED',

    /** Encode media object to server */
    POST_REGENERATE_IMAGE_REQUESTED: 'MEDIA_POST_REGENERATE_IMAGE_REQUESTED',
    POST_REGENERATE_IMAGE_SUCCEEDED: 'MEDIA_POST_REGENERATE_IMAGE_SUCCEEDED',
    POST_REGENERATE_IMAGE_FAILED: 'MEDIA_POST_REGENERATE_IMAGE_FAILED',
}

export {MEDIA}