import React from 'react'
import Bool from '../../../../core/widgets/others/Bool'
// import moment from 'moment'
import {
	Icon,
	HStack,
	Flex,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	VStack,
	Divider,
	chakra,
	Box,
} from '@chakra-ui/react'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import formatToDecimalAmount from '../../../../utils/formatToDecimalAmount'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import moment from 'moment-timezone'

/** Sort ascending */
export const sortAsc = (a, b) =>
	moment(a.createdAt, 'DD-MM-YYYY').isBefore(moment(b.createdAt, 'DD-MM-YYYY')) ? -1 : 1

/** Sort descending */
export const sortDesc = (a, b) =>
	moment(a.createdAt, 'DD-MM-YYYY').isBefore(moment(b.createdAt, 'DD-MM-YYYY')) ? 1 : -1

/**
 * Define our Table view columns
 */
const columns = [
	{
		id: 'uuid',
		Header: 'ID',
		accessor: 'uuid',
		Cell: ({ value }) => (
			<a href={`https://share.vidday.com/event/${value}`} target="_blank" title="View Event">
				{value}
			</a>
		),
		width: 65,
	},
	{
		id: 'recipients',
		Header: 'Recipients',
		accessor: 'recipients',
		width: 65,
		Cell: (row) => {
			return <span className="badge">{row.value.length}</span>
		},
	},
	// {
	//     id: 'admin',
	//     Header: 'Admin Name',
	//     accessor: (row) => (row.admin && row.admin.name ? row.admin.name : 'Admin'),
	//     Cell: (row) => (
	//         <a href={`mailto:${row.original.admin.email}`} title={row.original.admin.email}>
	//             {row.original.admin.name}
	//         </a>
	//     ),
	// },
	{
		id: 'contributors',
		Header: 'Clips',
		accessor: 'contributors',
		width: 65,
	},
	{
		id: 'media',
		Header: 'Media',
		accessor: 'media',
		Cell: ({ row }) => <span className="badge">{row.original.media.length}</span>,
		width: 65,
	},
	{
		id: 'renderJobs',
		Header: 'Renders',
		accessor: 'renderJobs',
		width: 65,
		Cell: ({ row }) => {
			//sort object array by dates moment timezone desc
			let jobs = [...row.original.renderJobs]
			jobs = jobs.sort(sortAsc)

			return (
				<HStack spacing="0.25rem">
					<chakra.span className="badge">{jobs.length}</chakra.span>
					{jobs.length > 0 && (
						<Popover>
							<PopoverTrigger>
								<Icon as={HelpRoundedIcon} boxSize="1rem" />
							</PopoverTrigger>
							<PopoverContent maxW="220px" outline="none" _focus={{ boxShadow: 'none' }}>
								<PopoverBody>
									<VStack divider={<Divider borderStyle="dotted" borderColor="#ccc" />}>
										{jobs.map((el, i, col) => {
											return (
												<Box w="full" pos="relative">
													{el.preview && (
														<chakra.span
															d="flex"
															pos="absolute"
															top="20px"
															left="-58px"
															boxSizing="content-box"
															borderWidth="2px"
															borderColor="white"
															alignItems="center"
															justifyContent="center"
															borderRadius="full"
															backgroundColor="link"
															color="white"
															fontWeight="400"
															fontSize="0.675rem"
															px="0.5rem">
															Preview
														</chakra.span>
													)}

													<chakra.span
														d="flex"
														alignItems="center"
														justifyContent="center"
														pos="absolute"
														top="-2px"
														left="-26px"
														boxSizing="content-box"
														borderWidth="2px"
														borderColor="white"
														w="20px"
														h="20px"
														borderRadius="full"
														backgroundColor="gray.700"
														color="white"
														fontWeight="bold"
														fontSize="0.75rem">
														{col.length - i}
													</chakra.span>

													<Flex w="full" justify="space-between">
														<Flex>Created:</Flex>
														<Flex>{moment(el.createdAt).format('MMM Do, ha')}</Flex>
													</Flex>
													<Flex w="full" justify="space-between">
														<Flex>Dispatched</Flex>
														<Flex>
															{el.dispatched ? (
																<Icon
																	boxSize="1rem"
																	as={CheckCircleRoundedIcon}
																	color="green"
																/>
															) : (
																<Icon
																	boxSize="1rem"
																	as={CancelRoundedIcon}
																	color="red"
																/>
															)}
														</Flex>
													</Flex>
													<Flex w="full" justify="space-between">
														<Flex>Job State</Flex>
														<Flex>
															<chakra.strong>{el.jobState || '-'}</chakra.strong>
														</Flex>
													</Flex>
												</Box>
											)
										})}
									</VStack>
									{/* Are you sure you want to have that milkshake? */}
								</PopoverBody>
							</PopoverContent>
						</Popover>
					)}
				</HStack>
			)
		},
	},
	{
		id: 'deadline',
		Header: 'Deadline',
		accessor: 'deadline', // the raw js date object, for sorting
		Cell: (row) => <div>{moment(row.value).format('MMM Do YYYY, ha') || null}</div>,
		width: 140,
	},
	{
		id: 'paid',
		width: 140,
		Header: 'Paid',
		accessor: 'paid', // the raw js date object, for sorting
		Cell: ({ row }) => {
			return (
				<HStack spacing="0.25rem">
					<Bool value={row.original.paid} />
					{row.original.paid && (
						<Popover>
							<PopoverTrigger>
								<Icon as={HelpRoundedIcon} boxSize="1rem" />
							</PopoverTrigger>
							<PopoverContent maxW="220px" outline="none" _focus={{ boxShadow: 'none' }}>
								<PopoverBody>
									<VStack divider={<Divider borderStyle="dotted" borderColor="#ccc" />}>
										{row.original.invoices.map((el) => (
											<Flex w="full" justify="space-between">
												<Flex>{moment(el.createdAt).format('MMM Do, ha')}</Flex>
												<Flex>{formatToDecimalAmount(el.amount, '$', el.currencyCode)}</Flex>
											</Flex>
										))}
									</VStack>
									{/* Are you sure you want to have that milkshake? */}
								</PopoverBody>
							</PopoverContent>
						</Popover>
					)}
				</HStack>
			)
		},
	},

	{
		id: 'occasion',
		Header: 'Occasion',
		accessor: 'occasion',
		className: 'occasion',
		width: 100,
	},
	{
		id: 'years',
		Header: 'Years',
		accessor: 'years',
		width: 65,
	},

	{
		id: 'length',
		Header: 'Length',
		accessor: 'length',
		width: 65,
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ row }) => <span className={'status ' + row.value}></span>,
		width: 65,
	},
]

export default columns
