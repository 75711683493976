const PRODUCTS = {
    /** Fetch all products (includes filters) */
    FETCH_REQUESTED: 'PRODUCTS_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'PRODUCTS_FETCH_SUCCEEDED',
    FETCH_FAILED: 'PRODUCTS_FETCH_FAILED',

    /** Post an product */
    POST_REQUESTED: 'PRODUCT_POST_REQUESTED',
    POST_SUCCEEDED:'PRODUCT_POST_SUCCEEDED',
    POST_FAILED: 'PRODUCT_POST_FAILED',

    /** Update an product */
    UPDATE_REQUESTED: 'PRODUCT_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'PRODUCT_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'PRODUCT_UPDATE_FAILED',

    /** Delete an product */
    DELETE_REQUESTED: 'PRODUCT_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'PRODUCT_DELETE_SUCCEEDED',
    DELETE_FAILED: 'PRODUCT_DELETE_FAILED',
}

export {PRODUCTS}