
import { normalize, schema } from 'normalizr'


const themeCategorySchema = new schema.Entity(
	'themeCategories',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

const themeCategoryListSchema = new schema.Array(themeCategorySchema)

export {themeCategoryListSchema}