
import { normalize, schema } from 'normalizr'


const regionSchema = new schema.Entity(
	'regions',
	{},
	{
		idAttribute: (data) => data.code,
	}
)

// Represent all related data coming through the fetch request when loading the user

const regionListSchema = new schema.Array(regionSchema)

export {regionListSchema}