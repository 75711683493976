import React from 'react'
import styled, { css } from 'styled-components'
import ProfileForm from '../../forms/Profile'
import RoleForm from '../../forms/Role'

const Edit = ({ row }) => {
	console.log(row, 'row ')
	return (
		<div>
			<RoleForm data={{ id: row.original.id, role: row.original.role }} />
			<ProfileForm data={row.original.profile} />
		</div>
	)
}
export default Edit
