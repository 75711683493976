import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
import productPriceSchema from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
// import Input from '../../../../../../core/widgets/form/input'
import Select from '../../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
// import Button from '../../../../../../core/widgets/buttons/Button'
import { updateProductPrice, postProductPrice } from '../../api/actions'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import schemaProfile from './schema'
import { updateUserProfile } from '../../../profiles/api/actions'
import { Heading, Button, Flex, VStack, Box } from '@chakra-ui/react'

import Input from '../../../../../../ui/molecules/Input'
import PhoneNumber from '../../../../../../ui/molecules/PhoneNumber'

/**
 * Retrieve countries from state and turn into array
 * @param {array} collection
 * @returns
 */
const getCountries = (s) => {
	var items = s.entities.countries.data || {}
	let collection = []
	collection = Object.values(items)
	return collection
}

/**
 * Map array of countries  to list of options for the Select
 * @param {array} collection
 * @returns
 */
const mapCountries = (collection) => {
	let result = []
	collection.forEach((el, i) => {
		result.push({ value: el.alpha2Code, label: el.name, prefix: `+${el.callingCodes[0]}`, flag: el.flag })
	})
	result.sort((a, b) => a.label?.localeCompare(b.label))
	return result
}

const ProfileForm = ({ data, onSubmitted }) => {
	const dispatch = useDispatch()

	/** History hook */
	const navigate = useNavigate()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaProfile),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		/** Construct our new object */
		const model = { ...data, ...values }
		console.log('profile', model, data)
		if (model.id) {
			dispatch(updateUserProfile(model))
		}

		//trigger submit callback if any
		onSubmitted != undefined && onSubmitted()
	}

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	const countryOptions = useSelector((s) => getCountries(s))
	const [countries, setCountries] = useState([])

	/** Set the countries on mount */
	useEffect(() => {
		if (countryOptions && countryOptions.length > 0 && countries.length == 0) {
			const r = mapCountries(countryOptions)
			setCountries(r)
		}
	}, [countryOptions])

	console.log(errors, 'errors profile', countries)
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box w="full" backgroundColor="#F2F6FA" borderRadius="md" p="1rem" mb="1rem">
				<Flex justify="space-between" alignItems="center">
					<Flex>
						<Heading as="h4" size="sm">
							Profile Form
						</Heading>
					</Flex>
					<Flex>
						<Button type="submit" disabled={formState.isSubmitting} maxH="2rem" size="sm">
							Save
						</Button>
					</Flex>
				</Flex>
				<VStack spacing="1rem" w="full">
					<Input control={control} errors={errors} name="firstName" label="First Name" />
					<Input control={control} errors={errors} name="lastName" label="Last Name" />

					{countries && countries.length > 0 && (
						<PhoneNumber
							control={control}
							errors={errors}
							name="sms"
							label="Mobile SMS"
							placeholder="Enter phone number"
							countries={countries}
							setError={setError}
							clearErrors={clearErrors}
						/>
					)}
				</VStack>
			</Box>
			{/* <InlineItems
				title="Profile Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Input
					size="small"
					control={control}
					name="firstName"
					id="firstName"
					variant="filled"
					label="First Name"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="lastName"
					id="lastName"
					variant="filled"
					label="Last Name"
					errors={errors}
				/>
				<Input
					size="small"
					control={control}
					name="sms"
					id="sms"
					variant="filled"
					label="SMS / Phone number"
					errors={errors}
				/>
			</InlineItems> */}
		</form>
	)
}

ProfileForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default ProfileForm
