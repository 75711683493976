import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { languageListSchema } from './normalizr'
import requests from './requests'
import { LANGUAGES } from './constants'

/**
 * Saga worker
 * Fetch Languages
 * @param {*} action 
 */
function* handleFetchLanguages(action) {
    try {
        const response = yield call(requests.fetchLanguages, action.payload)
        
        const normalizedData = normalize(response.data.data, languageListSchema)
        const data = {...response.data, ...normalizedData}
        delete data.data;


        yield put({ type: LANGUAGES.FETCH_SUCCEEDED, payload:data })
    }
    catch(e){
        yield put({type: LANGUAGES.FETCH_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Post a language
 * @param {*} action 
 */
 function* handlePostLanguage(action) {
    try {
        const response = yield call(requests.postLanguage, action.payload)
        yield put({ type: LANGUAGES.POST_SUCCEEDED, payload:response })
    }
    catch(e){
        yield put({type: LANGUAGES.POST_FAILED, payload:e })
    }
}


/**
 * Saga worker
 * Update a language
 * @param {*} action 
 */
 function* handleUpdateLanguage(action) {
    try {
        const response = yield call(requests.updateLanguage, action.payload)
        
        const normalizedData = normalize([response.data.data], languageListSchema)
        
        yield put({ type: LANGUAGES.UPDATE_SUCCEEDED, payload:normalizedData })
    }
    catch(e){
        yield put({type: LANGUAGES.UPDATE_FAILED, payload:e })
    }
}

/**
 * Saga worker
 * Update a language
 * @param {*} action 
 */
 function* handleDeleteLanguage(action) {
    try {
        const response = yield call(requests.deleteLanguage, action.payload)
        // const normalizedData = normalize(response.data, adminUserListSchema)
        yield put({ type: LANGUAGES.DELETE_SUCCEEDED, payload:response.data })
    }
    catch(e){
        yield put({type: LANGUAGES.DELETE_FAILED, payload:e })
    }
}


/**
 * Users Saga Watcher
 */
export function* watchLanguagesFetch(){
    // while(true){
        yield takeLatest(LANGUAGES.FETCH_REQUESTED, handleFetchLanguages)
    // }
}

export function* watchUpdateLanguage(){
    // while(true){
        yield takeEvery(LANGUAGES.UPDATE_REQUESTED, handleUpdateLanguage)
    // }
}

export function* watchPostLanguage(){
    // while(true){
        yield takeEvery(LANGUAGES.POST_REQUESTED, handlePostLanguage)
    // }
}

export function* watchDeleteLanguage(){
    // while(true){  
        yield takeEvery(LANGUAGES.DELETE_REQUESTED, handleDeleteLanguage)
    // }
}