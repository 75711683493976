import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from '../../../../core/reducers/formState'


const initialState = {
	data: {
		email: '',
	},
}

/** This reducer allows us to retrieve the email address stored by the login form so it can be retrieved by the register form if the user does not exist. */

const rootSlice = createSlice(
	combineFormState('LOGIN_USER', {
		name: 'forms.Login',
		initialState,
		reducers: {
			reset: (state) => initialState,
			updateEmail: (state, action) => {
				state.data.email = action.payload
			},
		},
	})
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const { reset, updateEmail } = rootSlice.actions
