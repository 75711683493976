const PRODUCT_PRICES = {
    /** Fetch all product price prices (includes filters) */
    FETCH_REQUESTED: 'PRODUCT_PRICES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'PRODUCT_PRICES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'PRODUCT_PRICES_FETCH_FAILED',

    /** Post a product price */
    POST_REQUESTED: 'PRODUCT_PRICE_POST_REQUESTED',
    POST_SUCCEEDED:'PRODUCT_PRICE_POST_SUCCEEDED',
    POST_FAILED: 'PRODUCT_PRICE_POST_FAILED',

    /** Update a product price */
    UPDATE_REQUESTED: 'PRODUCT_PRICE_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'PRODUCT_PRICE_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'PRODUCT_PRICE_UPDATE_FAILED',

    /** Delete a product price */
    DELETE_REQUESTED: 'PRODUCT_PRICE_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'PRODUCT_PRICE_DELETE_SUCCEEDED',
    DELETE_FAILED: 'PRODUCT_PRICE_DELETE_FAILED',
}

export {PRODUCT_PRICES}