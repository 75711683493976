const TAX_CODE_CATEGORIES = {
    /** Fetch all tax code categories (includes filters) */
    FETCH_REQUESTED: 'TAX_CODE_CATEGORIES_FETCH_REQUESTED',
    FETCH_SUCCEEDED:'TAX_CODE_CATEGORIES_FETCH_SUCCEEDED',
    FETCH_FAILED: 'TAX_CODE_CATEGORIES_FETCH_FAILED',

    /** Post an tax code category */
    POST_REQUESTED: 'TAX_CODE_CATEGORY_POST_REQUESTED',
    POST_SUCCEEDED:'TAX_CODE_CATEGORY_POST_SUCCEEDED',
    POST_FAILED: 'TAX_CODE_CATEGORY_POST_FAILED',

    /** Update an tax code category */
    UPDATE_REQUESTED: 'TAX_CODE_CATEGORY_UPDATE_REQUESTED',
    UPDATE_SUCCEEDED: 'TAX_CODE_CATEGORY_UPDATE_SUCCEEDED',
    UPDATE_FAILED: 'TAX_CODE_CATEGORY_UPDATE_FAILED',

    /** Delete an tax code category */
    DELETE_REQUESTED: 'TAX_CODE_CATEGORY_DELETE_REQUESTED',
    DELETE_SUCCEEDED: 'TAX_CODE_CATEGORY_DELETE_SUCCEEDED',
    DELETE_FAILED: 'TAX_CODE_CATEGORY_DELETE_FAILED',
}

export {TAX_CODE_CATEGORIES}