import * as Yup from 'yup'

const schemaProfile = Yup.object().shape({
	firstName: Yup.string().notRequired(),
	lastName: Yup.string().notRequired(),
	language: Yup.string().notRequired().default('EN'),
	sms: Yup.string()
		.matches(/[0-9]{9,15}/, { message: 'Phone number is not valid.', excludeEmptyString: true })
		.notRequired()
		.nullable(true)
		.default(null)
		.transform((v, o) => (o === '' ? null : v)),
	smsCountryCode: Yup.string().when('sms', {
		is: (val) => val != '' && val != null,
		then: (schema) => schema.required(),
		otherwise: (schema) =>
			schema
				.notRequired()
				.nullable(true)
				.default(null)
				.transform((v, o) => (o === '' ? null : v)),
	}),
})

export default schemaProfile
