import React from 'react' 
import {StyledDateFormat} from './styles'
import moment from 'moment'

const DateFormat = (props) => {
    const {value, ...rest} = props

    let date = value ? moment(value).format('MMM Do YYYY, ha') : '-'
   
    return  (
        <StyledDateFormat {...rest}>
            {date}
        </StyledDateFormat>
    )
}


export default DateFormat