import React from 'react'

import { useDispatch } from 'react-redux'

/** Table components (react-table) */
import { useTable, useSortBy, usePagination, useRowSelect, useExpanded } from 'react-table'

/** Table components (local) */
import FactorySubRow from '../FactorySubRow'

/** Custom hooks */
import useInterfacePanel from '../../../../interface/hooks/useInterfacePanel'

/** Import Filters */
import FilterItemPerView from '../../filters/FilterItemPerView'
import FilterPageNumber from '../../filters/FilterPageNumber'
import FilterSettingButton from '../../filters/FilterSettingButton'
import VerticalDivider from '../../filters/VerticalDivider'
import ButtonEditColumns from '../../buttons/ButtonEditColumns'
import ResultsParagraph from '../../others/ResultsParagraph'
import ButtonExport from '../../buttons/ButtonExport'
import SelectedRowActions from '../../others/SelectedRowsActions'
import { StyledTable } from '../styles'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import { FiltersWrapper, HR } from '../../styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTableContext } from '../../../../contexts/TableProvider'
import { Flex, HStack } from '@chakra-ui/react'
import { useSearchEngineContext } from '../../../../contexts/SearchEngineProvider'

const TableResults = () => {
	/** Retrieve the current results from the search Engine */
	const { state } = useSearchEngineContext()

	const {
		selectedFlatRows,
		state: { selectedRowIds },
	} = useTableContext()

	console.log('<TableResults /> : state: ', state)

	return (
		<Flex w="full" justifyContent="space-between" py="1rem">
			<Flex>
				<ResultsParagraph count={state.count} />
				{/* //collection.length */}
			</Flex>
			<Flex>
				<SelectedRowActions selectedIds={selectedRowIds} selectedRows={selectedFlatRows} />
			</Flex>
		</Flex>
	)
}

export default TableResults
