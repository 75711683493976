import { USER_PROFILES } from './constants'

const updateUserProfile = (data) => ({
    type: USER_PROFILES.UPDATE_REQUESTED,
    payload:data,
})


export {
    updateUserProfile,
}





