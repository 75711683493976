import React from 'react' 
import useSearchEngine from './hooks/useSearchEngine'
import buildEngineSlice from './reducers/searchEngine'
import {acceptedKeys, extractKeys, serialize} from './utils/searchEngine'

/** Export search engine related features */
export {
    useSearchEngine, 
    buildEngineSlice,
    acceptedKeys, 
    extractKeys, 
    serialize,
}

/************************************
 * MILESTONES :  
 * A. Render a tree of the applications and modules available to the user.
 * B. Sync the apps/modules and their corresponding redux state with the redux store.
 * B. Create/define actions to interact with the redux store.
 * C. Be able to retrieve a list of all existing modules (authorized and unauthorized).
 * 
 * 
 * 1. Be able to register/unregister an application (for the user)
 * 2. Be able to attach/detatch a module to/from an app (for the user)
 * 3. ✅ Helper to be able to define an app or a module 
 * 
 ***********************************/




/************************************
 * THIS FILE PROFILE HELPERS TO HELP 
 * DEFINING AND ACCESSING MODULES
 ***********************************/
const _validateApp = (definition) => {
     /** Ensure we passed a definition... */
     if(!definition) throw new Error('You need to pass a module definition')
     /** Ensure we have a module name */
     if(!definition.name) throw new Error('You need to pass a module definition')
     /** Ensure we have a title */
     if(!definition.title) throw new Error('You need to pass a title to the module')
     /** Ensure we have a path */
     if(!definition.path) throw new Error('You need to pass a title to the module')
    
     return definition
}

/**
 * Allow us to ensure that all required props have been defined for an app definition. 
 * @param {object} definition 
 */
export const defineApp = (definition) => {
    /** Make sure the app passed in has required props */
    _validateApp(definition)
    /** If it's an app, make sure there is no parent defined. */
    if(definition.parent) throw new Error('If you need to pass a parent, use defineModule instead of defineApp.')
    /** Then, we simply return the original definition */
    return definition;
}

/**
 * Inherit the same rules of @defineApp
 * It also add a rule for a parent prop, required as a module. 
 * @param {object} definition 
 */
export const defineModule = (definition) => {
    /** A module is the same as an app, but requires a parent */
    _validateApp(definition)
    /** Ensure we have a parent */
    if(!definition.parent) throw new Error('You need to specify a parent module, when using defineModule.')
    /** Then, we simply return the original definition */
    return definition;
}

/**
 * Allow us to ensure that all required props have been defined. 
 * @param {object} definition 
 */
export const combineApps = (app = {}, modules = []) => {
    /** Ensure we specified a parent */
    if(!app) throw new Error('You need to provide the parent app')
    /** Ensure we passed a definition... */
    if(modules.length <= 0) throw new Error('You need to at least pass one module definition to combine modules.')
    /** Create immutable object */
    const output = { ...app, children: modules }
    /** Then, we simply return  */
    return output;
}




// const Core = {
//     defineApp: defineApp,
//     defineModule: defineModule,
//     combineApps:combineApps,
// }


const apps = [];

/* **************************************
 *  Define Accessor
 * ************************************ */

/**
 * Define Accessor
 * @function
 */
const Core = ((config) => {

    return {
        defineApp: defineApp,
        defineModule:defineModule,
        combineApps:combineApps,
    }
})()

Core.get = () => {
    return apps;
}

/* **************************************
 *  Export Models Getter as default
 * ************************************ */

export default Core