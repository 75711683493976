/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { Component } from 'react'
import loadable from '@loadable/component'

/* **************************************
 *  VIDDAY LIBS
 * ************************************ */

import Loader from '@bit/vidday.vidday-ui.ui.loader'
import PageInfoWithBG from '@bit/vidday.vidday-ui.interface.page-info-with-bg'

/* **************************************
 *  COMPONENT
 * ************************************ */

export class PageLoader extends Component {
	render() {
		return (
			<PageInfoWithBG layout="search">
				<Loader strokeColor="#0587ff" />
			</PageInfoWithBG>
		)
	}
}

export default PageLoader
