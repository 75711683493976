import React from 'react' 
import {InlineItemsWrapper, InlineItem} from './styles'



/**
* Component rendering a box with header title and can render a list of items to be inlined (sort of like a table) or simply pass down children
* @param {*}
* @returns 
*/
const InlineItems = ({title, error, list, children, actions, ...rest}) => {

const listItems = list.map((el, i) => {
    return  <InlineItem key={i}>
                <div className="left">{el.name}</div>
                <div className="right">{el.content}</div>
            </InlineItem>
})

return (
    <InlineItemsWrapper error={error}>
        {title && <h3 className={actions? 'withActions' : ''}>
                {title} 
                {actions && <div className="right">{actions()}</div>}
            </h3>}
        {children}
        {list && list.length > 0 && listItems}
    </InlineItemsWrapper>
)
}

InlineItems.defaultProps = {
    title:'',
    list: [],
    error:false,
    actions:null,
}

export default InlineItems;