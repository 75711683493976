/** Import our instance config */
// import instance from '../../../../../api/config'
import axios from 'axios'
import config from '../../../../../../config.public'
import { pick, reject } from '../../../../../core/utils/index'
/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

// const acceptedKeys = ['slug', 'description', 'duration', 'name', 'group']
const acceptedKeysPUT = ['']
const rejectedKeysPUT = [
	'alpha2Code',
	'alpha3Code',
	'createdAt',
	'updatedAt',
	'regions',
	'defaultCurrency',
	'defaultLanguage',
]

/**
 * Fetch our countries
 */
const fetchCountries = async (params) => {
	const response = await instance.get('/geo-countries?' + 'sortBy=name' + params)
	return response
}

/**
 * Post a country
 * @param {*} data
 * @returns
 */
const postCountry = async (data) => {
	const filtered = reject(data, rejectedKeysPUT)
	const response = await instance.post('/geo-countries', filtered)
	return response
}

/**
 * Update a country
 * @param {*} data
 * @returns
 */
const updateCountry = async (data) => {
	const filtered = reject(data, rejectedKeysPUT)
	const response = await instance.put('/geo-countries/' + data.alpha2Code, filtered)
	return response
}

/**
 * Delete a country
 * @param {*} data
 * @returns
 */
const deleteCountry = async (data) => {
	const response = await instance.delete('/geo-countries/' + data.alpha2Code)
	return response
}

const requests = { fetchCountries, postCountry, updateCountry, deleteCountry }

export default requests
