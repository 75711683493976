export default {
	transparent: 'transparent',
	current: 'currentColor',
	black: '#000000',
	white: '#FFFFFF',
	light: '#f3f4f8',
	dark: '#263340',
	link: '#0066CC',

	whiteAlpha: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.06)',
		200: 'rgba(255, 255, 255, 0.08)',
		300: 'rgba(255, 255, 255, 0.16)',
		400: 'rgba(255, 255, 255, 0.24)',
		500: 'rgba(255, 255, 255, 0.36)',
		600: 'rgba(255, 255, 255, 0.48)',
		700: 'rgba(255, 255, 255, 0.64)',
		800: 'rgba(255, 255, 255, 0.80)',
		900: 'rgba(255, 255, 255, 0.92)',
	},

	blackAlpha: {
		50: 'rgba(0, 0, 0, 0.04)',
		100: 'rgba(0, 0, 0, 0.06)',
		200: 'rgba(0, 0, 0, 0.08)',
		300: 'rgba(0, 0, 0, 0.16)',
		400: 'rgba(0, 0, 0, 0.24)',
		500: 'rgba(0, 0, 0, 0.36)',
		600: 'rgba(0, 0, 0, 0.48)',
		700: 'rgba(0, 0, 0, 0.64)',
		800: 'rgba(0, 0, 0, 0.80)',
		900: 'rgba(0, 0, 0, 0.92)',
	},

	red: {
		50: '#FCEAE8',
		100: '#F7C5BF',
		200: '#F29F97',
		300: '#ED7A6E',
		400: '#E85445',
		500: '#E32F1C',
		600: '#B62516',
		700: '#881C11',
		800: '#5B130B',
		900: '#2D0906',
	},

	orange: {
		50: '#FFF0E5',
		100: '#FFD4B8',
		200: '#FFB88A',
		300: '#FF9D5C',
		400: '#FF812E',
		500: '#FF6600',
		600: '#CC5100',
		700: '#993D00',
		800: '#662900',
		900: '#331400',
	},
	yellow: {
		50: '#FFF5E5',
		100: '#FFE4B8',
		200: '#FFD28A',
		300: '#FFC05C',
		400: '#FFAF2E',
		500: '#FF9D00',
		600: '#CC7E00',
		700: '#995E00',
		800: '#663F00',
		900: '#331F00',
	},
	green: {
		50: '#EAFAF1',
		100: '#C5F2D8',
		200: '#9FEABF',
		300: '#7AE1A5',
		400: '#54D98C',
		500: '#2FD073',
		600: '#25A75C',
		700: '#1C7D45',
		800: '#13532E',
		900: '#092A17',
	},
	teal: {
		50: '#ECF9F7',
		100: '#C9EEE9',
		200: '#A6E2DA',
		300: '#84D7CC',
		400: '#61CCBD',
		500: '#3EC1AF',
		600: '#329A8C',
		700: '#257469',
		800: '#194D46',
		900: '#0C2723',
	},
	blue: {
		50: '#E5F0FF',
		100: '#B8D6FF',
		200: '#8ABBFF',
		300: '#5CA1FF',
		400: '#2E86FF',
		500: '#006BFF',
		600: '#0056CC',
		700: '#004099',
		800: '#002B66',
		900: '#001533',
	},
	cyan: {
		50: '#E5F6FF',
		100: '#B8E7FF',
		200: '#8AD7FF',
		300: '#5CC7FF',
		400: '#2EB7FF',
		500: '#00A8FF',
		600: '#0086CC',
		700: '#006599',
		800: '#004366',
		900: '#002233',
	},
	purple: {
		50: '#F5EBF9',
		100: '#E3C8EF',
		200: '#D1A4E5',
		300: '#BF81DA',
		400: '#AD5DD0',
		500: '#9B39C6',
		600: '#7C2E9E',
		700: '#5D2277',
		800: '#3E174F',
		900: '#1F0B28',
	},
	pink: {
		50: '#FFE5ED',
		100: '#FFB8CE',
		200: '#FF8AAE',
		300: '#FF5C8F',
		400: '#FF2E6F',
		500: '#FF0050',
		600: '#CC0040',
		700: '#990030',
		800: '#660020',
		900: '#330010',
	},
	gray: {
		50: '#EFF2F5',
		100: '#D2DBE4',
		200: '#B6C4D3',
		300: '#99ADC2',
		400: '#7D96B0',
		500: '#607F9F',
		600: '#4D667F',
		700: '#3A4C5F',
		800: '#263340',
		900: '#131920',
	},

	devtools: {
		lightBg: '#7c7c7c',
		darkBg: 'gray.700',
	},
}
