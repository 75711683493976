import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Route, Routes } from 'react-router-dom'
import Core, { buildEngineSlice } from '../../../../core'
import UnderConstruction from '../../../../views/utils/UnderConstruction'
import { fetchCountries } from '../countries/api/actions'
import ListView from './List'

/**
 * Define module
 */
export const instance = Core.defineModule({
	name: 'core.internationalization.regions',
	title: 'Regions',
	path: '/core/international/regions',
	parent: 'core.internationalization',
})

export const regionsSlice = buildEngineSlice({
	sliceName: 'regions',
	actionPrefix: 'REGIONS',
	initialState: {
		pageSize: 20,
		sortBy: 'countryCode',
	},
})

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchCountries())
	}, [])

	return (
		<Routes>
			<Route index element={<ListView />} />
		</Routes>
	)
}
