import styled, {css} from 'styled-components'

export const StyledModal = styled.div`
    padding:22px;
    background-color:rgba(255,255,255,0.94);
    z-index:2000;
    display:block;
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    top:0;
    top:-100%;
    opacity: 0;
    visibility: hidden;

    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    ${(props) => props.isOpen && css`
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        
        opacity: 1;
        visibility: visible;
        right:0;
        bottom:0;
        top:0;
        left:0;
    `}
    

`