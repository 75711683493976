import React from 'react'
import Core from '../core'

/**
 * Import all modules definition here.
 */
import { instance as auth } from './auth'
import { instance as core } from './core'
import { instance as vidday } from './vidday.app'
import { instance as home } from './vidday.home'
import { instance as user } from './user'

/**
 * Bundle modules definition together so
 * it can be imported from other places.
 */
const bundle = [auth, core, vidday, home, user]

console.log(JSON.stringify(bundle, null, 2), 'bundle generated of apps.')

export default bundle
