import { mode } from '@chakra-ui/theme-tools'

const Menu = {
	baseStyle: (props) => {
		return {
			list: {},
			item: {
				color: 'gray.800',
			},
		}
	},
}

export default Menu
