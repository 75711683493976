import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import IconButton from '../../../../../../core/widgets/buttons/ButtonIcon'
import ButtonIcon from '../../../../../../core/widgets/buttons/ButtonIcon'
import Button from '../../../../../../core/widgets/buttons/Button'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import SubRowHeader from '../../../../../../core/widgets/others/SubRowHeader'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import { useInterfacePanel } from '../../../../../../interface'
// import {deleteUser} from '../../api/actions'
import DeleteModal from '../../../../../../core/widgets/modals/DeleteModal'
import { Button as ChakraButton, HStack } from '@chakra-ui/react'
import { resendVerificationEmail, updateUser, updateUserRole } from '../../api/actions'
import { navigate } from '@storybook/addon-links'
import configPublic from '../../../../../../../config.public'

const Actions = ({ row, goToTab }) => {
	const dispatch = useDispatch()

	const { openPanel } = useInterfacePanel()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)

	const displayDeleteModal = () => {
		setIsDeleteOpen(true)
		openPanel('modal')
	}

	const onClose = () => {
		setIsDeleteOpen(false)
	}

	const onDelete = () => {
		/** Delete the entity here from db */
		// dispatch(deleteUser(row.original.id))
		onClose()
	}

	const resendActivationEmail = () => {
		const email = row.original.email
		dispatch(resendVerificationEmail(email))
	}
	const setAsAdmin = () => {
		const data = { id: row.original.id, role: 'admin' }
		dispatch(updateUserRole(data))
	}

	const impersonateUser = () => {
		const target = `${configPublic.app.host}/v2/dashboard?impersonateUserId=${row.original.id}`
		window.open(target, '_blank')
	}

	return (
		<div>
			<SubRowHeader>
				<p>
					<span className="badge">{row.original.id}</span>{' '}
					{row.original.profile && row.original.profile.firstName + ' ' + row.original.profile.lastName}
				</p>
				<div className="actions">
					<ButtonIcon revert onClick={impersonateUser}>
						<AspectRatioIcon fontSize="small" /> Impersonate User
					</ButtonIcon>
				</div>
			</SubRowHeader>

			<HStack>
				<ChakraButton size="sm" maxH="2rem" onClick={() => goToTab('edit')}>
					Edit
				</ChakraButton>
				<ChakraButton size="sm" maxH="2rem" onClick={resendActivationEmail}>
					Resend Activation Email
				</ChakraButton>
				<ChakraButton size="sm" maxH="2rem" colorScheme="red" onClick={() => displayDeleteModal()}>
					Delete
				</ChakraButton>

				<ChakraButton size="sm" maxH="2rem" colorScheme="red" onClick={setAsAdmin}>
					Mark as Admin
				</ChakraButton>
			</HStack>

			{isDeleteOpen && <DeleteModal name="User" onDelete={onDelete} onClose={onClose} />}
		</div>
	)
}
export default Actions
