import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../core/widgets/form/input'
import Select from '../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../core/widgets/form/datetimepicker'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../core/widgets/buttons/Button'
import { updateRegion, postRegion } from '../api/actions'
import schemaRegion from './schema'

const getCountries = (s) => {
	var items = s.entities.countries.data || {}
	let collection = []
	collection = _.values(items)
	return collection
}

const RegionForm = ({ data }) => {
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()
	const countries = useSelector((s) => getCountries(s))
	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaRegion),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		console.log(data)
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.id) {
			dispatch(updateRegion(model))
		} else {
			dispatch(postRegion(model))
		}
	}

	const countryOptions = []
	countries.forEach((el) => countryOptions.push({ value: el.alpha2Code, label: el.name }))

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Region Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Input
					size="small"
					control={control}
					name="code"
					id="code"
					variant="filled"
					label="Code"
					errors={errors}
					disabled={true}
				/>

				<Select
					control={control}
					margin="normal"
					name="countryCode"
					id="countryCode"
					variant="filled"
					label="Country"
					errors={errors}>
					{countryOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Input
					size="small"
					control={control}
					name="name"
					id="name"
					variant="filled"
					label="Name"
					errors={errors}
				/>

				<Input
					size="small"
					control={control}
					name="abbr"
					id="abbr"
					variant="filled"
					label="Abbr."
					errors={errors}
				/>
			</InlineItems>
		</form>
	)
}

RegionForm.defaultProps = {
	/** Important */
	data: {},
}

RegionForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default RegionForm
