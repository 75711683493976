import React, { useEffect } from 'react' 
import {StyledModal} from './styles'
import {useSelector} from 'react-redux'
import {useRightPanel} from '../RightPanel'
import {useSecondaryNavigation} from '../SecondaryNavigation'

import { createPortal } from 'react-dom';
import usePortal from '../../hooks/usePortal';
import useSSR from 'use-ssr'



/**
 * Hook that intends to retrieve the bottom 
 * Panel information from the reducer
 */
export const useModalPanel = () => {
    const isOpen = useSelector(s => s.interface.panels.modal)
    return {isOpen}
}

/**
 * Bottom Panel Component. 
 * This component is already set as a Portal.
 * @param {*} children 
 * @returns 
 */
const Modal = ({children, ...rest}) => {
    /** Check if we're using browser or server-side rendering */
    const { isServer, isBrowser } = useSSR()
    
    /** Target container to which we want to append to Portal */
    const target = usePortal("app-layout", 'Modal');
    
    /** Retrieve current reducer state for this panel */
    const {isOpen} = useModalPanel()
    

    /** Finally, let's build our portal */
    return isBrowser ? createPortal(
        (
            <StyledModal {...rest}  
                id="Modal" 
                isOpen={isOpen}>
                    {children}
            </StyledModal>
        ),
        target,
    ) : null;
}


export default Modal;