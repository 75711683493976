import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Core from '../../../core'
import UnderConstruction from '../../../views/utils/UnderConstruction'

import { instance as products } from './products'
import { Router as ProductsRoutes } from './products'

import { instance as productPrices } from './productPrices'
import { Router as ProductPricesRoutes } from './productPrices'

import { instance as taxCodeCategories } from './taxCodeCategories'
import { Router as TaxCodeCategoriesRoutes } from './taxCodeCategories'

import { instance as taxCodeRates } from './taxCodeRates'
import { Router as TaxCodeRatesRoutes } from './taxCodeRates'

/**
 * Define module
 */
export const sales = Core.defineModule({
	name: 'core.sales',
	title: 'Sales',
	path: '/core/sales',
	parent: 'core',
})

export const instance = Core.combineApps(sales, [products, productPrices, taxCodeCategories, taxCodeRates])

/**
 * Routes of the Home App module
 */
export const Router = (props) => {
	const { path } = instance

	return (
		<>
			<TaxCodeRatesRoutes />
			<TaxCodeCategoriesRoutes />
			<ProductPricesRoutes />
			<ProductsRoutes />
		</>
	)
}
