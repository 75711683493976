import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'

// type TimeColumn = {
//   start: number;
//   end: number;
//   setValue: (value: string) => void;
//   value: string;
//   exclude?: Array<number>;
//   notShowExclude?: boolean;
// };

const useStyles = createUseStyles(
	{
		control: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		control__time: {
			'cursor': 'pointer',
			'transition': 'opacity 0.5s',
			'&:hover': {
				opacity: 0.5,
			},
		},
		control__svg: {
			stroke: '#0066CC',
		},
		wrapper: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			height: '128px',
			width: '64px',
			overflow: 'hidden',
			userSelect: 'none',
		},
		selector: {
			width: '100%',
			height: '40px',
			backgroundColor: '#0066CC',
			position: 'absolute',
			top: '39px',
			borderRadius: '8px',
		},
		timeWrapper: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			lineHeight: '40px',
			fontSize: '20px',
			transition: 'transform 0.5s',
			paddingTop: '40px',
		},
		time: {
			zIndex: '1',
			color: '#0066CC',
			opacity: '0.5',
			transition: 'color 0.5s',
		},
		selected: {
			color: '#fff',
			opacity: '1',
		},
		disabled: {
			opacity: '0.2 !important',
		},
	},
	{
		name: 'timeit',
	}
)

export const MeridiemColumn = ({ start, end, setValue, value, exclude }) => {
	//: TimeColumn
	const classes = useStyles()

	const [selectorMove, setSelectorMove] = useState(value ? value : 'PM') //<number>

	const timeArray = ['AM', 'PM'] //: (string | number)[]

	useEffect(() => {
		setValue(selectorMove != 'PM' ? 'AM' : 'PM')
	}, [selectorMove])

	const controlBottom = () => {
		let prev = selectorMove
		if (prev !== end) {
			return setSelectorMove('PM')
		} else {
			return setSelectorMove('AM')
		}
	}

	const controlTop = () => {
		let prev = selectorMove
		if (prev !== start) {
			return setSelectorMove('AM')
		} else {
			let endnumber = end
			return setSelectorMove('PM')
		}
	}

	return (
		<div className={classes.control}>
			<div className={classes.control__time} onClick={controlTop}>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M19.9201 15.0499L13.4001 8.52989C12.6301 7.75989 11.3701 7.75989 10.6001 8.52989L4.08008 15.0499"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
						className={classes.control__svg}
					/>
				</svg>
			</div>
			<div className={classes.wrapper}>
				<div className={classes.selector} />
				<div
					className={classes.timeWrapper}
					style={{
						transform: `translateY(-${selectorMove && timeArray.indexOf(selectorMove) * 40}px)`,
					}}>
					{timeArray.map((time) => (
						<div
							key={time}
							className={`${classes.time} ${time === selectorMove ? classes.selected : ''} ${
								exclude && exclude.includes(+time) ? classes.disabled : ''
							}`}>
							{time}
						</div>
					))}
				</div>
			</div>
			<div className={classes.control__time} onClick={controlBottom}>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
						className={classes.control__svg}
					/>
				</svg>
			</div>
		</div>
	)
}
const TimeColumn = ({ start, end, setValue, value, exclude, notShowExclude }) => {
	//: TimeColumn
	const classes = useStyles()

	const [selectorMove, setSelectorMove] = useState(+value ? +value : 0) //<number>

	const timeArray = [] //: (string | number)[]
	for (let time = start; time <= end; time++) {
		if (notShowExclude) !exclude?.includes(time) && timeArray.push(time)
		else timeArray.push(time)
	}

	useEffect(() => {
		let prev = selectorMove
		if (exclude?.includes(prev)) {
			while (exclude?.includes(prev)) {
				prev = prev + 1
				setSelectorMove(prev)
			}
		}
	}, [])

	useEffect(() => {
		setValue(selectorMove.toString().length === 1 ? `0${selectorMove}` : selectorMove.toString())
	}, [selectorMove])

	const controlBottom = () => {
		let prev = selectorMove
		if (prev !== end) {
			if (exclude?.includes(prev + 1)) {
				while (exclude?.includes(prev + 1)) {
					if (prev + 2 > end) {
						return setSelectorMove(start)
					}
					prev = prev + 1
					setSelectorMove(prev + 1)
				}
			} else {
				return setSelectorMove(prev + 1)
			}
		} else {
			return setSelectorMove(start)
		}
	}

	const controlTop = () => {
		let prev = selectorMove
		if (prev !== start) {
			if (exclude?.includes(prev - 1)) {
				while (exclude?.includes(prev - 1)) {
					if (prev - 2 < start) {
						return setSelectorMove(end)
					}
					prev = prev - 1
					setSelectorMove(prev - 1)
				}
			} else {
				return setSelectorMove(prev - 1)
			}
		} else {
			let endnumber = end
			if (exclude?.includes(end)) {
				while (exclude?.includes(endnumber - 1)) {
					endnumber = endnumber - 1
					setSelectorMove(endnumber - 1)
				}
			} else {
				return setSelectorMove(end)
			}
		}
	}

	return (
		<div className={classes.control}>
			<div className={classes.control__time} onClick={controlTop}>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M19.9201 15.0499L13.4001 8.52989C12.6301 7.75989 11.3701 7.75989 10.6001 8.52989L4.08008 15.0499"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
						className={classes.control__svg}
					/>
				</svg>
			</div>
			<div className={classes.wrapper}>
				<div className={classes.selector} />
				<div
					className={classes.timeWrapper}
					style={{
						transform: `translateY(-${selectorMove && timeArray.indexOf(selectorMove) * 40}px)`,
					}}>
					{timeArray.map((time) => (
						<div
							key={time}
							className={`${classes.time} ${+time === selectorMove ? classes.selected : ''} ${
								exclude && exclude.includes(+time) ? classes.disabled : ''
							}`}>
							{time.toString().length === 1 ? `0${time}` : time}
						</div>
					))}
				</div>
			</div>
			<div className={classes.control__time} onClick={controlBottom}>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
						className={classes.control__svg}
					/>
				</svg>
			</div>
		</div>
	)
}

export default TimeColumn
