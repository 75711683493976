
import axios from 'axios'
import { pick, reject } from '../../../../../core/utils'
import config from '../../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const acceptedKeys = ['zone', 'code', 'name', 'description']
const acceptedKeysPUT = ['']
const rejectedKeysPUT = ['']

/**
 * Fetch our tax code categories
 */
const fetchTaxCodeCategories = async (params) => {
	const response = await instance.get('/tax-code-categories?' + params)
	return response
}

/**
 * Post a tax code category
 * @param {*} data
 * @returns
 */
const postTaxCodeCategory = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.post('/tax-code-categories', filteredData)
	return response
}

/**
 * Update a tax code category
 * @param {*} data
 * @returns
 */
const updateTaxCodeCategory = async (data) => {
	const filteredData = pick(data, acceptedKeys)
	const response = await instance.put('/tax-code-categories/' + data.id, filteredData)
	return response
}

/**
 * Delete a tax code category
 * @param {*} data
 * @returns
 */
const deleteTaxCodeCategory = async (id) => {
	const response = await instance.delete('/tax-code-categories/' + id)
	return response
}

const requests = { fetchTaxCodeCategories, postTaxCodeCategory, updateTaxCodeCategory, deleteTaxCodeCategory }

export default requests
