import React, { useEffect } from 'react'
import { Link, Route, Routes } from 'react-router-dom'

import Core from '../../core'
import { instance as accounts } from './accounts'
import { instance as internationalization } from './internationalization'
import { instance as announcements } from './announcements'
import { instance as promotions } from './promotions'
// import {instance as billing} from './billing'
import { instance as sales } from './sales'
import { instance as tools } from './tools'
import { instance as queues } from './queues'

import UnderConstruction from '../../views/utils/UnderConstruction'

/** Load icons */
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded'
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded'

import { Router as ToolsRouter } from './tools'
import { Router as QueuesRouter } from './queues'
import { Router as PromotionsRouter } from './promotions'
import { Router as AnnouncementsRouter } from './announcements'
import { Router as AccountsRouter } from './accounts'
import { Router as InternationalizationRouter } from './internationalization'
// import {Routes as BillingRoutes} from './billing'
import { Router as SalesRoutes } from './sales'
import useApps from '../../core/hooks/useApps'

/**
 * Define module
 */
export const core = Core.defineApp({
	name: 'core',
	title: 'Core App',
	path: '/core',
})

export const instance = Core.combineApps(core, [
	accounts,
	internationalization,
	announcements,
	promotions,
	sales,
	tools,
	queues,
]) //billing

/**
 * Define our navigation items
 */
export const navigationItems = [
	{ title: 'Accounts', Icon: <PeopleRoundedIcon />, target: `${instance.path}/accounts/users` },
	{ title: 'Promotions', Icon: <LoyaltyRoundedIcon />, target: `${instance.path}/promotions` },
	{ title: 'Announcements', Icon: <NotificationsActiveRoundedIcon />, target: `${instance.path}/announcements` },
	{ title: 'Tools', Icon: <LoyaltyRoundedIcon />, target: `${instance.path}/tools` },
	{ title: 'Queues', Icon: <TrackChangesRoundedIcon />, target: `${instance.path}/queues` },

	// {title:'Billing', Icon:<ReceiptRoundedIcon/>, target:`${instance.path}/billing`, children:[
	//     {title:'Invoices', Icon:null, target:`${instance.path}/billing/invoices`},
	//     {title:'Nexus', Icon:null, target:`${instance.path}/billing/nexus`},
	// ]},
	{
		title: 'Internationalization',
		Icon: <LocationOnRoundedIcon />,
		target: `${instance.path}/international/countries`,
		children: [
			{ title: 'Countries', Icon: null, target: `${instance.path}/international/countries` },
			// { title: 'Regions', Icon: null, target: `${instance.path}/international/regions` },
			// { title: 'Languages', Icon: null, target: `${instance.path}/international/languages` },
			// { title: 'Currencies', Icon: null, target: `${instance.path}/international/currencies` },
		],
	},
	// {
	// 	title: 'Sales',
	// 	Icon: <MonetizationOnRoundedIcon />,
	// 	target: `${instance.path}/sales/products`,
	// 	children: [
	// 		{ title: 'Products', Icon: null, target: `${instance.path}/sales/products` },
	// 		{ title: 'Prices', Icon: null, target: `${instance.path}/sales/prices` },
	// 		{ title: 'Tax Categories', Icon: null, target: `${instance.path}/sales/taxcategories` },
	// 		{ title: 'Tax Rates', Icon: null, target: `${instance.path}/sales/taxrates` },
	// 	],
	// },
]

/**
 * Routes of the Home App module
 */
export const Router = () => {
	const { registerApp, setCurrentApp } = useApps()
	// const { path } = instance
	console.log('countries here (core) ')
	// /** register our application */
	// useEffect(() => {
	// 	registerApp(instance)
	// 	setCurrentApp(instance.name)
	// }, [])

	return (
		<Routes>
			<Route index element={() => <>Index route of Core</>} />
			<Route path="announcements/*" element={<AnnouncementsRouter />} />
			<Route path="promotions/*" element={<PromotionsRouter />} />
			<Route path="accounts/*" element={<AccountsRouter />} />
			<Route path="international/*" element={<InternationalizationRouter />} />
			<Route path="tools/*" element={<ToolsRouter />} />
			<Route path="queues/*" element={<QueuesRouter />} />
		</Routes>
		// // <Switch>
		// //     <Route path={path+`/accounts`} component={UnderConstruction} />
		// //     <Route path={path+`/billing`} component={UnderConstruction} />
		// //     <Route path={path+`/international`} component={UnderConstruction} />
		// //     <Route path={path+`/promotions`} component={UnderConstruction} />
		// //     <Route path={path+`/announcements`} component={UnderConstruction} />
		// //     <Route path={path} component={UnderConstruction} />
		// // </Switch>
		// <>
		// 	{/* <BillingRoutes /> */}
		// 	{/* <AccountsRoutes />
		//     <PromotionsRoutes />
		//     <AnnouncementsRoutes />
		//     <InternationalizationRoutes />
		//     <SalesRoutes /> */}
		// </>
	)
}
