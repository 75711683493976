import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	Box,
	Modal,
	Icon,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

import Calendar from 'react-calendar'
// import '../../../../../../../public/css/react-calendar.css'

import moment from 'moment-timezone'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// import { Timeit } from "react-timeit"
// console.log(Timeit, "timeit")
import Timeit from '../TimeSelector'

const ModalCalendar = ({ value, onChange, onClose, isOpen, useTime, allowPast }) => {
	const formatDate = (val) => moment(val).format(useTime ? 'ddd, MMMM Do YYYY, h:mm a' : 'ddd, MMMM Do YYYY')

	const [tabIndex, setTabIndex] = React.useState(0)

	/** Save copy of the value received as the initial value */
	const [initialValue, setInitialValue] = useState(value)
	/** Format value that was received */
	const [initialFormattedValue, setInitialFormattedValue] = useState(formatDate(value))

	/** Prepare new date selection, using the initial value as default */
	const [newDate, setNewDate] = useState(initialValue)
	/** Format new date selection */
	const [newFormattedDate, setNewFormattedDate] = useState(formatDate(newDate))

	/** Update initial value when date changes */
	useEffect(() => {
		if (value != initialValue) {
			setInitialValue(value)
			setInitialFormattedValue(formatDate(value))
			setNewDate(value)
			setNewFormattedDate(formatDate(value))
		}
	}, [value])

	const handleChange = (val) => {
		if (useTime) {
			/** First, convert to moment so we can manipulate the date */
			const d = moment(val)
			let new_date
			/** If we have an initial value, retrieve the existing time from there */
			if (initialValue) {
				const h = moment(initialValue).hour()
				const m = moment(initialValue).minute()
				new_date = d.hour(h).minute(m)
			} else {
				/** If we didn't have an initial value, set default time to 5pm */
				new_date = d.add(17, 'h')
			}

			setNewDate(new_date.toDate())
			setNewFormattedDate(formatDate(new_date))
		} else {
			setNewDate(val)
			setNewFormattedDate(formatDate(val))
		}
	}

	/**
	 * Callback handler to update the time
	 * @param {*} val
	 */
	const handleTimeChange = (val) => {
		const t = val.split(' ')[0] // 12:54 AM => 12:54
		const h = t.split(':')[0] // 12:54 => 12
		const m = t.split(':')[1] // 12:54 => 54
		const meridiem = val.indexOf('PM') != -1 ? 'PM' : 'AM'

		const d = moment(newDate)
			.hour(meridiem == 'PM' ? parseInt(h) + 12 : parseInt(h))
			.minute(parseInt(m))
		setNewDate(d.toDate())
		setNewFormattedDate(moment(d).format('ddd, MMMM Do YYYY, h:mm a'))
	}

	const calendarProps = {}
	if (!allowPast) calendarProps.minDate = new Date()

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="sm">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Heading size="lg" variant="hero">
							Pick a date
						</Heading>

						<HStack
							p="0.5rem"
							spacing="1rem"
							w="full"
							backgroundColor="#E8ECF0"
							borderRadius="md"
							fontSize="0.875rem"
							color="#6C7A88">
							<Text>Current:</Text>
							<Text fontWeight="bold">{initialFormattedValue}</Text>
						</HStack>

						{initialValue != newDate && (
							<HStack
								p="0.5rem"
								spacing="1rem"
								w="full"
								borderRadius="md"
								borderColor="#E8ECF0"
								borderWidth="2px"
								fontSize="0.875rem">
								<Text>New date:</Text>
								<Text fontWeight="bold">{newFormattedDate}</Text>
							</HStack>
						)}

						<Tabs variant="enclosed" index={tabIndex} onChange={setTabIndex} w="full">
							<TabList>
								<Tab>Date</Tab>
								{useTime && <Tab>Time</Tab>}
							</TabList>
							<TabPanels>
								<TabPanel px="0">
									<Calendar onChange={handleChange} value={newDate} {...calendarProps} />
								</TabPanel>
								{useTime && (
									<TabPanel mx="auto">
										<Timeit
											defaultValue={`${moment(newDate).hour()}:${moment(newDate).minute()}`}
											useMeridiem
											onChange={(val) => handleTimeChange(val)}
										/>
									</TabPanel>
								)}
							</TabPanels>
						</Tabs>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							variant="ghost"
							onClick={() => {
								setNewDate(initialValue)
								onClose()
							}}>
							Cancel
						</Button>
						{useTime && tabIndex == 0 && (
							<Button
								onClick={() => {
									setTabIndex(1)
								}}>
								Select a time
							</Button>
						)}
						{((useTime && tabIndex == 1) || !useTime) && (
							<Button
								onClick={() => {
									setInitialValue(newDate)
									setInitialFormattedValue(formatDate(newDate))
									onChange(newDate)
									onClose()
								}}>
								Save
							</Button>
						)}
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalCalendar
