import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
// import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-styled-flexboxgrid'
// import schemaPromoCode from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../../../../core/widgets/form/input'
import Select from '../../../../../../core/widgets/form/select'
import DateTimePicker from '../../../../../../core/widgets/form/datetimepicker'
import { useDispatch } from 'react-redux'

import moment from 'moment-timezone'
import { timeZonesGeo } from '@bit/vidday.vidday-data.data.timezones-geo'
import MenuItem from '@material-ui/core/MenuItem'
import InlineItems from '../../../../../../core/widgets/others/InlineItems'
import Button from '../../../../../../core/widgets/buttons/Button'
import { updateCountry, postCountry } from '../../api/actions'
import schemaCountry from './schema'

const CountryForm = ({ data }) => {
	const { timezone, starts, ends } = data
	const dispatch = useDispatch()

	/** History hook */
	// const history = useHistory()

	/** Define form configuration and hooks  */
	const {
		control,
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schemaCountry),
	})

	/** Watch changes in form values */
	const values = watch()

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	/** Submit form using provided callback */
	const onSubmit = (values) => {
		console.log(data)
		/** Construct our new object */
		const model = { ...data, ...values }
		if (model.alpha2Code) {
			dispatch(updateCountry(model))
		} else {
			dispatch(postCountry(model))
			reset()
		}
	}

	const currencyOptions = [
		{ value: 'USD', label: 'U.S. Dollar (USD)' },
		{ value: 'CAD', label: 'Canadian Dollar (CAD)' },
		{ value: 'EUR', label: 'Euro (EUR)' },
		{ value: 'GBP', label: 'Pound sterling (GBP)' },
		{ value: 'AUD', label: 'Australian dollars (AUD)' },
	]

	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	// useEffect(() => {
	// 	// Set timezone automatically
	// 	// const autoTimezone = timezone ? timezone : moment.tz.guess(true)
	// 	// // Set deadline automatically
	// 	// const autoStartDate = starts ? starts : moment().add(2, 'days')
	//     // const autoEndDate = ends ? ends : moment().add(2, 'days')
	// 	// // Set initial values
	// 	// setValue('starts', autoStartDate)
	//     // setValue('ends', autoEndDate)
	// 	// setValue('timezone', autoTimezone)
	// }, [timezone, starts, ends])

	// useEffect(() => {
	//console.log("FORM CHANGE",moment(deadline).format(),moment(deadline).tz(values.timezone).format())
	// setValue('starts', moment(starts).tz(values.timezone))
	// setValue('ends', moment(ends).tz(values.timezone))
	// }, [values.timezone])

	/** Register uncontrolled components */
	useEffect(() => {
		// register({ name: 'timezone' }, { required: true })
		// register({ name: 'starts' }, { required: true })
		// register({ name: 'ends' }, { required: true })
	}, [register])

	/** Detect changes */
	const [hasChange, setHasChange] = useState(false)

	useEffect(() => {
		setHasChange(true)
	}, [values])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InlineItems
				title="Country Form"
				actions={() => <Button isSubmitting={status.isFetching || formState.isSubmitting}>Save</Button>}>
				<Select
					control={control}
					margin="normal"
					name="defaultCurrencyCode"
					id="type"
					variant="filled"
					label="Default Currency"
					errors={errors}>
					{currencyOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>

				<Select
					control={control}
					margin="normal"
					name="allowedCurrencies"
					id="type"
					variant="filled"
					multiple={true}
					label="Allowed Currencies"
					errors={errors}>
					{currencyOptions.map((el, i) => (
						<MenuItem key={i} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</Select>
			</InlineItems>
		</form>
	)
}

CountryForm.defaultProps = {
	/** Important */
	data: {},
}

CountryForm.propTypes = {
	/** Callback for the form submit */
	onSubmit: PropTypes.func.isRequired,
	/** Submit text as it should appear */
	submitText: PropTypes.string.isRequired,
}

export default CountryForm
