import { PRODUCTS } from './constants'

const fetchProducts = (params) => ({
    type: PRODUCTS.FETCH_REQUESTED,
    payload:params,
})

const setProducts = (events) => ({
    type: PRODUCTS.FETCH_SUCCEEDED,
    payload:events,
})

const setError = (error) => ({
    type: PRODUCTS.FETCH_FAILED,
    payload:error,
})


const postProduct = (data) => ({
    type: PRODUCTS.POST_REQUESTED,
    payload:data,
})

const updateProduct = (data) => ({
    type: PRODUCTS.UPDATE_REQUESTED,
    payload:data,
})

const deleteProduct = (id) => ({
    type: PRODUCTS.DELETE_REQUESTED,
    payload:id,
})


export {
    fetchProducts,
    setProducts,
    setError,
    postProduct,
    updateProduct,
    deleteProduct,
}





