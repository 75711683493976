const formatToDecimalAmount = (amountInCent = 0, fiatSymbol = '$', fiatCode) => {
	if (amountInCent == 0) return amountInCent.toFixed(2)
	if (fiatSymbol && fiatCode) {
		return `${fiatSymbol}${(amountInCent / 100).toFixed(2)} ${fiatCode}`
	}
	if (fiatSymbol) {
		return `${fiatSymbol}${(amountInCent / 100).toFixed(2)}`
	}
	return (amountInCent / 100).toFixed(2)
}

export default formatToDecimalAmount
